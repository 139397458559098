export const allSuttasPali = [
  ["DN 1", "Brahmajāla"],
  ["DN 2", "Sāmaññaphala"],
  ["DN 3", "Ambaṭṭha"],
  ["DN 4", "Soṇadaṇḍa"],
  ["DN 5", "Kūṭadanta"],
  ["DN 6", "Mahāli"],
  ["DN 7", "Jāliya"],
  ["DN 8", "Mahāsīhanāda"],
  ["DN 9", "Poṭṭhapāda"],
  ["DN 10", "Subha"],
  ["DN 11", "Kevaddha [Kevaṭṭa]"],
  ["DN 12", "Lohicca"],
  ["DN 13", "Tevijja"],
  ["DN 14", "Mahāpadāna"],
  ["DN 15", "Mahānidāna"],
  ["DN 16", "Mahāparinibbāna"],
  ["DN 17", "Mahāsudassana"],
  ["DN 18", "Janavasabha"],
  ["DN 19", "Mahāgovinda"],
  ["DN 20", "Mahāsamaya"],
  ["DN 21", "Sakkapañha"],
  ["DN 22", "Mahāsatipaṭṭhāna"],
  ["DN 23", "Pāyāsi"],
  ["DN 24", "Pātika [Pāthika]"],
  ["DN 25", "Udumbarikasīhanāda [Udumbarika]"],
  ["DN 26", "Cakkavattisīhanāda [Cakkavatti]"],
  ["DN 27", "Aggañña"],
  ["DN 28", "Sampasādanīya"],
  ["DN 29", "Pāsādika"],
  ["DN 30", "Lakkhaṇa"],
  ["DN 31", "Sīgālovāda [Siṅgāla]"],
  ["DN 32", "Āṭānāṭiya"],
  ["DN 33", "Saṅgīti"],
  ["DN 34", "Dasuttara"],
  ["MN 1", "Mūlapariyāya"],
  ["MN 2", "Sabbāsava "],
  ["MN 3", "Dhammadāyāda"],
  ["MN 4", "Bhayabherava"],
  ["MN 5", "Anaṅgaṇa"],
  ["MN 6", "Ākaṅkheyya"],
  ["MN 7", "Vatthūpama [Vattha]"],
  ["MN 8", "Sallekha"],
  ["MN 9", "Sammādiṭṭhi"],
  ["MN 10", "Satipaṭṭhāna"],
  ["MN 11", "Cūḷasīhanāda"],
  ["MN 12", "Mahāsīhanāda"],
  ["MN 13", "Mahādukkhakkhandha"],
  ["MN 14", "Cūḷadukkhakkhandha"],
  ["MN 15", "Anumāna"],
  ["MN 16", "Cetokhila"],
  ["MN 17", "Vanapattha"],
  ["MN 18", "Madhupiṇḍika"],
  ["MN 19", "Dvedhāvitakka"],
  ["MN 20", "Vitakkasaṇṭhāna"],
  ["MN 21", "Kakacūpama"],
  ["MN 22", "Alagaddūpama"],
  ["MN 23", "Vammika"],
  ["MN 24", "Rathavinīta"],
  ["MN 25", "Nivāpa"],
  ["MN 26", "Ariyapariyesanā [Pāsarāsi]"],
  ["MN 27", "Cūḷahatthipadopama"],
  ["MN 28", "Mahāhatthipadopama"],
  ["MN 29", "Mahāsāropama"],
  ["MN 30", "Cūḷasāropama"],
  ["MN 31", "Cūḷagosiṅga"],
  ["MN 32", "Mahāgosiṅga"],
  ["MN 33", "Mahāgopālaka"],
  ["MN 34", "Cūḷagopālaka"],
  ["MN 35", "Cūḷasaccaka"],
  ["MN 36", "Mahāsaccaka"],
  ["MN 37", "Cūḷataṇhāsaṅkhaya"],
  ["MN 38", "Mahātaṇhāsaṅkhaya"],
  ["MN 39", "Mahā-Assapura"],
  ["MN 40", "Cūḷa-Assapura"],
  ["MN 41", "Sāleyyaka"],
  ["MN 42", "Verañjaka"],
  ["MN 43", "Mahāvedalla"],
  ["MN 44", "Cūḷavedalla"],
  ["MN 45", "Cūḷadhammasamādāna"],
  ["MN 46", "Mahādhammasamādāna"],
  ["MN 47", "Vīmaṃsaka"],
  ["MN 48", "Kosambiya"],
  ["MN 49", "Brahmanimantanika"],
  ["MN 50", "Māratajjanīya"],
  ["MN 51", "Kandaraka"],
  ["MN 52", "Aṭṭhakanāgara"],
  ["MN 53", "Sekha"],
  ["MN 54", "Potaliya"],
  ["MN 55", "Jīvaka"],
  ["MN 56", "Upāli"],
  ["MN 57", "Kukkuravatika"],
  ["MN 58", "Abhayarājakumāra"],
  ["MN 59", "Bahuvedanīya"],
  ["MN 60", "Apaṇṇaka "],
  ["MN 61", "Ambalaṭṭhikārāhulovāda"],
  ["MN 62", "Mahārāhulovāda"],
  ["MN 63", "Cūḷamāluṅkya [Cūḷamālukya]"],
  ["MN 64", "Mahāmāluṅkya [Mahāmālukya]"],
  ["MN 65", "Bhaddāli"],
  ["MN 66", "Laṭukikopama"],
  ["MN 67", "Cātumā"],
  ["MN 68", "Naḷakapāna"],
  ["MN 69", "Gulissāni [Goliyāni]"],
  ["MN 70", "Kīṭāgiri"],
  ["MN 71", "Tevijjavacchagotta [Tevijjavaccha]"],
  ["MN 72", "Aggivacchagotta [Aggivaccha]"],
  ["MN 73", "Mahāvacchagotta [Mahāvaccha]"],
  ["MN 74", "Dīghanakha"],
  ["MN 75", "Māgandiya"],
  ["MN 76", "Sandaka"],
  ["MN 77", "Mahāsakuludāyi"],
  ["MN 78", "Samaṇamaṇḍikā [Samaṇamuṇḍika]"],
  ["MN 79", "Cūḷasakuludāyi"],
  ["MN 80", "Vekhanassa [Vekhanasa]"],
  ["MN 81", "Ghaṭīkāra [Ghaṭikāra]"],
  ["MN 82", "Raṭṭhapāla"],
  ["MN 83", "Makhādeva [Maghadeva]"],
  ["MN 84", "Madhurā"],
  ["MN 85", "Bodhirājakumāra"],
  ["MN 86", "Aṅgulimāla"],
  ["MN 87", "Piyajātika"],
  ["MN 88", "Bāhitika"],
  ["MN 89", "Dhammacetiya"],
  ["MN 90", "Kaṇṇakatthala"],
  ["MN 91", "Brahmāyu"],
  ["MN 92", "Sela"],
  ["MN 93", "Assalāyana"],
  ["MN 94", "Ghoṭamukha "],
  ["MN 95", "Caṅkī"],
  ["MN 96", "Esukārī"],
  ["MN 97", "Dhānañjāni [Dhanañjāni]"],
  ["MN 98", "Vāseṭṭha"],
  ["MN 99", "Subha"],
  ["MN 100", "Saṅgārava"],
  ["MN 101", "Devadaha"],
  ["MN 102", "Pañcattaya [Pañcāyatana]"],
  ["MN 103", "Kinti"],
  ["MN 104", "Sāmagāma"],
  ["MN 105", "Sunakkhatta"],
  ["MN 106", "Āneñjasappāya"],
  ["MN 107", "Gaṇakamoggallāna"],
  ["MN 108", "Gopakamoggallāna"],
  ["MN 109", "Mahāpuṇṇama"],
  ["MN 110", "Cūḷapuṇṇama"],
  ["MN 111", "Anupada"],
  ["MN 112", "Chabbisodhana"],
  ["MN 113", "Sappurisa"],
  ["MN 114", "Sevitabbāsevitabba "],
  ["MN 115", "Bahudhātuka"],
  ["MN 116", "Isigili"],
  ["MN 117", "Mahācattārīsaka"],
  ["MN 118", "Ānāpānasati"],
  ["MN 119", "Kāyagatāsati"],
  ["MN 120", "Saṅkhārupapatti"],
  ["MN 121", "Cūḷasuññata"],
  ["MN 122", "Mahāsuññata"],
  ["MN 123", "Acchariya-abbhūta"],
  ["MN 124", "Bakkula [Bākula]"],
  ["MN 125", "Dantabhūmi"],
  ["MN 126", "Bhūmija"],
  ["MN 127", "Anuruddha"],
  ["MN 128", "Upakkilesa"],
  ["MN 129", "Bālapaṇḍita"],
  ["MN 130", "Devadūta"],
  ["MN 131", "Bhaddekaratta"],
  ["MN 132", "Ānandabhaddekaratta"],
  ["MN 133", "Mahākaccānabhaddekaratta"],
  ["MN 134", "Lomasakaṅgiyabhaddekaratta"],
  ["MN 135", "Cūḷakammavibhaṅga"],
  ["MN 136", "Mahākammavibhaṅga"],
  ["MN 137", "Saḷāyatanavibhaṅga"],
  ["MN 138", "Uddesavibhaṅga"],
  ["MN 139", "Araṇavibhaṅga"],
  ["MN 140", "Dhātuvibhaṅga"],
  ["MN 141", "Saccavibhaṅga"],
  ["MN 142", "Dakkhiṇāvibhaṅga"],
  ["MN 143", "Anāthapiṇḍikovāda"],
  ["MN 144", "Channovāda"],
  ["MN 145", "Puṇṇovāda"],
  ["MN 146", "Nandakovāda"],
  ["MN 147", "Cūḷarāhulovāda"],
  ["MN 148", "Chachakka"],
  ["MN 149", "Mahāsaḷāyatanika"],
  ["MN 150", "Nagaravindeyya"],
  ["MN 151", "Piṇḍapātapārisuddhi"],
  ["MN 152", "Indriyabhāvanā"],
  ["SN 1", "Devatā Saṁyutta"],
  ["SN 2", "Devaputta Saṁyutta"],
  ["SN 3", "Kosala Saṁyutta"],
  ["SN 4", "Māra Saṁyutta"],
  ["SN 5", "Bhikkhunī Saṁyutta"],
  ["SN 6", "Brahma Saṁyutta"],
  ["SN 7", "Brāhmaṇa Saṁyutta"],
  ["SN 8", "Vaṅgīsa Saṁyutta"],
  ["SN 9", "Vana Saṁyutta"],
  ["SN 10", "Yakkha Saṁyutta"],
  ["SN 11", "Sakka Saṁyutta"],
  ["SN 12", "Nidāna Saṁyutta"],
  ["SN 13", "Abhisamaya Saṁyutta"],
  ["SN 14", "Dhātu Saṁyutta"],
  ["SN 15", "Anamatagga Saṁyutta"],
  ["SN 16", "Kassapa Saṁyutta"],
  ["SN 17", "Lābhasakkāra Saṁyutta"],
  ["SN 18", "Rāhula Saṁyutta"],
  ["SN 19", "Lakkhaṇa Saṁyutta"],
  ["SN 20", "Opamma Saṁyutta"],
  ["SN 21", "Bhikkhu Saṁyutta"],
  ["SN 22", "Khandha Saṁyutta"],
  ["SN 23", "Rādha Saṁyutta"],
  ["SN 24", "Diṭṭhi Saṁyutta"],
  ["SN 25", "Okkanta Saṁyutta"],
  ["SN 26", "Uppāda Saṁyutta"],
  ["SN 27", "Kilesa Saṁyutta"],
  ["SN 28", "Sāriputta Saṁyutta"],
  ["SN 29", "Nāga Saṁyutta"],
  ["SN 30", "Supaṇṇa Saṁyutta"],
  ["SN 31", "Gandhabbakāya Saṁyutta"],
  ["SN 32", "Valāhaka Saṁyutta"],
  ["SN 33", "Vacchagotta Saṁyutta"],
  ["SN 34", "Jhāna Saṁyutta"],
  ["SN 35", "Saḷāyatana Saṁyutta"],
  ["SN 36", "Vedanā Saṁyutta"],
  ["SN 37", "Mātugāma Saṁyutta"],
  ["SN 38", "Jambukhādaka Saṁyutta"],
  ["SN 39", "Sāmaṇḍaka Saṁyutta"],
  ["SN 40", "Moggallāna Saṁyutta"],
  ["SN 41", "Citta Saṁyutta"],
  ["SN 42", "Gāmaṇi Saṁyutta"],
  ["SN 43", "Asaṅkhata Saṁyutta"],
  ["SN 44", "Abyākata Saṁyutta"],
  ["SN 45", "Magga Saṁyutta"],
  ["SN 46", "Bojjhaṅga Saṁyutta"],
  ["SN 47", "Satipaṭṭhāna Saṁyutta"],
  ["SN 48", "Indriya Saṁyutta"],
  ["SN 49", "Sammappadhāna Saṁyutta"],
  ["SN 50", "Bala Saṁyutta"],
  ["SN 51", "Iddhipāda Saṁyutta"],
  ["SN 52", "Anuruddha Saṁyutta"],
  ["SN 53", "Jhāna Saṁyutta"],
  ["SN 54", "Ānāpāna Saṁyutta"],
  ["SN 55", "Sotāpatti Saṁyutta"],
  ["SN 56", "Sacca Saṁyutta"],
  ["SN 1.1", "Oghataraṇa"],
  ["SN 1.2", "Nimokkha"],
  ["SN 1.3", "Upanīyati"],
  ["SN 1.4", "Accenti"],
  ["SN 1.5", "Katichinda"],
  ["SN 1.6", "Jāgara"],
  ["SN 1.7", "Appaṭividita"],
  ["SN 1.8", "Susammuṭṭha"],
  ["SN 1.9", "Namānakāma"],
  ["SN 1.10", "Arañña"],
  ["SN 1.11", "Nandana"],
  ["SN 1.12", "Nandati"],
  ["SN 1.13", "Natthiputta"],
  ["SN 1.14", "Khattiya"],
  ["SN 1.15", "Saṇamāna"],
  ["SN 1.16", "Niddātandi"],
  ["SN 1.17", "Dukkara"],
  ["SN 1.18", "Hiri"],
  ["SN 1.19", "Kuṭikā"],
  ["SN 1.20", "Samiddhi"],
  ["SN 1.21", "Satti"],
  ["SN 1.22", "Phusati"],
  ["SN 1.23", "Jaṭā"],
  ["SN 1.24", "Manonivāraṇa"],
  ["SN 1.25", "Arahanta"],
  ["SN 1.26", "Pajjota"],
  ["SN 1.27", "Sara"],
  ["SN 1.28", "Mahaddhana"],
  ["SN 1.29", "Catucakka"],
  ["SN 1.30", "Eṇijaṅgha"],
  ["SN 1.31", "Sabbhi"],
  ["SN 1.32", "Maccharī"],
  ["SN 1.33", "Sādhu"],
  ["SN 1.34", "Nasanti"],
  ["SN 1.35", "Ujjhānasaññi"],
  ["SN 1.36", "Saddhā"],
  ["SN 1.37", "Samaya"],
  ["SN 1.38", "Sakalika"],
  ["SN 1.39", "Paṭhama Pajjunnadhītu"],
  ["SN 1.40", "Dutiya Pajjunnadhītu"],
  ["SN 1.41", "Āditta"],
  ["SN 1.42", "Kiṃdada"],
  ["SN 1.43", "Anna"],
  ["SN 1.44", "Ekamūla"],
  ["SN 1.45", "Anoma"],
  ["SN 1.46", "Accharā"],
  ["SN 1.47", "Vanaropa"],
  ["SN 1.48", "Jetavana"],
  ["SN 1.49", "Macchari"],
  ["SN 1.50", "Ghaṭīkāra"],
  ["SN 1.51", "Jarā"],
  ["SN 1.52", "Ajarasā"],
  ["SN 1.53", "Mitta"],
  ["SN 1.54", "Vatthu"],
  ["SN 1.55", "Paṭhama Janeti"],
  ["SN 1.56", "Dutiya Janeti"],
  ["SN 1.57", "Tatiya Janeti"],
  ["SN 1.58", "Paṭhama Uppatha"],
  ["SN 1.59", "Dutiya Uppatha"],
  ["SN 1.60", "Kavi"],
  ["SN 1.61", "Nāma"],
  ["SN 1.62", "Citta"],
  ["SN 1.63", "Taṇhā"],
  ["SN 1.64", "Saṃyojana"],
  ["SN 1.65", "Bandhana"],
  ["SN 1.66", "Abbhāhata"],
  ["SN 1.67", "Uḍḍita"],
  ["SN 1.68", "Pihita"],
  ["SN 1.69", "Icchā"],
  ["SN 1.70", "Loka"],
  ["SN 1.71", "Chetvā"],
  ["SN 1.72", "Ratha"],
  ["SN 1.73", "Vitta"],
  ["SN 1.74", "Vuṭṭhi"],
  ["SN 1.75", "Bhīta"],
  ["SN 1.76", "Najīrati"],
  ["SN 1.77", "Issara"],
  ["SN 1.78", "Kāma"],
  ["SN 1.79", "Pātheyya"],
  ["SN 1.80", "Pajjota"],
  ["SN 1.81", "Araṇa"],
  ["SN 2.1", "Paṭhama Kassapa"],
  ["SN 2.2", "Dutiya Kassapa"],
  ["SN 2.3", "Māgha"],
  ["SN 2.4", "Māgadha"],
  ["SN 2.5", "Dāmali"],
  ["SN 2.6", "Kāmada"],
  ["SN 2.7", "Pañcālacaṇḍa"],
  ["SN 2.8", "Tāyana"],
  ["SN 2.9", "Candima"],
  ["SN 2.10", "Suriya"],
  ["SN 2.11", "Candimasa"],
  ["SN 2.12", "Veṇhu"],
  ["SN 2.13", "Dīghalaṭṭhi"],
  ["SN 2.14", "Nandana"],
  ["SN 2.15", "Candana"],
  ["SN 2.16", "Vasudatta"],
  ["SN 2.17", "Subrahma"],
  ["SN 2.18", "Kakudha"],
  ["SN 2.19", "Uttara"],
  ["SN 2.20", "Anāthapiṇḍika"],
  ["SN 2.21", "Siva"],
  ["SN 2.22", "Khema"],
  ["SN 2.23", "Serī"],
  ["SN 2.24", "Ghaṭīkāra"],
  ["SN 2.25", "Chantu"],
  ["SN 2.26", "Rohitassa"],
  ["SN 2.27", "Nanda"],
  ["SN 2.28", "Nandivisāla"],
  ["SN 2.29", "Susīma"],
  ["SN 2.30", "Nānātitthiyasāvaka"],
  ["SN 3.1", "Dahara"],
  ["SN 3.2", "Purisa"],
  ["SN 3.3", "Rāja"],
  ["SN 3.4", "Piya"],
  ["SN 3.5", "Attarakkhita"],
  ["SN 3.6", "Appakā"],
  ["SN 3.7", "Atthakaraṇa"],
  ["SN 3.8", "Mallikā"],
  ["SN 3.9", "Yañña"],
  ["SN 3.10", "Bandhana"],
  ["SN 3.11", "Sattajaṭila"],
  ["SN 3.12", "Pañcarāja"],
  ["SN 3.13", "Doṇapāka"],
  ["SN 3.14", "Paṭhama Saṅgāma"],
  ["SN 3.15", "Dutiya Saṅgāma"],
  ["SN 3.16", "Dhītu"],
  ["SN 3.17", "Appamāda"],
  ["SN 3.18", "Kalyāṇamitta"],
  ["SN 3.19", "Paṭhama Aputtaka"],
  ["SN 3.20", "Dutiya Aputtaka"],
  ["SN 3.21", "Puggala"],
  ["SN 3.22", "Ayyakā"],
  ["SN 3.23", "Loka"],
  ["SN 3.24", "Issattha"],
  ["SN 3.25", "Pabbatūpama"],
  ["SN 4.1", "Tapokamma"],
  ["SN 4.2", "Nāga"],
  ["SN 4.3", "Subha"],
  ["SN 4.4", "Paṭhama Pāsa"],
  ["SN 4.5", "Dutiya Pāsa"],
  ["SN 4.6", "Sappa"],
  ["SN 4.7", "Soppasi"],
  ["SN 4.8", "Nandati"],
  ["SN 4.9", "Paṭhama Āyu"],
  ["SN 4.10", "Dutiya Āyu"],
  ["SN 4.11", "Pāsāṇa"],
  ["SN 4.12", "Sīha"],
  ["SN 4.13", "Sakalika"],
  ["SN 4.14", "Patirūpa"],
  ["SN 4.15", "Mānasa"],
  ["SN 4.16", "Patta"],
  ["SN 4.17", "Āyatana"],
  ["SN 4.18", "Piṇḍa"],
  ["SN 4.19", "Kassaka"],
  ["SN 4.20", "Rajja"],
  ["SN 4.21", "Sambahula"],
  ["SN 4.22", "Samiddhi"],
  ["SN 4.23", "Godhika"],
  ["SN 4.24", "Sattavassa"],
  ["SN 4.25", "Māradhītu"],
  ["SN 5.1", "Āḷavikā"],
  ["SN 5.2", "Somā"],
  ["SN 5.3", "Gotamī"],
  ["SN 5.4", "Vijayā"],
  ["SN 5.5", "Uppalavaṇṇā"],
  ["SN 5.6", "Cālā"],
  ["SN 5.7", "Upacālā"],
  ["SN 5.8", "Sīsūpacālā"],
  ["SN 5.9", "Selā"],
  ["SN 5.10", "Vajirā"],
  ["SN 6.1", "Āyācana"],
  ["SN 6.2", "Gārava"],
  ["SN 6.3", "Brahmadeva"],
  ["SN 6.4", "Bakabrahma"],
  ["SN 6.5", "Aparādiṭṭhi"],
  ["SN 6.6", "Pamāda"],
  ["SN 6.7", "Paṭhama Kokālika"],
  ["SN 6.8", "Katamorakatissa"],
  ["SN 6.9", "Tudubrahma"],
  ["SN 6.10", "Dutiya Kokālika"],
  ["SN 6.11", "Sanaṅkumāra"],
  ["SN 6.12", "Devadatta"],
  ["SN 6.13", "Andhakavinda"],
  ["SN 6.14", "Aruṇavatī"],
  ["SN 6.15", "Parinibbāna"],
  ["SN 7.1", "Dhanañjānī"],
  ["SN 7.2", "Akkosa"],
  ["SN 7.3", "Asurindaka"],
  ["SN 7.4", "Bilaṅgika"],
  ["SN 7.5", "Ahiṃsaka"],
  ["SN 7.6", "Jaṭā"],
  ["SN 7.7", "Suddhika"],
  ["SN 7.8", "Aggika"],
  ["SN 7.9", "Sundarika"],
  ["SN 7.10", "Bahudhītu"],
  ["SN 7.11", "Kasī"],
  ["SN 7.12", "Udaya"],
  ["SN 7.13", "Devahita"],
  ["SN 7.14", "Mahāsāḷa"],
  ["SN 7.15", "Mānatthaddha"],
  ["SN 7.16", "Paccanīka"],
  ["SN 7.17", "Navakammika"],
  ["SN 7.18", "Kaṭṭhahāra"],
  ["SN 7.19", "Mātuposaka"],
  ["SN 7.20", "Bhikkhaka"],
  ["SN 7.21", "Saṅgārava"],
  ["SN 7.22", "Khomadussa"],
  ["SN 8.1", "Nikkhanta"],
  ["SN 8.2", "Arati"],
  ["SN 8.3", "Pesalātimaññanā"],
  ["SN 8.4", "Ānanda"],
  ["SN 8.5", "Subhāsita"],
  ["SN 8.6", "Sāriputta"],
  ["SN 8.7", "Pavāraṇā"],
  ["SN 8.8", "Parosahassa"],
  ["SN 8.9", "Koṇḍañña"],
  ["SN 8.10", "Moggallāna"],
  ["SN 8.11", "Gaggarā"],
  ["SN 8.12", "Vaṅgīsa"],
  ["SN 9.1", "Viveka"],
  ["SN 9.2", "Upaṭṭhāna"],
  ["SN 9.3", "Kassapagotta"],
  ["SN 9.4", "Sambahulā"],
  ["SN 9.5", "Ānanda"],
  ["SN 9.6", "Anuruddha"],
  ["SN 9.7", "Nāgadatta"],
  ["SN 9.8", "Kulagharaṇī"],
  ["SN 9.9", "Vajjiputta"],
  ["SN 9.10", "Sajjhāya"],
  ["SN 9.11", "Ayoniso"],
  ["SN 9.12", "Majjhantika"],
  ["SN 9.13", "Pākatindriya"],
  ["SN 9.14", "Padumapuppha"],
  ["SN 10.1", "Indaka"],
  ["SN 10.2", "Sakka"],
  ["SN 10.3", "Sūciloma"],
  ["SN 10.4", "Maṇibhadda"],
  ["SN 10.5", "Sānu"],
  ["SN 10.6", "Piyaṅkara"],
  ["SN 10.7", "Punabbasu"],
  ["SN 10.8", "Sudatta"],
  ["SN 10.9", "Paṭhama Sukkā"],
  ["SN 10.10", "Dutiya Sukkā"],
  ["SN 10.11", "Cīrā"],
  ["SN 10.12", "Āḷavaka"],
  ["SN 11.1", "Suvīra"],
  ["SN 11.2", "Susīma"],
  ["SN 11.3", "Dhajagga"],
  ["SN 11.4", "Vepacitti"],
  ["SN 11.5", "Subhāsitajaya"],
  ["SN 11.6", "Kulāvaka"],
  ["SN 11.7", "Nadubbhiya"],
  ["SN 11.8", "Verocanāsurinda"],
  ["SN 11.9", "Araññāyatanaisi"],
  ["SN 11.10", "Isayosamuddaka"],
  ["SN 11.11", "Paṭhama Sattavatapada"],
  ["SN 11.12", "Dutiya Sattavatapada"],
  ["SN 11.13", "Mahāli"],
  ["SN 11.14", "Daḷidda"],
  ["SN 11.15", "Rāmaṇeyyaka"],
  ["SN 11.16", "Yajamāna"],
  ["SN 11.17", "Vandanā"],
  ["SN 11.18", "Paṭhama Sakkanamassana"],
  ["SN 11.19", "Dutiya Sakkanamassana"],
  ["SN 11.20", "Tatiya Sakkanamassana"],
  ["SN 11.21", "Chetvā"],
  ["SN 11.22", "Dubbaṇṇiya"],
  ["SN 11.23", "Māyā"],
  ["SN 11.24", "Accaya"],
  ["SN 11.25", "Akkodha"],
  ["SN 12.1", "Paṭiccasamuppāda"],
  ["SN 12.2", "Vibhaṅga"],
  ["SN 12.3", "Paṭipadā"],
  ["SN 12.4", "Vipassi"],
  ["SN 12.5", "Sikhī"],
  ["SN 12.6", "Vessabhu"],
  ["SN 12.7", "Kakusandha"],
  ["SN 12.8", "Konāgamana"],
  ["SN 12.9", "Kassapa"],
  ["SN 12.10", "Gotama"],
  ["SN 12.11", "Āhāra"],
  ["SN 12.12", "Moḷiyaphagguna"],
  ["SN 12.13", "Paṭhama Samaṇabrāhmaṇa"],
  ["SN 12.14", "Dutiya Samaṇabrāhmaṇa"],
  ["SN 12.15", "Kaccānagotta"],
  ["SN 12.16", "Dhammakathika"],
  ["SN 12.17", "Acelakassapa"],
  ["SN 12.18", "Timbaruka"],
  ["SN 12.19", "Bālapaṇḍita"],
  ["SN 12.20", "Paccaya"],
  ["SN 12.21", "Paṭhama Dasabala"],
  ["SN 12.22", "Dutiya Dasabala"],
  ["SN 12.23", "Upanisa"],
  ["SN 12.24", "Aññatitthiya"],
  ["SN 12.25", "Bhumija"],
  ["SN 12.26", "Upavāṇa"],
  ["SN 12.27", "Paccaya"],
  ["SN 12.28", "Bhikkhu"],
  ["SN 12.29", "Paṭhama Samaṇabrāhmaṇa"],
  ["SN 12.30", "Dutiya Samaṇabrāhmaṇa"],
  ["SN 12.31", "Bhūta"],
  ["SN 12.32", "Kaḷāra"],
  ["SN 12.33", "Paṭhama Ñāṇavatthu"],
  ["SN 12.34", "Dutiya Ñāṇavatthu"],
  ["SN 12.35", "Paṭhama Avijjādipaccaya"],
  ["SN 12.36", "Dutiya Avijjādipaccaya"],
  ["SN 12.37", "Natumha"],
  ["SN 12.38", "Paṭhama Cetanā"],
  ["SN 12.39", "Dutiya Cetanā"],
  ["SN 12.40", "Tatiya Cetanā"],
  ["SN 12.41", "Paṭhama Pañcabhayavera"],
  ["SN 12.42", "Dutiya Pañcabhayavera"],
  ["SN 12.43", "Dukkha"],
  ["SN 12.44", "Loka"],
  ["SN 12.45", "Ñātika"],
  ["SN 12.46", "Aññatara"],
  ["SN 12.47", "Jāṇussoṇi"],
  ["SN 12.48", "Lokāyatika"],
  ["SN 12.49", "Paṭhama Ariyasāvaka"],
  ["SN 12.50", "Dutiya Ariyasāvaka"],
  ["SN 12.51", "Parivīmaṃsana"],
  ["SN 12.52", "Upādāna"],
  ["SN 12.53", "Paṭhama Saññojana"],
  ["SN 12.54", "Dutiya Saññojana"],
  ["SN 12.55", "Paṭhama Mahārukkha"],
  ["SN 12.56", "Dutiya Mahārukkha"],
  ["SN 12.57", "Taruṇarukkha"],
  ["SN 12.58", "Nāmarūpa"],
  ["SN 12.59", "Viññāṇa"],
  ["SN 12.60", "Nidāna (paṭiccasamuppāda)"],
  ["SN 12.61", "Paṭhama Assutavantu"],
  ["SN 12.62", "Dutiya Assutavantu"],
  ["SN 12.63", "Puttamaṃsa"],
  ["SN 12.64", "Atthirāga"],
  ["SN 12.65", "Nagara"],
  ["SN 12.66", "Sammasana"],
  ["SN 12.67", "Naḷakalāpa"],
  ["SN 12.68", "Kosambi"],
  ["SN 12.69", "Upayanti"],
  ["SN 12.70", "Susīma"],
  ["SN 12.71", "Jarāmaraṇa"],
  ["SN 12.72", "Jāti"],
  ["SN 12.73", "Bhava"],
  ["SN 12.74", "Upādāna"],
  ["SN 12.75", "Taṇhā"],
  ["SN 12.76", "Vedanā"],
  ["SN 12.77", "Phassa"],
  ["SN 12.78", "Saḷāyatana"],
  ["SN 12.79", "Nāmarūpa"],
  ["SN 12.80", "Viññāṇa"],
  ["SN 12.81", "Saṃkhāra"],
  ["SN 12.82", "Satthā"],
  ["SN 12.83", "Sikkhā"],
  ["SN 12.84", "Yoga"],
  ["SN 12.85", "Chanda"],
  ["SN 12.86", "Ussoḷhī"],
  ["SN 12.87", "Appaṭivānī"],
  ["SN 12.88", "Ātappa"],
  ["SN 12.89", "Viriya"],
  ["SN 12.90", "Sātacca"],
  ["SN 12.91", "Sati"],
  ["SN 12.92", "Sampajañña"],
  ["SN 12.93", "Appamāda"],
  ["SN 13.1", "Nakhasikhā"],
  ["SN 13.2", "Pokkharaṇi"],
  ["SN 13.3", "Paṭhama Samhejjaudaka"],
  ["SN 13.4", "Dutiya Samhejjaudaka"],
  ["SN 13.5", "Paṭhama Paṭhavi"],
  ["SN 13.6", "Dutiya Paṭhavi"],
  ["SN 13.7", "Paṭhama Samudda"],
  ["SN 13.8", "Dutiya Samudda"],
  ["SN 13.9", "Paṭhama Pabbatūpama"],
  ["SN 13.10", "Dutiya Pabbatūpama"],
  ["SN 13.11", "Tatiya Pabbatūpama"],
  ["SN 14.1", "Dhātunānatta"],
  ["SN 14.2", "Phassanānatta"],
  ["SN 14.3", "No phassanānatta"],
  ["SN 14.4", "Vedanānānatta "],
  ["SN 14.5", "No vedanānānatta"],
  ["SN 14.6", "Bāhiradhātunānatta "],
  ["SN 14.7", "Pariyesanānānatta "],
  ["SN 14.8", "No pariyesanānānatta"],
  ["SN 14.9", "Lābhanānatta"],
  ["SN 14.10", "Nolābhanānatta"],
  ["SN 14.11", "Sattadhātu"],
  ["SN 14.12", "Sanidāna"],
  ["SN 14.13", "Giñjakāvasatha"],
  ["SN 14.14", "Hīnādhimuttika"],
  ["SN 14.15", "Caṅkama"],
  ["SN 14.16", "Sagātha"],
  ["SN 14.17", "Assaddha"],
  ["SN 14.18", "Assaddhamūlakatikapañcaka"],
  ["SN 14.19", "Ahirikamūlakatikacatukka"],
  ["SN 14.20", "Anottāpamūlakatikattaya"],
  ["SN 14.21", "Appassutamūlakatikadvaya"],
  ["SN 14.22", "Kusītamūlakatikeka"],
  ["SN 14.23", "Asamāhita"],
  ["SN 14.24", "Dussīla"],
  ["SN 14.25", "Pañcasikkhāpada"],
  ["SN 14.26", "Sattakammapatha"],
  ["SN 14.27", "Dasakammapatha"],
  ["SN 14.28", "Aṭṭhaṅgika"],
  ["SN 14.29", "Dasaṅgika"],
  ["SN 14.30", "Catudhātu"],
  ["SN 14.31", "Pubba"],
  ["SN 14.32", "Assādapariyesana"],
  ["SN 14.33", "Nocedaṃ"],
  ["SN 14.34", "Dukkhalakkhaṇa"],
  ["SN 14.35", "Abhinandana"],
  ["SN 14.36", "Uppāda"],
  ["SN 14.37", "Paṭhama Samaṇabrāhmaṇa"],
  ["SN 14.38", "Dutiya Samaṇabrāhmaṇa"],
  ["SN 14.39", "Tatiya Samaṇabrāhmaṇa"],
  ["SN 15.1", "Tiṇakaṭṭha"],
  ["SN 15.2", "Paṭhavī"],
  ["SN 15.3", "Assu"],
  ["SN 15.4", "Mātuthañña"],
  ["SN 15.5", "Pabbata"],
  ["SN 15.6", "Sāsapa"],
  ["SN 15.7", "Sāvaka"],
  ["SN 15.8", "Gaṅgā"],
  ["SN 15.9", "Daṇḍa"],
  ["SN 15.10", "Ekapuggala"],
  ["SN 15.11", "Duggata"],
  ["SN 15.12", "Sukhita"],
  ["SN 15.13", "Tiṃsamatta"],
  ["SN 15.14", "Mātu"],
  ["SN 15.15", "Pitu"],
  ["SN 15.16", "Bhātu"],
  ["SN 15.17", "Bhagini"],
  ["SN 15.18", "Putta"],
  ["SN 15.19", "Dhītu"],
  ["SN 15.20", "Vepullapabbata"],
  ["SN 16.1", "Santuṭṭhi"],
  ["SN 16.2", "Anottāpi"],
  ["SN 16.3", "Candūpama"],
  ["SN 16.4", "Kulūpaga"],
  ["SN 16.5", "Jiṇṇa"],
  ["SN 16.6", "Paṭhama Ovāda"],
  ["SN 16.7", "Dutiya Ovāda"],
  ["SN 16.8", "Tatiya Ovāda"],
  ["SN 16.9", "Jhānābhiññā"],
  ["SN 16.10", "Bhikkhunūupassaya"],
  ["SN 16.11", "Cīvara"],
  ["SN 16.12", "Tathāgata parammaraṇa"],
  ["SN 16.13", "Saddhammapatirūpaka"],
  ["SN 17.1", "Dāruṇa"],
  ["SN 17.2", "Balisa"],
  ["SN 17.3", "Kumma"],
  ["SN 17.4", "Dīghalomieḷaka"],
  ["SN 17.5", "Mīḷhaka"],
  ["SN 17.6", "Asanivicakka"],
  ["SN 17.7", "Diddhavisalla"],
  ["SN 17.8", "Sigāla"],
  ["SN 17.9", "Veramba"],
  ["SN 17.10", "Sagātha"],
  ["SN 17.11", "Suvaṇṇapāti"],
  ["SN 17.12", "Rūpiyapāti"],
  ["SN 17.13", "Suvaṇṇanikkha"],
  ["SN 17.14", "Suvaṇṇanikkhasata"],
  ["SN 17.15", "Siṅgīnikkha"],
  ["SN 17.16", "Siṅgīnikkhasata"],
  ["SN 17.17", "Paṭhavi"],
  ["SN 17.18", "Kiñcikkha"],
  ["SN 17.19", "Jīvita"],
  ["SN 17.20", "Janapadakalyāṇi"],
  ["SN 17.21", "Mātugāma"],
  ["SN 17.22", "Janapadakalyāṇi"],
  ["SN 17.23", "Ekaputta"],
  ["SN 17.24", "Ekadhītu"],
  ["SN 17.25", "Paṭhama Samaṇabrāhmaṇa"],
  ["SN 17.26", "Dutiya Samaṇabrāhmaṇa"],
  ["SN 17.27", "Tatiya Samaṇabrāhmaṇa"],
  ["SN 17.28", "Chavi"],
  ["SN 17.29", "Vālarajju"],
  ["SN 17.30", "Khīṇāsavabhikkhu"],
  ["SN 17.31", "Saṅghabheda"],
  ["SN 17.32", "Kusalamūlasamuccheda"],
  ["SN 17.33", "Kusaladhammasamuccheda"],
  ["SN 17.34", "Sukkadhammasamuccheda"],
  ["SN 17.35", "Attavadha"],
  ["SN 17.36", "Pañcarathasata"],
  ["SN 17.37", "Mātu"],
  ["SN 17.38", "Pitu"],
  ["SN 17.39", "Bhātu"],
  ["SN 17.40", "Bhagini"],
  ["SN 17.41", "Putta"],
  ["SN 17.42", "Dhītu"],
  ["SN 17.43", "Pajāpati"],
  ["SN 18.1", "Cakkhu ādi"],
  ["SN 18.2", "Rūpādiārammaṇa"],
  ["SN 18.3", "Viññāṇa"],
  ["SN 18.4", "Samphassa"],
  ["SN 18.5", "Vedanā"],
  ["SN 18.6", "Saññā"],
  ["SN 18.7", "Sañcetanā"],
  ["SN 18.8", "Taṇhā"],
  ["SN 18.9", "Dhātu"],
  ["SN 18.10", "Khandha"],
  ["SN 18.11", "Cakkhuādi"],
  ["SN 18.12", "Rūpādi"],
  ["SN 18.13", "Viññāṇa"],
  ["SN 18.14", "Samphassa"],
  ["SN 18.15", "Vedanā"],
  ["SN 18.16", "Saññā"],
  ["SN 18.17", "Sañcetanā"],
  ["SN 18.18", "Taṇhā"],
  ["SN 18.19", "Dhātu"],
  ["SN 18.20", "Bandha"],
  ["SN 18.21", "Mānānusaya"],
  ["SN 18.22", "Mānāpagata"],
  ["SN 19.1", "Aṭṭhi"],
  ["SN 19.2", "Maṃsapesi"],
  ["SN 19.3", "Maṃsapiṇḍa"],
  ["SN 19.4", "Nicchavi"],
  ["SN 19.5", "Asiloma"],
  ["SN 19.6", "Sattiloma"],
  ["SN 19.7", "Usuloma"],
  ["SN 19.8", "Paṭhama Sūciloma"],
  ["SN 19.9", "Dutiya Sūciloma"],
  ["SN 19.10", "Kumbhaṇḍa"],
  ["SN 19.11", "Sasīsaka"],
  ["SN 19.12", "Gūthakhādi"],
  ["SN 19.13", "Nicchavitthi"],
  ["SN 19.14", "Maṅgulitthi"],
  ["SN 19.15", "Okilinī"],
  ["SN 19.16", "Asīsaka"],
  ["SN 19.17", "Pāpabhikkhu"],
  ["SN 19.18", "Pāpabhikkhuṇī"],
  ["SN 19.19", "Pāpasikkhamānā"],
  ["SN 19.20", "Pāpasāmaṇera"],
  ["SN 19.21", "Pāpasāmaṇerī"],
  ["SN 20.1", "Kūṭa"],
  ["SN 20.2", "Nakhasikhā"],
  ["SN 20.3", "Kula"],
  ["SN 20.4", "Okkhāsata"],
  ["SN 20.5", "Satti"],
  ["SN 20.6", "Dhanuggaha"],
  ["SN 20.7", "Āṇi"],
  ["SN 20.8", "Kaliṅgarūpadhāna"],
  ["SN 20.9", "Nāga"],
  ["SN 20.10", "Biḷāla"],
  ["SN 20.11", "Paṭhama Sigāla"],
  ["SN 20.12", "Dutiya Sigāla"],
  ["SN 21.1", "Kolita"],
  ["SN 21.2", "Upatissa"],
  ["SN 21.3", "Ghaṭa"],
  ["SN 21.4", "Navabhikkhu"],
  ["SN 21.5", "Sujāta"],
  ["SN 21.6", "Lakuṇṭakabhaddiya"],
  ["SN 21.7", "Visākhapañcāliputta"],
  ["SN 21.8", "Nanda"],
  ["SN 21.9", "Tissa"],
  ["SN 21.10", "Theranāmaka"],
  ["SN 21.11", "Mahākappina"],
  ["SN 21.12", "Sahāyaka"],
  ["SN 22.1", "Nakulapitu"],
  ["SN 22.2", "Devadaha"],
  ["SN 22.3", "Paṭhama Hāliddikāni"],
  ["SN 22.4", "Dutiya Hāliddikāni"],
  ["SN 22.5", "Samādhi"],
  ["SN 22.6", "Paṭisallāna"],
  ["SN 22.7", "Paṭhama Upādāparitassanā"],
  ["SN 22.8", "Dutiya Upādāparitassanā"],
  ["SN 22.9", "Paṭhama Atītānāgata"],
  ["SN 22.10", "Dutiya Atītānāgata"],
  ["SN 22.11", "Tatiya Atītānāgata"],
  ["SN 22.12", "Anicca"],
  ["SN 22.13", "Dukkha"],
  ["SN 22.14", "Anatta"],
  ["SN 22.15", "Yadanicca"],
  ["SN 22.16", "Yaṃdukkha"],
  ["SN 22.17", "Yadanatta"],
  ["SN 22.18", "Paṭhama Hetu"],
  ["SN 22.19", "Dutiya Hetu"],
  ["SN 22.20", "Tatiya Hetu"],
  ["SN 22.21", "Ānanda"],
  ["SN 22.22", "Bhāra"],
  ["SN 22.23", "Pariññā"],
  ["SN 22.24", "Abhijāna"],
  ["SN 22.25", "Chandarāga"],
  ["SN 22.26", "Paṭhama Assāda"],
  ["SN 22.27", "Dutiya Assāda"],
  ["SN 22.28", "Tatiya Assāda"],
  ["SN 22.29", "Abhinandanaṃ"],
  ["SN 22.30", "Uppāda"],
  ["SN 22.31", "Aghamūla"],
  ["SN 22.32", "Pabhaṃgu"],
  ["SN 22.33", "Paṭhama Natumhāka"],
  ["SN 22.34", "Dutiya Natumhāka"],
  ["SN 22.35", "Paṭhama Bhikkhu"],
  ["SN 22.36", "Dutiya Bhikkhu"],
  ["SN 22.37", "Paṭhama Ānanda"],
  ["SN 22.38", "Dutiya Ānanda"],
  ["SN 22.39", "Paṭhama Anudhamma"],
  ["SN 22.40", "Dutiya Anudhamma"],
  ["SN 22.41", "Tatiya Anudhamma"],
  ["SN 22.42", "Catuttha Anudhamma"],
  ["SN 22.43", "Attadīpa"],
  ["SN 22.44", "Paṭipadā"],
  ["SN 22.45", "Paṭhama Aniccatā"],
  ["SN 22.46", "Dutiya Aniccatā"],
  ["SN 22.47", "Samanupassanā"],
  ["SN 22.48", "Khandha"],
  ["SN 22.49", "Paṭhama Soṇa"],
  ["SN 22.50", "Dutiya Soṇa"],
  ["SN 22.51", "Paṭhama Nandikkhaya"],
  ["SN 22.52", "Dutiya Nandikkhaya"],
  ["SN 22.53", "Upaya"],
  ["SN 22.54", "Bīja"],
  ["SN 22.55", "Udāna"],
  ["SN 22.56", "Upādāna"],
  ["SN 22.57", "Sattaṭṭhāna"],
  ["SN 22.58", "Sambuddha"],
  ["SN 22.59", "Pañcavaggiya [Anattalakkhaṇa]"],
  ["SN 22.60", "Mahāli"],
  ["SN 22.61", "Āditta"],
  ["SN 22.62", "Niruttipatha"],
  ["SN 22.63", "Upādiya"],
  ["SN 22.64", "Maññamāna"],
  ["SN 22.65", "Abhinandana"],
  ["SN 22.66", "Anicca"],
  ["SN 22.67", "Dukkha"],
  ["SN 22.68", "Anatta"],
  ["SN 22.69", "Anattaniya"],
  ["SN 22.70", "Rajanīya"],
  ["SN 22.71", "Rādha"],
  ["SN 22.72", "Surādha"],
  ["SN 22.73", "Assāda"],
  ["SN 22.74", "Paṭhama Samudaya"],
  ["SN 22.75", "Dutiya Samudaya"],
  ["SN 22.76", "Paṭhama Arahanta"],
  ["SN 22.77", "Dutiya Arahanta"],
  ["SN 22.78", "Sīhopama"],
  ["SN 22.79", "Khajjanīya"],
  ["SN 22.80", "Piṇḍolya"],
  ["SN 22.81", "Pārileyyaka"],
  ["SN 22.82", "Puṇṇamā"],
  ["SN 22.83", "Ānanda"],
  ["SN 22.84", "Tissa"],
  ["SN 22.85", "Yamaka"],
  ["SN 22.86", "Anurādha"],
  ["SN 22.87", "Vakkali"],
  ["SN 22.88", "Assaji"],
  ["SN 22.89", "Khemaka"],
  ["SN 22.90", "Channa"],
  ["SN 22.91", "Paṭhama Rāhula"],
  ["SN 22.92", "Dutiya Rāhula"],
  ["SN 22.93", "Nadi"],
  ["SN 22.94", "Puppha"],
  ["SN 22.95", "Pheṇapiṇḍūpama"],
  ["SN 22.96", "Gomaya"],
  ["SN 22.97", "Nakhasikhopama"],
  ["SN 22.98", "Suddhika"],
  ["SN 22.99", "Paṭhama Gaddulabaddha"],
  ["SN 22.100", "Dutiya Gaddulabaddha"],
  ["SN 22.101", "Vāsijaṭopama"],
  ["SN 22.102", "Aniccasaññā"],
  ["SN 22.103", "Anta"],
  ["SN 22.104", "Dukkha"],
  ["SN 22.105", "Sakkāya"],
  ["SN 22.106", "Pariññeyya"],
  ["SN 22.107", "Paṭhama Samaṇa"],
  ["SN 22.108", "Dutiya Samaṇa"],
  ["SN 22.109", "Sotāpanta"],
  ["SN 22.110", "Arahanta"],
  ["SN 22.111", "Paṭhama Chandarāga"],
  ["SN 22.112", "Dutiya Chandarāga"],
  ["SN 22.113", "Avijjā"],
  ["SN 22.114", "Vijjā"],
  ["SN 22.115", "Paṭhama Dhammakathika"],
  ["SN 22.116", "Dutiya Dhammakathika"],
  ["SN 22.117", "Bandhana"],
  ["SN 22.118", "Paṭhama Paripucchita"],
  ["SN 22.119", "Dutiya Paripucchita"],
  ["SN 22.120", "Saññojaniya"],
  ["SN 22.121", "Upādāniya"],
  ["SN 22.122", "Sīlavanta"],
  ["SN 22.123", "Sutavanta"],
  ["SN 22.124", "Paṭhama Kappa"],
  ["SN 22.125", "Dutiya Kappa"],
  ["SN 22.126", "Paṭhama Samudayadhamma"],
  ["SN 22.127", "Dutiya Samudayadhamma"],
  ["SN 22.128", "Tatiya Samudayadhamma"],
  ["SN 22.129", "Paṭhama Assāda"],
  ["SN 22.130", "Dutiya Assāda"],
  ["SN 22.131", "Paṭhama Samudaya"],
  ["SN 22.132", "Dutiya Samudaya"],
  ["SN 22.133", "Paṭhama Koṭṭhita"],
  ["SN 22.134", "Dutiya Koṭṭhita"],
  ["SN 22.135", "Tatiya Koṭṭhita"],
  ["SN 22.136", "Kukkuḷa"],
  ["SN 22.137", "Paṭhama Anicca"],
  ["SN 22.138", "Dutiya Anicca"],
  ["SN 22.139", "Tatiya Anicca"],
  ["SN 22.140", "Paṭhama Dukkha"],
  ["SN 22.141", "Dutiya Dukkha"],
  ["SN 22.142", "Tatiya Dukkha"],
  ["SN 22.143", "Paṭhama Anatta"],
  ["SN 22.144", "Dutiya Anatta"],
  ["SN 22.145", "Tatiya Anatta"],
  ["SN 22.146", "Nibbidābahula"],
  ["SN 22.147", "Aniccānupassanā"],
  ["SN 22.148", "Dukkhānupassanā"],
  ["SN 22.149", "Anattānupassanā"],
  ["SN 22.150", "Ajjhatta"],
  ["SN 22.151", "Etaṃ mama"],
  ["SN 22.152", "So atta"],
  ["SN 22.153", "No ca me siyā"],
  ["SN 22.154", "Micchādiṭṭhi"],
  ["SN 22.155", "Sakkāyadiṭṭhi"],
  ["SN 22.156", "Attānudiṭṭhi"],
  ["SN 22.157", "Paṭhama Abhinivesa"],
  ["SN 22.158", "Dutiya Abhinivesa"],
  ["SN 22.159", "Ānanda"],
  ["SN 23.1", "Māra"],
  ["SN 23.2", "Satta"],
  ["SN 23.3", "Bhavanetti"],
  ["SN 23.4", "Pariññeyya"],
  ["SN 23.5", "Paṭhama Samaṇa"],
  ["SN 23.6", "Dutiya Samaṇa"],
  ["SN 23.7", "Sotāpanna"],
  ["SN 23.8", "Arahanta"],
  ["SN 23.9", "Paṭhama Chandarāga"],
  ["SN 23.10", "Dutiya Chandarāga"],
  ["SN 23.11", "Māra"],
  ["SN 23.12", "Māradhamma"],
  ["SN 23.13", "Anicca"],
  ["SN 23.14", "Aniccadhamma"],
  ["SN 23.15", "Dukkhaṃ"],
  ["SN 23.16", "Dukkhadhamma"],
  ["SN 23.17", "Anatta"],
  ["SN 23.18", "Anattadhamma"],
  ["SN 23.19", "Khayadhamma"],
  ["SN 23.20", "Vayadhamma"],
  ["SN 23.21", "Samudayadhamma"],
  ["SN 23.22", "Nirodhadhamma"],
  ["SN 23.23", "Māra"],
  ["SN 23.24", "Māradhamma"],
  ["SN 23.25", "Anicca"],
  ["SN 23.26", "Aniccadhamma"],
  ["SN 23.27", "Dukkha"],
  ["SN 23.28", "Dukkhadhamma"],
  ["SN 23.29", "Anatta"],
  ["SN 23.30", "Anattadhamma"],
  ["SN 23.31", "Khayadhamma"],
  ["SN 23.32", "Vayadhamma"],
  ["SN 23.33", "Samudayadhamma"],
  ["SN 23.34", "Nirodhadhamma"],
  ["SN 23.35", "Māra"],
  ["SN 23.36", "Māradhamma"],
  ["SN 23.37", "Anicca"],
  ["SN 23.38", "Aniccadhamma"],
  ["SN 23.39", "Dukkha"],
  ["SN 23.40", "Dukkhadhamma"],
  ["SN 23.41", "Anatta"],
  ["SN 23.42", "Anattadhamma"],
  ["SN 23.43", "Khayadhamma"],
  ["SN 23.44", "Vayadhamma"],
  ["SN 23.45", "Samudayadhamma"],
  ["SN 23.46", "Nirodhadhamma"],
  ["SN 24.1", "Vāta"],
  ["SN 24.2", "Etaṃ mama"],
  ["SN 24.3", "So attā"],
  ["SN 24.4", "No ca me siyā"],
  ["SN 24.5", "Natthi dinna"],
  ["SN 24.6", "Karato"],
  ["SN 24.7", "Hetu"],
  ["SN 24.8", "Mahādiṭṭhi"],
  ["SN 24.9", "Sassataloka"],
  ["SN 24.10", "Asassataloka"],
  ["SN 24.11", "Antavā"],
  ["SN 24.12", "Anantavā"],
  ["SN 24.13", "Taṃ jivaṃ taṃ sarira"],
  ["SN 24.14", "Aññaṃ jīvaṃ aññaṃ sarīra"],
  ["SN 24.15", "Hoti tathāgata"],
  ["SN 24.16", "Na hoti tathāgata"],
  ["SN 24.17", "Hoti ca na ca hoti tathāgata"],
  ["SN 24.18", "Neva hoti na nahoti tathāgata"],
  ["SN 24.19", "Vāta"],
  ["SN 24.20", "Etaṃ mama"],
  ["SN 24.21", "So attā"],
  ["SN 24.22", "No ca me siyā"],
  ["SN 24.23", "Natthi dinna"],
  ["SN 24.24", "Karato"],
  ["SN 24.25", "Natthi hetu"],
  ["SN 24.26", "Mahādiṭṭhi"],
  ["SN 24.27", "Sassataloka"],
  ["SN 24.28", "Asassataloka"],
  ["SN 24.29", "Antavāloka"],
  ["SN 24.30", "Anantavāloka"],
  ["SN 24.31", "Taṃ jivaṃ taṃ sarira"],
  ["SN 24.32", "Aññaṃ jīvaṃ aññaṃ sarīra"],
  ["SN 24.33", "Hoti tathāgata"],
  ["SN 24.34", "Na hoti tathāgata"],
  ["SN 24.35", "Hoti ca na ca hoti tathāgata"],
  ["SN 24.36", "Neva hoti na nahoti tathāgata"],
  ["SN 24.37", "Rūpī attā"],
  ["SN 24.38", "Arūpī attā"],
  ["SN 24.39", "Rūpīva arūpīva attā"],
  ["SN 24.40", "Neva rūpī nārūpī attā"],
  ["SN 24.41", "Ekantasukhī attā"],
  ["SN 24.42", "ekantadukkhī attā"],
  ["SN 24.43", "Sukhadukkhī attā"],
  ["SN 24.44", "Adukkhamasukhī attā"],
  ["SN 24.45", "Vāta"],
  ["SN 24.46", "Etaṃ mama"],
  ["SN 24.47", "So attā"],
  ["SN 24.48", "No ca me siyā"],
  ["SN 24.49", "Natthi dinna"],
  ["SN 24.50", "Karato"],
  ["SN 24.51", "Natthi hetu"],
  ["SN 24.52", "Mahādiṭṭhi"],
  ["SN 24.53", "Sassataloka"],
  ["SN 24.54", "Asassataloka"],
  ["SN 24.55", "Antavāloka"],
  ["SN 24.56", "Anantavāloka"],
  ["SN 24.57", "Taṃ jivaṃ taṃ sarira"],
  ["SN 24.58", "Aññaṃ jīvaṃ aññaṃ sarīra"],
  ["SN 24.59", "Hoti tathāgata"],
  ["SN 24.60", "Na hoti tathāgata"],
  ["SN 24.61", "Hoti ca na ca hoti tathāgata"],
  ["SN 24.62", "Neva hoti na nahoti tathāgata"],
  ["SN 24.63", "Rūpī attā"],
  ["SN 24.64", "Arūpī attā"],
  ["SN 24.65", "Rūpīva arūpīva attā"],
  ["SN 24.66", "Neva rūpī nārūpī attā"],
  ["SN 24.67", "Ekantasukhī attā"],
  ["SN 24.68", "Ekantadukkhī attā"],
  ["SN 24.69", "Sukhadukkhī attā"],
  ["SN 24.70", "Adukkhamasukhī attā"],
  ["SN 24.71", "Vāta"],
  ["SN 24.72", "Etaṃ mama"],
  ["SN 24.73", "So attā"],
  ["SN 24.74", "No ca me siyā"],
  ["SN 24.75", "Natthi dinna"],
  ["SN 24.76", "Karato"],
  ["SN 24.77", "Natthi hetu"],
  ["SN 24.78", "Mahādiṭṭhi"],
  ["SN 24.79", "Sassataloka"],
  ["SN 24.80", "Asassataloka"],
  ["SN 24.81", "Antavāloka"],
  ["SN 24.82", "Anantavāloka"],
  ["SN 24.83", "Taṃ jivaṃ taṃ sarira"],
  ["SN 24.84", "Aññaṃ jīvaṃ aññaṃ sarīra"],
  ["SN 24.85", "Hoti tathāgata"],
  ["SN 24.86", "Na hoti tathāgata"],
  ["SN 24.87", "Hoti ca na ca hoti tathāgata"],
  ["SN 24.88", "Hoti ca na ca hoti tathāgata"],
  ["SN 24.89", "Rūpī attā"],
  ["SN 24.90", "Arūpī attā"],
  ["SN 24.91", "Rūpīva arūpīva attā"],
  ["SN 24.92", "Neva rūpī nārūpī attā"],
  ["SN 24.93", "Ekantasukhī attā"],
  ["SN 24.94", "Ekantadukkhī attā"],
  ["SN 24.95", "Sukhadukkhī attā"],
  ["SN 24.96", "Adukkhamasukhī attā"],
  ["SN 25.1", "Cakkhu"],
  ["SN 25.2", "Rūpa"],
  ["SN 25.3", "Viññāṇa"],
  ["SN 25.4", "Phassa"],
  ["SN 25.5", "Vedanā"],
  ["SN 25.6", "Saññā"],
  ["SN 25.7", "Cetanā"],
  ["SN 25.8", "Taṇhā"],
  ["SN 25.9", "Dhātu"],
  ["SN 25.10", "Khandha"],
  ["SN 26.1", "Cakkhu"],
  ["SN 26.2", "Rūpa"],
  ["SN 26.3", "Viññāṇa"],
  ["SN 26.4", "Phassa"],
  ["SN 26.5", "Vedanā"],
  ["SN 26.6", "Saññā"],
  ["SN 26.7", "Cetanā"],
  ["SN 26.8", "Taṇhā"],
  ["SN 26.9", "Dhātu"],
  ["SN 26.10", "Khandha"],
  ["SN 27.1", "Cakkhu"],
  ["SN 27.2", "Rūpa"],
  ["SN 27.3", "Viññāṇa"],
  ["SN 27.4", "Phassa"],
  ["SN 27.5", "Vedanā"],
  ["SN 27.6", "Saññā"],
  ["SN 27.7", "Cetanā"],
  ["SN 27.8", "Taṇhā"],
  ["SN 27.9", "Dhātu"],
  ["SN 27.10", "Khandha"],
  ["SN 28.1", "Vivekaja"],
  ["SN 28.2", "Avitakka"],
  ["SN 28.3", "Piti"],
  ["SN 28.4", "Upekkhā"],
  ["SN 28.5", "Ākāsānañcāyatana"],
  ["SN 28.6", "Viññāṇañcāyatana"],
  ["SN 28.7", "Ākiñcaññāyatana"],
  ["SN 28.8", "Nevasaññānāsaññayatana"],
  ["SN 28.9", "Nirodhasamāpatti"],
  ["SN 28.10", "Sūcimukhī"],
  ["SN 29.1", "Suddhika"],
  ["SN 29.2", "Paṇītatara"],
  ["SN 29.3", "Paṭhama Uposatha"],
  ["SN 29.4", "Dutiya Uposatha"],
  ["SN 29.5", "Tatiya Uposatha"],
  ["SN 29.6", "Catuttha Uposatha"],
  ["SN 29.7", "Paṭhama Suta"],
  ["SN 29.8", "Dutiya Suta"],
  ["SN 29.9", "Tatiya Suta"],
  ["SN 29.10", "Catuttha Suta"],
  ["SN 29.11", "Annadāyaka aṇḍaja"],
  ["SN 29.12", "Pānadāyaka aṇḍaja"],
  ["SN 29.13", "Vatthadāyaka aṇḍaja"],
  ["SN 29.14", "Yānadāyaka aṇḍaja"],
  ["SN 29.15", "Mālādāyaka aṇḍaja"],
  ["SN 29.16", "Gandhadāyaka aṇḍaja"],
  ["SN 29.17", "Vilepanadāyaka aṇḍaja"],
  ["SN 29.18", "Seyyadāyaka aṇḍaja"],
  ["SN 29.19", "Āvasathadāyaka aṇḍaja"],
  ["SN 29.20", "Padīpeyyadāyaka aṇḍaja"],
  ["SN 29.21", "Annadāyaka jalābuja"],
  ["SN 29.22", "Pānadāyaka jalābuja"],
  ["SN 29.23", "Vatthadāyaka jalābuja"],
  ["SN 29.24", "Yānadāyaka jalābuja"],
  ["SN 29.25", "Mālādāyaka jalābuja"],
  ["SN 29.26", "Gandhadāyaka jalābuja"],
  ["SN 29.27", "Vilepanadāyaka jalābuja"],
  ["SN 29.28", "Seyyadāyaka jalābuja"],
  ["SN 29.29", "Āvasathadāyaka jalābuja"],
  ["SN 29.30", "Padīpeyyadāyaka jalābuja"],
  ["SN 29.31", "Annadāyaka saṃsedaja"],
  ["SN 29.32", "Pānadāyaka saṃsedaja"],
  ["SN 29.33", "Vatthadāyaka saṃsedaja"],
  ["SN 29.34", "Yānadāyaka saṃsedaja"],
  ["SN 29.35", "Mālādāyaka saṃsedaja"],
  ["SN 29.36", "Gandhadāyaka saṃsedaja"],
  ["SN 29.37", "Vilepanadāyaka saṃsedaja"],
  ["SN 29.38", "Seyyadāyaka saṃsedaja"],
  ["SN 29.39", "Āvasathadāyaka saṃsedaja"],
  ["SN 29.40", "Padīpeyyadāyaka saṃsedaja"],
  ["SN 29.41", "Annadāyaka opapātika"],
  ["SN 29.42", "Pānadāyaka opapātika"],
  ["SN 29.43", "Vatthadāyaka opapātika"],
  ["SN 29.44", "Yānadāyaka opapātika"],
  ["SN 29.45", "Mālādāyaka opapātika"],
  ["SN 29.46", "Gandhadāyaka opapātika"],
  ["SN 29.47", "Vilepanadāyaka opapātika"],
  ["SN 29.48", "Seyyadāyaka opapātika"],
  ["SN 29.49", "Āvasathadāyaka opapātika"],
  ["SN 29.50", "Padīpeyyadāyaka opapātika"],
  ["SN 30.1", "Suddhi"],
  ["SN 30.2", "Haranti"],
  ["SN 30.3", "Paṭhama Dvayakāri"],
  ["SN 30.4", "Dutiya Dvayakāri"],
  ["SN 30.5", "Tatiya Dvayakāri"],
  ["SN 30.6", "Catuttha Dvayakāri"],
  ["SN 30.7", "Annadāyaka aṇḍaja"],
  ["SN 30.8", "Pānadāyaka aṇḍaja"],
  ["SN 30.9", "Vatthadāyaka aṇḍaja"],
  ["SN 30.10", "Yānadāyaka aṇḍaja"],
  ["SN 30.11", "Mālādāyaka aṇḍaja"],
  ["SN 30.12", "Gandhadāyaka aṇḍaja"],
  ["SN 30.13", "Vilepanadāyaka aṇḍaja"],
  ["SN 30.14", "Seyyadāyaka aṇḍaja"],
  ["SN 30.15", "Āvasathadāyaka aṇḍaja"],
  ["SN 30.16", "Padīpeyyadāyaka aṇḍaja"],
  ["SN 30.17", "Annadāyaka jalābuja"],
  ["SN 30.18", "Pānadāyaka jalābuja"],
  ["SN 30.19", "Vatthadāyaka jalābuja"],
  ["SN 30.20", "Yānadāyaka jalābuja"],
  ["SN 30.21", "Mālādāyaka jalābuja"],
  ["SN 30.22", "Gandhadāyaka jalābuja"],
  ["SN 30.23", "Vilepanadāyaka jalābuja"],
  ["SN 30.24", "Seyyadāyaka jalābuja"],
  ["SN 30.25", "Āvasathadāyaka jalābuja"],
  ["SN 30.26", "Padīpeyyadāyaka jalābuja"],
  ["SN 30.27", "Annadāyaka saṃsedaja"],
  ["SN 30.28", "Pānadāyaka saṃsedaja"],
  ["SN 30.29", "Vatthadāyaka saṃsedaja"],
  ["SN 30.30", "Yānadāyaka saṃsedaja"],
  ["SN 30.31", "Mālādāyaka saṃsedaja"],
  ["SN 30.32", "Gandhadāyaka saṃsedaja"],
  ["SN 30.33", "Vilepanadāyaka saṃsedaja"],
  ["SN 30.34", "Seyyadāyaka saṃsedaja"],
  ["SN 30.35", "Āvasathadāyaka saṃsedaja"],
  ["SN 30.36", "Padīpeyyadāyaka saṃsedaja"],
  ["SN 30.37", "Annadāyaka opapātika"],
  ["SN 30.38", "Pānadāyaka opapātika"],
  ["SN 30.39", "Vatthadāyaka opapātika"],
  ["SN 30.40", "Yānadāyaka opapātika"],
  ["SN 30.41", "Mālādāyaka opapātika"],
  ["SN 30.42", "Gandhadāyaka opapātika"],
  ["SN 30.43", "Vilepanadāyaka opapātika"],
  ["SN 30.44", "Seyyadāyaka opapātika"],
  ["SN 30.45", "Āvasathadāyaka opapātika"],
  ["SN 30.46", "Padīpeyyadāyaka opapātika"],
  ["SN 31.1", "Suddhika"],
  ["SN 31.2", "Sucarita"],
  ["SN 31.3", "Mūlagandha"],
  ["SN 31.4", "Sāragandha"],
  ["SN 31.5", "Pheggugandha"],
  ["SN 31.6", "Tacagandha"],
  ["SN 31.7", "Papaṭikagandha"],
  ["SN 31.8", "Pattagandha"],
  ["SN 31.9", "Pupphagandha"],
  ["SN 31.10", "Phalagandha"],
  ["SN 31.11", "Rasagandha"],
  ["SN 31.12", "Gandhagandha"],
  ["SN 31.13", "Annadāna mūlagandha"],
  ["SN 31.14", "Pānadāna mūlagandha"],
  ["SN 31.15", "Vatthadāna mūlagandha"],
  ["SN 31.16", "Yānadāna mūlagandha"],
  ["SN 31.17", "Māladāna mūlagandha"],
  ["SN 31.18", "Gandhadāna mūlagandha"],
  ["SN 31.19", "Vilepanadāna mūlagandha"],
  ["SN 31.20", "Seyyadāna mūlagandha"],
  ["SN 31.21", "Āvasathadāna mūlagandha"],
  ["SN 31.22", "Padīpeyyadāna mūlagandha"],
  ["SN 31.23", "Ananadāna sāragandha"],
  ["SN 31.24", "Pānadāna sāragandha"],
  ["SN 31.25", "Vatthadāna sāragandha"],
  ["SN 31.26", "Yānadāna sāragandha"],
  ["SN 31.27", "Māladāna sāragandha"],
  ["SN 31.28", "Gandhadāna sāragandha"],
  ["SN 31.29", "Vilepanadāna sāragandha"],
  ["SN 31.30", "Seyyadāna sāragandha"],
  ["SN 31.31", "Āvasathadāna sāragandha"],
  ["SN 31.32", "Padīpeyyadāna sāragandha"],
  ["SN 31.33", "Annadāna pheggugandha"],
  ["SN 31.34", "Pānadāna pheggugandha"],
  ["SN 31.35", "Vatthadāna pheggugandha"],
  ["SN 31.36", "Yānadāna pheggugandha"],
  ["SN 31.37", "Māladāna pheggugandha"],
  ["SN 31.38", "Gandhadāna pheggugandha"],
  ["SN 31.39", "Vilepanadāna pheggugandha"],
  ["SN 31.40", "Seyyadāna pheggugandha"],
  ["SN 31.41", "Āvasathadāna pheggugandha"],
  ["SN 31.42", "Padīpeyyadāna pheggugandha"],
  ["SN 31.43", "Annadāna tacagandha"],
  ["SN 31.44", "Pānadāna tacagandha"],
  ["SN 31.45", "Vatthadāna tacagandha"],
  ["SN 31.46", "Yānadāna tacagandha"],
  ["SN 31.47", "Māladāna tacagandha"],
  ["SN 31.48", "Gandhadāna tacagandha"],
  ["SN 31.49", "Vilepanadāna tacagandha"],
  ["SN 31.50", "Seyyadāna tacagandha"],
  ["SN 31.51", "Āvasathadāna tacagandha"],
  ["SN 31.52", "Padīpeyyadāna tacagandha"],
  ["SN 31.53", "Annadāna papaṭikāgandha"],
  ["SN 31.54", "Pānadāna papaṭikāgandha"],
  ["SN 31.55", "Vatthadāna papaṭikāgandha"],
  ["SN 31.56", "Yānadāna papaṭikāgandha"],
  ["SN 31.57", "Māladāna papaṭikāgandha"],
  ["SN 31.58", "Gandhadāna papaṭikāgandha"],
  ["SN 31.59", "Vilepanadāna papaṭikāgandha"],
  ["SN 31.60", "Seyyadāna papaṭikāgandha"],
  ["SN 31.61", "Āvasathadāna papaṭikāgandha"],
  ["SN 31.62", "Padīpeyyadāna papaṭikāgandha"],
  ["SN 31.63", "Annadāna pattagandha"],
  ["SN 31.64", "Pānadāna pattagandha"],
  ["SN 31.65", "Vatthadāna pattagandha"],
  ["SN 31.66", "Yānadāna pattagandha"],
  ["SN 31.67", "Māladāna pattagandha"],
  ["SN 31.68", "Gandhadāna pattagandha"],
  ["SN 31.69", "Vilepanadāna pattagandha"],
  ["SN 31.70", "Seyyadāna pattagandha"],
  ["SN 31.71", "Āvasathadāna pattagandha"],
  ["SN 31.72", "Padīpeyyadāna pattagandha"],
  ["SN 31.73", "Annadāna pupphagandha"],
  ["SN 31.74", "Pānadāna pupphagandha"],
  ["SN 31.75", "Vatthadāna pupphagandha"],
  ["SN 31.76", "Yānadāna pupphagandha"],
  ["SN 31.77", "Māladāna pupphagandha"],
  ["SN 31.78", "Gandhadāna pupphagandha"],
  ["SN 31.79", "Vilepanadāna pupphagandha"],
  ["SN 31.80", "Seyyadāna pupphagandha"],
  ["SN 31.81", "Āvasathadāna pupphagandha"],
  ["SN 31.82", "Padīpeyyadāna pupphagandha"],
  ["SN 31.83", "Annadāna phalagandha"],
  ["SN 31.84", "Pānadāna phalagandha"],
  ["SN 31.85", "Vatthadāna phalagandha"],
  ["SN 31.86", "Yānadāna phalagandha"],
  ["SN 31.87", "Māladāna phalagandha"],
  ["SN 31.88", "Gandhadāna phalagandha"],
  ["SN 31.89", "Vilepanadāna phalagandha"],
  ["SN 31.90", "Seyyadāna phalagandha"],
  ["SN 31.91", "Āvasathadāna phalagandha"],
  ["SN 31.92", "Padīpeyyadāna phalagandha"],
  ["SN 31.93", "Annadāna rasagandha"],
  ["SN 31.94", "Pānadāna rasagandha"],
  ["SN 31.95", "Vatthadāna rasagandha"],
  ["SN 31.96", "Yānadāna rasagandha"],
  ["SN 31.97", "Māladāna rasagandha"],
  ["SN 31.98", "Gandhadāna rasagandha"],
  ["SN 31.99", "Vilepanadāna rasagandha"],
  ["SN 31.100", "Seyyadāna rasagandha"],
  ["SN 31.101", "Āvasathadāna rasagandha"],
  ["SN 31.102", "Padīpeyyadāna rasagandha"],
  ["SN 31.103", "Annadāna gandhagandha"],
  ["SN 31.104", "Pānadāna gandhagandha"],
  ["SN 31.105", "Vatthadāna gandhagandha"],
  ["SN 31.106", "Yānadāna gandhagandha"],
  ["SN 31.107", "Māladāna gandhagandha"],
  ["SN 31.108", "Gandhadāna gandhagandha"],
  ["SN 31.109", "Vilepanadāna gandhagandha"],
  ["SN 31.110", "Seyyadāna gandhagandha"],
  ["SN 31.111", "Āvasathadāna gandhagandha"],
  ["SN 31.112", "Padīpeyyadāna gandhagandha"],
  ["SN 32.1", "Suddhika"],
  ["SN 32.2", "Sucarita"],
  ["SN 32.3", "Annadāyaka sītavalāhaka"],
  ["SN 32.4", "Pānadāyaka sītavalāhaka"],
  ["SN 32.5", "Vatthadāyaka sītavalāhaka"],
  ["SN 32.6", "Yānadāyaka sītavalāhaka"],
  ["SN 32.7", "Māladāyaka sītavalāhaka"],
  ["SN 32.8", "Gandhadāyaka sītavalāhaka"],
  ["SN 32.9", "Vilepanadāyaka sītavalāhaka"],
  ["SN 32.10", "Seyyadāyaka sītavalāhaka"],
  ["SN 32.11", "Āvasathadāyaka sītavalāhaka"],
  ["SN 32.12", "Padīpeyyadāyaka sītavalāhaka"],
  ["SN 32.13", "Annadāyaka uṇhavalāhaka"],
  ["SN 32.14", "Pānadāyaka uṇhavalāhaka"],
  ["SN 32.15", "Vatthadāyaka uṇhavalāhaka"],
  ["SN 32.16", "Yānadāyaka uṇhavalāhaka"],
  ["SN 32.17", "Māladāyaka uṇhavalāhaka"],
  ["SN 32.18", "Gandhadāyaka uṇhavalāhaka"],
  ["SN 32.19", "Vilepanadāyaka uṇhavalāhaka"],
  ["SN 32.20", "Seyyadāyaka uṇhavalāhaka"],
  ["SN 32.21", "Āvasathadāyaka uṇhavalāhaka"],
  ["SN 32.22", "Padīpeyyadāyaka uṇhavalāhaka"],
  ["SN 32.23", "Annadāyaka abbhavalāhaka"],
  ["SN 32.24", "Pānadāyaka abbhavalāhaka"],
  ["SN 32.25", "Vatthadāyaka abbhavalāhaka"],
  ["SN 32.26", "Yānadāyaka abbhavalāhaka"],
  ["SN 32.27", "Māladāyaka abbhavalāhaka"],
  ["SN 32.28", "Gandhadāyaka abbhavalāhaka"],
  ["SN 32.29", "Vilepanadāyaka abbhavalāhaka"],
  ["SN 32.30", "Seyyadāyaka abbhavalāhaka"],
  ["SN 32.31", "Āvasathadāyaka abbhavalāhaka"],
  ["SN 32.32", "Padīpeyyadāyaka abbhavalāhaka"],
  ["SN 32.33", "Annadāyaka vātavalāhaka"],
  ["SN 32.34", "Pānadāyaka vātavalāhaka"],
  ["SN 32.35", "Vatthadāyaka vātavalāhaka"],
  ["SN 32.36", "Yānadāyaka vātavalāhaka"],
  ["SN 32.37", "Māladāyaka vātavalāhaka"],
  ["SN 32.38", "Gandhadāyaka vātavalāhaka"],
  ["SN 32.39", "Vilepanadāyaka vātavalāhaka"],
  ["SN 32.40", "Seyyadāyaka vātavalāhaka"],
  ["SN 32.41", "Āvasathadāyaka vātavalāhaka"],
  ["SN 32.42", "Padīpeyyadāyaka vātavalāhaka"],
  ["SN 32.43", "Annadāyaka vassavalāhaka"],
  ["SN 32.44", "Pānadāyaka vassavalāhaka"],
  ["SN 32.45", "Vatthadāyaka vassavalāhaka"],
  ["SN 32.46", "Yānadāyaka vassavalāhaka"],
  ["SN 32.47", "Māladāyaka vassavalāhaka"],
  ["SN 32.48", "Gandhadāyaka vassavalāhaka"],
  ["SN 32.49", "Vilepanadāyaka vassavalāhaka"],
  ["SN 32.50", "Seyyadāyaka vassavalāhaka"],
  ["SN 32.51", "Āvasathadāyaka vassavalāhaka"],
  ["SN 32.52", "Padīpeyyadāyaka vassavalāhaka"],
  ["SN 32.53", "Sītavalāhaka"],
  ["SN 32.54", "Uṇhavalāhaka"],
  ["SN 32.55", "Abbhavalāhaka"],
  ["SN 32.56", "Vātavalāhaka"],
  ["SN 32.57", "Vassavalāhaka"],
  ["SN 33.1", "Rūpa aññāṇa"],
  ["SN 33.2", "Vedanā aññāṇa"],
  ["SN 33.3", "Saññā aññāṇa"],
  ["SN 33.4", "Saṃkhāra aññāṇa"],
  ["SN 33.5", "Viññāṇa aññāṇa"],
  ["SN 33.6", "Rūpa adassana"],
  ["SN 33.7", "Vedanā adassana"],
  ["SN 33.8", "Saññā adassana"],
  ["SN 33.9", "Saṃkhāra adassana"],
  ["SN 33.10", "Viññāṇa adassana"],
  ["SN 33.11", "Rūpa anabhisamaya"],
  ["SN 33.12", "Vedanā anabhisamaya"],
  ["SN 33.13", "Saññā anabhisamaya"],
  ["SN 33.14", "Saṃkhāra anabhisamaya"],
  ["SN 33.15", "Viññāṇa anabhisamaya"],
  ["SN 33.16", "Rūpa ananubodha"],
  ["SN 33.17", "Vavavavedanā ananubodha"],
  ["SN 33.18", "Saññā ananubodha"],
  ["SN 33.19", "Saṃkhāra ananubodha"],
  ["SN 33.20", "Viññāṇa ananubodha"],
  ["SN 33.21", "Rūpa appaṭivedha"],
  ["SN 33.22", "Vedanā appaṭivedha"],
  ["SN 33.23", "Saññā appaṭivedha"],
  ["SN 33.24", "Saṃkhāra appaṭivedha"],
  ["SN 33.25", "Viññāṇa appaṭivedha"],
  ["SN 33.26", "Rūpa asallakkhaṇa"],
  ["SN 33.27", "Vedanā asallakkhaṇa"],
  ["SN 33.28", "Saññā asallakkhaṇa"],
  ["SN 33.29", "Saṃkhāra asallakkhaṇa"],
  ["SN 33.30", "Viññāṇa asallakkhaṇa"],
  ["SN 33.31", "Rūpa anupalakkhaṇa"],
  ["SN 33.32", "Vedanā anupalakkhaṇa"],
  ["SN 33.33", "Saññā anupalakkhaṇa"],
  ["SN 33.34", "Saṃkhāra anupalakkhaṇa"],
  ["SN 33.35", "Viññāṇa anupalakkhaṇa"],
  ["SN 33.36", "Rūpa apaccupalakkhaṇa"],
  ["SN 33.37", "Vedanā apaccupalakkhaṇa"],
  ["SN 33.38", "Saññā apaccupalakkhaṇa"],
  ["SN 33.39", "Saṅkhāra apaccupalakkhaṇa"],
  ["SN 33.40", "Viññāṇa apaccupalakkhaṇa"],
  ["SN 33.41", "Rūpa asamapekkhaṇa"],
  ["SN 33.42", "Vedanā asamapekkhaṇa"],
  ["SN 33.43", "Saññā asamapekkhaṇa"],
  ["SN 33.44", "Saṃkhāra asamapekkhaṇa"],
  ["SN 33.45", "Viññāṇa asamapekkhaṇa"],
  ["SN 33.46", "Rūpa apaccupekkhaṇa"],
  ["SN 33.47", "Vedanā apaccupekkhaṇa"],
  ["SN 33.48", "Saññā apaccupekkhaṇa"],
  ["SN 33.49", "Saṃkhāra apaccupekkhaṇa"],
  ["SN 33.50", "Viññāṇa apaccupekkhaṇa"],
  ["SN 33.51", "Rūpa apaccakkhakamma"],
  ["SN 33.52", "Vedanā apaccakkhakamma"],
  ["SN 33.53", "Saññā apaccakkhakamma"],
  ["SN 33.54", "Saṃkhāra apaccakkhakamma"],
  ["SN 33.55", "Viññāṇa apaccakkhakamma"],
  ["SN 34.1", "Samādhisamāpatti"],
  ["SN 34.2", "Ṭhiti"],
  ["SN 34.3", "Vuṭṭhāna"],
  ["SN 34.4", "Kallita"],
  ["SN 34.5", "Ārammaṇa"],
  ["SN 34.6", "Gocara"],
  ["SN 34.7", "Abhinīhāra"],
  ["SN 34.8", "Sakkaccakāri"],
  ["SN 34.9", "Sātaccakārī"],
  ["SN 34.10", "Sappāyakārī"],
  ["SN 34.11", "Samāpatti ṭhiti"],
  ["SN 34.12", "Samāpatti vuṭṭhāna"],
  ["SN 34.13", "Samāpatti kallita"],
  ["SN 34.14", "Samāpatti ārammaṇa"],
  ["SN 34.15", "Samāpatti gocara"],
  ["SN 34.16", "Samāpatti abhinīhāra"],
  ["SN 34.17", "Samāpatti sakkaccakārī"],
  ["SN 34.18", "Samāpatti sātaccakārī"],
  ["SN 34.19", "Samāpatti sappāyakāri"],
  ["SN 34.20", "Ṭhiti vuṭṭhāna"],
  ["SN 34.21", "Ṭhiti kallita"],
  ["SN 34.22", "Ṭhiti ārammaṇa"],
  ["SN 34.23", "Ṭhiti gocara"],
  ["SN 34.24", "Ṭhiti abhinihāra"],
  ["SN 34.25", "Ṭhiti sakkaccakārī"],
  ["SN 34.26", "Ṭhiti sātaccakārī"],
  ["SN 34.27", "Ṭhiti sappāyakārī"],
  ["SN 34.28", "Vuṭṭhāna kallita"],
  ["SN 34.29", "Vuṭṭhāna ārammaṇa"],
  ["SN 34.30", "Vuṭṭhāna gocara"],
  ["SN 34.31", "Vuṭṭhāna abhinīhāra"],
  ["SN 34.32", "Vuṭṭhāna sakkaccakārī"],
  ["SN 34.33", "Vuṭṭhāna sāttaccakārī"],
  ["SN 34.34", "Vuṭṭhāna sappāyakārī"],
  ["SN 34.35", "Kallita ārammaṇa"],
  ["SN 34.36", "Kallita gocara"],
  ["SN 34.37", "Kallita abhinīhāra"],
  ["SN 34.38", "Kallita sakkaccakārī"],
  ["SN 34.39", "Kallita sātaccakāri"],
  ["SN 34.40", "Kallita sappāyakāri"],
  ["SN 34.41", "Ārammaṇa gocara"],
  ["SN 34.42", "Ārammaṇa abhinihāra"],
  ["SN 34.43", "Ārammaṇa sakkaccakārī"],
  ["SN 34.44", "Ārammaṇa sātaccakārī"],
  ["SN 34.45", "Ārammaṇa sappāyakāri"],
  ["SN 34.46", "Gocara abhinīhāra"],
  ["SN 34.47", "Gocara sakkaccakāri"],
  ["SN 34.48", "Gocara sātaccakārī"],
  ["SN 34.49", "Gocara sappāyakārī"],
  ["SN 34.50", "Abhinihāra sakkaccakāri"],
  ["SN 34.51", "Abhinīhāra sātaccakārī"],
  ["SN 34.52", "Abhinīhāra sappāyakāri"],
  ["SN 34.53", "Sakkacca sātaccakārī"],
  ["SN 34.54", "Sakkacca sappāyakārī"],
  ["SN 34.55", "Sātacca sappāyakārī"],
  ["SN 35.1", "Paṭhama Ajjhattānicca"],
  ["SN 35.2", "Paṭhama Ajjhattadukkha"],
  ["SN 35.3", "Paṭhama Ajjhattānatta"],
  ["SN 35.4", "Paṭhama Bāhirānicca"],
  ["SN 35.5", "Paṭhama Bāhiradukkha"],
  ["SN 35.6", "Paṭhama Bāhirānatta"],
  ["SN 35.7", "Dutiya Ajjhattānicca"],
  ["SN 35.8", "Dutiya Ajjhattadukkha"],
  ["SN 35.9", "Dutiya Ajjhattānatta"],
  ["SN 35.10", "Dutiya Bāhirānicca"],
  ["SN 35.11", "Dutiya Bāhiradukkha"],
  ["SN 35.12", "Dutiya Bāhirānatta"],
  ["SN 35.13", "Paṭhama Sambodha"],
  ["SN 35.14", "Dutiya Sambodha"],
  ["SN 35.15", "Paṭhama Assādapariyesana"],
  ["SN 35.16", "Dutiya Assādapariyesana"],
  ["SN 35.17", "Paṭhama No ve assāda"],
  ["SN 35.18", "Dutiya No ve assāda"],
  ["SN 35.19", "Paṭhama Abhinanda"],
  ["SN 35.20", "Dutiya Abhinanda"],
  ["SN 35.21", "Paṭhama Uppāda"],
  ["SN 35.22", "Dutiya Uppāda"],
  ["SN 35.23", "Sabba"],
  ["SN 35.24", "Paṭhama Pahāna"],
  ["SN 35.25", "Dutiya Pahāna"],
  ["SN 35.26", "Paṭhama Aparijāna"],
  ["SN 35.27", "Dutiya Aparijāna"],
  ["SN 35.28", "Ādittapariyāya"],
  ["SN 35.29", "Andhabhūta"],
  ["SN 35.30", "Sāruppapaṭipadā"],
  ["SN 35.31", "Paṭhama Sappāyapaṭipadā"],
  ["SN 35.32", "Dutiya Sappāyapaṭipadā"],
  ["SN 35.33", "Jātidhamma"],
  ["SN 35.34", "Jarādhamma"],
  ["SN 35.35", "Vyādhidhamma"],
  ["SN 35.36", "Maraṇadhamma"],
  ["SN 35.37", "Sokadhamma"],
  ["SN 35.38", "Saṃkilesadhamma"],
  ["SN 35.39", "Khayadhamma"],
  ["SN 35.40", "Vayadhamma"],
  ["SN 35.41", "Samudayadhamma"],
  ["SN 35.42", "Nirodhadhamma"],
  ["SN 35.43", "Anicca"],
  ["SN 35.44", "Dukkha"],
  ["SN 35.45", "Anatta"],
  ["SN 35.46", "Abhiññeyya"],
  ["SN 35.47", "Pariññeyya"],
  ["SN 35.48", "Pahātabba"],
  ["SN 35.49", "Sacchikātabba"],
  ["SN 35.50", "Abhiññeyyapariññeyya"],
  ["SN 35.51", "Upadduta"],
  ["SN 35.52", "Upassaṭṭha"],
  ["SN 35.53", "Avijjāpahāna"],
  ["SN 35.54", "Saññojanapahāna"],
  ["SN 35.55", "Saññojanasamugghāta"],
  ["SN 35.56", "Āsavappahāna"],
  ["SN 35.57", "Āsavasamugghāta"],
  ["SN 35.58", "Anusayappahāna"],
  ["SN 35.59", "Anusayasamugghāta"],
  ["SN 35.60", "Sabbūpādānapariññā"],
  ["SN 35.61", "Paṭhama Sabbūpādānapariyādāna"],
  ["SN 35.62", "Dutiya Sabbūpādānapariyādāna"],
  ["SN 35.63", "Paṭhama Migajāla"],
  ["SN 35.64", "Dutiya Migajāla"],
  ["SN 35.65", "Samiddhi-mārapañha"],
  ["SN 35.66", "Samiddhi-sattapañha"],
  ["SN 35.67", "Samiddhi-dukkhapañha"],
  ["SN 35.68", "Samiddhi-lokapañha"],
  ["SN 35.69", "Upasena"],
  ["SN 35.70", "Upavāṇa"],
  ["SN 35.71", "Paṭhama Chaphassāyatana"],
  ["SN 35.72", "Dutiya Chaphassāyatana"],
  ["SN 35.73", "Tatiya Chaphassāyatana"],
  ["SN 35.74", "Paṭhama Gilāna"],
  ["SN 35.75", "Dutiya Gilāna"],
  ["SN 35.76", "Anicca"],
  ["SN 35.77", "Dukkha"],
  ["SN 35.78", "Anatta"],
  ["SN 35.79", "Paṭhama Avijjā"],
  ["SN 35.80", "Dutiya Avijjā"],
  ["SN 35.81", "Sambahulabhikkhu"],
  ["SN 35.82", "Loka"],
  ["SN 35.83", "Phagguna"],
  ["SN 35.84", "Palokadhamma"],
  ["SN 35.85", "Suññaloka"],
  ["SN 35.86", "Saṅkhittadhamma"],
  ["SN 35.87", "Channa"],
  ["SN 35.88", "Puṇṇa"],
  ["SN 35.89", "Bāhiya"],
  ["SN 35.90", "Paṭhama Ejā"],
  ["SN 35.91", "Dutiya Ejā"],
  ["SN 35.92", "Paṭhama Dvaya"],
  ["SN 35.93", "Dutiya Dvaya"],
  ["SN 35.94", "Adanta agutta"],
  ["SN 35.95", "Māluṅkyaputta"],
  ["SN 35.96", "Parihānadhamma"],
  ["SN 35.97", "Pamādavihāri"],
  ["SN 35.98", "Saṃvara"],
  ["SN 35.99", "Samādhi"],
  ["SN 35.100", "Paṭisallāna"],
  ["SN 35.101", "Paṭhama Natumhāka"],
  ["SN 35.102", "Dutiya Natumhāka"],
  ["SN 35.103", "Uddaka"],
  ["SN 35.104", "Yogakkhemi"],
  ["SN 35.105", "Upādāya"],
  ["SN 35.106", "Dukkhasamudaya"],
  ["SN 35.107", "Lokasamudaya"],
  ["SN 35.108", "Seyya"],
  ["SN 35.109", "Saññojana"],
  ["SN 35.110", "Upādāna"],
  ["SN 35.111", "Paṭhama Parijānana"],
  ["SN 35.112", "Dutiya Parijānana"],
  ["SN 35.113", "Upassuti"],
  ["SN 35.114", "Paṭhama Mārapāsa"],
  ["SN 35.115", "Dutiya Mārapāsa"],
  ["SN 35.116", "Lokantagamana"],
  ["SN 35.117", "Kāmaguṇa"],
  ["SN 35.118", "Sakkapañha"],
  ["SN 35.119", "Pañcasikhapañha"],
  ["SN 35.120", "Sāriputtasaddhivihārika"],
  ["SN 35.121", "Rāhulovāda"],
  ["SN 35.122", "Saññojana"],
  ["SN 35.123", "Upādāna"],
  ["SN 35.124", "Vesāli"],
  ["SN 35.125", "Vajji"],
  ["SN 35.126", "Nālandā"],
  ["SN 35.127", "Bhāradvāja"],
  ["SN 35.128", "Soṇa"],
  ["SN 35.129", "Ghosita"],
  ["SN 35.130", "Hāliddakāni"],
  ["SN 35.131", "Nakulapitu"],
  ["SN 35.132", "Lohicca"],
  ["SN 35.133", "Verahaccāni"],
  ["SN 35.134", "Devadaha"],
  ["SN 35.135", "Khaṇa"],
  ["SN 35.136", "Sagayha"],
  ["SN 35.137", "Agayha"],
  ["SN 35.138", "Paṭhama Palāsa"],
  ["SN 35.139", "Dutiya Palāsa"],
  ["SN 35.140", "Ajjhattānicca"],
  ["SN 35.141", "Ajjhattadukkha"],
  ["SN 35.142", "Ajjhattānatta"],
  ["SN 35.143", "Bāhirānicca"],
  ["SN 35.144", "Bāhiradukkha"],
  ["SN 35.145", "Bāhirānatta"],
  ["SN 35.146", "Kamma"],
  ["SN 35.147", "Paṭhama Sappāya"],
  ["SN 35.148", "Dutiya Sappāya"],
  ["SN 35.149", "Tatiya Sappāya"],
  ["SN 35.150", "Catuttha Sappāya"],
  ["SN 35.151", "Antevāsika"],
  ["SN 35.152", "Kimatthiya"],
  ["SN 35.153", "Atthinukhopariyāya"],
  ["SN 35.154", "Indriyasampanna"],
  ["SN 35.155", "Dhammakathika"],
  ["SN 35.156", "Paṭhama Nandikkhaya"],
  ["SN 35.157", "Dutiya Nandikkhaya"],
  ["SN 35.158", "Tatiya Nandikkhaya"],
  ["SN 35.159", "Catuttha Nandikkhaya"],
  ["SN 35.160", "Paṭhama Jīvakambavane"],
  ["SN 35.161", "Dutiya Jīvakambavane"],
  ["SN 35.162", "Paṭhama Koṭṭhita"],
  ["SN 35.163", "Dutiya Koṭṭhita"],
  ["SN 35.164", "Tatiya Koṭṭhita"],
  ["SN 35.165", "Micchādiṭṭhippahāna"],
  ["SN 35.166", "Sakkāyadiṭṭhippahāna"],
  ["SN 35.167", "Attānudiṭṭhippahāna"],
  ["SN 35.168", "Aniccachanda"],
  ["SN 35.169", "Aniccarāga"],
  ["SN 35.170", "Aniccachandarāga"],
  ["SN 35.171", "Dukkhachanda"],
  ["SN 35.172", "Dukkharāga"],
  ["SN 35.173", "Dukkhachandarāga"],
  ["SN 35.174", "Anattachanda"],
  ["SN 35.175", "Anantarāga"],
  ["SN 35.176", "Anattachandarāga"],
  ["SN 35.177", "Bāhira aniccachanda"],
  ["SN 35.178", "Bāhira aniccarāga"],
  ["SN 35.179", "Bāhira aniccachandarāga"],
  ["SN 35.180", "Bāhira dukkhachanda"],
  ["SN 35.181", "Bāhira dukkharāga"],
  ["SN 35.182", "Bāhira dukkhachandarāga"],
  ["SN 35.183", "Bāhira anattachanda"],
  ["SN 35.184", "Bāhira anattarāga"],
  ["SN 35.185", "Bāhira anattachandarāga"],
  ["SN 35.186", "Atītānicca"],
  ["SN 35.187", "Anāgatānicca"],
  ["SN 35.188", "Paccuppannānicca"],
  ["SN 35.189", "Atītadukkha"],
  ["SN 35.190", "Anāgatadukkha"],
  ["SN 35.191", "Paccuppannadukkha"],
  ["SN 35.192", "Atītānatta"],
  ["SN 35.193", "Anāgatānatta"],
  ["SN 35.194", "Paccuppannānattanicca"],
  ["SN 35.195", "Bāhira atītānicca"],
  ["SN 35.196", "Bāhira anāgatānicca"],
  ["SN 35.197", "Bāhira paccuppannānicca"],
  ["SN 35.198", "Bāhira atīta dukkha"],
  ["SN 35.199", "Bāhira anāgata dukkha"],
  ["SN 35.200", "Bāhira paccuppannadukkha"],
  ["SN 35.201", "Bāhira atītānatta"],
  ["SN 35.202", "Bāhira anāgatānatta"],
  ["SN 35.203", "Bāhira paccuppannānatta"],
  ["SN 35.204", "Atītayadanicca"],
  ["SN 35.205", "Anāgatayadanicca"],
  ["SN 35.206", "Paccuppannayadanicca"],
  ["SN 35.207", "Atīta yaṃ dukkha"],
  ["SN 35.208", "Anāgata yaṃ dukkha"],
  ["SN 35.209", "Paccuppanna yaṃ dukkha"],
  ["SN 35.210", "Atīta yadanatta"],
  ["SN 35.211", "Anāgata yadanatta"],
  ["SN 35.212", "Paccuppanna yadanta"],
  ["SN 35.213", "Bāhirātīta yadanicca"],
  ["SN 35.214", "Bāhirānāgata yadanicca"],
  ["SN 35.215", "Bāhira paccuppanna yadanicca"],
  ["SN 35.216", "Bāhirātīta yaṃ dukkha"],
  ["SN 35.217", "Bāhirānāgata yaṃ dukkha"],
  ["SN 35.218", "Bāhirapaccuppanna yaṃ dukkha"],
  ["SN 35.219", "Bāhirātīta yadanatta"],
  ["SN 35.220", "Bāhirānāgata yadanatta"],
  ["SN 35.221", "Bāhirapaccuppanna yadanta"],
  ["SN 35.222", "Ajjhattāyatana anicca"],
  ["SN 35.223", "Ajjhattāyatana dukkha"],
  ["SN 35.224", "Ajjhattāyatana anatta"],
  ["SN 35.225", "Bāhirāyatana anicca"],
  ["SN 35.226", "Bāhirāyatana dukkha"],
  ["SN 35.227", "Bāhirāyatana anatta"],
  ["SN 35.228", "Paṭhama Samudda"],
  ["SN 35.229", "Dutiya Samudda"],
  ["SN 35.230", "Bāḷisikopama"],
  ["SN 35.231", "Khīrarukkhopama"],
  ["SN 35.232", "Koṭṭhita"],
  ["SN 35.233", "Kāmabhu"],
  ["SN 35.234", "Udāyī"],
  ["SN 35.235", "Ādittapariyāya"],
  ["SN 35.236", "Paṭhama Hatthapādupama"],
  ["SN 35.237", "Dutiya Hatthapādupama"],
  ["SN 35.238", "Āsivisopama"],
  ["SN 35.239", "Rathūpama"],
  ["SN 35.240", "Kummopama"],
  ["SN 35.241", "Paṭhama Dārukkhandhopama"],
  ["SN 35.242", "Dutiya Dārukkhandhopama"],
  ["SN 35.243", "Avassutapariyāya"],
  ["SN 35.244", "Dukkhadhamma"],
  ["SN 35.245", "Kiṃsukopama"],
  ["SN 35.246", "Vīṇopama"],
  ["SN 35.247", "Chappāṇaka"],
  ["SN 35.248", "Yavakalāpī"],
  ["SN 36.1", "Samādhi"],
  ["SN 36.2", "Sukha"],
  ["SN 36.3", "Pahāna"],
  ["SN 36.4", "Pātāla"],
  ["SN 36.5", "Daṭṭhabba"],
  ["SN 36.6", "Salla"],
  ["SN 36.7", "Paṭhama Gelañña"],
  ["SN 36.8", "Dutiya Gelañña"],
  ["SN 36.9", "Anicca"],
  ["SN 36.10", "Phassamūlaka"],
  ["SN 36.11", "Rahogata"],
  ["SN 36.12", "Paṭhama Ākāsa"],
  ["SN 36.13", "Dutiya Ākāsa"],
  ["SN 36.14", "Agāra"],
  ["SN 36.15", "Paṭhama Santaka"],
  ["SN 36.16", "Dutiya Santaka"],
  ["SN 36.17", "Paṭhama Aṭṭhaka"],
  ["SN 36.18", "Dutiya Aṭṭhaka"],
  ["SN 36.19", "Pañcakaṅga"],
  ["SN 36.20", "Bhikkhu"],
  ["SN 36.21", "Moḷiyasīvaka"],
  ["SN 36.22", "Aṭṭhasatapariyāya"],
  ["SN 36.23", "Bhikkhu"],
  ["SN 36.24", "Pubbe"],
  ["SN 36.25", "Ñāṇa"],
  ["SN 36.26", "Sambahulabhikkhu"],
  ["SN 36.27", "Paṭhama Samaṇabrāhmaṇa"],
  ["SN 36.28", "Dutiya Samaṇabrāhmaṇa"],
  ["SN 36.29", "Tatiya Samaṇabrāhmaṇa"],
  ["SN 36.30", "Suddhikavedanā"],
  ["SN 36.31", "Nirāmisa"],
  ["SN 37.1", "Mātugāma"],
  ["SN 37.2", "Purisa"],
  ["SN 37.3", "Āveṇikadukkha"],
  ["SN 37.4", "Tīhidhammehi"],
  ["SN 37.5", "Kodhana"],
  ["SN 37.6", "Upanāhī"],
  ["SN 37.7", "Issukī"],
  ["SN 37.8", "Maccharī"],
  ["SN 37.9", "Aticārī"],
  ["SN 37.10", "Dussīla"],
  ["SN 37.11", "Appassuta"],
  ["SN 37.12", "Kusīta"],
  ["SN 37.13", "Muṭṭhassati"],
  ["SN 37.14", "Pañcavera"],
  ["SN 37.15", "Akkodhana"],
  ["SN 37.16", "Anupanāhī"],
  ["SN 37.17", "Anissukī"],
  ["SN 37.18", "Amaccharī"],
  ["SN 37.19", "Anaticārī"],
  ["SN 37.20", "Sīlavanta"],
  ["SN 37.21", "Bahussuta"],
  ["SN 37.22", "Āraddhaviriya"],
  ["SN 37.23", "Upaṭṭhitasati"],
  ["SN 37.24", "Pañcasīla"],
  ["SN 37.25", "Visārada"],
  ["SN 37.26", "Pasayha"],
  ["SN 37.27", "Ahibhuyha"],
  ["SN 37.28", "Eka"],
  ["SN 37.29", "Aṅga"],
  ["SN 37.30", "Nāsitabba"],
  ["SN 37.31", "Hetu"],
  ["SN 37.32", "Ṭhāna"],
  ["SN 37.33", "Visāradavāda"],
  ["SN 37.34", "Vaḍḍhī"],
  ["SN 38.1", "Nibbāna"],
  ["SN 38.2", "Arahatta"],
  ["SN 38.3", "Dhammavādī"],
  ["SN 38.4", "Kimatthiya"],
  ["SN 38.5", "Assāsa"],
  ["SN 38.6", "Paramassāsa"],
  ["SN 38.7", "Vedanā"],
  ["SN 38.8", "Āsava"],
  ["SN 38.9", "Avijjā"],
  ["SN 38.10", "Taṇhā"],
  ["SN 38.11", "Ogha"],
  ["SN 38.12", "Upādāna"],
  ["SN 38.13", "Bhava"],
  ["SN 38.14", "Dukkha"],
  ["SN 38.15", "Sakkāya"],
  ["SN 38.16", "Dukkara"],
  ["SN 39.1", "Nibbāna"],
  ["SN 39.2", "Arahatta"],
  ["SN 39.3", "Dhammavādī"],
  ["SN 39.4", "Kimatthiya"],
  ["SN 39.5", "Assāsa"],
  ["SN 39.6", "Paramassāsa"],
  ["SN 39.7", "Vedanā"],
  ["SN 39.8", "Āsava"],
  ["SN 39.9", "Avijjā"],
  ["SN 39.10", "Taṇhā"],
  ["SN 39.11", "Ogha"],
  ["SN 39.12", "Upādāna"],
  ["SN 39.13", "Bhava"],
  ["SN 39.14", "Dukkha"],
  ["SN 39.15", "Sakkāya"],
  ["SN 39.16", "Dukkara"],
  ["SN 40.1", "Savitakka"],
  ["SN 40.2", "Avitakka"],
  ["SN 40.3", "Sukha"],
  ["SN 40.4", "Upekhā"],
  ["SN 40.5", "Ākāsānañcāyatana"],
  ["SN 40.6", "Viññānañcāyatana"],
  ["SN 40.7", "Ākiñcaññāyatana"],
  ["SN 40.8", "Nevasaññānāsaññāyatana"],
  ["SN 40.9", "Animitta"],
  ["SN 40.10", "Sakka"],
  ["SN 40.11", "Candana"],
  ["SN 41.1", "Saṃyojana"],
  ["SN 41.2", "Paṭhama Isidatta"],
  ["SN 41.3", "Dutiya Isidatta"],
  ["SN 41.4", "Mahaka"],
  ["SN 41.5", "Paṭhama Kāmabhu"],
  ["SN 41.6", "Dutiya Kāmabhu"],
  ["SN 41.7", "Godatta"],
  ["SN 41.8", "Nigaṇṭha"],
  ["SN 41.9", "Acela"],
  ["SN 41.10", "Gilāna"],
  ["SN 42.1", "Caṇḍagāmaṇī"],
  ["SN 42.2", "Tālapuṭa"],
  ["SN 42.3", "Yodhājīva"],
  ["SN 42.4", "Hatthāroha"],
  ["SN 42.5", "Assāroha"],
  ["SN 42.6", "Asibaddhaka"],
  ["SN 42.7", "Khettūpama"],
  ["SN 42.8", "Saṅkhadhama"],
  ["SN 42.9", "Kula"],
  ["SN 42.10", "Maṇicūḷaka"],
  ["SN 42.11", "Bhadraka"],
  ["SN 42.12", "Rāsiya"],
  ["SN 42.13", "Pāṭaliya"],
  ["SN 43.1", "Kāyagatāsati"],
  ["SN 43.2", "Samathavipassanā"],
  ["SN 43.3", "Savitakkasavicāra"],
  ["SN 43.4", "Suññatasamādhi"],
  ["SN 43.5", "Satipaṭṭhāna"],
  ["SN 43.6", "Sammappadhāna"],
  ["SN 43.7", "Iddhipāda"],
  ["SN 43.8", "Indriya"],
  ["SN 43.9", "Bala"],
  ["SN 43.10", "Bojjhaṅga"],
  ["SN 43.11", "Maggaṅga"],
  ["SN 43.12", "Asaṅkhata"],
  ["SN 43.13", "Anata"],
  ["SN 43.14", "Anāsava"],
  ["SN 43.15", "Sacca"],
  ["SN 43.16", "Pāra"],
  ["SN 43.17", "Nipuṇa"],
  ["SN 43.18", "Sududdasa"],
  ["SN 43.19", "Ajara"],
  ["SN 43.20", "Dhuva"],
  ["SN 43.21", "Apalokita"],
  ["SN 43.22", "Anidassana"],
  ["SN 43.23", "Nippapañca"],
  ["SN 43.24", "Santa"],
  ["SN 43.25", "Amata"],
  ["SN 43.26", "Paṇita"],
  ["SN 43.27", "Siva"],
  ["SN 43.28", "Khema"],
  ["SN 43.29", "Taṇhakkhaya"],
  ["SN 43.30", "Acchariya"],
  ["SN 43.31", "Abbhuta"],
  ["SN 43.32", "Anītika"],
  ["SN 43.33", "Anītikadhamma"],
  ["SN 43.34", "Nibbāna"],
  ["SN 43.35", "Abyāpajjha"],
  ["SN 43.36", "Virāga"],
  ["SN 43.37", "Suddhi"],
  ["SN 43.38", "Mutti"],
  ["SN 43.39", "Anālaya"],
  ["SN 43.40", "Dīpa"],
  ["SN 43.41", "Leṇa"],
  ["SN 43.42", "Tāṇa"],
  ["SN 43.43", "Saraṇa"],
  ["SN 43.44", "Parāyaṇa"],
  ["SN 44.1", "Khemā"],
  ["SN 44.2", "Anurādha"],
  ["SN 44.3", "Upagata [Paṭhamasāriputtakoṭṭhikasutta]"],
  ["SN 44.4", "Samudaya [Dutiyasāriputtakoṭṭhikasutta]"],
  ["SN 44.5", "Pema [Tatiyasāriputtakoṭṭhikasutta]"],
  ["SN 44.6", "Ārāma [Catutthasāriputtakoṭṭhikasutta]"],
  ["SN 44.7", "Moggallāna"],
  ["SN 44.8", "Vacchagotta"],
  ["SN 44.9", "Kutuhalasālā"],
  ["SN 44.10", "Ānanda (Atthatta)"],
  ["SN 44.11", "Sabhiya"],
  ["SN 45.1", "Avijjā"],
  ["SN 45.2", "Upaḍḍha"],
  ["SN 45.3", "Sāriputta"],
  ["SN 45.4", "Brāhmaṇa"],
  ["SN 45.5", "Kimatthiya"],
  ["SN 45.6", "Paṭhama Bhikkhu"],
  ["SN 45.7", "Dutiya Bhikkhu"],
  ["SN 45.8", "Vibhaṅga"],
  ["SN 45.9", "Sūka"],
  ["SN 45.10", "Nandiya"],
  ["SN 45.11", "Paṭhama Vihāra"],
  ["SN 45.12", "Dutiya Vihāra"],
  ["SN 45.13", "Sekha"],
  ["SN 45.14", "Paṭhama Uppāda"],
  ["SN 45.15", "Dutiya Uppāda"],
  ["SN 45.16", "Paṭhama Parisuddha"],
  ["SN 45.17", "Dutiya Parisuddha"],
  ["SN 45.18", "Paṭhama Kukkuṭārāma"],
  ["SN 45.19", "Dutiya Kukkuṭārāma"],
  ["SN 45.20", "Tatiya Kukkuṭārāma"],
  ["SN 45.21", "Micchatta"],
  ["SN 45.22", "Akusaladhamma"],
  ["SN 45.23", "Paṭhama Paṭipadā"],
  ["SN 45.24", "Dutiya Paṭipadā"],
  ["SN 45.25", "Paṭhama Asappurisa"],
  ["SN 45.26", "Dutiya Asappurisa"],
  ["SN 45.27", "Kumbha"],
  ["SN 45.28", "Samādhi"],
  ["SN 45.29", "Vedanā"],
  ["SN 45.30", "Uttiya"],
  ["SN 45.31", "Paṭipatti"],
  ["SN 45.32", "Paṭipanna"],
  ["SN 45.33", "Viraddha"],
  ["SN 45.34", "Pāraṅgama"],
  ["SN 45.35", "Paṭhama Sāmañña"],
  ["SN 45.36", "Dutiya Sāmañña"],
  ["SN 45.37", "Paṭhama Brahmañña"],
  ["SN 45.38", "Dutiya Brahmañña"],
  ["SN 45.39", "Paṭhama Brahmacariya"],
  ["SN 45.40", "Dutiya Brahmacariya"],
  ["SN 45.41", "Virāga"],
  ["SN 45.42", "Saṃyojana"],
  ["SN 45.43", "Anusaya"],
  ["SN 45.44", "Addhāna"],
  ["SN 45.45", "Āsavakkhaya"],
  ["SN 45.46", "Vijjāvimutti"],
  ["SN 45.47", "Ñāṇadassana"],
  ["SN 45.48", "Anupādā"],
  ["SN 45.49", "Paṭhama Kalyāṇamitta"],
  ["SN 45.50", "Paṭhama Sīla"],
  ["SN 45.51", "Paṭhama Chanda"],
  ["SN 45.52", "Paṭhama Atta"],
  ["SN 45.53", "Paṭhama Diṭṭhi"],
  ["SN 45.54", "Paṭhama Appamāda"],
  ["SN 45.55", "Paṭhama Yoniso"],
  ["SN 45.56", "Dutiya Kalyāṇamitta"],
  ["SN 45.57", "Dutiya Sīla"],
  ["SN 45.58", "Dutiya Chanda"],
  ["SN 45.59", "Dutiya Atta"],
  ["SN 45.60", "Dutiya Diṭṭhi"],
  ["SN 45.61", "Dutiya Appamāda"],
  ["SN 45.62", "Dutiya Yoniso"],
  ["SN 45.63", "Paṭhama Kalyāṇamitta"],
  ["SN 45.64", "Paṭhama Sīla"],
  ["SN 45.65", "Paṭhama Chanda"],
  ["SN 45.66", "Paṭhama Atta"],
  ["SN 45.67", "Paṭhama Diṭṭhi"],
  ["SN 45.68", "Paṭhama Appamāda"],
  ["SN 45.69", "Paṭhama Yoniso"],
  ["SN 45.70", "Dutiya Kalyāṇamitta"],
  ["SN 45.71", "Dutiya Sīla"],
  ["SN 45.72", "Dutiya Chanda"],
  ["SN 45.73", "Dutiya Atta"],
  ["SN 45.74", "Dutiya Diṭṭhi"],
  ["SN 45.75", "Dutiya Appamāda"],
  ["SN 45.76", "Dutiya Yoniso"],
  ["SN 45.77", "Paṭhama Kalyāṇamitta"],
  ["SN 45.78", "Paṭhama Sīla"],
  ["SN 45.79", "Paṭhama Chanda"],
  ["SN 45.80", "Paṭhama Atta"],
  ["SN 45.81", "Paṭhama Diṭṭhi"],
  ["SN 45.82", "Paṭhama Appamāda"],
  ["SN 45.83", "Paṭhama Yoniso"],
  ["SN 45.84", "Dutiya Kalyāṇamitta"],
  ["SN 45.85", "Dutiya Sīla"],
  ["SN 45.86", "Dutiya Chanda"],
  ["SN 45.87", "Dutiya Atta"],
  ["SN 45.88", "Dutiya Diṭṭhi"],
  ["SN 45.89", "Dutiya Appamāda"],
  ["SN 45.90", "Dutiya Yoniso"],
  ["SN 45.91", "Paṭhama Pācīnaninna"],
  ["SN 45.92", "Dutiya Pācīnaninna"],
  ["SN 45.93", "Tatiya Pācīnaninna"],
  ["SN 45.94", "Catuttha Pācīnaninna"],
  ["SN 45.95", "Pācīnaninna 5"],
  ["SN 45.96", "Pācīnaninna 6"],
  ["SN 45.97", "Paṭhama Samuddaninna"],
  ["SN 45.98", "Dutiya Samuddaninna"],
  ["SN 45.99", "Tatiya Samuddaninna"],
  ["SN 45.100", "Catuttha Samuddaninna"],
  ["SN 45.101", "Samuddaninna 5"],
  ["SN 45.102", "Samuddaninna 6"],
  ["SN 45.103", "Paṭhama Pācīnaninna"],
  ["SN 45.104", "Dutiya Pācīnaninna"],
  ["SN 45.105", "Tatiya Pācīnaninna"],
  ["SN 45.106", "Catuttha Pācīnaninna"],
  ["SN 45.107", "Pācīnaninna 5"],
  ["SN 45.108", "Pācīnaninna 6"],
  ["SN 45.109", "Paṭhama Samuddaninna"],
  ["SN 45.110", "Dutiya Samuddaninna"],
  ["SN 45.111", "Tatiya Samuddaninna"],
  ["SN 45.112", "Catuttha Samuddaninna"],
  ["SN 45.113", "Samuddaninna 5"],
  ["SN 45.114", "Samuddaninna 6"],
  ["SN 45.115", "Paṭhama Pācīnaninna"],
  ["SN 45.116", "Dutiya Pācīnaninna"],
  ["SN 45.117", "Tatiya Pācīnaninna"],
  ["SN 45.118", "Catuttha Pācīnaninna"],
  ["SN 45.119", "Pācīnaninna 5"],
  ["SN 45.120", "Pācīnaninna 6"],
  ["SN 45.121", "Paṭhama Samuddaninna"],
  ["SN 45.122", "Dutiya Samuddaninna"],
  ["SN 45.123", "Tatiya Samuddaninna"],
  ["SN 45.124", "Catuttha Samuddaninna"],
  ["SN 45.125", "Samuddaninna 5"],
  ["SN 45.126", "Samuddaninna 6"],
  ["SN 45.127", "Paṭhama Pācīnaninna"],
  ["SN 45.128", "Dutiya Pācīnaninna"],
  ["SN 45.129", "Tatiya Pācīnaninna"],
  ["SN 45.130", "Catuttha Pācīnaninna"],
  ["SN 45.131", "Pācīnaninna 5"],
  ["SN 45.132", "Pācīnaninna 6"],
  ["SN 45.133", "Paṭhama Samuddaninna"],
  ["SN 45.134", "Dutiya Samuddaninna"],
  ["SN 45.135", "Tatiya Samuddaninna"],
  ["SN 45.136", "Catuttha Samuddaninna"],
  ["SN 45.137", "Samuddaninna 5"],
  ["SN 45.138", "Samuddaninna 6"],
  ["SN 45.139", "Tathāgata"],
  ["SN 45.140", "Pada"],
  ["SN 45.141", "Kūṭa"],
  ["SN 45.142", "Mūla"],
  ["SN 45.143", "Sāra"],
  ["SN 45.144", "Vassika"],
  ["SN 45.145", "Rāja"],
  ["SN 45.146", "Candima"],
  ["SN 45.147", "Suriya"],
  ["SN 45.148", "Vattha"],
  ["SN 45.149", "Bala"],
  ["SN 45.150", "Bīja"],
  ["SN 45.151", "Nāga"],
  ["SN 45.152", "Rukkha"],
  ["SN 45.153", "Kumbha"],
  ["SN 45.154", "Sūka"],
  ["SN 45.155", "Ākāsa"],
  ["SN 45.156", "Paṭhama Megha"],
  ["SN 45.157", "Dutiya Megha"],
  ["SN 45.158", "Nāvā"],
  ["SN 45.159", "Āgantuka"],
  ["SN 45.160", "Nadī"],
  ["SN 45.161", "Esanā"],
  ["SN 45.162", "Vidhā"],
  ["SN 45.163", "Āsava"],
  ["SN 45.164", "Bhava"],
  ["SN 45.165", "Dukkhatā"],
  ["SN 45.166", "Khīla"],
  ["SN 45.167", "Mala"],
  ["SN 45.168", "Nīgha"],
  ["SN 45.169", "Vedanā"],
  ["SN 45.170", "Taṇhā/Tasinā"],
  ["SN 45.171", "Ogha"],
  ["SN 45.172", "Yoga"],
  ["SN 45.173", "Upādāna"],
  ["SN 45.174", "Gantha"],
  ["SN 45.175", "Anusaya"],
  ["SN 45.176", "Kāmaguṇa"],
  ["SN 45.177", "Nīvaraṇa"],
  ["SN 45.178", "Upādānakkhandha"],
  ["SN 45.179", "Orambhāgiyasaṃyojana"],
  ["SN 45.180", "Uddhambhāgiyasaṃyojana"],
  ["SN 46.1", "Himavanta"],
  ["SN 46.2", "Kāya"],
  ["SN 46.3", "Sīla"],
  ["SN 46.4", "Vattha"],
  ["SN 46.5", "Bhikkhu"],
  ["SN 46.6", "Kuṇḍaliya"],
  ["SN 46.7", "Kūṭāgāra"],
  ["SN 46.8", "Upavāna"],
  ["SN 46.9", "Paṭhama Uppāda"],
  ["SN 46.10", "Dutiya Uppāda"],
  ["SN 46.11", "Pāṇa"],
  ["SN 46.12", "Paṭhama Suriya"],
  ["SN 46.13", "Dutiya Suriya"],
  ["SN 46.14", "Paṭhama Gilāna"],
  ["SN 46.15", "Dutiya Gilāna"],
  ["SN 46.16", "Tatiya Gilāna"],
  ["SN 46.17", "Apāra"],
  ["SN 46.18", "Āraddha"],
  ["SN 46.19", "Ariya"],
  ["SN 46.20", "Nibbidā"],
  ["SN 46.21", "Bodhāya"],
  ["SN 46.22", "Desanā"],
  ["SN 46.23", "Ṭhāna"],
  ["SN 46.24", "Yoniso"],
  ["SN 46.25", "Aparihāniya"],
  ["SN 46.26", "Khaya"],
  ["SN 46.27", "Nirodha"],
  ["SN 46.28", "Nibbedha"],
  ["SN 46.29", "Ekadhamma"],
  ["SN 46.30", "Udayī"],
  ["SN 46.31", "Paṭhama Kusala"],
  ["SN 46.32", "Dutiya Kusala"],
  ["SN 46.33", "Paṭhama Kilesa"],
  ["SN 46.34", "Dutiya Kilesa"],
  ["SN 46.35", "Yoniso"],
  ["SN 46.36", "Buddhi"],
  ["SN 46.37", "Āvaraṇa"],
  ["SN 46.38", "Anīvaraṇa"],
  ["SN 46.39", "Mahārukkha"],
  ["SN 46.40", "Nīvaraṇa"],
  ["SN 46.41", "Vidhā"],
  ["SN 46.42", "Cakkavatti"],
  ["SN 46.43", "Māra"],
  ["SN 46.44", "Duppañña"],
  ["SN 46.45", "Paññava"],
  ["SN 46.46", "Daḷidda"],
  ["SN 46.47", "Adaḷidda"],
  ["SN 46.48", "Ādicca"],
  ["SN 46.49", "Paṭhama Aṅga"],
  ["SN 46.50", "Dutiya Aṅga"],
  ["SN 46.51", "Āhāra"],
  ["SN 46.52", "Pariyāya"],
  ["SN 46.53", "Aggi"],
  ["SN 46.54", "Metta"],
  ["SN 46.55", "Saṅgārava"],
  ["SN 46.56", "Abhaya"],
  ["SN 46.57", "Aṭṭhika"],
  ["SN 46.58", "Puḷavaka"],
  ["SN 46.59", "Vinīlaka"],
  ["SN 46.60", "Vicchiddaka"],
  ["SN 46.61", "Uddhumātaka"],
  ["SN 46.62", "Mettā"],
  ["SN 46.63", "Karuṇā"],
  ["SN 46.64", "Muditā"],
  ["SN 46.65", "Upekhā"],
  ["SN 46.66", "Ānāpānasati"],
  ["SN 46.67", "Asubha"],
  ["SN 46.68", "Maraṇa"],
  ["SN 46.69", "Pa.tikkuula"],
  ["SN 46.70", "Sabbaloke"],
  ["SN 46.71", "Anicca"],
  ["SN 46.72", "Dukkha"],
  ["SN 46.73", "Anatta"],
  ["SN 46.74", "Pahāna"],
  ["SN 46.75", "Virāga"],
  ["SN 46.76", "Nirodha"],
  ["SN 46.77", "Paṭhama Pācīnaninna"],
  ["SN 46.78", "Dutiya Pācīnaninna"],
  ["SN 46.79", "Tatiya Pācīnaninna"],
  ["SN 46.80", "Catuttha Pācīnaninna"],
  ["SN 46.81", "Pācīnaninna 5"],
  ["SN 46.82", "Pācīnaninna 6"],
  ["SN 46.83", "Paṭhama Samuddaninna"],
  ["SN 46.84", "Dutiya Samuddaninna"],
  ["SN 46.85", "Tatiya Samuddaninna"],
  ["SN 46.86", "Catuttha Samuddaninna"],
  ["SN 46.87", "Samuddaninna 5"],
  ["SN 46.88", "Samuddaninna 6"],
  ["SN 46.89", "Tathāgata"],
  ["SN 46.90", "Pada"],
  ["SN 46.91", "Kūṭa"],
  ["SN 46.92", "Mūla"],
  ["SN 46.93", "Sāra"],
  ["SN 46.94", "Vassika"],
  ["SN 46.95", "Rāja"],
  ["SN 46.96", "Candima"],
  ["SN 46.97", "Suriya"],
  ["SN 46.98", "Vattha"],
  ["SN 46.99", "Bala"],
  ["SN 46.100", "Bīja"],
  ["SN 46.101", "Nāga"],
  ["SN 46.102", "Rukkha"],
  ["SN 46.103", "Kumbha"],
  ["SN 46.104", "Sūka"],
  ["SN 46.105", "Ākāsa"],
  ["SN 46.106", "Paṭhama Megha"],
  ["SN 46.107", "Dutiya Megha"],
  ["SN 46.108", "Nāvā"],
  ["SN 46.109", "Āgantuka"],
  ["SN 46.110", "Nadī"],
  ["SN 46.111", "Esanā"],
  ["SN 46.112", "Vidhā"],
  ["SN 46.113", "Āsava"],
  ["SN 46.114", "Bhava"],
  ["SN 46.115", "Dukkhatā"],
  ["SN 46.116", "Khīla"],
  ["SN 46.117", "Mala"],
  ["SN 46.118", "Nīgha"],
  ["SN 46.119", "Vedanā"],
  ["SN 46.120", "Taṇhā"],
  ["SN 46.121", "Ogha"],
  ["SN 46.122", "Yoga"],
  ["SN 46.123", "Upādāna"],
  ["SN 46.124", "Gantha"],
  ["SN 46.125", "Anusaya"],
  ["SN 46.126", "Kāmaguṇa"],
  ["SN 46.127", "Nīvaraṇa"],
  ["SN 46.128", "Upādānakkhandha"],
  ["SN 46.129", "Orambhāgiyasaṃyojana"],
  ["SN 46.130", "Uddhambhāgiyasaṃyojana"],
  ["SN 46.131", "Paṭhama Pācīnaninna"],
  ["SN 46.132", "Dutiya Pācīnaninna"],
  ["SN 46.133", "Tatiya Pācīnaninna"],
  ["SN 46.134", "Catuttha Pācīnaninna"],
  ["SN 46.135", "Pācīnaninna 5"],
  ["SN 46.136", "Pācīnaninna 6"],
  ["SN 46.137", "Paṭhama Samuddaninna"],
  ["SN 46.138", "Dutiya Samuddaninna"],
  ["SN 46.139", "Tatiya Samuddaninna"],
  ["SN 46.140", "Catuttha Samuddaninna"],
  ["SN 46.141", "Samuddaninna 5"],
  ["SN 46.142", "Samuddaninna 6"],
  ["SN 46.143", "Tathāgata"],
  ["SN 46.144", "Pada"],
  ["SN 46.145", "Kūṭa"],
  ["SN 46.146", "Mūla"],
  ["SN 46.147", "Sāra"],
  ["SN 46.148", "Vassika"],
  ["SN 46.149", "Rāja"],
  ["SN 46.150", "Candima"],
  ["SN 46.151", "Suriya"],
  ["SN 46.152", "Vattha"],
  ["SN 46.153", "Bala"],
  ["SN 46.154", "Bīja"],
  ["SN 46.155", "Nāga"],
  ["SN 46.156", "Rukkha"],
  ["SN 46.157", "Kumbha"],
  ["SN 46.158", "Sūka"],
  ["SN 46.159", "Ākāsa"],
  ["SN 46.160", "Paṭhama Megha"],
  ["SN 46.161", "Dutiya Megha"],
  ["SN 46.162", "Nāvā"],
  ["SN 46.163", "Āgantuka"],
  ["SN 46.164", "Nadī"],
  ["SN 46.165", "Esanā"],
  ["SN 46.166", "Vidhā"],
  ["SN 46.167", "Āsava"],
  ["SN 46.168", "Bhava"],
  ["SN 46.169", "Dukkhatā"],
  ["SN 46.170", "Khīla"],
  ["SN 46.171", "Mala"],
  ["SN 46.172", "Nīgha"],
  ["SN 46.173", "Vedanā"],
  ["SN 46.174", "Taṇhā"],
  ["SN 46.175", "Ogha"],
  ["SN 46.176", "Yoga"],
  ["SN 46.177", "Upādāna"],
  ["SN 46.178", "Gantha"],
  ["SN 46.179", "Anusaya"],
  ["SN 46.180", "Kāmaguṇa"],
  ["SN 46.181", "Nīvaraṇa"],
  ["SN 46.182", "Upādānakkhandha"],
  ["SN 46.183", "Orambhāgiyasaṃyojana"],
  ["SN 46.184", "Uddhambhāgiyasaṃyojana"],
  ["SN 47.1", "Ambapāli"],
  ["SN 47.2", "Sato"],
  ["SN 47.3", "Bhikkhu"],
  ["SN 47.4", "Sālā"],
  ["SN 47.5", "Kusalarāsi"],
  ["SN 47.6", "Sakuṇagghi"],
  ["SN 47.7", "Makkaṭa"],
  ["SN 47.8", "Sūda"],
  ["SN 47.9", "Gilāna"],
  ["SN 47.10", "Bhikkhunūpassaya"],
  ["SN 47.11", "Mahāpurisa"],
  ["SN 47.12", "Nālandā"],
  ["SN 47.13", "Cunda"],
  ["SN 47.14", "Ukkacela"],
  ["SN 47.15", "Bāhiya"],
  ["SN 47.16", "Uttiya"],
  ["SN 47.17", "Ariya"],
  ["SN 47.18", "Brahma"],
  ["SN 47.19", "Sedaka"],
  ["SN 47.20", "Janapadakalyāṇī"],
  ["SN 47.21", "Sīla"],
  ["SN 47.22", "Ṭhiti"],
  ["SN 47.23", "Parihāna"],
  ["SN 47.24", "Suddhaka"],
  ["SN 47.25", "Brāhmaṇa"],
  ["SN 47.26", "Padesa"],
  ["SN 47.27", "Samatta"],
  ["SN 47.28", "Loka"],
  ["SN 47.29", "Sirivaḍḍha"],
  ["SN 47.30", "Mānadinna"],
  ["SN 47.31", "Ananussuta"],
  ["SN 47.32", "Virāga"],
  ["SN 47.33", "Viraddha"],
  ["SN 47.34", "Bhāvita"],
  ["SN 47.35", "Sato"],
  ["SN 47.36", "Aññā"],
  ["SN 47.37", "Chanda"],
  ["SN 47.38", "Pariññāta"],
  ["SN 47.39", "Bhāvanā"],
  ["SN 47.40", "Vibhaṅga"],
  ["SN 47.41", "Amata"],
  ["SN 47.42", "Samudaya"],
  ["SN 47.43", "Magga"],
  ["SN 47.44", "Sato"],
  ["SN 47.45", "Kusalarāsi"],
  ["SN 47.46", "Pātimokkha"],
  ["SN 47.47", "Duccarita"],
  ["SN 47.48", "Mitta"],
  ["SN 47.49", "Vedanā"],
  ["SN 47.50", "Āsava"],
  ["SN 47.51", "Paṭhama Pācīnaninna"],
  ["SN 47.52", "Dutiya Pācīnaninna"],
  ["SN 47.53", "Tatiya Pācīnaninna"],
  ["SN 47.54", "Catuttha Pācīnaninna"],
  ["SN 47.55", "Pācīnaninna 5"],
  ["SN 47.56", "Pācīnaninna 6"],
  ["SN 47.57", "Paṭhama Samuddaninna"],
  ["SN 47.58", "Dutiya Samuddaninna"],
  ["SN 47.59", "Tatiya Samuddaninna"],
  ["SN 47.60", "Catuttha Samuddaninna"],
  ["SN 47.61", "Samuddaninna 5"],
  ["SN 47.62", "Samuddaninna 6"],
  ["SN 47.63", "Tathāgata"],
  ["SN 47.64", "Pada"],
  ["SN 47.65", "Kūṭa"],
  ["SN 47.66", "Mūla"],
  ["SN 47.67", "Sāra"],
  ["SN 47.68", "Vassika"],
  ["SN 47.69", "Rāja"],
  ["SN 47.70", "Candima"],
  ["SN 47.71", "Suriya"],
  ["SN 47.72", "Vattha"],
  ["SN 47.73", "Bala"],
  ["SN 47.74", "Bīja"],
  ["SN 47.75", "Nāga"],
  ["SN 47.76", "Rukkha"],
  ["SN 47.77", "Kumbha"],
  ["SN 47.78", "Sūka"],
  ["SN 47.79", "Ākāsa"],
  ["SN 47.80", "Paṭhama Megha"],
  ["SN 47.81", "Dutiya Megha"],
  ["SN 47.82", "Nāvā"],
  ["SN 47.83", "Āgantuka"],
  ["SN 47.84", "Nadī"],
  ["SN 47.85", "Esanā"],
  ["SN 47.86", "Vidhā"],
  ["SN 47.87", "Āsava"],
  ["SN 47.88", "Bhava"],
  ["SN 47.89", "Dukkhatā"],
  ["SN 47.90", "Khīla"],
  ["SN 47.91", "Mala"],
  ["SN 47.92", "Nīgha"],
  ["SN 47.93", "Vedanā"],
  ["SN 47.94", "Taṇhā"],
  ["SN 47.95", "Ogha"],
  ["SN 47.96", "Yoga"],
  ["SN 47.97", "Upādāna"],
  ["SN 47.98", "Gantha"],
  ["SN 47.99", "Anusaya"],
  ["SN 47.100", "Kāmaguṇa"],
  ["SN 47.101", "Nīvaraṇa"],
  ["SN 47.102", "Upādānakkhandha"],
  ["SN 47.103", "Orambhāgiyasaṃyojana"],
  ["SN 47.104", "Uddhambhāgiyasaṃyojana"],
  ["SN 48.1", "Suddhaka"],
  ["SN 48.2", "Paṭhama Sota"],
  ["SN 48.3", "Dutiya Sota"],
  ["SN 48.4", "Paṭhama Arahanta"],
  ["SN 48.5", "Dutiya Arahanta"],
  ["SN 48.6", "Paṭhama Samaṇa"],
  ["SN 48.7", "Dutiya Samaṇa"],
  ["SN 48.8", "Daṭṭhabba"],
  ["SN 48.9", "Paṭhama Vibhaṅga [Paṭhama Indriyavibhaṅga]"],
  ["SN 48.10", "Dutiya Vibhaṅga [Dutiya Indriyavibhaṅga]"],
  ["SN 48.11", "Paṭilābha"],
  ["SN 48.12", "Paṭhama Saṅkhitta"],
  ["SN 48.13", "Dutiya Saṅkhitta"],
  ["SN 48.14", "Tatiya Saṅkhitta"],
  ["SN 48.15", "Paṭhama Vitthāra"],
  ["SN 48.16", "Dutiya Vitthāra"],
  ["SN 48.17", "Tatiya Vitthāra"],
  ["SN 48.18", "Paṭipanna"],
  ["SN 48.19", "Sampanna"],
  ["SN 48.20", "Āsavakkhaya"],
  ["SN 48.21", "Punabbhava"],
  ["SN 48.22", "Jīvitindriya"],
  ["SN 48.23", "Aññindriya"],
  ["SN 48.24", "Ekabījī"],
  ["SN 48.25", "Suddhaka"],
  ["SN 48.26", "Sotāpanna"],
  ["SN 48.27", "Arahanta"],
  ["SN 48.28", "Buddha"],
  ["SN 48.29", "Paṭhama Samaṇabrāhmaṇa"],
  ["SN 48.30", "Dutiya Samaṇabrāhmaṇa"],
  ["SN 48.31", "Suddhaka"],
  ["SN 48.32", "Sotāpanna"],
  ["SN 48.33", "Arahanta"],
  ["SN 48.34", "Paṭhama Samaṇabrāhmaṇa"],
  ["SN 48.35", "Dutiya Samaṇabrāhmaṇa"],
  ["SN 48.36", "Paṭhama Vibhaṅga"],
  ["SN 48.37", "Dutiya Vibhaṅga"],
  ["SN 48.38", "Tatiya Vibhaṅga"],
  ["SN 48.39", "Kaṭṭhopama"],
  ["SN 48.40", "Uppāṭika"],
  ["SN 48.41", "Jarā"],
  ["SN 48.42", "Uṇṇābhabrāhmaṇa"],
  ["SN 48.43", "Sāketa"],
  ["SN 48.44", "Pubbakoṭṭhaka"],
  ["SN 48.45", "Paṭhama Pubbārāma"],
  ["SN 48.46", "Dutiya Pubbārāma"],
  ["SN 48.47", "Tatiya Pubbārāma"],
  ["SN 48.48", "Catuttha Pubbārāma"],
  ["SN 48.49", "Piṇḍola"],
  ["SN 48.50", "Āpaṇa"],
  ["SN 48.51", "Sālā"],
  ["SN 48.52", "Mallika"],
  ["SN 48.53", "Sekha"],
  ["SN 48.54", "Pada"],
  ["SN 48.55", "Sāra"],
  ["SN 48.56", "Patiṭṭhita"],
  ["SN 48.57", "Brahma"],
  ["SN 48.58", "Sūkarakhata"],
  ["SN 48.59", "Paṭhama Uppāda"],
  ["SN 48.60", "Dutiya Uppāda"],
  ["SN 48.61", "Saṃyojana"],
  ["SN 48.62", "Anusaya"],
  ["SN 48.63", "Addhāna"],
  ["SN 48.64", "Āsavakkhaya"],
  ["SN 48.65", "Dve phalā"],
  ["SN 48.66", "Sattānisamsā"],
  ["SN 48.67", "Paṭhama Rukkha"],
  ["SN 48.68", "Dutiya Rukkha"],
  ["SN 48.69", "Tatiya Rukkha"],
  ["SN 48.70", "Catuttha Rukkha"],
  ["SN 48.71", "Paṭhama Pācīnaninna"],
  ["SN 48.72", "Dutiya Pācīnaninna"],
  ["SN 48.73", "Tatiya Pācīnaninna"],
  ["SN 48.74", "Catuttha Pācīnaninna"],
  ["SN 48.75", "Pācīnaninna 5"],
  ["SN 48.76", "Pācīnaninna 6"],
  ["SN 48.77", "Paṭhama Samuddaninna"],
  ["SN 48.78", "Dutiya Samuddaninna"],
  ["SN 48.79", "Tatiya Samuddaninna"],
  ["SN 48.80", "Catuttha Samuddaninna"],
  ["SN 48.81", "Samuddaninna 5"],
  ["SN 48.82", "Samuddaninna 6"],
  ["SN 48.83", "Tathāgata"],
  ["SN 48.84", "Pada"],
  ["SN 48.85", "Kūṭa"],
  ["SN 48.86", "Mūla"],
  ["SN 48.87", "Sāra"],
  ["SN 48.88", "Vassika"],
  ["SN 48.89", "Rāja"],
  ["SN 48.90", "Candima"],
  ["SN 48.91", "Suriya"],
  ["SN 48.92", "Vattha"],
  ["SN 48.93", "Bala"],
  ["SN 48.94", "Bīja"],
  ["SN 48.95", "Nāga"],
  ["SN 48.96", "Rukkha"],
  ["SN 48.97", "Kumbha"],
  ["SN 48.98", "Sūka"],
  ["SN 48.99", "Ākāsa"],
  ["SN 48.100", "Paṭhama Megha"],
  ["SN 48.101", "Dutiya Megha"],
  ["SN 48.102", "Nāvā"],
  ["SN 48.103", "Āgantuka"],
  ["SN 48.104", "Nadī"],
  ["SN 48.105", "Esanā"],
  ["SN 48.106", "Vidhā"],
  ["SN 48.107", "Āsava"],
  ["SN 48.108", "Bhava"],
  ["SN 48.109", "Dukkhatā"],
  ["SN 48.110", "Khīla"],
  ["SN 48.111", "Mala"],
  ["SN 48.112", "Nīgha"],
  ["SN 48.113", "Vedanā"],
  ["SN 48.114", "Taṇhā"],
  ["SN 48.115", "Ogha"],
  ["SN 48.116", "Yoga"],
  ["SN 48.117", "Upādāna"],
  ["SN 48.118", "Gantha"],
  ["SN 48.119", "Anusaya"],
  ["SN 48.120", "Kāmaguṇa"],
  ["SN 48.121", "Nīvaraṇa"],
  ["SN 48.122", "Upādānakkhandha"],
  ["SN 48.123", "Orambhāgiyasaṃyojana"],
  ["SN 48.124", "Uddhambhāgiyasaṃyojana"],
  ["SN 48.125", "Paṭhama Pācīnaninna"],
  ["SN 48.126", "Dutiya Pācīnaninna"],
  ["SN 48.127", "Tatiya Pācīnaninna"],
  ["SN 48.128", "Catuttha Pācīnaninna"],
  ["SN 48.129", "Pācīnaninna 5"],
  ["SN 48.130", "Pācīnaninna 6"],
  ["SN 48.131", "Paṭhama Samuddaninna"],
  ["SN 48.132", "Dutiya Samuddaninna"],
  ["SN 48.133", "Tatiya Samuddaninna"],
  ["SN 48.134", "Catuttha Samuddaninna"],
  ["SN 48.135", "Samuddaninna 5"],
  ["SN 48.136", "Samuddaninna 6"],
  ["SN 48.137", "Tathāgata"],
  ["SN 48.138", "Pada"],
  ["SN 48.139", "Kūṭa"],
  ["SN 48.140", "Mūla"],
  ["SN 48.141", "Sāra"],
  ["SN 48.142", "Vassika"],
  ["SN 48.143", "Rāja"],
  ["SN 48.144", "Candima"],
  ["SN 48.145", "Suriya"],
  ["SN 48.146", "Vattha"],
  ["SN 48.147", "Bala"],
  ["SN 48.148", "Bīja"],
  ["SN 48.149", "Nāga"],
  ["SN 48.150", "Rukkha"],
  ["SN 48.151", "Kumbha"],
  ["SN 48.152", "Sūka"],
  ["SN 48.153", "Ākāsa"],
  ["SN 48.154", "Paṭhama Megha"],
  ["SN 48.155", "Dutiya Megha"],
  ["SN 48.156", "Nāvā"],
  ["SN 48.157", "Āgantuka"],
  ["SN 48.158", "Nadī"],
  ["SN 48.159", "Esanā"],
  ["SN 48.160", "Vidhā"],
  ["SN 48.161", "Āsava"],
  ["SN 48.162", "Bhava"],
  ["SN 48.163", "Dukkhatā"],
  ["SN 48.164", "Khīla"],
  ["SN 48.165", "Mala"],
  ["SN 48.166", "Nīgha"],
  ["SN 48.167", "Vedanā"],
  ["SN 48.168", "Taṇhā"],
  ["SN 48.169", "Ogha"],
  ["SN 48.170", "Yoga"],
  ["SN 48.171", "Upādāna"],
  ["SN 48.172", "Gantha"],
  ["SN 48.173", "Anusaya"],
  ["SN 48.174", "Kāmaguṇa"],
  ["SN 48.175", "Nīvaraṇa"],
  ["SN 48.176", "Upādānakkhandha"],
  ["SN 48.177", "Orambhāgiyasaṃyojana"],
  ["SN 48.178", "Uddhambhāgiyasaṃyojana"],
  ["SN 49.1", "Paṭhama Pācīnaninna"],
  ["SN 49.2", "Dutiya Pācīnaninna"],
  ["SN 49.3", "Tatiya Pācīnaninna"],
  ["SN 49.4", "Catuttha Pācīnaninna"],
  ["SN 49.5", "Pācīnaninna 5"],
  ["SN 49.6", "Pācīnaninna 6"],
  ["SN 49.7", "Paṭhama Samuddaninna"],
  ["SN 49.8", "Dutiya Samuddaninna"],
  ["SN 49.9", "Tatiya Samuddaninna"],
  ["SN 49.10", "Catuttha Samuddaninna"],
  ["SN 49.11", "Samuddaninna 5"],
  ["SN 49.12", "Samuddaninna 6"],
  ["SN 49.13", "Tathāgata"],
  ["SN 49.14", "Pada"],
  ["SN 49.15", "Kūṭa"],
  ["SN 49.16", "Mūla"],
  ["SN 49.17", "Sāra"],
  ["SN 49.18", "Vassika"],
  ["SN 49.19", "Rāja"],
  ["SN 49.20", "Candima"],
  ["SN 49.21", "Suriya"],
  ["SN 49.22", "Vattha"],
  ["SN 49.23", "Bala"],
  ["SN 49.24", "Bīja"],
  ["SN 49.25", "Nāga"],
  ["SN 49.26", "Rukkha"],
  ["SN 49.27", "Kumbha"],
  ["SN 49.28", "Sūka"],
  ["SN 49.29", "Ākāsa"],
  ["SN 49.30", "Paṭhama Megha"],
  ["SN 49.31", "Dutiya Megha"],
  ["SN 49.32", "Nāvā"],
  ["SN 49.33", "Āgantuka"],
  ["SN 49.34", "Nadī"],
  ["SN 49.35", "Esanā"],
  ["SN 49.36", "Vidhā"],
  ["SN 49.37", "Āsava"],
  ["SN 49.38", "Bhava"],
  ["SN 49.39", "Dukkhatā"],
  ["SN 49.40", "Khīla"],
  ["SN 49.41", "Mala"],
  ["SN 49.42", "Nīgha"],
  ["SN 49.43", "Vedanā"],
  ["SN 49.44", "Taṇhā"],
  ["SN 49.45", "Ogha"],
  ["SN 49.46", "Yoga"],
  ["SN 49.47", "Upādāna"],
  ["SN 49.48", "Gantha"],
  ["SN 49.49", "Anusaya"],
  ["SN 49.50", "Kāmaguṇa"],
  ["SN 49.51", "Nīvaraṇa"],
  ["SN 49.52", "Upādānakkhandha"],
  ["SN 49.53", "Orambhāgiyasaṃyojana"],
  ["SN 49.54", "Uddhambhāgiyasaṃyojana"],
  ["SN 50.1", "Paṭhama Pācīnaninna - Samuddaninna 6"],
  ["SN 50.2", "Dutiya Pācīnaninna"],
  ["SN 50.3", "Tatiya Pācīnaninna"],
  ["SN 50.4", "Catuttha Pācīnaninna"],
  ["SN 50.5", "Pācīnaninna 5"],
  ["SN 50.6", "Pācīnaninna 6"],
  ["SN 50.7", "Paṭhama Samuddaninna"],
  ["SN 50.8", "Dutiya Samuddaninna"],
  ["SN 50.9", "Tatiya Samuddaninna"],
  ["SN 50.10", "Catuttha Samuddaninna"],
  ["SN 50.11", "Samuddaninna 5"],
  ["SN 50.12", "Samuddaninna 6"],
  ["SN 50.13", "Tathāgata"],
  ["SN 50.14", "Pada"],
  ["SN 50.15", "Kūṭa"],
  ["SN 50.16", "Mūla"],
  ["SN 50.17", "Sāra"],
  ["SN 50.18", "Vassika"],
  ["SN 50.19", "Rāja"],
  ["SN 50.20", "Candima"],
  ["SN 50.21", "Suriya"],
  ["SN 50.22", "Vattha"],
  ["SN 50.23", "Bala"],
  ["SN 50.24", "Bīja"],
  ["SN 50.25", "Nāga"],
  ["SN 50.26", "Rukkha"],
  ["SN 50.27", "Kumbha"],
  ["SN 50.28", "Sūka"],
  ["SN 50.29", "Ākāsa"],
  ["SN 50.30", "Paṭhama Megha"],
  ["SN 50.31", "Dutiya Megha"],
  ["SN 50.32", "Nāvā"],
  ["SN 50.33", "Āgantuka"],
  ["SN 50.34", "Nadī"],
  ["SN 50.35", "Esanā"],
  ["SN 50.36", "Vidhā"],
  ["SN 50.37", "Āsava"],
  ["SN 50.38", "Bhava"],
  ["SN 50.39", "Dukkhatā"],
  ["SN 50.40", "Khīla"],
  ["SN 50.41", "Mala"],
  ["SN 50.42", "Nīgha"],
  ["SN 50.43", "Vedanā"],
  ["SN 50.44", "Taṇhā"],
  ["SN 50.45", "Ogha"],
  ["SN 50.46", "Yoga"],
  ["SN 50.47", "Upādāna"],
  ["SN 50.48", "Gantha"],
  ["SN 50.49", "Anusaya"],
  ["SN 50.50", "Kāmaguṇa"],
  ["SN 50.51", "Nīvaraṇa"],
  ["SN 50.52", "Upādānakkhandha"],
  ["SN 50.53", "Orambhāgiyasaṃyojana"],
  ["SN 50.54", "Uddhambhāgiyasaṃyojana"],
  ["SN 50.55", "Paṭhama Pācīnaninna"],
  ["SN 50.56", "Dutiya Pācīnaninna"],
  ["SN 50.57", "Tatiya Pācīnaninna"],
  ["SN 50.58", "Catuttha Pācīnaninna"],
  ["SN 50.59", "Pācīnaninna 5"],
  ["SN 50.60", "Pācīnaninna 6"],
  ["SN 50.61", "Paṭhama Samuddaninna"],
  ["SN 50.62", "Dutiya Samuddaninna"],
  ["SN 50.63", "Tatiya Samuddaninna"],
  ["SN 50.64", "Catuttha Samuddaninna"],
  ["SN 50.65", "Samuddaninna 5"],
  ["SN 50.66", "Samuddaninna 6"],
  ["SN 50.67", "Tathāgata"],
  ["SN 50.68", "Pada"],
  ["SN 50.69", "Kūṭa"],
  ["SN 50.70", "Mūla"],
  ["SN 50.71", "Sāra"],
  ["SN 50.72", "Vassika"],
  ["SN 50.73", "Rāja"],
  ["SN 50.74", "Candima"],
  ["SN 50.75", "Suriya"],
  ["SN 50.76", "Vattha"],
  ["SN 50.77", "Bala"],
  ["SN 50.78", "Bīja"],
  ["SN 50.79", "Nāga"],
  ["SN 50.80", "Rukkha"],
  ["SN 50.81", "Kumbha"],
  ["SN 50.82", "Sūka"],
  ["SN 50.83", "Ākāsa"],
  ["SN 50.84", "Paṭhama Megha"],
  ["SN 50.85", "Dutiya Megha"],
  ["SN 50.86", "Nāvā"],
  ["SN 50.87", "Āgantuka"],
  ["SN 50.88", "Nadī"],
  ["SN 50.89", "Esanā"],
  ["SN 50.90", "Vidhā"],
  ["SN 50.91", "Āsava"],
  ["SN 50.92", "Bhava"],
  ["SN 50.93", "Dukkhatā"],
  ["SN 50.94", "Khīla"],
  ["SN 50.95", "Mala"],
  ["SN 50.96", "Nīgha"],
  ["SN 50.97", "Vedanā"],
  ["SN 50.98", "Taṇhā"],
  ["SN 50.99", "Ogha"],
  ["SN 50.100", "Yoga"],
  ["SN 50.101", "Upādāna"],
  ["SN 50.102", "Gantha"],
  ["SN 50.103", "Anusaya"],
  ["SN 50.104", "Kāmaguṇa"],
  ["SN 50.105", "Nīvaraṇa"],
  ["SN 50.106", "Upādānakkhandha"],
  ["SN 50.107", "Orambhāgiyasaṃyojana"],
  ["SN 50.108", "Uddhambhāgiyasaṃyojana"],
  ["SN 51.1", "Apāra"],
  ["SN 51.2", "Viraddha"],
  ["SN 51.3", "Ariya"],
  ["SN 51.4", "Nibbidā"],
  ["SN 51.5", "Padesa"],
  ["SN 51.6", "Samatta"],
  ["SN 51.7", "Bhikkhu"],
  ["SN 51.8", "Arahanta"],
  ["SN 51.9", "Ñāṇa"],
  ["SN 51.10", "Cetiya"],
  ["SN 51.11", "Pubba [Hetu]"],
  ["SN 51.12", "Mahapphala"],
  ["SN 51.13", "Chanda"],
  ["SN 51.14", "Moggallāna"],
  ["SN 51.15", "Uṇṇābhabrāhmaṇa [Brāhmaṇa]"],
  ["SN 51.16", "Mahiddhika"],
  ["SN 51.17", "Iddhividha"],
  ["SN 51.18", "Bhikkhu"],
  ["SN 51.19", "Bhāvanā"],
  ["SN 51.20", "Vibhaṅga"],
  ["SN 51.21", "Magga"],
  ["SN 51.22", "Ayoguḷa"],
  ["SN 51.23", "Bhikkhu"],
  ["SN 51.24", "Suddhaka"],
  ["SN 51.25", "Paṭhama Phala"],
  ["SN 51.26", "Dutiya Phala"],
  ["SN 51.27", "Paṭhama Ānanda"],
  ["SN 51.28", "Dutiya Ānanda"],
  ["SN 51.29", "Paṭhama Sambahulabhikkhu"],
  ["SN 51.30", "Dutiya Sambahulabhikkhu"],
  ["SN 51.31", "Moggallāna"],
  ["SN 51.32", "Tathāgata"],
  ["SN 51.33", "Paṭhama Pācīnaninna"],
  ["SN 51.34", "Dutiya Pācīnaninna"],
  ["SN 51.35", "Tatiya Pācīnaninna"],
  ["SN 51.36", "Catuttha Pācīnaninna"],
  ["SN 51.37", "Pācīnaninna 5"],
  ["SN 51.38", "Pācīnaninna 6"],
  ["SN 51.39", "Paṭhama Samuddaninna"],
  ["SN 51.40", "Dutiya Samuddaninna"],
  ["SN 51.41", "Tatiya Samuddaninna"],
  ["SN 51.42", "Catuttha Samuddaninna"],
  ["SN 51.43", "Samuddaninna 5"],
  ["SN 51.44", "Samuddaninna 6"],
  ["SN 51.45", "Tathāgata"],
  ["SN 51.46", "Pada"],
  ["SN 51.47", "Kūṭa"],
  ["SN 51.48", "Mūla"],
  ["SN 51.49", "Sāra"],
  ["SN 51.50", "Vassika"],
  ["SN 51.51", "Rāja"],
  ["SN 51.52", "Candima"],
  ["SN 51.53", "Suriya"],
  ["SN 51.54", "Vattha"],
  ["SN 51.55", "Bala"],
  ["SN 51.56", "Bīja"],
  ["SN 51.57", "Nāga"],
  ["SN 51.58", "Rukkha"],
  ["SN 51.59", "Kumbha"],
  ["SN 51.60", "Sūka"],
  ["SN 51.61", "Ākāsa"],
  ["SN 51.62", "Paṭhama Megha"],
  ["SN 51.63", "Dutiya Megha"],
  ["SN 51.64", "Nāvā"],
  ["SN 51.65", "Āgantuka"],
  ["SN 51.66", "Nadī"],
  ["SN 51.67", "Esanā"],
  ["SN 51.68", "Vidhā"],
  ["SN 51.69", "Āsava"],
  ["SN 51.70", "Bhava"],
  ["SN 51.71", "Dukkhatā"],
  ["SN 51.72", "Khīla"],
  ["SN 51.73", "Mala"],
  ["SN 51.74", "Nīgha"],
  ["SN 51.75", "Vedanā"],
  ["SN 51.76", "Taṇhā"],
  ["SN 51.77", "Ogha"],
  ["SN 51.78", "Yoga"],
  ["SN 51.79", "Upādāna"],
  ["SN 51.80", "Gantha"],
  ["SN 51.81", "Anusaya"],
  ["SN 51.82", "Kāmaguṇa"],
  ["SN 51.83", "Nīvaraṇa"],
  ["SN 51.84", "Upādānakkhandha"],
  ["SN 51.85", "Orambhāgiyasaṃyojana"],
  ["SN 51.86", "Uddhambhāgiyasaṃyojana"],
  ["SN 52.1", "Paṭhama Rahogata"],
  ["SN 52.2", "Dutiya Rahogata"],
  ["SN 52.3", "Sutanu"],
  ["SN 52.4", "Paṭhama Kaṇṭakī"],
  ["SN 52.5", "Dutiya Kaṇṭakī"],
  ["SN 52.6", "Tatiya Kaṇṭakī"],
  ["SN 52.7", "Taṇhakkhaya"],
  ["SN 52.8", "Salalāgāra"],
  ["SN 52.9", "Ambapāli"],
  ["SN 52.10", "Gilāna"],
  ["SN 52.11", "Sahassa"],
  ["SN 52.12", "Iddhividha"],
  ["SN 52.13", "Dibbasota"],
  ["SN 52.14", "Cetopariya"],
  ["SN 52.15", "Ṭhāna"],
  ["SN 52.16", "Kamma"],
  ["SN 52.17", "Sabbatthagāminī"],
  ["SN 52.18", "Anekadhātu"],
  ["SN 52.19", "Nānādhimuttika"],
  ["SN 52.20", "Indriya"],
  ["SN 52.21", "Jhāna"],
  ["SN 52.22", "Pubbenivāsa"],
  ["SN 52.23", "Dibbacakkhu"],
  ["SN 52.24", "Āsavakkhaya"],
  ["SN 53.1", "Paṭhama Pācīnaninna"],
  ["SN 53.2", "Dutiya Pācīnaninna"],
  ["SN 53.3", "Tatiya Pācīnaninna"],
  ["SN 53.4", "Catuttha Pācīnaninna"],
  ["SN 53.5", "Pācīnaninna 5"],
  ["SN 53.6", "Pācīnaninna 6"],
  ["SN 53.7", "Paṭhama Samuddaninna"],
  ["SN 53.8", "Dutiya Samuddaninna"],
  ["SN 53.9", "Tatiya Samuddaninna"],
  ["SN 53.10", "Catuttha Samuddaninna"],
  ["SN 53.11", "Samuddaninna 5"],
  ["SN 53.12", "Samuddaninna 6"],
  ["SN 53.13", "Tathāgata"],
  ["SN 53.14", "Pada"],
  ["SN 53.15", "Kūṭa"],
  ["SN 53.16", "Mūla"],
  ["SN 53.17", "Sāra"],
  ["SN 53.18", "Vassika"],
  ["SN 53.19", "Rāja"],
  ["SN 53.20", "Candima"],
  ["SN 53.21", "Suriya"],
  ["SN 53.22", "Vattha"],
  ["SN 53.23", "Bala"],
  ["SN 53.24", "Bīja"],
  ["SN 53.25", "Nāga"],
  ["SN 53.26", "Rukkha"],
  ["SN 53.27", "Kumbha"],
  ["SN 53.28", "Sūka"],
  ["SN 53.29", "Ākāsa"],
  ["SN 53.30", "Paṭhama Megha"],
  ["SN 53.31", "Dutiya Megha"],
  ["SN 53.32", "Nāvā"],
  ["SN 53.33", "Āgantuka"],
  ["SN 53.34", "Nadī"],
  ["SN 53.35", "Esanā"],
  ["SN 53.36", "Vidhā"],
  ["SN 53.37", "Āsava"],
  ["SN 53.38", "Bhava"],
  ["SN 53.39", "Dukkhatā"],
  ["SN 53.40", "Khīla"],
  ["SN 53.41", "Mala"],
  ["SN 53.42", "Nīgha"],
  ["SN 53.43", "Vedanā"],
  ["SN 53.44", "Taṇhā"],
  ["SN 53.45", "Ogha"],
  ["SN 53.46", "Yoga"],
  ["SN 53.47", "Upādāna"],
  ["SN 53.48", "Gantha"],
  ["SN 53.49", "Anusaya"],
  ["SN 53.50", "Kāmaguṇa"],
  ["SN 53.51", "Nīvaraṇa"],
  ["SN 53.52", "Upādānakkhandha"],
  ["SN 53.53", "Orambhāgiyasaṃyojana"],
  ["SN 53.54", "Uddhambhāgiyasaṃyojana"],
  ["SN 54.1", "Ekadhamma"],
  ["SN 54.2", "Bojajhaṅga"],
  ["SN 54.3", "Suddhaka"],
  ["SN 54.4", "Paṭhama Phala"],
  ["SN 54.5", "Dutiya Phala"],
  ["SN 54.6", "Ariṭṭha"],
  ["SN 54.7", "Kappina"],
  ["SN 54.8", "Dīpa"],
  ["SN 54.9", "Vesālī"],
  ["SN 54.10", "Kimbila"],
  ["SN 54.11", "Icchānaṅgala"],
  ["SN 54.12", "Kaṅkheyya"],
  ["SN 54.13", "Paṭhama Ānanda"],
  ["SN 54.14", "Dutiya Ānanda"],
  ["SN 54.15", "Paṭhama Bhikkhu"],
  ["SN 54.16", "Dutiya Bhikkhu"],
  ["SN 54.17", "Saṃyojana"],
  ["SN 54.18", "Anusaya"],
  ["SN 54.19", "Addhāna"],
  ["SN 54.20", "Āsavakkhaya"],
  ["SN 55.1", "Rāja"],
  ["SN 55.2", "Brahmacariyogadha"],
  ["SN 55.3", "Dīghāvu upāsaka"],
  ["SN 55.4", "Paṭhama Sāriputta"],
  ["SN 55.5", "Dutiya Sāriputta"],
  ["SN 55.6", "Thapati"],
  ["SN 55.7", "Veludavāreyya"],
  ["SN 55.8", "Paṭhama Giñjakāvasatha"],
  ["SN 55.9", "Dutiya Giñjakāvasatha"],
  ["SN 55.10", "Tatiya Giñjakāvasatha"],
  ["SN 55.11", "Sahassa"],
  ["SN 55.12", "Brāhmaṇa"],
  ["SN 55.13", "Ānanda"],
  ["SN 55.14", "Duggati"],
  ["SN 55.15", "Vinipāta"],
  ["SN 55.16", "Paṭhama Mittāmacca"],
  ["SN 55.17", "Dutiya Mittāmacca"],
  ["SN 55.18", "Paṭhama Devacārika"],
  ["SN 55.19", "Dutiya Devacārika"],
  ["SN 55.20", "Tatiya Devacārika"],
  ["SN 55.21", "Paṭhama Mahānāma"],
  ["SN 55.22", "Dutiya Mahānāma"],
  ["SN 55.23", "Godhā"],
  ["SN 55.24", "Paṭhama Sarakāni"],
  ["SN 55.25", "Dutiya Sarakāni"],
  ["SN 55.26", "Paṭhama Anāthapiṇḍika"],
  ["SN 55.27", "Dutiya Anāthapiṇḍika"],
  ["SN 55.28", "Duvera [Tatiya Anāthapiṇḍika]"],
  ["SN 55.29", "Bhikkhu"],
  ["SN 55.30", "Licchavi"],
  ["SN 55.31", "Paṭhama Abhisanda"],
  ["SN 55.32", "Dutiya Abhisanda"],
  ["SN 55.33", "Tatiya Abhisanda"],
  ["SN 55.34", "Paṭhama Devapada"],
  ["SN 55.35", "Dutiya Devapada"],
  ["SN 55.36", "Sabhāgata"],
  ["SN 55.37", "Mahānāma"],
  ["SN 55.38", "Vassa"],
  ["SN 55.39", "Kāligodhā"],
  ["SN 55.40", "Nandiya"],
  ["SN 55.41", "Paṭhama Asaṅkheyya"],
  ["SN 55.42", "Dutiya Asaṅkheyya"],
  ["SN 55.43", "Tatiya Asaṅkheyya"],
  ["SN 55.44", "Paṭhama Aḍḍha"],
  ["SN 55.45", "Dutiya Aḍḍha"],
  ["SN 55.46", "Suddhaka"],
  ["SN 55.47", "Nandiya"],
  ["SN 55.48", "Bhaddiya"],
  ["SN 55.49", "Mahānāma"],
  ["SN 55.50", "Aṅga"],
  ["SN 55.51", "Sagāthaka"],
  ["SN 55.52", "Vassaṃvuttha"],
  ["SN 55.53", "Dhammadinna"],
  ["SN 55.54", "Gilāna"],
  ["SN 55.55", "Paṭhama Phala"],
  ["SN 55.56", "Dutiya Phala"],
  ["SN 55.57", "Tatiya Phala"],
  ["SN 55.58", "Catuttha Phala"],
  ["SN 55.59", "Paṭilābhapañña"],
  ["SN 55.60", "Vuḍḍhipañña"],
  ["SN 55.61", "Vepullapañña"],
  ["SN 55.62", "Mahāpañña"],
  ["SN 55.63", "Puthupañña"],
  ["SN 55.64", "Vipulapañña"],
  ["SN 55.65", "Gambhīrapañña"],
  ["SN 55.66", "Asāmantapañña"],
  ["SN 55.67", "Bhūripañña"],
  ["SN 55.68", "Paññābāhulla"],
  ["SN 55.69", "Sīghapañña"],
  ["SN 55.70", "Lahupañña"],
  ["SN 55.71", "Hāsupañña"],
  ["SN 55.72", "Javanapañña"],
  ["SN 55.73", "Tikkhapañña"],
  ["SN 55.74", "Nibbedhikapañña"],
  ["SN 56.1", "Samādhi"],
  ["SN 56.2", "Paṭisallāna"],
  ["SN 56.3", "Paṭhama Kulaputta"],
  ["SN 56.4", "Dutiya Kulaputta"],
  ["SN 56.5", "Paṭhama Samaṇabrāhmaṇa"],
  ["SN 56.6", "Dutiya Samaṇabrāhmaṇa"],
  ["SN 56.7", "Vitakka"],
  ["SN 56.8", "Cintā"],
  ["SN 56.9", "Viggāhikakathā"],
  ["SN 56.10", "Tiracchānakathā"],
  ["SN 56.11", "Dhammacakkappavattana "],
  ["SN 56.12", "Tathāgata"],
  ["SN 56.13", "Khandha"],
  ["SN 56.14", "Āyatana"],
  ["SN 56.15", "Paṭhama Dhāraṇa"],
  ["SN 56.16", "Dutiya Dhāraṇa"],
  ["SN 56.17", "Avijjā"],
  ["SN 56.18", "Vijjā"],
  ["SN 56.19", "Saṅkāsana"],
  ["SN 56.20", "Tatha"],
  ["SN 56.21", "Paṭhama Koṭigāma"],
  ["SN 56.22", "Dutiya Koṭigāma"],
  ["SN 56.23", "Sammāsambuddha"],
  ["SN 56.24", "Arahanta"],
  ["SN 56.25", "Āsavakkhaya"],
  ["SN 56.26", "Mitta"],
  ["SN 56.27", "Tatha"],
  ["SN 56.28", "Loka"],
  ["SN 56.29", "Pariññeyya"],
  ["SN 56.30", "Gavamapati"],
  ["SN 56.31", "Siṃsapā"],
  ["SN 56.32", "Khadira"],
  ["SN 56.33", "Daṇḍa"],
  ["SN 56.34", "Cela"],
  ["SN 56.35", "Sattisata"],
  ["SN 56.36", "Pāṇa"],
  ["SN 56.37", "Paṭhama Suriyūpama"],
  ["SN 56.38", "Dutiya Suriyūpama"],
  ["SN 56.39", "Indakhīla"],
  ["SN 56.40", "Vāda"],
  ["SN 56.41", "Cintā"],
  ["SN 56.42", "Papāta"],
  ["SN 56.43", "Pariḷāha"],
  ["SN 56.44", "Kūṭāgāra"],
  ["SN 56.45", "Vāla"],
  ["SN 56.46", "Andhakāra"],
  ["SN 56.47", "Paṭhama Chiggala"],
  ["SN 56.48", "Dutiya Chiggala"],
  ["SN 56.49", "Paṭhama Sineru"],
  ["SN 56.50", "Dutiya Sineru"],
  ["SN 56.51", "Nakhasikhā"],
  ["SN 56.52", "Pokkharaṇī"],
  ["SN 56.53", "Paṭhama Sambhejja"],
  ["SN 56.54", "Dutiya Sambhejja"],
  ["SN 56.55", "Paṭhama Paṭhavi"],
  ["SN 56.56", "Dutiya Paṭhavi"],
  ["SN 56.57", "Paṭhama Samudda"],
  ["SN 56.58", "Dutiya Samudda"],
  ["SN 56.59", "Paṭhama Pabbatūpama"],
  ["SN 56.60", "Dutiya Pabbatūpama"],
  ["SN 56.61", "Aññatra"],
  ["SN 56.62", "Paccanta"],
  ["SN 56.63", "Paññā"],
  ["SN 56.64", "Surāmeraya"],
  ["SN 56.65", "Odakaja"],
  ["SN 56.66", "Matteyya"],
  ["SN 56.67", "Petteyya"],
  ["SN 56.68", "Sāmañña"],
  ["SN 56.69", "Brahmañña"],
  ["SN 56.70", "Apacāyī"],
  ["SN 56.71", "Pāṇātipāta"],
  ["SN 56.72", "Adinnādāna"],
  ["SN 56.73", "Kāmesumicchācāra"],
  ["SN 56.74", "Musāvāda"],
  ["SN 56.75", "Pisuṇavācā"],
  ["SN 56.76", "Pharusavācā"],
  ["SN 56.77", "Samphappalāpa"],
  ["SN 56.78", "Bījagāma"],
  ["SN 56.79", "Vikālabhojana"],
  ["SN 56.80", "Vilepana"],
  ["SN 56.81", "Naccagīta"],
  ["SN 56.82", "Uccāsayana"],
  ["SN 56.83", "Jātarūpa"],
  ["SN 56.84", "Āmakadhañña"],
  ["SN 56.85", "Āmakamaṃsa"],
  ["SN 56.86", "Kumārika"],
  ["SN 56.87", "Dāsidāsa"],
  ["SN 56.88", "Ajeḷaka"],
  ["SN 56.89", "Kukkuṭasūkara"],
  ["SN 56.90", "Hatthino"],
  ["SN 56.91", "Khettavatthu"],
  ["SN 56.92", "Kayavikkaya"],
  ["SN 56.93", "Dūteyya"],
  ["SN 56.94", "Tulākūṭādi"],
  ["SN 56.95", "Ukkoṭanādi"],
  ["SN 56.96", "Paṭhama Chedanādi"],
  ["SN 56.97", "Dutiya Chedanādi"],
  ["SN 56.98", "Tatiya Chedanādi"],
  ["SN 56.99", "Catuttha Chedanādi"],
  ["SN 56.100", "Chedanādi 5"],
  ["SN 56.101", "Chedanādi 6"],
  ["SN 56.102", "Paṭhama Manussacuti"],
  ["SN 56.103", "Dutiya Manussacuti"],
  ["SN 56.104", "Tatiya Manussacuti"],
  ["SN 56.105", "Catuttha Manussacuti"],
  ["SN 56.106", "Manussacuti 5"],
  ["SN 56.107", "Manussacuti 6"],
  ["SN 56.108", "Paṭhama Devacuti"],
  ["SN 56.109", "Dutiya Devacuti"],
  ["SN 56.110", "Tatiya Devacuti"],
  ["SN 56.111", "Catuttha Devacuti"],
  ["SN 56.112", "Devacuti 5"],
  ["SN 56.113", "Devacuti 6"],
  ["SN 56.114", "Paṭhama Nirayacuti"],
  ["SN 56.115", "Dutiya Nirayacuti"],
  ["SN 56.116", "Tatiya Nirayacuti"],
  ["SN 56.117", "Catuttha Nirayacuti"],
  ["SN 56.118", "Nirayacuti 5"],
  ["SN 56.119", "Nirayacuti 6"],
  ["SN 56.120", "Paṭhama Tiracchānacuti"],
  ["SN 56.121", "Dutiya Tiracchānacuti"],
  ["SN 56.122", "Tatiya Tiracchānacuti"],
  ["SN 56.123", "Catuttha Tiracchānacuti"],
  ["SN 56.124", "Tiracchānacuti 5"],
  ["SN 56.125", "Tiracchānacuti 6"],
  ["SN 56.126", "Paṭhama Petticuti"],
  ["SN 56.127", "Dutiya Petticuti"],
  ["SN 56.128", "Tatiya Petticuti"],
  ["SN 56.129", "Catuttha Petticuti"],
  ["SN 56.130", "Petticuti 5"],
  ["SN 56.131", "Petticuti 6"],

  ["AN 1.1", "Cittapariyādāna [Rūpādi] 1"],
  ["AN 1.2", "Cittapariyādāna [Rūpādi] 2"],
  ["AN 1.3", "Cittapariyādāna [Rūpādi] 3"],
  ["AN 1.4", "Cittapariyādāna [Rūpādi] 4"],
  ["AN 1.5", "Cittapariyādāna [Rūpādi] 5"],
  ["AN 1.6", "Cittapariyādāna [Rūpādi] 6"],
  ["AN 1.7", "Cittapariyādāna [Rūpādi] 7"],
  ["AN 1.8", "Cittapariyādāna [Rūpādi] 8"],
  ["AN 1.9", "Cittapariyādāna [Rūpādi] 9"],
  ["AN 1.10", "Cittapariyādāna [Rūpādi] 10"],
  ["AN 1.11", "Nīvaraṇappahāṇa 1"],
  ["AN 1.12", "Nīvaraṇappahāṇa 2"],
  ["AN 1.13", "Nīvaraṇappahāṇa 3"],
  ["AN 1.14", "Nīvaraṇappahāṇa 4"],
  ["AN 1.15", "Nīvaraṇappahāṇa 5"],
  ["AN 1.16", "Nīvaraṇappahāṇa 6"],
  ["AN 1.17", "Nīvaraṇappahāṇa 7"],
  ["AN 1.18", "Nīvaraṇappahāṇa 8"],
  ["AN 1.19", "Nīvaraṇappahāṇa 9"],
  ["AN 1.20", "Nīvaraṇappahāṇa 10"],
  ["AN 1.21", "Akammanīya 1"],
  ["AN 1.22", "Akammanīya 2"],
  ["AN 1.23", "Akammanīya 3"],
  ["AN 1.24", "Akammanīya 4"],
  ["AN 1.25", "Akammanīya 5"],
  ["AN 1.26", "Akammanīya 6"],
  ["AN 1.27", "Akammanīya 7"],
  ["AN 1.28", "Akammanīya 8"],
  ["AN 1.29", "Akammanīya 9"],
  ["AN 1.30", "Akammanīya 10"],
  ["AN 1.31", "Adanta 1"],
  ["AN 1.32", "Adanta 2"],
  ["AN 1.33", "Adanta 3"],
  ["AN 1.34", "Adanta 4"],
  ["AN 1.35", "Adanta 5"],
  ["AN 1.36", "Adanta 6"],
  ["AN 1.37", "Adanta 7"],
  ["AN 1.38", "Adanta 8"],
  ["AN 1.39", "Adanta 9"],
  ["AN 1.40", "Adanta 10"],
  ["AN 1.41", "Sūka [Paṇihitaaccha] 1"],
  ["AN 1.42", "Sūka [Paṇihitaaccha] 2"],
  ["AN 1.43", "Sūka [Paṇihitaaccha] 3"],
  ["AN 1.44", "Sūka [Paṇihitaaccha] 4"],
  ["AN 1.45", "Sūka [Paṇihitaaccha] 5"],
  ["AN 1.46", "Sūka [Paṇihitaaccha] 6"],
  ["AN 1.47", "Sūka [Paṇihitaaccha] 7"],
  ["AN 1.48", "Sūka [Paṇihitaaccha] 8"],
  ["AN 1.49", "Sūka [Paṇihitaaccha] 9"],
  ["AN 1.50", "Sūka [Paṇihitaaccha] 10"],
  ["AN 1.51", "Pabhassara [Accharāsaṅghāta] 1"],
  ["AN 1.52", "Pabhassara [Accharāsaṅghāta] 2"],
  ["AN 1.53", "Pabhassara [Accharāsaṅghāta] 3"],
  ["AN 1.54", "Pabhassara [Accharāsaṅghāta] 4"],
  ["AN 1.55", "Pabhassara [Accharāsaṅghāta] 5"],
  ["AN 1.56", "Pabhassara [Accharāsaṅghāta] 6"],
  ["AN 1.57", "Pabhassara [Accharāsaṅghāta] 7"],
  ["AN 1.58", "Pabhassara [Accharāsaṅghāta] 8"],
  ["AN 1.59", "Pabhassara [Accharāsaṅghāta] 9"],
  ["AN 1.60", "Pabhassara [Accharāsaṅghāta] 10"],
  ["AN 1.61", "Viriyārambha [Viriyārambhādi] 1"],
  ["AN 1.62", "Viriyārambha [Viriyārambhādi] 2"],
  ["AN 1.63", "Viriyārambha [Viriyārambhādi] 3"],
  ["AN 1.64", "Viriyārambha [Viriyārambhādi] 4"],
  ["AN 1.65", "Viriyārambha [Viriyārambhādi] 5"],
  ["AN 1.66", "Viriyārambha [Viriyārambhādi] 6"],
  ["AN 1.67", "Viriyārambha [Viriyārambhādi] 7"],
  ["AN 1.68", "Viriyārambha [Viriyārambhādi] 8"],
  ["AN 1.69", "Viriyārambha [Viriyārambhādi] 9"],
  ["AN 1.70", "Viriyārambha [Viriyārambhādi] 10"],
  ["AN 1.71", "Kalyāṇamitta [Kalyāṇamittādi] 1"],
  ["AN 1.72", "Kalyāṇamitta [Kalyāṇamittādi] 2"],
  ["AN 1.73", "Kalyāṇamitta [Kalyāṇamittādi] 3"],
  ["AN 1.74", "Kalyāṇamitta [Kalyāṇamittādi] 4"],
  ["AN 1.75", "Kalyāṇamitta [Kalyāṇamittādi] 5"],
  ["AN 1.76", "Kalyāṇamitta [Kalyāṇamittādi] 6"],
  ["AN 1.77", "Kalyāṇamitta [Kalyāṇamittādi] 7"],
  ["AN 1.78", "Kalyāṇamitta [Kalyāṇamittādi] 8"],
  ["AN 1.79", "Kalyāṇamitta [Kalyāṇamittādi] 9"],
  ["AN 1.80", "Kalyāṇamitta [Kalyāṇamittādi] 10"],
  ["AN 1.81", "Kalyāṇamitta [Kalyāṇamittādi] 11"],
  ["AN 1.82-97", "Pamāda [Pamādādi] 1-16"],
  ["AN 1.98-139", "Ajjhattika [Pamādādi 2] 1-42"],
  ["AN 1.140-149", "Adhamma 1-10"],
  ["AN 1.150-169", "Anāpatti 1-20"],
  ["AN 1.170", "Ekapuggala 1"],
  ["AN 1.171", "Ekapuggala 2"],
  ["AN 1.172", "Ekapuggala 3"],
  ["AN 1.173", "Ekapuggala 4"],
  ["AN 1.174", "Ekapuggala 5"],
  ["AN 1.175-186", "Ekapuggala 6-17"],
  ["AN 1.187", "Ekapuggala 18"],
  ["AN 1.188-197", "Etadagga (Paṭhama) 1-10"],
  ["AN 1.198-208", "Etadagga (Dutiya) 1-11"],
  ["AN 1.209-218", "Etadagga (Tatiya) 1-10"],
  ["AN 1.219-234", "Etadagga (Catuttha) 1-16"],
  ["AN 1.235-247", "Etadagga (Pañcama) 1-13"],
  ["AN 1.248-257", "Etadagga (Chaṭṭha) 1-10"],
  ["AN 1.258-267", "Etadagga (Sattama) 1-10"],
  ["AN 1.268-277", "Aṭṭhāna  1-10"],
  ["AN 1.278-286", "Aṭṭhāna  11-19"],
  ["AN 1.287-295", "Aṭṭhāna  20-28"],
  ["AN 1.296-305", "Ekadhamma (Paṭhama) 1-10"],
  ["AN 1.306", "Ekadhamma (Dutiya) 1"],
  ["AN 1.307", "Ekadhamma (Dutiya) 2"],
  ["AN 1.308", "Ekadhamma (Dutiya) 3"],
  ["AN 1.309", "Ekadhamma (Dutiya) 4"],
  ["AN 1.310", "Ekadhamma (Dutiya) 5"],
  ["AN 1.311", "Ekadhamma (Dutiya) 6"],
  ["AN 1.312", "Ekadhamma (Dutiya) 7"],
  ["AN 1.313", "Ekadhamma (Dutiya) 8"],
  ["AN 1.314", "Ekadhamma (Dutiya) 9"],
  ["AN 1.315", "Ekadhamma (Dutiya) 10"],
  ["AN 1.316", "Ekadhamma (Tatiya) 1"],
  ["AN 1.317", "Ekadhamma (Tatiya) 2"],
  ["AN 1.318", "Ekadhamma (Tatiya) 3"],
  ["AN 1.319", "Ekadhamma (Tatiya) 4"],
  ["AN 1.320-321", "Ekadhamma (Tatiya) 5-6"],
  ["AN 1.322-323", "Ekadhamma (Tatiya) 7-8"],
  ["AN 1.324-327", "Ekadhamma (Tatiya) 9-12"],
  ["AN 1.326-327", "Ekadhamma (Tatiya) 11-12"],
  ["AN 1.328", "Ekadhamma (Tatiya) 13"],
  ["AN 1.329-332", "Ekadhamma (Tatiya) 14-17"],
  ["AN 1.333-377", "Jambudīpapeyyāla [Ekadhamma (Catuttha)] 1-45"],
  ["AN 1.378-393", "Pasādakaradhamma 1-16"],
  ["AN 1.394", "Accharāsaṅghāta [Apara accharāsaṅghāta] 1"],
  ["AN 1.395-401", "Accharāsaṅghāta [Apara accharāsaṅghāta] 2-8"],
  ["AN 1.402-405", "Accharāsaṅghāta [Apara accharāsaṅghāta] 9-12"],
  ["AN 1.406-409", "Accharāsaṅghāta [Apara accharāsaṅghāta] 13-16"],
  ["AN 1.410-413", "Accharāsaṅghāta [Apara accharāsaṅghāta] 17-20"],
  ["AN 1.414-418", "Accharāsaṅghāta [Apara accharāsaṅghāta] 21-25"],
  ["AN 1.419-423", "Accharāsaṅghāta [Apara accharāsaṅghāta] 26-30"],
  ["AN 1.424-430", "Accharāsaṅghāta [Apara accharāsaṅghāta] 31-37"],
  ["AN 1.431-438", "Accharāsaṅghāta [Apara accharāsaṅghāta] 38-45"],
  ["AN 1.439-446", "Accharāsaṅghāta [Apara accharāsaṅghāta] 46-53"],
  ["AN 1.447-454", "Accharāsaṅghāta [Apara accharāsaṅghāta] 54-61"],
  ["AN 1.455-464", "Accharāsaṅghāta [Apara accharāsaṅghāta] 62-71"],
  ["AN 1.465-474", "Accharāsaṅghāta [Apara accharāsaṅghāta] 72-81"],
  ["AN 1.475-484", "Accharāsaṅghāta [Apara accharāsaṅghāta] 82-91"],
  ["AN 1.485-494", "Accharāsaṅghāta [Apara accharāsaṅghāta] 92-101"],
  ["AN 1.495-534", "Accharāsaṅghāta [Apara accharāsaṅghāta] 102-141"],
  ["AN 1.535-574", "Accharāsaṅghāta [Apara accharāsaṅghāta] 142-181"],
  ["AN 1.575-582", "Kāyagatāsati 1-8"],
  ["AN 1.583", "Kāyagatāsati 9"],
  ["AN 1.584", "Kāyagatāsati 10"],
  ["AN 1.585", "Kāyagatāsati 11"],
  ["AN 1.586-590", "Kāyagatāsati 12-16"],
  ["AN 1.591-592", "Kāyagatāsati 17-18"],
  ["AN 1.593-595", "Kāyagatāsati 19-21"],
  ["AN 1.596-599", "Kāyagatāsati 22-25"],
  ["AN 1.600-615", "Kāyagatāsati 26-41"],
  ["AN 1.616-627", "Amata 1-12"],
  ["AN 2.1", "Vajja"],
  ["AN 2.2", "Padhāna"],
  ["AN 2.3", "Tapanīya"],
  ["AN 2.4", "Atapanīya"],
  ["AN 2.5", "Upaññāta"],
  ["AN 2.6", "Saññojanīya [Saññojana]"],
  ["AN 2.7", "Kaṇha"],
  ["AN 2.8", "Sukka"],
  ["AN 2.9", "Cariya"],
  ["AN 2.10", "Vassūpanāyika"],
  ["AN 2.11", "Adhikaraṇa 1"],
  ["AN 2.12", "Adhikaraṇa 2"],
  ["AN 2.13", "Adhikaraṇa 3"],
  ["AN 2.14", "Adhikaraṇa 4"],
  ["AN 2.15", "Adhikaraṇa 5"],
  ["AN 2.16", "Adhikaraṇa 6"],
  ["AN 2.17", "Adhikaraṇa 7"],
  ["AN 2.18", "Adhikaraṇa 8"],
  ["AN 2.19", "Adhikaraṇa 9"],
  ["AN 2.20", "Adhikaraṇa 10"],
  ["AN 2.21", "Bāla 1"],
  ["AN 2.22", "Bāla 2"],
  ["AN 2.23", "Bāla 3"],
  ["AN 2.24", "Bāla 4"],
  ["AN 2.25", "Bāla 5"],
  ["AN 2.26", "Bāla 6"],
  ["AN 2.27", "Bāla 7"],
  ["AN 2.28", "Bāla 8"],
  ["AN 2.29", "Bāla 9"],
  ["AN 2.30", "Bāla 10"],
  ["AN 2.31", "Bāla 11"],
  ["AN 2.32", "Samacitta 1"],
  ["AN 2.33", "Samacitta 2"],
  ["AN 2.34", "Samacitta 3"],
  ["AN 2.35", "Samacitta 4"],
  ["AN 2.36", "Samacitta 5"],
  ["AN 2.37", "Samacitta 6"],
  ["AN 2.38", "Samacitta 7"],
  ["AN 2.39", "Samacitta 8"],
  ["AN 2.40", "Samacitta 9"],
  ["AN 2.41", "Samacitta 10"],
  ["AN 2.42", "Parisa 1"],
  ["AN 2.43", "Parisa 2"],
  ["AN 2.44", "Parisa 3"],
  ["AN 2.45", "Parisa 4"],
  ["AN 2.46", "Parisa 5"],
  ["AN 2.47", "Parisa 6"],
  ["AN 2.48", "Parisa 7"],
  ["AN 2.49", "Parisa 8"],
  ["AN 2.50", "Parisa 9"],
  ["AN 2.51", "Parisa 10"],
  ["AN 2.52", "Puggala 1"],
  ["AN 2.53", "Puggala 2"],
  ["AN 2.54", "Puggala 3"],
  ["AN 2.55", "Puggala 4"],
  ["AN 2.56", "Puggala 5"],
  ["AN 2.57", "Puggala 6"],
  ["AN 2.58", "Puggala 7"],
  ["AN 2.59", "Puggala 8"],
  ["AN 2.60", "Puggala 9"],
  ["AN 2.61", "Puggala 10"],
  ["AN 2.62", "Puggala 11"],
  ["AN 2.63", "Puggala 12"],
  ["AN 2.64-76", "Sukha 1-13"],
  ["AN 2.77-86", "Sanimitta 1-10"],
  ["AN 2.87-97", "Dhamma 1-11"],
  ["AN 2.98", "Bāla 1"],
  ["AN 2.99-117", "Bāla 2-20"],
  ["AN 2.118", "Āsā [Āsāduppajaha] 1"],
  ["AN 2.119", "Āsā [Āsāduppajaha] 2"],
  ["AN 2.120", "Āsā [Āsāduppajaha] 3"],
  ["AN 2.121", "Āsā [Āsāduppajaha] 4"],
  ["AN 2.122", "Āsā [Āsāduppajaha] 5"],
  ["AN 2.123", "Āsā [Āsāduppajaha] 6"],
  ["AN 2.124", "Āsā [Āsāduppajaha] 7"],
  ["AN 2.125", "Āsā [Āsāduppajaha] 8"],
  ["AN 2.126", "Āsā [Āsāduppajaha] 9"],
  ["AN 2.127", "Āsā [Āsāduppajaha] 10"],
  ["AN 2.128", "Āsā [Āsāduppajaha] 11"],
  ["AN 2.129", "Āsā [Āsāduppajaha] 12"],
  ["AN 2.130", "Āyācana 1"],
  ["AN 2.131", "Āyācana 2"],
  ["AN 2.132", "Āyācana 3"],
  ["AN 2.133", "Āyācana 4"],
  ["AN 2.134", "Āyācana 5"],
  ["AN 2.135", "Āyācana 6"],
  ["AN 2.136", "Āyācana 7"],
  ["AN 2.137", "Āyācana 8"],
  ["AN 2.138", "Āyācana 9"],
  ["AN 2.139", "Āyācana 10"],
  ["AN 2.140", "Āyācana 11"],
  ["AN 2.141", "Dāna 1"],
  ["AN 2.142", "Dāna 2"],
  ["AN 2.143", "Dāna 3"],
  ["AN 2.144", "Dāna 4"],
  ["AN 2.145", "Dāna 5"],
  ["AN 2.146", "Dāna 6"],
  ["AN 2.147", "Dāna 7"],
  ["AN 2.148", "Dāna 8"],
  ["AN 2.149", "Dāna 9"],
  ["AN 2.150", "Dāna 10"],
  ["AN 2.151-162", "Santhāra 1-12"],
  ["AN 2.163-179", "Samāpatti 1-17"],
  ["AN 2.180-229", "Kodha [Kodhapeyyāla] 1-50"],
  ["AN 2.230-279", "Akusalapeyyāla 1-50"],
  ["AN 2.280-309", "Vinayapeyyāla 1-30"],
  ["AN 2.310-479", "Rāgapeyyāla 1-170"],
  ["AN 3.1", "Bhaya"],
  ["AN 3.2", "Lakkhaṇa"],
  ["AN 3.3", "Cintī"],
  ["AN 3.4", "Accaya"],
  ["AN 3.5", "Ayoniso"],
  ["AN 3.6", "Akusala"],
  ["AN 3.7", "Sāvajja"],
  ["AN 3.8", "Sabyābajjha"],
  ["AN 3.9", "Khata"],
  ["AN 3.10", "Mala"],
  ["AN 3.11", "Ñāta"],
  ["AN 3.12", "Sāraṇīya"],
  ["AN 3.13", "Āsaṃsa"],
  ["AN 3.14", "Cakkavatti"],
  ["AN 3.15", "Pacetana [Sacetana]"],
  ["AN 3.16", "Apaṇṇaka"],
  ["AN 3.17", "Attabyābādha"],
  ["AN 3.18", "Devaloka"],
  ["AN 3.19", "Paṭhama Pāpaṇika"],
  ["AN 3.20", "Dutiya Pāpaṇika"],
  ["AN 3.21", "Saviṭṭha [Samiddha]"],
  ["AN 3.22", "Gilāna"],
  ["AN 3.23", "Saṅkhāra"],
  ["AN 3.24", "Bahukāra"],
  ["AN 3.25", "Vajirūpama"],
  ["AN 3.26", "Sevitabba"],
  ["AN 3.27", "Jigucchitabba"],
  ["AN 3.28", "Gūthabhāṇī"],
  ["AN 3.29", "Andha"],
  ["AN 3.30", "Avakujja"],
  ["AN 3.31", "Sabrahmaka"],
  ["AN 3.32", "Ānanda"],
  ["AN 3.33", "Sāriputta"],
  ["AN 3.34", "Nidāna"],
  ["AN 3.35", "Hatthaka"],
  ["AN 3.36", "Devadūta"],
  ["AN 3.37", "Paṭhama Catumahārāja"],
  ["AN 3.38", "Dutiya Catumahārāja"],
  ["AN 3.39", "Sukhumāla"],
  ["AN 3.40", "Ādhipateyya"],
  ["AN 3.41", "Sammukhībhāva"],
  ["AN 3.42", "Tiṭhāna"],
  ["AN 3.43", "Atthavasa"],
  ["AN 3.44", "Kathāpavatti"],
  ["AN 3.45", "Paṇḍita"],
  ["AN 3.46", "Sīlavanta"],
  ["AN 3.47", "Saṅkhatalakkhaṇa"],
  ["AN 3.48", "Pabbatarāja"],
  ["AN 3.49", "Ātappakaraṇīya"],
  ["AN 3.50", "Mahācora"],
  ["AN 3.51", "Paṭhama Dvebrāhmaṇa"],
  ["AN 3.52", "Dutiya Dvebrāhmaṇa"],
  ["AN 3.53", "Aññatarabrāhmaṇa"],
  ["AN 3.54", "Paribbājaka"],
  ["AN 3.55", "Nibbuta"],
  ["AN 3.56", "Paloka"],
  ["AN 3.57", "Vacchagotta"],
  ["AN 3.58", "Tikaṇṇa"],
  ["AN 3.59", "Jāṇussoṇi"],
  ["AN 3.60", "Saṅgārava"],
  ["AN 3.61", "Titthāyatana"],
  ["AN 3.62", "Bhaya"],
  ["AN 3.63", "Venāgapura"],
  ["AN 3.64", "Sarabha"],
  ["AN 3.65", "Kesaputti [Kālāma]"],
  ["AN 3.66", "Sāḷha"],
  ["AN 3.67", "Kathāvatthu"],
  ["AN 3.68", "Aññatitthiya"],
  ["AN 3.69", "Akusalamūla"],
  ["AN 3.70", "Uposatha"],
  ["AN 3.71", "Channa"],
  ["AN 3.72", "Ājīvaka"],
  ["AN 3.73", "Mahānāmasakka"],
  ["AN 3.74", "Nigaṇṭha"],
  ["AN 3.75", "Nivesaka"],
  ["AN 3.76", "Paṭhama Bhava"],
  ["AN 3.77", "Dutiya Bhava"],
  ["AN 3.78", "Sīlabbata"],
  ["AN 3.79", "Gandhajāta"],
  ["AN 3.80", "Cūḷanikā"],
  ["AN 3.81", "Samaṇa"],
  ["AN 3.82", "Gadrabha"],
  ["AN 3.83", "Khetta"],
  ["AN 3.84", "Vajjiputta"],
  ["AN 3.85", "Sekkha"],
  ["AN 3.86", "Paṭhama Sikkhā"],
  ["AN 3.87", "Dutiya Sikkhā"],
  ["AN 3.88", "Tatiya Sikkhā"],
  ["AN 3.89", "Paṭhama Sikkhattaya"],
  ["AN 3.90", "Dutiya Sikkhattaya"],
  ["AN 3.91", "Paṅkadhā [Saṅkavā]"],
  ["AN 3.92", "Accāyika"],
  ["AN 3.93", "Paviveka"],
  ["AN 3.94", "Sarada"],
  ["AN 3.95", "Parisā"],
  ["AN 3.96", "Paṭhama Ājānīya"],
  ["AN 3.97", "Dutiya Ājānīya"],
  ["AN 3.98", "Tatiya Ājānīya"],
  ["AN 3.99", "Potthaka"],
  ["AN 3.100", "Loṇakapalla"],
  ["AN 3.101", "Paṃsudhovaka"],
  ["AN 3.102", "Nimitta"],
  ["AN 3.103", "Pubbevasambodha"],
  ["AN 3.104", "Paṭhama Assāda"],
  ["AN 3.105", "Dutiya Assāda"],
  ["AN 3.106", "Samaṇabrāhmaṇa"],
  ["AN 3.107", "Ruṇṇa"],
  ["AN 3.108", "Atitti"],
  ["AN 3.109", "Arakkhita"],
  ["AN 3.110", "Byāpanna"],
  ["AN 3.111", "Paṭhama Nidāna"],
  ["AN 3.112", "Dutiya Nidāna"],
  ["AN 3.113", "Āpāyika"],
  ["AN 3.114", "Dullabha"],
  ["AN 3.115", "Appameyya"],
  ["AN 3.116", "Āneñja"],
  ["AN 3.117", "Vipattisampadā"],
  ["AN 3.118", "Apaṇṇaka"],
  ["AN 3.119", "Kammanta"],
  ["AN 3.120", "Paṭhama Soceyya"],
  ["AN 3.121", "Dutiya Soceyya"],
  ["AN 3.122", "Moneyya"],
  ["AN 3.123", "Kusināra"],
  ["AN 3.124", "Bhaṇḍana"],
  ["AN 3.125", "Gotamakacetiya"],
  ["AN 3.126", "Bharaṇḍukālāma"],
  ["AN 3.127", "Hatthaka"],
  ["AN 3.128", "Kaṭuviya"],
  ["AN 3.129", "Paṭhama Anuruddha"],
  ["AN 3.130", "Dutiya Anuruddha"],
  ["AN 3.131", "Paṭicchanna"],
  ["AN 3.132", "Lekha"],
  ["AN 3.133", "Yodhājīva"],
  ["AN 3.134", "Parisā"],
  ["AN 3.135", "Mitta"],
  ["AN 3.136", "Uppādā"],
  ["AN 3.137", "Kesakambala"],
  ["AN 3.138", "Sampadā"],
  ["AN 3.139", "Vuddhi"],
  ["AN 3.140", "Assakhaḷuṅka"],
  ["AN 3.141", "Assaparassa"],
  ["AN 3.142", "Assājānīya"],
  ["AN 3.143", "Paṭhama Moranivāpa"],
  ["AN 3.144", "Dutiya Moranivāpa"],
  ["AN 3.145", "Tatiya Moranivāpa"],
  ["AN 3.146", "Akusala"],
  ["AN 3.147", "Sāvajja"],
  ["AN 3.148", "Visama"],
  ["AN 3.149", "Asuci"],
  ["AN 3.150", "Paṭhama Khata"],
  ["AN 3.151", "Dutiya Khata"],
  ["AN 3.152", "Tatiya Khata"],
  ["AN 3.153", "Catuttha Khata"],
  ["AN 3.154", "Vandanā"],
  ["AN 3.155", "Pubbaṇha"],
  ["AN 3.156", "Paṭhama Paṭipadā [Acelaka]"],
  ["AN 3.157-162", "Paṭipadā [Acelaka] 2-7"],
  ["AN 3.163-182", "Kammapathapeyyāla 1-20"],
  ["AN 3.183-352", "Rāgapeyyāla 1-170"],
  ["AN 4.1", "Anubuddha"],
  ["AN 4.2", "Papatita"],
  ["AN 4.3", "Paṭhama Khata"],
  ["AN 4.4", "Dutiya Khata"],
  ["AN 4.5", "Anusota"],
  ["AN 4.6", "Appassuta"],
  ["AN 4.7", "Sobhenti [Sobhana]"],
  ["AN 4.8", "Vesārajja"],
  ["AN 4.9", "Taṇhā [Taṇhuppāda]"],
  ["AN 4.10", "Yoga"],
  ["AN 4.11", "Caranta [Cara]"],
  ["AN 4.12", "Sīla"],
  ["AN 4.13", "Padhāna"],
  ["AN 4.14", "Saṃvarappadhāna [Saṃvara]"],
  ["AN 4.15", "Aggapaññatti [Paññatti]"],
  ["AN 4.16", "Sokhumma"],
  ["AN 4.17", "Agati [Paṭhama Agati]"],
  ["AN 4.18", "Nāgati [Dutiya Agati]"],
  ["AN 4.19", "Agatināgati [Tatiya Agati]"],
  ["AN 4.20", "Bhattuddesa [Bhattuddesaka]"],
  ["AN 4.21", "Paṭhama Uruvela"],
  ["AN 4.22", "Dutiya Uruvela"],
  ["AN 4.23", "Loka"],
  ["AN 4.24", "Kāḷakārāma"],
  ["AN 4.25", "Brahmacariya"],
  ["AN 4.26", "Kuhaka [Kuha]"],
  ["AN 4.27", "Santuṭṭhi"],
  ["AN 4.28", "Ariyavaṃsa"],
  ["AN 4.29", "Dhammapada"],
  ["AN 4.30", "Paribbājaka"],
  ["AN 4.31", "Cakka"],
  ["AN 4.32", "Saṅgahavatthu [Saṅgaha]"],
  ["AN 4.33", "Sīha"],
  ["AN 4.34", "Aggappasāda"],
  ["AN 4.35", "Vassakāra"],
  ["AN 4.36", "Doṇa"],
  ["AN 4.37", "Aparihāniya"],
  ["AN 4.38", "Patilīna"],
  ["AN 4.39", "Ujjaya"],
  ["AN 4.40", "Udāyī"],
  ["AN 4.41", "Samādhibhāvanā"],
  ["AN 4.42", "Pañhavyākaraṇa [Pañhabyākaraṇa]"],
  ["AN 4.43", "Paṭhama Kodhagaru"],
  ["AN 4.44", "Dutiya Kodhagaru"],
  ["AN 4.45", "Paṭhama Rohitassa [Rohitassa]"],
  ["AN 4.46", "Dutiya Rohitassa"],
  ["AN 4.47", "Suvidūravidūra [Suvidūra]"],
  ["AN 4.48", "Visākha"],
  ["AN 4.49", "Vipallāsa"],
  ["AN 4.50", "Upakkilesa"],
  ["AN 4.51", "Paṭhama Puññābhisanda"],
  ["AN 4.52", "Dutiya Puññābhisanda"],
  ["AN 4.53", "Paṭhama Saṃvāsa"],
  ["AN 4.54", "Dutiya Saṃvāsa"],
  ["AN 4.55", "Paṭhama Samajīvī"],
  ["AN 4.56", "Dutiya Samajīvī"],
  ["AN 4.57", "Suppavāsā"],
  ["AN 4.58", "Sudatta"],
  ["AN 4.59", "Bhojanadāyaka [Bhojana]"],
  ["AN 4.60", "Gihīsāmīcipaṭipadā [Gihisāmīci]"],
  ["AN 4.61", "Pattakamma"],
  ["AN 4.62", "Ānaṇya"],
  ["AN 4.63", "Sabrahma [Brahma]"],
  ["AN 4.64", "Niraya"],
  ["AN 4.65", "Rūpappamāṇa [Rūpa]"],
  ["AN 4.66", "Sarāga"],
  ["AN 4.67", "Ahimetta [Ahirāja]"],
  ["AN 4.68", "Devadatta"],
  ["AN 4.69", "Padhāna"],
  ["AN 4.70", "Adhammika"],
  ["AN 4.71", "Padhāna apaṇṇaka paṭipadā [Padhāna]"],
  ["AN 4.72", "Diṭṭhi apaṇṇaka paṭipadā [Sammādiṭṭhi]"],
  ["AN 4.73", "Asappurisa [Sappurisa]"],
  ["AN 4.74", "Paṭhama Agga"],
  ["AN 4.75", "Dutiya Agga"],
  ["AN 4.76", "Kusinārā [Kusināra]"],
  ["AN 4.77", "Acinteyya"],
  ["AN 4.78", "Dakkhiṇāvisuddhi [Dakkhiṇa]"],
  ["AN 4.79", "Vaṇijjā [Vaṇijja]"],
  ["AN 4.80", "Kamboja"],
  ["AN 4.81", "Pāṇātipātī [Pāṇātipāta]"],
  ["AN 4.82", "Musāvādī [Musāvāda]"],
  ["AN 4.83", "Vaṇṇa [Avaṇṇāraha]"],
  ["AN 4.84", "Kodhagaru"],
  ["AN 4.85", "Tamotamaparāyana [Tamotama]"],
  ["AN 4.86", "Onatonata [Oṇatoṇata]"],
  ["AN 4.87", "Samaṇamacala putta [Putta]"],
  ["AN 4.88", "Samaṇamacala saññojana [Saṃyojana]"],
  ["AN 4.89", "Samaṇamacala diṭṭhi [Sammādiṭṭhi]"],
  ["AN 4.90", "Samaṇamacala khandha [Khandha]"],
  ["AN 4.91", "Asura"],
  ["AN 4.92", "Paṭhama Samādhi"],
  ["AN 4.93", "Dutiya Samādhi"],
  ["AN 4.94", "Tatiya Samādhi"],
  ["AN 4.95", "Chavālāta"],
  ["AN 4.96", "Rāgavinaya"],
  ["AN 4.97", "Khippanisanti"],
  ["AN 4.98", "Attahita"],
  ["AN 4.99", "Sikkhāpada"],
  ["AN 4.100", "Potaliya"],
  ["AN 4.101", "Paṭhama Valāhaka"],
  ["AN 4.102", "Dutiya Valāhaka"],
  ["AN 4.103", "Kumbha"],
  ["AN 4.104", "Udakarahada"],
  ["AN 4.105", "Paṭhama Amba"],
  ["AN 4.106", "Dutiya Amba"],
  ["AN 4.107", "Mūsikā [Mūsika]"],
  ["AN 4.108", "Balivadda [Balībadda]"],
  ["AN 4.109", "Rukkha"],
  ["AN 4.110", "Āsivisa [Āsīvisa]"],
  ["AN 4.111", "Kesi"],
  ["AN 4.112", "Assājānīyajava [Java]"],
  ["AN 4.113", "Assājānīyapatoda [Patoda]"],
  ["AN 4.114", "Nāga"],
  ["AN 4.115", "Ṭhāna"],
  ["AN 4.116", "Appamāda"],
  ["AN 4.117", "Ārakkha"],
  ["AN 4.118", "Saṃvejanīya"],
  ["AN 4.119", "Paṭhama Bhaya"],
  ["AN 4.120", "Dutiya Bhaya"],
  ["AN 4.121", "Tatiya Bhaya [Attānuvāda]"],
  ["AN 4.122", "Udakorohabhaya [Ūmibhaya]"],
  ["AN 4.123", "Paṭhama Puggala [Paṭhama Nānākaraṇa]"],
  ["AN 4.124", "Dutiya Puggala [Dutiya Nānākaraṇa]"],
  ["AN 4.125", "Tatiya Puggala [Paṭhama Mettā]"],
  ["AN 4.126", "Puggala 4 [Dutiya Mettā]"],
  ["AN 4.127", "Paṭhama Tathāgatacchariya [Paṭhama Tathāgataacchariya]"],
  ["AN 4.128", "Dutiya Tathāgatacchariya [Dutiya Tathāgataacchariya]"],
  ["AN 4.129", "Ānandācchariya"],
  ["AN 4.130", "Cakkavattiacchariya"],
  ["AN 4.131", "Saṃyojanapuggala [Saṃyojana]"],
  ["AN 4.132", "Paṭibhānapuggala [Paṭibhāna]"],
  ["AN 4.133", "Neyyapuggala [Ugghaṭitaññū]"],
  ["AN 4.134", "Phalupajivīpuggala [Uṭṭhānaphala]"],
  ["AN 4.135", "Vajjapuggala [Sāvajja]"],
  ["AN 4.136", "Paripūrakāripuggala [Paṭhama Sīla]"],
  ["AN 4.137", "Garupuggala [Dutiya Sīla]"],
  ["AN 4.138", "Nikaṭṭhapuggala [Nikaṭṭha]"],
  ["AN 4.139", "Dhammakathika"],
  ["AN 4.140", "Vādī"],
  ["AN 4.141", "Ābhā"],
  ["AN 4.142", "Pabhā"],
  ["AN 4.143", "Āloka"],
  ["AN 4.144", "Obhāsa"],
  ["AN 4.145", "Pajjota"],
  ["AN 4.146", "Paṭhama Kāla"],
  ["AN 4.147", "Dutiya Kāla"],
  ["AN 4.148", "Vacīduccarita [Duccarita]"],
  ["AN 4.149", "Vacīsucarita [Sucarita]"],
  ["AN 4.150", "Sāra"],
  ["AN 4.151", "Indriya"],
  ["AN 4.152", "Paṭhama Bala [Saddhābala]"],
  ["AN 4.153", "Dutiya Bala [Paññābala]"],
  ["AN 4.154", "Tatiya Bala [Satibala]"],
  ["AN 4.155", "Bala 4 [Paṭisaṅkhānabala]"],
  ["AN 4.156", "Asaṅkheyya [Kappa]"],
  ["AN 4.157", "Roga"],
  ["AN 4.158", "Parihāṇi [Parihāni]"],
  ["AN 4.159", "Bhikkhunī"],
  ["AN 4.160", "Sugatavinaya"],
  ["AN 4.161", "Paṭhama Paṭipadā [Saṃkhitta]"],
  ["AN 4.162", "Dutiya Paṭipadā [Vitthāra]"],
  ["AN 4.163", "Tatiya Paṭipadā [Asubha]"],
  ["AN 4.164", "Paṭipadā 4 [Paṭhama Khama]"],
  ["AN 4.165", "Paṭipadā 5 [Dutiya Khama]"],
  ["AN 4.166", "Paṭipadā 6 [Ubhaya]"],
  ["AN 4.167", "Moggallānapaṭipadā [Mahāmoggallāna]"],
  ["AN 4.168", "Sāriputtapaṭipadā [Sāriputta]"],
  ["AN 4.169", "Kilesaparinibbāna [Sasaṅkhāra]"],
  ["AN 4.170", "Arahattappatti [Yuganaddha]"],
  ["AN 4.171", "Sañcetanā [Cetanā]"],
  ["AN 4.172", "Sāriputtapaṭisambhidā [Vibhatti]"],
  ["AN 4.173", "Mahākoṭṭhita [Mahākoṭṭhika]"],
  ["AN 4.174", "Ānanda"],
  ["AN 4.175", "Upavāna [Upavāṇa]"],
  ["AN 4.176", "Āyācamāna [Āyācana]"],
  ["AN 4.177", "Rāhula"],
  ["AN 4.178", "Cetovimutti [Jambālī]"],
  ["AN 4.179", "Parinibbānahetu [Nibbāna]"],
  ["AN 4.180", "Mahāpadesadesanā [Mahāpadesa]"],
  ["AN 4.181", "Yodhājīva"],
  ["AN 4.182", "Pāṭibhoga"],
  ["AN 4.183", "Suta [Paṭhama Vassakāra]"],
  ["AN 4.184", "Jāṇussonī [Abhaya]"],
  ["AN 4.185", "Catukoṭikasuññatā [Brāhmaṇasacca]"],
  ["AN 4.186", "Bahussuta [Ummagga]"],
  ["AN 4.187", "Vassakāra[Vassakāra 2]"],
  ["AN 4.188", "Upaka"],
  ["AN 4.189", "Sacchikaraṇīya"],
  ["AN 4.190", "Bhikkhusaṅghathomana [Uposatha]"],
  ["AN 4.191", "Sotānudhata [Sotānugata]"],
  ["AN 4.192", "Ṭhāna"],
  ["AN 4.193", "Bhaddiya"],
  ["AN 4.194", "Sāpūgiya [Sāmugiya]"],
  ["AN 4.195", "Vappa"],
  ["AN 4.196", "Sāḷha"],
  ["AN 4.197", "Mallikā [Mallikādevī]"],
  ["AN 4.198", "Tapa [Attantapa]"],
  ["AN 4.199", "Taṇhājālinī [Taṇhā]"],
  ["AN 4.200", "Pemadosa [Pema]"],
  ["AN 4.201", "Paṭhama Sappurisa [Sikkhāpada]"],
  ["AN 4.202", "Dutiya Sappurisa [Assaddha]"],
  ["AN 4.203", "Tatiya Sappurisa [Sattakamma]"],
  ["AN 4.204", "Sappurisa 4 [Dasakamma]"],
  ["AN 4.205", "Sappurisa 5 [Aṭṭhaṅgika]"],
  ["AN 4.206", "Sappurisa 6 [Dasamagga]"],
  ["AN 4.207", "Paṭhama Pāpa kalyāṇa [Paṭhama Pāpadhamma]"],
  ["AN 4.208", "Dutiya Pāpa kalyāṇa [Dutiya Pāpadhamma]"],
  ["AN 4.209", "Paṭhama Pāpadhamma kalyāṇadhamma [Tatiya Pāpadhamma]"],
  ["AN 4.210", "Dutiya Pāpadhamma kalyāṇadhamma [Catuttha Pāpadhamma]"],
  ["AN 4.211", "Parisasobhana [Parisā]"],
  ["AN 4.212", "Paṭhama Niraya sagga nikkhitta [Diṭṭhi]"],
  ["AN 4.213", "Dutiya Niraya sagga nikkhitta [Akataññutā]"],
  ["AN 4.214", "Tatiya Niraya sagga nikkhitta [Pāṇātipātī]"],
  ["AN 4.215", "Niraya sagga nikkhitta 4 [Paṭhama Magga]"],
  ["AN 4.216", "Niraya sagga nikkhitta 5 [Dutiya Magga]"],
  ["AN 4.217", "Niraya sagga nikkhitta 6 [Paṭhama Vohārapatha]"],
  ["AN 4.218", "Niraya sagga nikkhitta 7 [Dutiya Vohārapatha]"],
  ["AN 4.219", "Niraya sagga nikkhitta 8 [Ahirika]"],
  ["AN 4.220", "Niraya sagga nikkhitta 9 [Dussīla]"],
  ["AN 4.221", "Vacīsucarita [Duccarita]"],
  ["AN 4.222", "Diṭṭhi [Paṭhama Bālapaṇḍita]"],
  ["AN 4.223", "Akataññutā [Dutiya Bālapaṇḍita]"],
  ["AN 4.224", "Pāṇātipātī [Tatiya Bālapaṇḍita]"],
  ["AN 4.225", "Paṭhama Magga [Catuttha Bālapaṇḍita]"],
  ["AN 4.226", "Dutiya Magga [Bālapaṇḍita 5]"],
  ["AN 4.227", "Paṭhama Vohārapatha [Bālapaṇḍita 6]"],
  ["AN 4.228", "Dutiya Vohārapatha [Bālapaṇḍita 7]"],
  ["AN 4.229", "Ahirika [Bālapaṇḍita 8]"],
  ["AN 4.230", "Duppañña [Bālapaṇḍita 9]"],
  ["AN 4.231", "Kavi"],
  ["AN 4.232", "Saṃkhitta [Paṭhama Kamma]"],
  ["AN 4.233", "Vitthāra [Dutiya Kamma]"],
  ["AN 4.234", "Soṇakāyana [Tatiya Kamma]"],
  ["AN 4.235", "Paṭhama Sikkhāpada [Catuttha Kamma]"],
  ["AN 4.236", "Dutiya Sikkhāpada Kamma 5]"],
  ["AN 4.237", "Ariyamagga [Kamma 6]"],
  ["AN 4.238", "Bojjhaṅga [Kamma 7]"],
  ["AN 4.239", "Sāvajja anavajjakamma [Sāvajja]"],
  ["AN 4.240", "Sabyāpajjha abyāpajjhakamma [Abyābajjha]"],
  ["AN 4.241", "Samaṇa"],
  ["AN 4.242", "Sappurisānisaṃsa"],
  ["AN 4.243", "Saṅghabhedaka [Pāpabhikkhu]"],
  ["AN 4.244", "Āpattibhaya"],
  ["AN 4.245", "Sikkhānisaṃsa"],
  ["AN 4.246", "Seyyā"],
  ["AN 4.247", "Thūpāraha"],
  ["AN 4.248", "Paññāvuddhi"],
  ["AN 4.249", "Bahukāra"],
  ["AN 4.250", "Anariyavohāra [Paṭhama Vohāra]"],
  ["AN 4.251", "Ariyavohāra [Dutiya Vohāra]"],
  ["AN 4.252", "Anariyavohāra [Tatiya Vohāra]"],
  ["AN 4.253", "Ariyavohāra [Catuttha Vohāra]"],
  ["AN 4.254", "Abhiññā"],
  ["AN 4.255", "Pariyesanā"],
  ["AN 4.256", "Saṅgahavatthu"],
  ["AN 4.257", "Māluṅkyāputta [Mālukyaputta]"],
  ["AN 4.258", "Kula"],
  ["AN 4.259", "Paṭhama Ājānīya"],
  ["AN 4.260", "Dutiya Ājānīya"],
  ["AN 4.261", "Bala"],
  ["AN 4.262", "Araññasenāsana [Arañña]"],
  ["AN 4.263", "Sāvajja anavajja [Kamma]"],
  ["AN 4.264", "Pāṇātipāta [Pāṇātipātī]"],
  ["AN 4.265", "Adinnādāna [Adinnādāyī]"],
  ["AN 4.266", "Kāmamicchācāra [Micchācārī]"],
  ["AN 4.267", "Musāvāda [Musāvādī]"],
  ["AN 4.268", "Pisuṇāvācā [Pisuṇavācā]"],
  ["AN 4.269", "Pharusāvācā [Pharusavācā]"],
  ["AN 4.270", "Samphappalāpa"],
  ["AN 4.271", "Abhijjhālu"],
  ["AN 4.272", "Byāpannacitta"],
  ["AN 4.273", "Micchādiṭṭhi"],
  ["AN 4.274", "Catusatipaṭṭhāna rāga abhiññāya [Satipaṭṭhāna]"],
  ["AN 4.275", "Catusammappadhāna rāga abhiññāya [Sammappadhāna]"],
  ["AN 4.276", "Caturiddhipāda rāga abhiññāya [Iddhipāda]"],
  ["AN 4.277-279", "Rāga pariññāya peyyāla [Pariññādi]"],
  ["AN 4.280-282", "Rāga parikkhayāya peyyāla"],
  ["AN 4.283-285", "Rāga pahānāya peyyāla"],
  ["AN 4.286-288", "Rāga khayāya peyyāla"],
  ["AN 4.289-291", "Rāga vayāya peyyāla"],
  ["AN 4.292-294", "Rāga virāgāya peyyāla"],
  ["AN 4.295-297", "Rāga nirodhāya peyyāla"],
  ["AN 4.298-300", "Rāga cāgāya peyyāla"],
  ["AN 4.301-303", "Rāga paṭinissagāya peyyāla"],
  ["AN 4.304-333", "Dosa peyyāla [Dosaabhiññādi]"],
  ["AN 4.334-363", "Moha peyyāla"],
  ["AN 4.364-393", "Kodha peyyāla"],
  ["AN 4.394-423", "Upanāha peyyāla"],
  ["AN 4.424-453", "Makkha peyyāla"],
  ["AN 4.454-483", "Paḷassa peyyāla"],
  ["AN 4.484-513", "Issā peyyāla"],
  ["AN 4.514-543", "Macchariya peyyāla"],
  ["AN 4.544-573", "Māyā peyyāla"],
  ["AN 4.574-603", "Sāṭheyya peyyāla"],
  ["AN 4.604-633", "Thambha peyyāla"],
  ["AN 4.634-663", "Sārambha peyyāla"],
  ["AN 4.664-693", "Māna peyyāla"],
  ["AN 4.694-723", "Atimāna peyyāla"],
  ["AN 4.724-753", "Pamāda peyyāla"],
  ["AN 4.754-783", "Macchariya peyyāla"],
  ["AN 5.1", "Sekhabalasaṅkhitta [Saṃkhitta]"],
  ["AN 5.2", "Sekhabalavitthata [Vitthata]"],
  ["AN 5.3", "Dukkha"],
  ["AN 5.4", "Yathābhata"],
  ["AN 5.5", "Sikkhāpaccakkhāna [Sikkhā]"],
  ["AN 5.6", "Akusalasamāpatti [Samāpatti]"],
  ["AN 5.7", "Kāmesu palāḷita [Kāma]"],
  ["AN 5.8", "Cavana"],
  ["AN 5.9", "Paṭhama Agārava"],
  ["AN 5.10", "Dutiya Agārava"],
  ["AN 5.11", "Tathāgatabala [Ananussuta]"],
  ["AN 5.12", "Sekhabala agga [Kūṭa]"],
  ["AN 5.13", "Balasaṅkhitta [Saṃkhitta]"],
  ["AN 5.14", "Balavitthata [Vitthata]"],
  ["AN 5.15", "Baladaṭṭhabba [Daṭṭhabba]"],
  ["AN 5.16", "Bala agga [Punakūṭa]"],
  ["AN 5.17", "Attahita [Paṭhama Hita]"],
  ["AN 5.18", "Parahita [Dutiya Hita]"],
  ["AN 5.19", "Neva attahitano parahita [Tatiya Hita]"],
  ["AN 5.20", "Attahitaparahita [Catuttha Hita]"],
  ["AN 5.21", "Paṭhama Agārava"],
  ["AN 5.22", "Dutiya Agārava"],
  ["AN 5.23", "Upakkilesa"],
  ["AN 5.24", "Dussīla"],
  ["AN 5.25", "Anuggahīta [Anuggahita]"],
  ["AN 5.26", "Vimuttāyatana"],
  ["AN 5.27", "Samādhi"],
  ["AN 5.28", "Pañcaṅgikasamādhi [Pañcaṅgika]"],
  ["AN 5.29", "Caṅkamānisaṃsa [Caṅkama]"],
  ["AN 5.30", "Nāgita"],
  ["AN 5.31", "Sumanā [Sumana]"],
  ["AN 5.32", "Cundī"],
  ["AN 5.33", "Uggaha"],
  ["AN 5.34", "Sīhasenāpati"],
  ["AN 5.35", "Dānānisaṃsa"],
  ["AN 5.36", "Kāladāna"],
  ["AN 5.37", "Bhojana"],
  ["AN 5.38", "Saddha"],
  ["AN 5.39", "Putta"],
  ["AN 5.40", "Mahāsāla [Mahāsālaputta]"],
  ["AN 5.41", "Pañcabhoga ādiya [Ādiya]"],
  ["AN 5.42", "Sappurisa"],
  ["AN 5.43", "Pañcaiṭṭhadhamma [Iṭṭha]"],
  ["AN 5.44", "Manāpadāyī"],
  ["AN 5.45", "Puññābhisanda"],
  ["AN 5.46", "Pañcasampadā [Sampadā]"],
  ["AN 5.47", "Pañcadhana [Dhana]"],
  ["AN 5.48", "Alabbhanīyaṭhāna"],
  ["AN 5.49", "Kosala"],
  ["AN 5.50", "Sokasallaharaṇa [Nārada]"],
  ["AN 5.51", "Nīvaraṇa [Āvaraṇa]"],
  ["AN 5.52", "Akusalarāsi"],
  ["AN 5.53", "Padhāniyaṅga"],
  ["AN 5.54", "Asamayasamaya [Samaya]"],
  ["AN 5.55", "Mātāputta"],
  ["AN 5.56", "Upajjhāya"],
  ["AN 5.57", "Ṭhāna [Abhiṇhapaccavekkhitabbaṭhāna]"],
  ["AN 5.58", "Licchavikumāra [Licchavikumāraka]"],
  ["AN 5.59", "Paṭhama Vuḍḍhapabbajita"],
  ["AN 5.60", "Dutiya Vuḍḍhapabbajita"],
  ["AN 5.61", "Paṭhama Saññā"],
  ["AN 5.62", "Dutiya Saññā"],
  ["AN 5.63", "Paṭhama Vaḍḍhī [Paṭhama Vaḍḍhi]"],
  ["AN 5.64", "Dutiya Vaḍḍhī [Dutiya Vaḍḍhi]"],
  ["AN 5.65", "Alaṃsākaccha [Sākaccha]"],
  ["AN 5.66", "Alaṃsājīva [Sājīva]"],
  ["AN 5.67", "Paṭhama Iddhipāda"],
  ["AN 5.68", "Dutiya Iddhipāda"],
  ["AN 5.69", "Nibbidā"],
  ["AN 5.70", "Āsavakkhaya"],
  ["AN 5.71", "Paṭhama Cetovimuttiphala"],
  ["AN 5.72", "Dutiya Cetovimuttiphala"],
  ["AN 5.73", "Paṭhama Dhammavihārī"],
  ["AN 5.74", "Dutiya Dhammavihārī"],
  ["AN 5.75", "Paṭhama Yodhājīvūpama [Paṭhama Yodhājīva]"],
  ["AN 5.76", "Dutiya Yodhājīvūpama [Dutiya Yodhājīva]"],
  ["AN 5.77", "Paṭhama Anāgatabhaya"],
  ["AN 5.78", "Dutiya Anāgatabhaya"],
  ["AN 5.79", "Tatiya Anāgatabhaya"],
  ["AN 5.80", "Catuttha Anāgatabhaya"],
  ["AN 5.81", "Rajanīya"],
  ["AN 5.82", "Vītarāga"],
  ["AN 5.83", "Kuhaka"],
  ["AN 5.84", "Assaddha"],
  ["AN 5.85", "Akkhama"],
  ["AN 5.86", "Paṭisambhidā [Paṭisambhidāpatta]"],
  ["AN 5.87", "Sīla [Sīlavanta]"],
  ["AN 5.88", "Thera"],
  ["AN 5.89", "Paṭhama Sekha"],
  ["AN 5.90", "Dutiya Sekha"],
  ["AN 5.91", "Paṭhama Sampadā"],
  ["AN 5.92", "Dutiya Sampadā"],
  ["AN 5.93", "Aññabyākaraṇa [Byākaraṇa]"],
  ["AN 5.94", "Phāsuvihāra"],
  ["AN 5.95", "Paṭhama Akuppa [Akuppa]"],
  ["AN 5.96", "Dutiya Akuppa [Sutadhara]"],
  ["AN 5.97", "Tatiya Akuppa [Kathā]"],
  ["AN 5.98", "Akuppa 4 [Āraññaka]"],
  ["AN 5.99", "Sīha"],
  ["AN 5.100", "Kakudha [Kakudhathera]"],
  ["AN 5.101", "Sekhavesārajja [Sārajja]"],
  ["AN 5.102", "Saṅkita [Ussaṅkita]"],
  ["AN 5.103", "Mahācora"],
  ["AN 5.104", "Samaṇasukhumāla"],
  ["AN 5.105", "Phāsuvihāra"],
  ["AN 5.106", "Ānanda"],
  ["AN 5.107", "Sīlasampanna [Sīla]"],
  ["AN 5.108", "Asekha"],
  ["AN 5.109", "Cātuddisa"],
  ["AN 5.110", "Arañña"],
  ["AN 5.111", "Kulūpaga [Kulūpaka]"],
  ["AN 5.112", "Pacchāsamaṇa"],
  ["AN 5.113", "Sammāsamādhi"],
  ["AN 5.114", "Andhakavinda"],
  ["AN 5.115", "Maccharī [Maccharinī]"],
  ["AN 5.116", "Vaṇṇa [Vaṇṇanā]"],
  ["AN 5.117", "Issukī [Issukinī]"],
  ["AN 5.118", "Micchādiṭṭhika"],
  ["AN 5.119", "Micchāvācā"],
  ["AN 5.120", "Micchāvāyāma"],
  ["AN 5.121", "Gilāna"],
  ["AN 5.122", "Satisūpaṭṭhita"],
  ["AN 5.123", "Dūpaṭṭhākagilāna [Paṭhama Upaṭṭhāka]"],
  ["AN 5.124", "Gilānūpaṭṭhāka [Dutiya Upaṭṭhāka]"],
  ["AN 5.125", "Paṭhama Āyussa [Paṭhama Anāyussā]"],
  ["AN 5.126", "Dutiya Āyussa [Dutiya Anāyussā]"],
  ["AN 5.127", "Vapakāsa"],
  ["AN 5.128", "Samaṇadukkhasukha [Samaṇasukha]"],
  ["AN 5.129", "Parikuppa"],
  ["AN 5.130", "Sampadā [Byasana]"],
  ["AN 5.131", "Paṭhama Cakkānuvattana"],
  ["AN 5.132", "Dutiya Cakkānuvattana"],
  ["AN 5.133", "Dhammarāja [Dhammarājā]"],
  ["AN 5.134", "Khattiyarāja [Yassaṃdisaṃ]"],
  ["AN 5.135", "Paṭhama Patthanā"],
  ["AN 5.136", "Dutiya Patthanā"],
  ["AN 5.137", "Appaṃsupati"],
  ["AN 5.138", "Bhattādaka"],
  ["AN 5.139", "Akkhama"],
  ["AN 5.140", "Sotāra [Sota]"],
  ["AN 5.141", "Avajānāti"],
  ["AN 5.142", "Ārambhati [Ārabhati]"],
  ["AN 5.143", "Sārandada"],
  ["AN 5.144", "Tikaṇḍakī"],
  ["AN 5.145", "Niraya"],
  ["AN 5.146", "Bhikkhumitta [Mitta]"],
  ["AN 5.147", "Asappurisadāna"],
  ["AN 5.148", "Sappurisadāna"],
  ["AN 5.149", "Paṭhama Samayavimutta"],
  ["AN 5.150", "Dutiya Samayavimutta"],
  ["AN 5.151", "Paṭhama Sammattaniyāma"],
  ["AN 5.152", "Dutiya Sammattaniyāma"],
  ["AN 5.153", "Tatiya Sammattaniyāma"],
  ["AN 5.154", "Paṭhama Saddhammasammosa"],
  ["AN 5.155", "Dutiya Saddhammasammosa"],
  ["AN 5.156", "Tatiya Saddhammasammosa"],
  ["AN 5.157", "Dukkathā"],
  ["AN 5.158", "Sārajja"],
  ["AN 5.159", "Udāyī"],
  ["AN 5.160", "Duppaṭivinodaya"],
  ["AN 5.161", "Paṭhama Āghātapaṭivinaya"],
  ["AN 5.162", "Dutiya Āghātapaṭivinaya"],
  ["AN 5.163", "Sākacchā [Sākaccha]"],
  ["AN 5.164", "Sājīva"],
  ["AN 5.165", "Pañhapucchā"],
  ["AN 5.166", "Nirodha"],
  ["AN 5.167", "Codanā"],
  ["AN 5.168", "Sīla"],
  ["AN 5.169", "Khippanisanti"],
  ["AN 5.170", "Bhaddaji"],
  ["AN 5.171", "Sārajja"],
  ["AN 5.172", "Visārada"],
  ["AN 5.173", "Niraya"],
  ["AN 5.174", "Vera"],
  ["AN 5.175", "Upāsakacaṇḍāla [Caṇḍāla]"],
  ["AN 5.176", "Pīti"],
  ["AN 5.177", "Vaṇijjā"],
  ["AN 5.178", "Rāja [Rājā]"],
  ["AN 5.179", "Gihī [Gihi]"],
  ["AN 5.180", "Gavesī"],
  ["AN 5.181", "Āraññaka [Āraññika]"],
  ["AN 5.182", "Paṃsukūlika [Cīvara]"],
  ["AN 5.183", "Rukkhamūlika"],
  ["AN 5.184", "Sosānika"],
  ["AN 5.185", "Abbhokāsika"],
  ["AN 5.186", "Nesajjika"],
  ["AN 5.187", "Yathāsanthatika"],
  ["AN 5.188", "Ekāsanika"],
  ["AN 5.189", "Khalupacchābhattika"],
  ["AN 5.190", "Pattapiṇḍika"],
  ["AN 5.191", "Soṇa"],
  ["AN 5.192", "Doṇa [Doṇabrāhmaṇa]"],
  ["AN 5.193", "Saṅgārava"],
  ["AN 5.194", "Kāraṇapāli [Kāraṇapālī]"],
  ["AN 5.195", "Piṅgiyānī"],
  ["AN 5.196", "Mahāsupina"],
  ["AN 5.197", "Vassantarāya [Vassa]"],
  ["AN 5.198", "Subhāsitavācā [Vācā]"],
  ["AN 5.199", "Kula"],
  ["AN 5.200", "Nissāraṇīya"],
  ["AN 5.201", "Kimbila [Kimila]"],
  ["AN 5.202", "Dhammasavaṇa [Dhammassavana]"],
  ["AN 5.203", "Ājānīya [Assājānīya]"],
  ["AN 5.204", "Bala"],
  ["AN 5.205", "Cetokhila"],
  ["AN 5.206", "Vinibandha"],
  ["AN 5.207", "Yāgu"],
  ["AN 5.208", "Dantakaṭṭha"],
  ["AN 5.209", "Gītassara"],
  ["AN 5.210", "Muṭṭhassati"],
  ["AN 5.211", "Akkosaka"],
  ["AN 5.212", "Bhaṇḍanakāraka"],
  ["AN 5.213", "Sīla"],
  ["AN 5.214", "Bahubhāṇi"],
  ["AN 5.215", "Paṭhama Akkhanti"],
  ["AN 5.216", "Dutiya Akkhanti"],
  ["AN 5.217", "Paṭhama Apāsādika"],
  ["AN 5.218", "Dutiya Apāsādika"],
  ["AN 5.219", "Aggi"],
  ["AN 5.220", "Madhurā"],
  ["AN 5.221", "Paṭhama Dīghacārika"],
  ["AN 5.222", "Dutiya Dīghacārika"],
  ["AN 5.223", "Paṭhama Atinivāsa [Atinivāsa]"],
  ["AN 5.224", "Dutiya Atinivāsa [Maccharī]"],
  ["AN 5.225", "Paṭhama Kulūpaga [Paṭhama Kulūpaka]"],
  ["AN 5.226", "Dutiya Kulūpaga [Dutiya Kulūpaka]"],
  ["AN 5.227", "Bhoga"],
  ["AN 5.228", "Ussūrabhatta"],
  ["AN 5.229", "Paṭhama Kaṇhasappa"],
  ["AN 5.230", "Dutiya Kaṇhasappa"],
  ["AN 5.231", "Abhāvanīya [Āvāsika]"],
  ["AN 5.232", "Piya"],
  ["AN 5.233", "Sobhana"],
  ["AN 5.234", "Bahūpakāra"],
  ["AN 5.235", "Anukampaka [Anukampa]"],
  ["AN 5.236", "Paṭhama Avaṇṇāraha"],
  ["AN 5.237", "Dutiya Avaṇṇāraha"],
  ["AN 5.238", "Tatiya Avaṇṇāraha"],
  ["AN 5.239", "Paṭhama Macchariya"],
  ["AN 5.240", "Dutiya Macchariya"],
  ["AN 5.241", "Duccarita [Paṭhama Duccarita]"],
  ["AN 5.242", "Kāyaduccarita [Paṭhama Kāyaduccarita]"],
  ["AN 5.243", "Vacīduccarita [Paṭhama Vacīduccarita]"],
  ["AN 5.244", "Manoduccarita [Paṭhama Manoduccarita]"],
  ["AN 5.245", "Aparaduccarita [Dutiya Duccarita]"],
  ["AN 5.246", "Aparakāyaduccarita [Dutiya Kāyaduccarita]"],
  ["AN 5.247", "Aparavacīduccarita [Dutiya Vacīduccarita]"],
  ["AN 5.248", "Aparamanoduccarita [Dutiya Manoduccarita]"],
  ["AN 5.249", "Sīvathikā [Sivathika]"],
  ["AN 5.250", "Puggalappasāda"],
  ["AN 5.251", "Upasampādetabba"],
  ["AN 5.252", "Nissaya"],
  ["AN 5.253", "Sāmaṇera"],
  ["AN 5.254", "Macchariya [Pañcamacchariya]"],
  ["AN 5.255", "Macchariyapahāna [Macchariyappahāna]"],
  ["AN 5.256", "Paṭhamajjhāna [Paṭhamajhāna]"],
  ["AN 5.257", "Dutiyajjhāna"],
  ["AN 5.258", "Tatiyajjhāna"],
  ["AN 5.259", "Catutthajjhāna"],
  ["AN 5.260", "Sotāpattiphalasacchikiriyā"],
  ["AN 5.261", "Sakadāgāmiphalasacchikiriyā"],
  ["AN 5.262", "Anāgāmiphalasacchikiriyā"],
  ["AN 5.263", "Arahattaphalasacchikiriyā"],
  ["AN 5.264", "Aparapaṭhamajjhāna [Aparapaṭhamajhāna]"],
  ["AN 5.265", "Aparadutiyajjhāna"],
  ["AN 5.266", "Aparatatiyajjhāna"],
  ["AN 5.267", "Aparacatutthajjhāna"],
  ["AN 5.268", "Aparasotāpattiphalasacchikiriyā"],
  ["AN 5.269", "Aparasakadāgāmiphalasacchikiriyā"],
  ["AN 5.270", "Aparānāgāmiphalasacchikiriyā"],
  ["AN 5.271", "Aparārahattaphalasacchikiriyā"],
  ["AN 5.272", "Bhattuddesaka"],
  ["AN 5.273", "Senāsanapaññāpaka"],
  ["AN 5.274", "Senāsanagāhāpaka"],
  ["AN 5.275", "Bhaṇḍāgārika"],
  ["AN 5.276", "Cīvarapaṭiggāhaka"],
  ["AN 5.277", "Cīvarabhājaka"],
  ["AN 5.278", "Yāgubhājaka"],
  ["AN 5.279", "Phalabhājaka"],
  ["AN 5.280", "Khajjakabhājaka"],
  ["AN 5.281", "Appamattakavissajjaka"],
  ["AN 5.282", "Sāṭikagāhāpaka"],
  ["AN 5.283", "Pattagāhāpaka"],
  ["AN 5.284", "Ārāmikapesaka"],
  ["AN 5.285", "Sāmaṇerapesaka"],
  ["AN 5.286", "Bhikkhu"],
  ["AN 5.287", "Bhikkhunī"],
  ["AN 5.288", "Sikkhamānā"],
  ["AN 5.289", "Sāmaṇera"],
  ["AN 5.290", "Sāmaṇerī"],
  ["AN 5.291", "Upāsaka"],
  ["AN 5.292", "Upāsikā"],
  ["AN 5.293", "Ājīvaka"],
  ["AN 5.294", "Nigaṇṭha"],
  ["AN 5.295", "Muṇḍasāvaka"],
  ["AN 5.296", "Jaṭilaka"],
  ["AN 5.297", "Paribbājaka"],
  ["AN 5.298", "Māgandika"],
  ["AN 5.299", "Tedaṇḍika"],
  ["AN 5.300", "Āruddhaka"],
  ["AN 5.301", "Gotamaka"],
  ["AN 5.302", "Devadhammika"],
  ["AN 5.303-307", "Rāga abhiññāya peyyāla"],
  ["AN 5.308-312", "Rāga pariññāya peyyāla"],
  ["AN 5.313-317", "Rāga parikkhayāya peyyāla"],
  ["AN 5.318-322", "Rāga pahānāya peyyāla"],
  ["AN 5.323-327", "Rāga khayāya peyyāla"],
  ["AN 5.328-332", "Rāga vayāya peyyāla"],
  ["AN 5.333-337", "Rāga virāgāya peyyāla"],
  ["AN 5.338-342", "Rāga nirodhāya peyyāla"],
  ["AN 5.343-347", "Rāga cāgāya peyyāla"],
  ["AN 5.348-352", "Rāga paṭinissagāya peyyāla"],
  ["AN 5.353-402", "Dosa peyyāla"],
  ["AN 5.403-452", "Moha peyyāla"],
  ["AN 5.453-502", "Kodha peyyāla"],
  ["AN 5.503-552", "Upanāha peyyāla"],
  ["AN 5.553-602", "Makkha peyyāla"],
  ["AN 5.603-652", "Paḷassa peyyāla"],
  ["AN 5.653-702", "Issā peyyāla"],
  ["AN 5.703-752", "Macchariya peyyāla"],
  ["AN 5.753-802", "Māyā peyyāla"],
  ["AN 5.803-852", "Sāṭheyya peyyāla"],
  ["AN 5.853-902", "Thambha peyyāla"],
  ["AN 5.903-952", "Sārambha peyyāla"],
  ["AN 5.953-1002", "Māna peyyāla"],
  ["AN 5.1003-1052", "Atimāna peyyāla"],
  ["AN 5.1053-1102", "Pamāda peyyāla"],
  ["AN 5.1103-1152", "Macchariya peyyāla"],
  ["AN 6.1", "Paṭhama Āhuneyya"],
  ["AN 6.2", "Dutiya Āhuneyya"],
  ["AN 6.3", "Indriya"],
  ["AN 6.4", "Bala"],
  ["AN 6.5", "Paṭhama Ājānīya"],
  ["AN 6.6", "Dutiya Ājānīya"],
  ["AN 6.7", "Tatiya Ājānīya"],
  ["AN 6.8", "Anuttariya"],
  ["AN 6.9", "Anussatiṭṭhāna"],
  ["AN 6.10", "Mahānāma"],
  ["AN 6.11", "Paṭhama Sārāṇīya [Paṭhama Sāraṇīya]"],
  ["AN 6.12", "Dutiya Sārāṇīya [Dutiya Sāraṇīya]"],
  ["AN 6.13", "Nissāraṇīya"],
  ["AN 6.14", "Bhaddaka"],
  ["AN 6.15", "Anutappa [Anutappiya]"],
  ["AN 6.16", "Nakulapitu"],
  ["AN 6.17", "Kusala [Soppa]"],
  ["AN 6.18", "Macchika [Macchabandha]"],
  ["AN 6.19", "Paṭhama Maraṇasati [Paṭhama Maraṇassati]"],
  ["AN 6.20", "Dutiya Maraṇasati [Dutiya Maraṇassati]"],
  ["AN 6.21", "Sāmagāmaka [Sāmaka]"],
  ["AN 6.22", "Aparihānīyadhamma [Aparihāniya]"],
  ["AN 6.23", "Bhaya"],
  ["AN 6.24", "Himavanta"],
  ["AN 6.25", "Anussatiṭṭhāna"],
  ["AN 6.26", "Mahākaccāna"],
  ["AN 6.27", "Paṭhama Samaya"],
  ["AN 6.28", "Dutiya Samaya"],
  ["AN 6.29", "Udāyi [Udāyī]"],
  ["AN 6.30", "Anuttariya"],
  ["AN 6.31", "Sekha"],
  ["AN 6.32", "Paṭhama Aparihāna"],
  ["AN 6.33", "Dutiya Aparihāna"],
  ["AN 6.34", "Moggallāna [Mahāmoggallāna]"],
  ["AN 6.35", "Vijjābhāgiya"],
  ["AN 6.36", "Vivādamūla"],
  ["AN 6.37", "Chaḷaṅgadāna"],
  ["AN 6.38", "Attakāra [Attakārī]"],
  ["AN 6.39", "Kammanidāna [Nidāna]"],
  ["AN 6.40", "Kimbila [Kimila]"],
  ["AN 6.41", "Dārukkhandha"],
  ["AN 6.42", "Nāgita"],
  ["AN 6.43", "Nāga"],
  ["AN 6.44", "Migasālā"],
  ["AN 6.45", "Iṇa"],
  ["AN 6.46", "Mahācunda"],
  ["AN 6.47", "Paṭhama Sandiṭṭhika"],
  ["AN 6.48", "Dutiya Sandiṭṭhika"],
  ["AN 6.49", "Khema"],
  ["AN 6.50", "Indriyasaṃvara"],
  ["AN 6.51", "Ānanda"],
  ["AN 6.52", "Khattiya"],
  ["AN 6.53", "Appamāda"],
  ["AN 6.54", "Dhammika"],
  ["AN 6.55", "Soṇa"],
  ["AN 6.56", "Phagguṇa [Phagguna]"],
  ["AN 6.57", "Chaḷābhijāti [Chaḷabhijāti]"],
  ["AN 6.58", "Āsava"],
  ["AN 6.59", "Dārukammika"],
  ["AN 6.60", "Hatthisāriputta"],
  ["AN 6.61", "Majjhe"],
  ["AN 6.62", "Purisindriyañāna [Purisindriyañāṇa]"],
  ["AN 6.63", "Nibbedhika"],
  ["AN 6.64", "Sīhanāda"],
  ["AN 6.65", "Anāgāmiphala"],
  ["AN 6.66", "Arahattaphala [Arahatta]"],
  ["AN 6.67", "Mitta"],
  ["AN 6.68", "Saṅgaṇikārāma"],
  ["AN 6.69", "Devatā"],
  ["AN 6.70", "Samādhi"],
  ["AN 6.71", "Sakkhibhabba"],
  ["AN 6.72", "Bala"],
  ["AN 6.73", "Paṭhama Paṭhamajjhāna [Paṭhama Tajjhāna]"],
  ["AN 6.74", "Dutiya Paṭhamajjhāna [Dutiya Tajjhāna]"],
  ["AN 6.75", "Dukkha"],
  ["AN 6.76", "Arahatta"],
  ["AN 6.77", "Uttarīmanussadhamma [Uttarimanussadhamma]"],
  ["AN 6.78", "Sukhasomanassa"],
  ["AN 6.79", "Adhigama"],
  ["AN 6.80", "Mahantatta"],
  ["AN 6.81", "Paṭhama Niraya"],
  ["AN 6.82", "Dutiya Niraya"],
  ["AN 6.83", "Aggadhamma"],
  ["AN 6.84", "Rattidivasa"],
  ["AN 6.85", "Sītibhāva"],
  ["AN 6.86", "Āvaraṇa"],
  ["AN 6.87", "Voropita"],
  ["AN 6.88", "Sussūsati"],
  ["AN 6.89", "Appahāya"],
  ["AN 6.90", "Pahīṇa [Pahīna]"],
  ["AN 6.91", "Abhabba"],
  ["AN 6.92", "Paṭhama Abhabbaṭṭhāna"],
  ["AN 6.93", "Dutiya Abhabbaṭṭhāna"],
  ["AN 6.94", "Tatiya Abhabbaṭṭhāna"],
  ["AN 6.95", "Catuttha Abhabbaṭṭhāna"],
  ["AN 6.96", "Pātubhāva"],
  ["AN 6.97", "Ānisaṃsa"],
  ["AN 6.98", "Anicca"],
  ["AN 6.99", "Dukkha"],
  ["AN 6.100", "Anatta"],
  ["AN 6.101", "Nibbāna"],
  ["AN 6.102", "Anavatthita"],
  ["AN 6.103", "Ukkhittaasika [Ukkhittāsika]"],
  ["AN 6.104", "Atammaya"],
  ["AN 6.105", "Bhava"],
  ["AN 6.106", "Taṇhā"],
  ["AN 6.107", "Rāga"],
  ["AN 6.108", "Duccarita"],
  ["AN 6.109", "Vitakka"],
  ["AN 6.110", "Saññā"],
  ["AN 6.111", "Dhātu"],
  ["AN 6.112", "Assāda"],
  ["AN 6.113", "Arati"],
  ["AN 6.114", "Asantuṭṭhi [Santuṭṭhitā]"],
  ["AN 6.115", "Dovacassatā"],
  ["AN 6.116", "Uddhacca"],
  ["AN 6.117", "Kāyānupassī"],
  ["AN 6.118", "Ajjhattakāyānupassī ādi [Dhammānupassī]"],
  ["AN 6.119", "Tapussa"],
  ["AN 6.120-139", "Bhallika ādi [Bhallikādi]"],
  ["AN 6.140", "Rāga abhiññāya anuttariya"],
  ["AN 6.141", "Rāga abhiññāya anussati"],
  ["AN 6.142", "Rāga abhiññāya saññā"],
  ["AN 6.143-169", "Rāga peyyāla"],
  ["AN 6.170-649", "Dosādi peyyāla"],
  ["AN 7.1", "Paṭhama Piyabhikkhū [Paṭhama Piya]"],
  ["AN 7.2", "Dutiya Piyabhikkhū [Dutiya Piya]"],
  ["AN 7.3", "Saṅkhittasattabala [Saṃkhittabala]"],
  ["AN 7.4", "Vitthatasattabala [Vitthatabala]"],
  ["AN 7.5", "Saṅkhittadhana [Saṃkhittadhana]"],
  ["AN 7.6", "Vitthatadhana"],
  ["AN 7.7", "Uggamahāmatta [Ugga]"],
  ["AN 7.8", "Sattasaññojana [Saṃyojana]"],
  ["AN 7.9", "Saññojanappahāna [Pahāna]"],
  ["AN 7.10", "Macchariyasaṃyojana [Macchariya]"],
  ["AN 7.11", "Anusaya [Paṭhama Anusaya]"],
  ["AN 7.12", "Anusayappahāna [Dutiya Anusaya]"],
  ["AN 7.13", "Kulūpagamana [Kula]"],
  ["AN 7.14", "Āhuneyyapuggala [Puggala]"],
  ["AN 7.15", "Udakūpamapuggala [Udakūpamā]"],
  ["AN 7.16", "Aniccānupassī"],
  ["AN 7.17", "Dukkhānupassi [Dukkhānupassī]"],
  ["AN 7.18", "Anattānupassī"],
  ["AN 7.19", "Sukhānupassī [Nibbāna]"],
  ["AN 7.20", "Niddasavatthu"],
  ["AN 7.21", "Sārandada"],
  ["AN 7.22", "Vassakāra"],
  ["AN 7.23", "Paṭhama Bhikkhu aparihānīya [Paṭhama Sattaka]"],
  ["AN 7.24", "Dutiya Bhikkhu aparihānīya [Dutiya Sattaka]"],
  ["AN 7.25", "Tatiya Bhikkhu aparihānīya [Tatiya Sattaka]"],
  ["AN 7.26", "Catuttha Bhikkhu aparihānīya [Catuttha Bojjhaṅga]"],
  ["AN 7.27", "Bhikkhu aparihānīya 5 [Saññā]"],
  ["AN 7.28", "Sekha aparihānīya [Paṭhama Parihāni]"],
  ["AN 7.29", "Upāsaka aparihānīya [Dutiya Parihāni]"],
  ["AN 7.30", "Upāsaka vipatti [Vipatti]"],
  ["AN 7.31", "Upāsaka parābhava [Parābhava]"],
  ["AN 7.32", "Appamādagārava"],
  ["AN 7.33", "Hirigārava"],
  ["AN 7.34", "Paṭhama Sovacassatā"],
  ["AN 7.35", "Dutiya Sovacassatā"],
  ["AN 7.36", "Mitta [Paṭhama Mitta]"],
  ["AN 7.37", "Bhikkhumitta [Dutiya Mitta]"],
  ["AN 7.38", "Paṭhama Paṭisambhidā"],
  ["AN 7.39", "Dutiya Paṭisambhidā"],
  ["AN 7.40", "Paṭhama Cittavasavattana [Paṭhama Vasa]"],
  ["AN 7.41", "Dutiya Cittavasavattana [Dutiya Vasa]"],
  ["AN 7.42", "Paṭhama Niddasavatthu [Paṭhama Niddasa]"],
  ["AN 7.43", "Dutiya Niddasavatthu [Dutiya Niddasa]"],
  ["AN 7.44", "Viññāṇaṭṭhiti [Sattaviññāṇaṭṭhiti]"],
  ["AN 7.45", "Samādhiparikkhāra"],
  ["AN 7.46", "Paṭhama aggi [Paṭhama Aggi]"],
  ["AN 7.47", "Mahāyañña [Dutiya Aggi]"],
  ["AN 7.48", "Saṅkhittasattasaññā [Paṭhama Saññā]"],
  ["AN 7.49", "Vitthatasattasaññā [Dutiya Saññā]"],
  ["AN 7.50", "Methuna"],
  ["AN 7.51", "Saṃyogavisaṃyoga dhammapariyāya [Saṃyoga]"],
  ["AN 7.52", "Dānamahapphala"],
  ["AN 7.53", "Nandamātā"],
  ["AN 7.54", "Abyākatavatthu [Abyākata]"],
  ["AN 7.55", "Purisagati"],
  ["AN 7.56", "Tissabrahma [Tissabrahmā]"],
  ["AN 7.57", "Sīhasenāpati"],
  ["AN 7.58", "Arakkheyya"],
  ["AN 7.59", "Kimbila [Kimila]"],
  ["AN 7.60", "Sattadhamma"],
  ["AN 7.61", "Pacalāyana [Pacalāyamāna]"],
  ["AN 7.62", "Māpuññabhāyi [Metta]"],
  ["AN 7.63", "Sattabhariyā [Bhariyā]"],
  ["AN 7.64", "Kodhana"],
  ["AN 7.65", "Hirottappa [Hirīottappa]"],
  ["AN 7.66", "Sattasuriyuggamana [Sattasūriya]"],
  ["AN 7.67", "Nagarūpama [Nagaropama]"],
  ["AN 7.68", "Dhammaññū"],
  ["AN 7.69", "Pāricchattaka"],
  ["AN 7.70", "Sakkāragarukāra [Sakkacca]"],
  ["AN 7.71", "Bhāvanānuyutta [Bhāvanā]"],
  ["AN 7.72", "Aggikkhandhopama"],
  ["AN 7.73", "Sunetta"],
  ["AN 7.74", "Araka"],
  ["AN 7.75", "Paṭhama Vinayadhara"],
  ["AN 7.76", "Dutiya Vinayadhara"],
  ["AN 7.77", "Tatiya Vinayadhara"],
  ["AN 7.78", "Catuttha Vinayadhara"],
  ["AN 7.79", "Paṭhama Vinayadharasobhana"],
  ["AN 7.80", "Dutiya Vinayadharasobhana"],
  ["AN 7.81", "Tatiya Vinayadharasobhana"],
  ["AN 7.82", "Catuttha Vinayadharasobhana"],
  ["AN 7.83", "Satthusāsana"],
  ["AN 7.84", "Adhikaraṇasamatha"],
  ["AN 7.85", "Bhikkhudhamma [Bhikkhu]"],
  ["AN 7.86", "Samaṇadhamma [Samaṇa]"],
  ["AN 7.87", "Brāhmaṇadhamma [Brāhmaṇa]"],
  ["AN 7.88", "Sotthiyadhamma [Sottiya]"],
  ["AN 7.89", "Nahātakadhamma [Nhātaka]"],
  ["AN 7.90", "Vedagudhamma [Vedagū]"],
  ["AN 7.91", "Ariyadhamma [Ariya]"],
  ["AN 7.92", "Arahattadhamma [Arahā]"],
  ["AN 7.93", "Asaddhamma"],
  ["AN 7.94", "Saddhamma"],
  ["AN 7.95", "Cakkhu aniccānupassī"],
  ["AN 7.96-102", "Cakkhudukkhānupassī peyyāla"],
  ["AN 7.103-614", "Sotāniccānupassī peyyāla"],
  ["AN 7.615-644", "Rāga peyyāla"],
  ["AN 7.645-1124", "Dosādi peyyāla"],
  ["AN 8.1", "Mettānisaṃsa [Mettā]"],
  ["AN 8.2", "Ādibrahmacariyapaññā [Paññā]"],
  ["AN 8.3", "Paṭhama Piya [Paṭhama Appiya]"],
  ["AN 8.4", "Dutiya Piya [Dutiya Appiya]"],
  ["AN 8.5", "Paṭhama Lokadhamma"],
  ["AN 8.6", "Dutiya Lokadhamma"],
  ["AN 8.7", "Devadattavipatti"],
  ["AN 8.8", "Uttara [Uttaravipatti]"],
  ["AN 8.9", "Nanda"],
  ["AN 8.10", "Kāraṇḍava"],
  ["AN 8.11", "Verañja"],
  ["AN 8.12", "Sīhasenāpati [Sīha]"],
  ["AN 8.13", "Ājañña [Assājānīya]"],
  ["AN 8.14", "Khaluṅka [Assakhaḷuṅka]"],
  ["AN 8.15", "Mala"],
  ["AN 8.16", "Dūteyya"],
  ["AN 8.17", "Purisabandhana [Paṭhama Bandhana]"],
  ["AN 8.18", "Itthibandhana [Dutiya Bandhana]"],
  ["AN 8.19", "Pahārāda"],
  ["AN 8.20", "Uposatha"],
  ["AN 8.21", "Vesālika-ugga [Paṭhama Ugga]"],
  ["AN 8.22", "Hatthigāmaka ugga [Dutiya Ugga]"],
  ["AN 8.23", "Hatthakāḷavaka [Paṭhama Hatthaka]"],
  ["AN 8.24", "Hatthakāḷavaka saṅgahavatthu [Dutiya Hatthaka]"],
  ["AN 8.25", "Mahānāma"],
  ["AN 8.26", "Jīvaka"],
  ["AN 8.27", "Bala [Paṭhama Bala]"],
  ["AN 8.28", "Khīṇāsavabala [Dutiya Bala]"],
  ["AN 8.29", "Akkhaṇa"],
  ["AN 8.30", "Anuruddha [Anuruddhamahāvitakka]"],
  ["AN 8.31", "Paṭhama Dāna"],
  ["AN 8.32", "Dutiya Dāna"],
  ["AN 8.33", "Dānavatthu"],
  ["AN 8.34", "Khettūpama [Khetta]"],
  ["AN 8.35", "Dānūpapatti"],
  ["AN 8.36", "Puññakiriyavatthu"],
  ["AN 8.37", "Sappurisadāna"],
  ["AN 8.38", "Sappurisa"],
  ["AN 8.39", "Puññābhisanda [Abhisanda]"],
  ["AN 8.40", "Apāyasaṃvattanika [Duccaritavipāka]"],
  ["AN 8.41", "Saṅkhitta aṭṭhaṅguposatha [Saṃkhittūposatha]"],
  ["AN 8.42", "Vitthata aṭṭhaṅguposatha [Vitthatūposatha]"],
  ["AN 8.43", "Visākhūposatha [Visākhā]"],
  ["AN 8.44", "Vāseṭṭhuposatha [Vāseṭṭha]"],
  ["AN 8.45", "Bojjhuposatha [Bojjha]"],
  ["AN 8.46", "Anuruddhamanāpakāyika [Anuruddha]"],
  ["AN 8.47", "Visākhamanāpakāyika [Dutiya Visākhā]"],
  ["AN 8.48", "Nakulamātumanāpakāyika [Nakulamātā]"],
  ["AN 8.49", "Paṭhama Lokavijaya [Paṭhama Idhalokika]"],
  ["AN 8.50", "Dutiya Lokavijaya [Dutiya Idhalokika]"],
  ["AN 8.51", "Gotamī"],
  ["AN 8.52", "Bhikkhunovādaka [Ovāda]"],
  ["AN 8.53", "Saṅkhitta gotamiyovāda [Saṃkhitta]"],
  ["AN 8.54", "Byagghapajja [Vyagghapajja, Dīghajāṇu]"],
  ["AN 8.55", "Ujjaya"],
  ["AN 8.56", "Kāmādhivacana [Bhaya]"],
  ["AN 8.57", "Paṭhama Āhuneyyabhikkhu [Paṭhama Āhuneyya]"],
  ["AN 8.58", "Dutiya Āhuneyyabhikkhu [Dutiya Āhuneyya]"],
  ["AN 8.59", "Paṭhama Aṭṭhapuggala [Paṭhama Puggala]"],
  ["AN 8.60", "Dutiya Aṭṭhapuggala [Dutiya Puggala]"],
  ["AN 8.61", "Lābhicchā [Icchā]"],
  ["AN 8.62", "Alaṃ"],
  ["AN 8.63", "Saṅkhittadesita [Saṃkhitta]"],
  ["AN 8.64", "Adhidevañāṇadassana [Gayāsīsa]"],
  ["AN 8.65", "Abhibhāyatana"],
  ["AN 8.66", "Vimokkha"],
  ["AN 8.67", "Anariyavohāra"],
  ["AN 8.68", "Ariyavohāra"],
  ["AN 8.69", "Parisa [Parisā]"],
  ["AN 8.70", "Bhumicāla [Bhūmicāla]"],
  ["AN 8.71", "Paṭhama Samantapāsādika [Paṭhama Saddhā]"],
  ["AN 8.72", "Dutiya Samantapāsādika [Dutiya Saddhā]"],
  ["AN 8.73", "Paṭhama Maraṇasati [Paṭhama Maraṇassati]"],
  ["AN 8.74", "Dutiya Maraṇasati [Dutiya Maraṇassati]"],
  ["AN 8.75", "Paṭhama Sampadā"],
  ["AN 8.76", "Dutiya Sampadā"],
  ["AN 8.77", "Icchā"],
  ["AN 8.78", "Alaṃ"],
  ["AN 8.79", "Sekhaparihāniya [Parihāna]"],
  ["AN 8.80", "Kusītārambhavatthu"],
  ["AN 8.81", "Satisampajañña"],
  ["AN 8.82", "Puṇṇiya"],
  ["AN 8.83", "Kiṃmūlaka [Mūlaka]"],
  ["AN 8.84", "Mahācoraṅga [Cora]"],
  ["AN 8.85", "Tathāgatādhivacana [Samaṇa]"],
  ["AN 8.86", "Nāgita [Yasa]"],
  ["AN 8.87", "Pattanikkujjana [Pattanikujjana]"],
  ["AN 8.88", "Appasādappasāda [Appasādapavedanīya]"],
  ["AN 8.89", "Paṭisāraṇiyakamma [Paṭisāraṇīya]"],
  ["AN 8.90", "Tassapāpiyyasikā sammāvattana [Sammāvattana]"],
  ["AN 8.91-117", "Upāsikā"],
  ["AN 8.118", "Aṭṭhamaggaṅga"],
  ["AN 8.119", "Aṭṭhābhibhāyatana"],
  ["AN 8.120", "Aṭṭhavimokkha"],
  ["AN 8.121-147", "Rāga pariññāya peyyāla"],
  ["AN 8.148-627", "Dosādi peyyāla"],
  ["AN 9.1", "Sambodhipakkhiya [Sambodhi]"],
  ["AN 9.2", "Nissayasampanna [Nissaya]"],
  ["AN 9.3", "Meghiya"],
  ["AN 9.4", "Nandaka"],
  ["AN 9.5", "Balasaṅgahavatthu [Bala]"],
  ["AN 9.6", "Sevitabbāsevitabba [Sevanā]"],
  ["AN 9.7", "Sutavāparibbājaka [Sutavā]"],
  ["AN 9.8", "Sajjhaparibbājaka [Sajjha]"],
  ["AN 9.9", "Puggala"],
  ["AN 9.10", "Āhuneyyapuggala [Āhuneyya]"],
  ["AN 9.11", "Sāriputta sīhanāda [Sīhanāda]"],
  ["AN 9.12", "Sopādisesa [Saupādisesa]"],
  ["AN 9.13", "Mahākoṭṭhita [Koṭṭhika]"],
  ["AN 9.14", "Samiddhi"],
  ["AN 9.15", "Gaṇḍopama [Gaṇḍa]"],
  ["AN 9.16", "Saññā"],
  ["AN 9.17", "Kulopagamana [Kula]"],
  ["AN 9.18", "Navaṅguposatha [Mettā]"],
  ["AN 9.19", "Devatā"],
  ["AN 9.20", "Velāma"],
  ["AN 9.21", "Ṭhāna [Tiṭhāna]"],
  ["AN 9.22", "Khaluṅka [Assakhaḷuṅka]"],
  ["AN 9.23", "Taṇhāmūlaka [Taṇhā]"],
  ["AN 9.24", "Sattāvāsa [Sattā]"],
  ["AN 9.25", "Paññāparicita [Paññā]"],
  ["AN 9.26", "Silāyūpopama [Silāyūpa]"],
  ["AN 9.27", "Paṭhama Verabhaya [Paṭhama Vera]"],
  ["AN 9.28", "Dutiya Verabhaya [Dutiya Vera]"],
  ["AN 9.29", "Āghātavatthu [Paṭhama Āghāta]"],
  ["AN 9.30", "Āghātapaṭivinaya [Dutiya Āghāta]"],
  ["AN 9.31", "Anupubbanirodha"],
  ["AN 9.32", "Anupubbavihāra [Paṭhama Vihāra]"],
  ["AN 9.33", "Anupubbavihārasamāpatti [Dutiya Vihāra]"],
  ["AN 9.34", "Nibbānasukha [Nibbāna]"],
  ["AN 9.35", "Gāvīupamā [Gāvī]"],
  ["AN 9.36", "Jhānanissaya [Jhāna]"],
  ["AN 9.37", "Ānanda"],
  ["AN 9.38", "Lokāyatika brāhmaṇa [Lokāyatika]"],
  ["AN 9.39", "Devāsurasaṅgāma [Deva]"],
  ["AN 9.40", "Āraññakanāgopama [Nāga]"],
  ["AN 9.41", "Tapussagahapati [Tapussa]"],
  ["AN 9.42", "Sambādha"],
  ["AN 9.43", "Kāyasakkhi [Kāyasakkhī]"],
  ["AN 9.44", "Paññāvimutta [Paññā]"],
  ["AN 9.45", "Ubhatobhāgavimutta [Ubhata]"],
  ["AN 9.46", "Sandiṭṭhikadhamma [Paṭhama Sandiṭṭhika]"],
  ["AN 9.47", "Sandiṭṭhikanibbāna [Dutiya Sandiṭṭhika]"],
  ["AN 9.48", "Nibbāna"],
  ["AN 9.49", "Parinibbāna"],
  ["AN 9.50", "Tadaṅganibbāna [Tadaṅga]"],
  ["AN 9.51", "Diṭṭhadhammanibbāna [Diṭṭhadhammika]"],
  ["AN 9.52", "Khema [Paṭhama Khema]"],
  ["AN 9.53", "Khemappatta [Dutiya Khema]"],
  ["AN 9.54", "Amata [Paṭhama Amata]"],
  ["AN 9.55", "Amatappatta [Dutiya Amata]"],
  ["AN 9.56", "Abhaya [Paṭhama Abhaya]"],
  ["AN 9.57", "Abhayappatta [Dutiya Abhaya]"],
  ["AN 9.58", "Passaddhi [Paṭhama Passaddhi]"],
  ["AN 9.59", "Anupubbapassaddhi [Dutiya Passaddhi]"],
  ["AN 9.60", "Nirodha"],
  ["AN 9.61", "Anupubbanirodha"],
  ["AN 9.62", "Arahattā bhabbābhabbadhamma [Abhabba]"],
  ["AN 9.63", "Sikkhādubbalya satipaṭṭhāna [Sikkhādubbalya]"],
  ["AN 9.64", "Nīvaraṇa satipaṭṭhāna [Nīvaraṇa]"],
  ["AN 9.65", "Kāmaguṇa satipaṭṭhāna [Kāmaguṇa]"],
  ["AN 9.66", "Upādānakkhandha satipaṭṭhāna [Upādānakkhandha]"],
  ["AN 9.67", "Orambhāgiya satipaṭṭhāna [Orambhāgiya]"],
  ["AN 9.68", "Gati satipaṭṭhāna [Gati]"],
  ["AN 9.69", "Macchariya satipaṭṭhāna [Macchariya]"],
  ["AN 9.70", "Uddhambhāgiya satipaṭṭhāna [Uddhambhāgiya]"],
  ["AN 9.71", "Cetokhila satipaṭṭhāna [Cetokhila]"],
  ["AN 9.72", "Vinibandha satipaṭṭhāna [Cetasovinibandha]"],
  ["AN 9.73", "Sikkhādubbalya sammappadhāna"],
  ["AN 9.75", "Nīvaraṇa sammappadhāna"],
  ["AN 9.74", "Kāmaguṇa sammappadhāna"],
  ["AN 9.76", "Upādānakkhandha sammappadhāna"],
  ["AN 9.77", "Orambhāgiya sammappadhāna"],
  ["AN 9.78", "Gati sammappadhāna"],
  ["AN 9.79", "Macchariya sammappadhāna"],
  ["AN 9.80", "Uddhambhāgiya sammappadhāna"],
  ["AN 9.81", "Cetokhila sammappadhāna"],
  ["AN 9.82", "Vinibandha sammappadhāna"],
  ["AN 9.83", "Sikkhādubbalya iddhipāda"],
  ["AN 9.84", "Nīvaraṇa iddhipāda"],
  ["AN 9.85", "Kāmaguṇa iddhipāda"],
  ["AN 9.86", "Upādānakkhandha iddhipāda"],
  ["AN 9.87", "Orambhāgiya iddhipāda"],
  ["AN 9.88", "Gati iddhipāda"],
  ["AN 9.89", "Macchariya iddhipāda"],
  ["AN 9.90", "Uddhambhāgiya iddhipāda"],
  ["AN 9.91", "Cetokhila iddhipāda"],
  ["AN 9.92", "Vinibandha iddhipāda"],
  ["AN 9.93", "Navasaññā"],
  ["AN 9.94", "Jhānasamāpatti"],
  ["AN 9.95-112", "Rāga pariññāya peyyāla"],
  ["AN 9.113-432", "Dosādi peyyāla"],
  ["AN 10.1", "Kimatthiya"],
  ["AN 10.2", "Cetanākaraṇīya [Cetana]"],
  ["AN 10.3", "Paṭhama Upanisa [Sīla]"],
  ["AN 10.4", "Dutiya Upanisa [Upanisa]"],
  ["AN 10.5", "Tatiya Upanisa [Ānanda]"],
  ["AN 10.6", "Samādhi"],
  ["AN 10.7", "Sāriputta"],
  ["AN 10.8", "Saddhā [Jhāna]"],
  ["AN 10.9", "Santavimokkha [Santa]"],
  ["AN 10.10", "Vijjā"],
  ["AN 10.11", "Senāsana"],
  ["AN 10.12", "Pañcaṅga"],
  ["AN 10.13", "Saṃyojana"],
  ["AN 10.14", "Cetokhila"],
  ["AN 10.15", "Appamāda"],
  ["AN 10.16", "Āhuneyya"],
  ["AN 10.17", "Paṭhama Nāthakaraṇa [Paṭhama Nātha]"],
  ["AN 10.18", "Dutiya Nāthakaraṇa [Dutiya Nātha]"],
  ["AN 10.19", "Paṭhama Ariyavāsa [Paṭhama Ariyāvāsa]"],
  ["AN 10.20", "Dutiya Ariyavāsa [Dutiya Ariyāvāsa]"],
  ["AN 10.21", "Sīhanāda [Sīha]"],
  ["AN 10.22", "Adhivuttipada [Adhivutti]"],
  ["AN 10.23", "Kāya"],
  ["AN 10.24", "Mahācunda [Cunda]"],
  ["AN 10.25", "Kasiṇa"],
  ["AN 10.26", "Kālī [Kāḷī]"],
  ["AN 10.27", "Paṭhama Mahāpañha [Paṭhama Mahāpañhā]"],
  ["AN 10.28", "Dutiya Mahāpañha [Dutiya Mahāpañhā]"],
  ["AN 10.29", "Paṭhama Kosala"],
  ["AN 10.30", "Dutiya Kosala"],
  ["AN 10.31", "Upāli"],
  ["AN 10.32", "Pātimokkhaṭṭhapana [Ṭṭhapana]"],
  ["AN 10.33", "Ubbāhikā"],
  ["AN 10.34", "Upasampadā"],
  ["AN 10.35", "Nissaya"],
  ["AN 10.36", "Sāmaṇera"],
  ["AN 10.37", "Saṅghabheda [Paṭhama Bheda]"],
  ["AN 10.38", "Saṅghasāmaggi [Dutiya Bheda]"],
  ["AN 10.39", "Paṭhama Ānanda"],
  ["AN 10.40", "Dutiya Ānanda"],
  ["AN 10.41", "Vivāda"],
  ["AN 10.42", "Paṭhama Vivādamūla [Paṭhama Mūla]"],
  ["AN 10.43", "Dutiya Vivādamūla [Dutiya Mūla]"],
  ["AN 10.44", "Kusinārā [Kusināra]"],
  ["AN 10.45", "Rājantepurappavesana [Pavesana]"],
  ["AN 10.46", "Sakya [Sakka]"],
  ["AN 10.47", "Mahāli"],
  ["AN 10.48", "Dasadhamma [Pabbajitaabhiṇha]"],
  ["AN 10.49", "Sarīraṭṭhadhamma [Sarīraṭṭha]"],
  ["AN 10.50", "Bhaṇḍana"],
  ["AN 10.51", "Sacitta"],
  ["AN 10.52", "Sāriputta"],
  ["AN 10.53", "Ṭhiti"],
  ["AN 10.54", "Samatha"],
  ["AN 10.55", "Parihāna"],
  ["AN 10.56", "Paṭhama Saññā"],
  ["AN 10.57", "Dutiya Saññā"],
  ["AN 10.58", "Mūlaka [Mūlā]"],
  ["AN 10.59", "Pabbajjā"],
  ["AN 10.60", "Girimānanda"],
  ["AN 10.61", "Avijjā"],
  ["AN 10.62", "Taṇhā"],
  ["AN 10.63", "Niṭṭhaṅgata [Niṭṭhā]"],
  ["AN 10.64", "Aveccappasanna [Avecca]"],
  ["AN 10.65", "Paṭhama Sukha"],
  ["AN 10.66", "Dutiya Sukha"],
  ["AN 10.67", "Paṭhama Lakapāna [Paṭhama Naḷakapāna]"],
  ["AN 10.68", "Dutiya Lakapāna [Dutiya Naḷakapāna]"],
  ["AN 10.69", "Paṭhama Kathāvatthu"],
  ["AN 10.70", "Dutiya Kathāvatthu"],
  ["AN 10.71", "Ākaṅkheyya [Ākaṅkha]"],
  ["AN 10.72", "Kaṇṭaka"],
  ["AN 10.73", "Iṭṭhadhamma [Iṭṭha]"],
  ["AN 10.74", "Vaḍḍha [Vaḍḍhi]"],
  ["AN 10.75", "Migasālā"],
  ["AN 10.76", "Tayodhamma [Abhabba]"],
  ["AN 10.77", "Kāka"],
  ["AN 10.78", "Nigaṇṭha"],
  ["AN 10.79", "Āghātavatthu [Paṭhama Vatthu]"],
  ["AN 10.80", "Āghātapaṭivinaya [Dutiya Vatthu]"],
  ["AN 10.81", "Bāhuna [Vāhana]"],
  ["AN 10.82", "Ānanda"],
  ["AN 10.83", "Puṇṇiya"],
  ["AN 10.84", "Byākaraṇa"],
  ["AN 10.85", "Katthī"],
  ["AN 10.86", "Adhimāna [Aññā]"],
  ["AN 10.87", "Adhikaraṇika [Nappiya]"],
  ["AN 10.88", "Akkosaka"],
  ["AN 10.89", "Kokālika"],
  ["AN 10.90", "Khīṇāsavabala [Bala]"],
  ["AN 10.91", "Kāmabhogī"],
  ["AN 10.92", "Bhaya [Vera]"],
  ["AN 10.93", "Kiṃdiṭṭhika [Diṭṭhi]"],
  ["AN 10.94", "Vajjiyamāhita [Vajja]"],
  ["AN 10.95", "Uttiya"],
  ["AN 10.96", "Kokanada [Kokanuda]"],
  ["AN 10.97", "Āhuneyya"],
  ["AN 10.98", "Thera"],
  ["AN 10.99", "Upāli"],
  ["AN 10.100", "Bhabbābhabba [Abhabba]"],
  ["AN 10.101", "Samaṇasaññā [Saññā]"],
  ["AN 10.102", "Bojjhaṅga"],
  ["AN 10.103", "Micchatta"],
  ["AN 10.104", "Bīja"],
  ["AN 10.105", "Vijjā"],
  ["AN 10.106", "Nijjara"],
  ["AN 10.107", "Dhovana"],
  ["AN 10.108", "Tikicchaka"],
  ["AN 10.109", "Vamana"],
  ["AN 10.110", "Niddhamanīya [Niddhamana]"],
  ["AN 10.111", "Aseka [Paṭhama Asekha]"],
  ["AN 10.112", "Asekhiyadhamma [Dutiya Asekha]"],
  ["AN 10.113", "Paṭhama Adhamma"],
  ["AN 10.114", "Dutiya Adhamma"],
  ["AN 10.115", "Tatiya Adhamma"],
  ["AN 10.116", "Ajita"],
  ["AN 10.117", "Saṅgārava"],
  ["AN 10.118", "Orimatīra [Orima]"],
  ["AN 10.119", "Paṭhama Paccorohaṇī"],
  ["AN 10.120", "Dutiya Paccorohaṇī"],
  ["AN 10.121", "Pubbaṅgama"],
  ["AN 10.122", "Āsavakkhaya [Āsava]"],
  ["AN 10.123", "Paṭhama"],
  ["AN 10.124", "Dutiya"],
  ["AN 10.125", "Tatiya"],
  ["AN 10.126", "Catuttha"],
  ["AN 10.127", "Pañcama"],
  ["AN 10.128", "Chaṭṭhama [Chaṭṭha]"],
  ["AN 10.129", "Sapata [Sattama]"],
  ["AN 10.130", "Aṭṭhama"],
  ["AN 10.131", "Navama"],
  ["AN 10.132", "Dasama"],
  ["AN 10.133", "Ekādasama"],
  ["AN 10.134", "Sādhu"],
  ["AN 10.135", "Ariyadhamma"],
  ["AN 10.136", "Kusala [Akusala]"],
  ["AN 10.137", "Attha"],
  ["AN 10.138", "Dhamma"],
  ["AN 10.139", "Sāsava"],
  ["AN 10.140", "Sāvajja"],
  ["AN 10.141", "Tapanīya"],
  ["AN 10.142", "Ācayagāmī [Ācayagāmi]"],
  ["AN 10.143", "Dukkhudraya"],
  ["AN 10.144", "Dukkhavipāka"],
  ["AN 10.145", "Ariyamagga"],
  ["AN 10.146", "Sukkamagga [Kaṇhamagga]"],
  ["AN 10.147", "Saddhamma"],
  ["AN 10.148", "Sappurisadhamma"],
  ["AN 10.149", "Uppādetabba"],
  ["AN 10.150", "Āsevitabba"],
  ["AN 10.151", "Bhāvetabba"],
  ["AN 10.152", "Bahulīkattabba [Bahulīkātabba]"],
  ["AN 10.153", "Anussaritabba"],
  ["AN 10.154", "Sacchikātabba"],
  ["AN 10.155", "Sevitabba"],
  ["AN 10.156-166", "Bhajitabbādi"],
  ["AN 10.167", "Brāhmaṇa paccorohaṇī [Brāhmaṇapaccorohaṇī]"],
  ["AN 10.168", "Ariyapaccorohaṇī"],
  ["AN 10.169", "Saṅgārava"],
  ["AN 10.170", "Orimatīra [Orima]"],
  ["AN 10.171", "Paṭhama Adhamma"],
  ["AN 10.172", "Dutiya Adhamma"],
  ["AN 10.173", "Tatiya Adhamma"],
  ["AN 10.174", "Kammanidāna"],
  ["AN 10.175", "Saparikkamana [Parikkamana]"],
  ["AN 10.176", "Cunda"],
  ["AN 10.177", "Jāṇussoṇī [Jāṇussoṇi]"],
  ["AN 10.178", "Sādhu"],
  ["AN 10.179", "Ariyadhamma"],
  ["AN 10.180", "Kusala"],
  ["AN 10.181", "Attha"],
  ["AN 10.182", "Dhamma"],
  ["AN 10.183", "Sāsavadhamma [Āsava]"],
  ["AN 10.184", "Sāvajja [Vajja]"],
  ["AN 10.185", "Tapanīya"],
  ["AN 10.186", "Ācayagāmī [Ācayagāmi]"],
  ["AN 10.187", "Dukkhudraya"],
  ["AN 10.188", "Dukkhavipāka [Vipāka]"],
  ["AN 10.189", "Ariyamagga"],
  ["AN 10.190", "Kaṇhamagga"],
  ["AN 10.191", "Saddhamma"],
  ["AN 10.192", "Sappurisadhamma"],
  ["AN 10.193", "Uppādetabbadhamma"],
  ["AN 10.194", "Āsevitabbadhamma"],
  ["AN 10.195", "Bhāvetabbadhamma"],
  ["AN 10.196", "Bahulīkātabbadhamma [Bahulīkātabba]"],
  ["AN 10.197", "Anussaritabbadhamma [Anussaritabba]"],
  ["AN 10.198", "Sacchikātabbadhamma [Sacchikātabba]"],
  ["AN 10.199-210", "Sevitabbādi dvādasa [Nasevitabbādi]"],
  ["AN 10.211", "Paṭhama Niraya [Paṭhama Nirayasagga]"],
  ["AN 10.212", "Dutiya Niraya [Dutiya Nirayasagga]"],
  ["AN 10.213", "Mātugāma"],
  ["AN 10.214", "Upāsikā"],
  ["AN 10.215", "Visārada"],
  ["AN 10.216", "Saṃsappaniya pariyāya [Saṃsappanīya]"],
  ["AN 10.217", "Paṭhama Sañcetanika"],
  ["AN 10.218", "Dutiya Sañcetanika"],
  ["AN 10.219", "Karajakāya"],
  ["AN 10.220", "Adhammacariyā"],
  ["AN 10.221", "Paṭhama"],
  ["AN 10.222", "Dutiya"],
  ["AN 10.223", "Tatiya"],
  ["AN 10.224", "Catuttha"],
  ["AN 10.225", "Pañcama"],
  ["AN 10.226-228", "Chaṭṭhamādi"],
  ["AN 10.229", "Navama"],
  ["AN 10.230-232", "Dasamādi"],
  ["AN 10.233", "Terasama"],
  ["AN 10.234-236", "Catuddasamādi"],
  ["AN 10.237-746", "Rāgādi peyyāla"],
  ["AN 11.1", "Kimatthiya"],
  ["AN 11.2", "Cetanākaraṇīya [Cetana]"],
  ["AN 11.3", "Paṭhama Upanisā"],
  ["AN 11.4", "Dutiya Upanisā"],
  ["AN 11.5", "Tatiya Upanisā"],
  ["AN 11.6", "Vyasana [Byasana]"],
  ["AN 11.7", "Paṭhama Saññā [Saññā]"],
  ["AN 11.8", "Manasikāra"],
  ["AN 11.9", "Sandha [Saddha]"],
  ["AN 11.10", "Moranivāpa"],
  ["AN 11.11", "Paṭhama Mahānāma"],
  ["AN 11.12", "Dutiya Mahānāma"],
  ["AN 11.13", "Nandiya"],
  ["AN 11.14", "Subhūti"],
  ["AN 11.15", "Mettānisaṃsa [Mettā]"],
  ["AN 11.16", "Aṭṭhakanāgara [Dasama]"],
  ["AN 11.17", "Gopālaka [Gopāla]"],
  ["AN 11.18", "Paṭhama Samādhi"],
  ["AN 11.19", "Dutiya Samādhi"],
  ["AN 11.20", "Tatiya Samādhi"],
  ["AN 11.21", "Catuttha Samādhi"],
  ["AN 11.22", "Aniccānupassanā"],
  ["AN 11.23", "Dukkhānupassanā"],
  ["AN 11.24", "Anattānupassanā"],
  ["AN 11.25", "Khayānupassanā"],
  ["AN 11.26", "Vayānupassanā"],
  ["AN 11.27", "Virāgānupassanā"],
  ["AN 11.28", "Nirodhānupassanā"],
  ["AN 11.29", "Paṭinissaggānupassanā"],
  ["AN 11.30-501", "Aniccānupassanā"],
  ["AN 11.502-981", "Aniccānupassanādi"],
  ["AN 11.982-1151", "Rāgādi peyyāla"],

  ["Kp 1", "Saraṇattaya [Saraṇagamana]"],
  ["Kp 2", "Dasasikkhāpada"],
  ["Kp 3", "Dvattiṃsākāra"],
  ["Kp 4", "Kumārapañhā [Samanerapañhā]"],
  ["Kp 5", "Maṅgala"],
  ["Kp 6", "Ratana"],
  ["Kp 7", "Tirokuṭṭa [Tirokuddakaṇḍa]"],
  ["Kp 8", "Nidhikaṇḍa"],
  ["Kp 9", "Metta [Karaṇīyamettā]"],
  ["Dhp 1-20", "1. Yamakavagga"],
  ["Dhp 21-32", "2. Appamādavagga"],
  ["Dhp 33-43", "3. Cittavagga"],
  ["Dhp 44-59", "4. Pupphavagga"],
  ["Dhp 60-75", "5. Bālavagga"],
  ["Dhp 76-89", "6. Paṇḍitavagga"],
  ["Dhp 90-99", "7. Arahantavagga"],
  ["Dhp 100-115", "8. Sahassavagga"],
  ["Dhp 116-128", "9. Pāpavagga"],
  ["Dhp 129-145", "10. Daṇḍavagga"],
  ["Dhp 146-156", "11. Jarāvagga"],
  ["Dhp 157-166", "12. Attavagga"],
  ["Dhp 167-178", "13. Lokavagga"],
  ["Dhp 179-196", "14. Buddhavagga"],
  ["Dhp 197-208", "15. Sukhavagga"],
  ["Dhp 209-220", "16. Piyavagga"],
  ["Dhp 221-234", "17. Kodhavagga"],
  ["Dhp 235-255", "18. Malavagga"],
  ["Dhp 256-272", "19. Dhammaṭṭhavagga"],
  ["Dhp 273-289", "20. Maggavagga"],
  ["Dhp 290-305", "21. Pakiṇṇakavagga"],
  ["Dhp 306-319", "22. Nirayavagga"],
  ["Dhp 320-333", "23. Nāgavagga"],
  ["Dhp 334-359", "24. Taṇhāvagga"],
  ["Dhp 360-382", "25. Bhikkhuvagga"],
  ["Dhp 383-423", "26. Brāhmaṇavagga"],
  ["Ud 1.1", "Paṭhama Bodhi"],
  ["Ud 1.2", "Dutiya Bodhi"],
  ["Ud 1.3", "Tatiya Bodhi"],
  ["Ud 1.4", "Huṃhuṅka [Nigrodha]"],
  ["Ud 1.5", "Brāhmaṇa [Thera]"],
  ["Ud 1.6", "Mahākassapa"],
  ["Ud 1.7", "Ajakalāpaka"],
  ["Ud 1.8", "Saṅgāmaji"],
  ["Ud 1.9", "Jaṭila"],
  ["Ud 1.10", "Bāhiya"],
  ["Ud 2.1", "Mucalinda"],
  ["Ud 2.2", "Rāja"],
  ["Ud 2.3", "Daṇḍa"],
  ["Ud 2.4", "Sakkāra"],
  ["Ud 2.5", "Upāsaka"],
  ["Ud 2.6", "Gabbhinī"],
  ["Ud 2.7", "Ekaputtaka"],
  ["Ud 2.8", "Suppavāsā"],
  ["Ud 2.9", "Visākhā"],
  ["Ud 2.10", "Bhaddiya"],
  ["Ud 3.1", "Kammavipākaja [Kamma]"],
  ["Ud 3.2", "Nanda"],
  ["Ud 3.3", "Yasoja"],
  ["Ud 3.4", "Sāriputta"],
  ["Ud 3.5", "Mahāmoggallāna"],
  ["Ud 3.6", "Pilindavaccha"],
  ["Ud 3.7", "Sakkudāna [Kassapa]"],
  ["Ud 3.8", "Piṇḍapātika"],
  ["Ud 3.9", "Sippa"],
  ["Ud 3.10", "Loka [Lokavalokana]"],
  ["Ud 4.1", "Meghiya"],
  ["Ud 4.2", "Uddhata"],
  ["Ud 4.3", "Gopālaka"],
  ["Ud 4.4", "Yakkhapahāra [Juṇha]"],
  ["Ud 4.5", "Nāga"],
  ["Ud 4.6", "Piṇḍola"],
  ["Ud 4.7", "Sāriputta"],
  ["Ud 4.8", "Sundarī"],
  ["Ud 4.9", "Upasena"],
  ["Ud 4.10", "Sāriputtaupasama"],
  ["Ud 5.1", "Piyatara [Rāja]"],
  ["Ud 5.2", "Appāyuka"],
  ["Ud 5.3", "Suppabuddhakuṭṭhi"],
  ["Ud 5.4", "Kumāraka"],
  ["Ud 5.5", "Uposatha"],
  ["Ud 5.6", "Soṇa"],
  ["Ud 5.7", "Kaṅkhārevata [Revata]"],
  ["Ud 5.8", "Saṅghabheda [Ānanda]"],
  ["Ud 5.9", "Sadhāyamāna"],
  ["Ud 5.10", "Cūḷapanthaka"],
  ["Ud 6.1", "Āyusaṅkhārossajjana"],
  ["Ud 6.2", "Sattajaṭila [Jaṭila]"],
  ["Ud 6.3", "Paccavekkhaṇa"],
  ["Ud 6.4", "Paṭhama Nānātitthiya"],
  ["Ud 6.5", "Dutiya Nānātitthiya"],
  ["Ud 6.6", "Tatiya Nānātitthiya"],
  ["Ud 6.7", "Subhūti"],
  ["Ud 6.8", "Gaṇikā"],
  ["Ud 6.9", "Upātidhāvanti [Upāti]"],
  ["Ud 6.10", "Uppajjanti [Tathāgatuppāda]"],
  ["Ud 7.1", "Paṭhama Lakuṇṭakabhaddiya"],
  ["Ud 7.2", "Dutiya Lakuṇṭakabhaddiya"],
  ["Ud 7.3", "Paṭhama Satta"],
  ["Ud 7.4", "Dutiya Satta"],
  ["Ud 7.5", "Aparalakuṇṭakabhaddiya"],
  ["Ud 7.6", "Taṇhāsaṅkhaya"],
  ["Ud 7.7", "Papañcakkhaya"],
  ["Ud 7.8", "Kaccāna"],
  ["Ud 7.9", "Udapāna"],
  ["Ud 7.10", "Udena"],
  ["Ud 8.1", "Paṭhama Nibbānapaṭisaṃyutta [Paṭhama Parinibbāna]"],
  ["Ud 8.2", "Dutiya Nibbānapaṭisaṃyutta [Dutiya Parinibbāna]"],
  ["Ud 8.3", "Tatiya Nibbānapaṭisaṃyutta [Tatiya Parinibbāna]"],
  ["Ud 8.4", "Catuttha Nibbānapaṭisaṃyutta [Catuttha Catuttha Parinibbāna]"],
  ["Ud 8.5", "Cunda"],
  ["Ud 8.6", "Pāṭaligāmiya"],
  ["Ud 8.7", "Dvidhāpatha"],
  ["Ud 8.8", "Visākhā"],
  ["Ud 8.9", "Paṭhama Dabba"],
  ["Ud 8.10", "Dutiya Dabba"],
  ["Iti 1", "Lobha"],
  ["Iti 2", "Dosa"],
  ["Iti 3", "Moha"],
  ["Iti 4", "Kodha"],
  ["Iti 5", "Makkha"],
  ["Iti 6", "Māna"],
  ["Iti 7", "Sabbapariññā"],
  ["Iti 8", "Mānapariññā"],
  ["Iti 9", "Lobhapariññā"],
  ["Iti 10", "Dosapariññā"],
  ["Iti 11", "Mohapariññā"],
  ["Iti 12", "Kodhapariññā"],
  ["Iti 13", "Makkhapariññā"],
  ["Iti 14", "Avijjānīvaraṇa"],
  ["Iti 15", "Taṇhāsaṃyojana"],
  ["Iti 16", "Paṭhama Sekha"],
  ["Iti 17", "Dutiya Sekha"],
  ["Iti 18", "Saṅghabheda"],
  ["Iti 19", "Saṅghasāmaggī"],
  ["Iti 20", "Paduṭṭhacitta"],
  ["Iti 21", "Pasannacitta"],
  ["Iti 22", "Metta"],
  ["Iti 23", "Ubhayattha"],
  ["Iti 24", "Aṭṭhipuñja"],
  ["Iti 25", "Musāvāda"],
  ["Iti 26", "Dāna"],
  ["Iti 27", "Mettābhāvanā"],
  ["Iti 28", "Dukkhavihāra"],
  ["Iti 29", "Sukhavihāra"],
  ["Iti 30", "Tapanīya"],
  ["Iti 31", "Atapanīya"],
  ["Iti 32", "Paṭhama Sīla"],
  ["Iti 33", "Dutiya Sīla"],
  ["Iti 34", "Ātāpī"],
  ["Iti 35", "Paṭhama Nakuhana"],
  ["Iti 36", "Dutiya Nakuhana"],
  ["Iti 37", "Somanassa"],
  ["Iti 38", "Vitakka"],
  ["Iti 39", "Desanā"],
  ["Iti 40", "Vijjā"],
  ["Iti 41", "Paññāparihīna"],
  ["Iti 42", "Sukkadhamma"],
  ["Iti 43", "Ajāta"],
  ["Iti 44", "Nibbānadhātu"],
  ["Iti 45", "Paṭisallāna"],
  ["Iti 46", "Sikkhānisaṃsa"],
  ["Iti 47", "Jāgariya"],
  ["Iti 48", "Āpāyika"],
  ["Iti 49", "Diṭṭhigata"],
  ["Iti 50", "Mūla"],
  ["Iti 51", "Dhātu"],
  ["Iti 52", "Paṭhama Vedanā"],
  ["Iti 53", "Dutiya Vedanā"],
  ["Iti 54", "Paṭhama Esanā"],
  ["Iti 55", "Dutiya Esanā"],
  ["Iti 56", "Paṭhama Āsava"],
  ["Iti 57", "Dutiya Āsava"],
  ["Iti 58", "Taṇhā"],
  ["Iti 59", "Māradheyya"],
  ["Iti 60", "Puññakiriyavatthu"],
  ["Iti 61", "Cakkhu"],
  ["Iti 62", "Indriya"],
  ["Iti 63", "Addhā"],
  ["Iti 64", "Duccarita"],
  ["Iti 65", "Sucarita"],
  ["Iti 66", "Soceyya"],
  ["Iti 67", "Moneyya"],
  ["Iti 68", "Paṭhama Rāga"],
  ["Iti 69", "Dutiya Rāga"],
  ["Iti 70", "Micchādiṭṭhika"],
  ["Iti 71", "Sammādiṭṭhika"],
  ["Iti 72", "Nissaraṇiya"],
  ["Iti 73", "Santatara"],
  ["Iti 74", "Putta"],
  ["Iti 75", "Avuṭṭhika"],
  ["Iti 76", "Sukhapatthanā"],
  ["Iti 77", "Bhidura [Bhindanā]"],
  ["Iti 78", "Dhātusosaṃsandana"],
  ["Iti 79", "Parihāna"],
  ["Iti 80", "Vitakka"],
  ["Iti 81", "Sakkāra"],
  ["Iti 82", "Devasadda"],
  ["Iti 83", "Pañcapubbanimitta"],
  ["Iti 84", "Bahujanahita"],
  ["Iti 85", "Asubhānupassī"],
  ["Iti 86", "Dhammānudhammapaṭipanna"],
  ["Iti 87", "Andhakaraṇa"],
  ["Iti 88", "Antarāmala"],
  ["Iti 89", "Devadatta"],
  ["Iti 90", "Aggappasāda"],
  ["Iti 91", "Jīvika"],
  ["Iti 92", "Saṅghāṭikaṇṇa"],
  ["Iti 93", "Aggi"],
  ["Iti 94", "Upaparikkha"],
  ["Iti 95", "Kāmūpapatti"],
  ["Iti 96", "Kāmayoga"],
  ["Iti 97", "Kalyāṇasīla"],
  ["Iti 98", "Dāna"],
  ["Iti 99", "Tevijja"],
  ["Iti 100", "Brāhmaṇadhammayāga"],
  ["Iti 101", "Sulabha"],
  ["Iti 102", "Āsavakkhaya"],
  ["Iti 103", "Samaṇabrāhmaṇa"],
  ["Iti 104", "Sīlasampanna"],
  ["Iti 105", "Taṇhuppāda"],
  ["Iti 106", "Sabrahmaka"],
  ["Iti 107", "Bahukāra"],
  ["Iti 108", "Kuha"],
  ["Iti 109", "Nadīsota"],
  ["Iti 110", "Cara"],
  ["Iti 111", "Sampannasīla"],
  ["Iti 112", "Loka"],
  ["Snp 1.1", "Uraga"],
  ["Snp 1.2", "Dhaniya"],
  ["Snp 1.3", "Khaggavisāṇa"],
  ["Snp 1.4", "Kasibhāradvāja"],
  ["Snp 1.5", "Cunda"],
  ["Snp 1.6", "Parābhava"],
  ["Snp 1.7", "Vasala"],
  ["Snp 1.8", "Metta [Karaṇīyamettā]"],
  ["Snp 1.9", "Hemavata"],
  ["Snp 1.10", "Āḷavaka"],
  ["Snp 1.11", "Vijaya"],
  ["Snp 1.12", "Muni"],
  ["Snp 2.1", "Ratana"],
  ["Snp 2.2", "Āmagandha"],
  ["Snp 2.3", "Hiri"],
  ["Snp 2.4", "Maṅgala [Mahāmaṅgala]"],
  ["Snp 2.5", "Sūciloma"],
  ["Snp 2.6", "Dhammacariya"],
  ["Snp 2.7", "Brāhmaṇadhammika"],
  ["Snp 2.8", "Nāvā"],
  ["Snp 2.9", "Kiṃsīla"],
  ["Snp 2.10", "Uṭṭhāna"],
  ["Snp 2.11", "Rāhula"],
  ["Snp 2.12", "Nigrodhakappa [Vaṅgīsa]"],
  ["Snp 2.13", "Sammāparibbājanīya"],
  ["Snp 2.14", "Dhammika"],
  ["Snp 3.1", "Pabbajjā"],
  ["Snp 3.2", "Padhāna"],
  ["Snp 3.3", "Subhāsita"],
  ["Snp 3.4", "Sundarikabhāradvāja"],
  ["Snp 3.5", "Māgha"],
  ["Snp 3.6", "Sabhiya"],
  ["Snp 3.7", "Sela"],
  ["Snp 3.8", "Salla"],
  ["Snp 3.9", "Vāseṭṭha"],
  ["Snp 3.10", "Kokālika"],
  ["Snp 3.11", "Nālaka"],
  ["Snp 3.12", "Dvayatānupassanā"],
  ["Snp 4.1", "Kāma"],
  ["Snp 4.2", "Guhaṭṭhaka"],
  ["Snp 4.3", "Duṭṭhaṭṭhaka"],
  ["Snp 4.4", "Suddhaṭṭhaka"],
  ["Snp 4.5", "Paramaṭṭhaka"],
  ["Snp 4.6", "Jarā"],
  ["Snp 4.7", "Tissametteyya"],
  ["Snp 4.8", "Pasūra"],
  ["Snp 4.9", "Māgaṇḍiya"],
  ["Snp 4.10", "Purābheda"],
  ["Snp 4.11", "Kalahavivāda"],
  ["Snp 4.12", "Cūḷabyūha [Cūḷaviyūha]"],
  ["Snp 4.13", "Mahābyūha [Mahāviyūha]"],
  ["Snp 4.14", "Tuvaṭaka"],
  ["Snp 4.15", "Attadaṇḍa"],
  ["Snp 4.16", "Sāriputta"],
  ["Snp 5.1", "Vatthugāthā"],
  ["Snp 5.2", "Ajitamāṇavapucchā"],
  ["Snp 5.3", "Tissametteyyamāṇavapucchā"],
  ["Snp 5.4", "Puṇṇakamāṇavapucchā"],
  ["Snp 5.5", "Mettagūmāṇavapucchā"],
  ["Snp 5.6", "Dhotakamāṇavapucchā"],
  ["Snp 5.7", "Upasīvamāṇavapucchā"],
  ["Snp 5.8", "Nandamāṇavapucchā"],
  ["Snp 5.9", "Hemakamāṇavapucchā"],
  ["Snp 5.10", "Todeyyamāṇavapucchā"],
  ["Snp 5.11", "Kappamāṇavapucchā"],
  ["Snp 5.12", "Jatukaṇṇimāṇavapucchā"],
  ["Snp 5.13", "Bhadrāvudhamāṇavapucchā"],
  ["Snp 5.14", "Udayamāṇavapucchā"],
  ["Snp 5.15", "Posālamāṇavapucchā"],
  ["Snp 5.16", "Mogharājamāṇavapucchā"],
  ["Snp 5.17", "Piṅgiyamāṇavapucchā"],
  ["Snp 5.18", "Pārāyanatthutigāthā"],
  ["Snp 5.19", "Pārāyanānugītigāthā"],
  ["Vv 1", "Paṭhamapīṭhavimānavatthu"],
  ["Vv 2", "Dutiyapīṭhavimānavatthu"],
  ["Vv 3", "Tatiyapīṭhavimānavatthu"],
  ["Vv 4", "Catutthapīṭhavimānavatthu"],
  ["Vv 5", "Kuñjaravimānavatthu"],
  ["Vv 6", "Paṭhamanāvāvimānavatthu"],
  ["Vv 7", "Dutiyanāvāvimānavatthu"],
  ["Vv 8", "Tatiyanāvāvimānavatthu"],
  ["Vv 9", "Dīpavimānavatthu"],
  ["Vv 10", "Tiladakkhiṇavimānavatthu"],
  ["Vv 11", "Paṭhamapatibbatāvimānavatthu"],
  ["Vv 12", "Dutiyapatibbatāvimānavatthu"],
  ["Vv 13", "Paṭhamasuṇisāvimānavatthu"],
  ["Vv 14", "Dutiyasuṇisāvimānavatthu"],
  ["Vv 15", "Uttarāvimānavatthu"],
  ["Vv 16", "Sirimāvimānavatthu"],
  ["Vv 17", "Kesakārīvimānavatthu"],
  ["Vv 18", "Dāsivimānavatthu"],
  ["Vv 19", "Lakhumāvimānavatthu"],
  ["Vv 20", "Ācāmadāyikāvimānavatthu"],
  ["Vv 21", "Caṇḍālivimānavatthu"],
  ["Vv 22", "Bhadditthivimānavatthu"],
  ["Vv 23", "Soṇadinnāvimānavatthu"],
  ["Vv 24", "Uposathāvimānavatthu"],
  ["Vv 25", "Niddāvimānavatthu"],
  ["Vv 26", "Suniddāvimānavatthu"],
  ["Vv 27", "Paṭhamabhikkhādāyikāvimānavatthu"],
  ["Vv 28", "Dutiyabhikkhādāyikāvimānavatthu"],
  ["Vv 29", "Uḷāravimānavatthu"],
  ["Vv 30", "Ucchudāyikāvimānavatthu"],
  ["Vv 31", "Pallaṅkavimānavatthu"],
  ["Vv 32", "Latāvimānavatthu"],
  ["Vv 33", "Guttilavimānavatthu"],
  ["Vv 34", "Daddallavimānavatthu"],
  ["Vv 35", "Pesavatīvimānavatthu"],
  ["Vv 36", "Mallikāvimānavatthu"],
  ["Vv 37", "Visālakkhivimānavatthu"],
  ["Vv 38", "Pāricchattakavimānavatthu"],
  ["Vv 39", "Mañjiṭṭhakavimānavatthu"],
  ["Vv 40", "Pabhassaravimānavatthu"],
  ["Vv 41", "Nāgavimānavatthu"],
  ["Vv 42", "Alomavimānavatthu"],
  ["Vv 43", "Kañjikadāyikāvimānavatthu"],
  ["Vv 44", "Vihāravimānavatthu"],
  ["Vv 45", "Caturitthivimānavatthu"],
  ["Vv 46", "Ambavimānavatthu"],
  ["Vv 47", "Pītavimānavatthu"],
  ["Vv 48", "Ucchuvimānavatthu"],
  ["Vv 49", "Vandanavimānavatthu"],
  ["Vv 50", "Rajjumālāvimānavatthu"],
  ["Vv 51", "Maṇḍūkadevaputtavimānavatthu"],
  ["Vv 52", "Revatīvimānavatthu"],
  ["Vv 53", "Chattamāṇavakavimānavatthu"],
  ["Vv 54", "Kakkaṭakarasadāyakavimānavatthu"],
  ["Vv 55", "Dvārapālavimānavatthu"],
  ["Vv 56", "Paṭhamakaraṇīyavimānavatthu"],
  ["Vv 57", "Dutiyakaraṇīyavimānavatthu"],
  ["Vv 58", "Paṭhamasūcivimānavatthu"],
  ["Vv 59", "Dutiyasūcivimānavatthu"],
  ["Vv 60", "Paṭhamanāgavimānavatthu"],
  ["Vv 61", "Dutiyanāgavimānavatthu"],
  ["Vv 62", "Tatiyanāgavimānavatthu"],
  ["Vv 63", "Cūḷarathavimānavatthu"],
  ["Vv 64", "Mahārathavimānavatthu"],
  ["Vv 65", "Paṭhamaagāriyavimānavatthu"],
  ["Vv 66", "Dutiyaagāriyavimānavatthu"],
  ["Vv 67", "Phaladāyakavimānavatthu"],
  ["Vv 68", "Paṭhamaupassayadāyakavimānavatthu"],
  ["Vv 69", "Dutiyaupassayadāyakavimānavatthu"],
  ["Vv 70", "Bhikkhādāyakavimānavatthu"],
  ["Vv 71", "Yavapālakavimānavatthu"],
  ["Vv 72", "Paṭhamakuṇḍalīvimānavatthu"],
  ["Vv 73", "Dutiyakuṇḍalīvimānavatthu"],
  ["Vv 74", "(Uttara) Pāyāsivimānavatthu"],
  ["Vv 75", "Cittalatāvimānavatthu"],
  ["Vv 76", "Nandanavimānavatthu"],
  ["Vv 77", "Maṇithūṇavimānavatthu"],
  ["Vv 78", "Suvaṇṇavimānavatthu"],
  ["Vv 79", "Ambavimānavatthu"],
  ["Vv 80", "Gopālavimānavatthu"],
  ["Vv 81", "Kaṇḍakavimānavatthu"],
  ["Vv 82", "Anekavaṇṇavimānavatthu"],
  ["Vv 83", "Maṭṭhakuṇḍalīvimānavatthu"],
  ["Vv 84", "Serīsakavimānavatthu"],
  ["Vv 85", "Sunikkhittavimānavatthu"],
  ["Pv 1", "Khettūpamapetavatthu"],
  ["Pv 2", "Sūkaramukhapetavatthu"],
  ["Pv 3", "Pūtimukhapetavatthu"],
  ["Pv 4", "Piṭṭhadhītalikapetavatthu"],
  ["Pv 5", "Tirokuṭṭapetavatthu"],
  ["Pv 6", "Pañcaputtakhādapetivatthu"],
  ["Pv 7", "Sattaputtakhādapetivatthu"],
  ["Pv 8", "Goṇapetavatthu"],
  ["Pv 9", "Mahāpesakārapetivatthu"],
  ["Pv 10", "Khallāṭiyapetivatthu"],
  ["Pv 11", "Nāgapetavatthu"],
  ["Pv 12", "Uragapetavatthu"],
  ["Pv 13", "Saṁsāramocakapetivatthu"],
  ["Pv 14", "Sāriputtattheramātupetivatthu"],
  ["Pv 15", "Mattāpetivatthu"],
  ["Pv 16", "Nandāpetivatthu"],
  ["Pv 17", "Maṭṭhakuṇḍalīpetavatthu"],
  ["Pv 18", "Kaṇhapetavatthu"],
  ["Pv 19", "Dhanapālaseṭṭhipetavatthu"],
  ["Pv 20", "Cūḷaseṭṭhipetavatthu"],
  ["Pv 21", "Aṅkurapetavatthu"],
  ["Pv 22", "Uttaramātupetivatthu"],
  ["Pv 23", "Suttapetavatthu"],
  ["Pv 24", "Kaṇṇamuṇḍapetivatthu"],
  ["Pv 25", "Ubbaripetavatthu"],
  ["Pv 26", "Abhijjamānapetavatthu"],
  ["Pv 27", "Sāṇavāsītherapetavatthu"],
  ["Pv 28", "Rathakārapetivatthu"],
  ["Pv 29", "Bhusapetavatthu"],
  ["Pv 30", "Kumārapetavatthu"],
  ["Pv 31", "Seriṇīpetivatthu"],
  ["Pv 32", "Migaluddakapetavatthu"],
  ["Pv 33", "Dutiyamigaluddakapetavatthu"],
  ["Pv 34", "Kūṭavinicchayikapetavatthu"],
  ["Pv 35", "Dhātuvivaṇṇapetavatthu"],
  ["Pv 36", "Ambasakkarapetavatthu"],
  ["Pv 37", "Serīsakapetavatthu"],
  ["Pv 38", "Nandakapetavatthu"],
  ["Pv 39", "Revatīpetavatthu"],
  ["Pv 40", "Ucchupetavatthu"],
  ["Pv 41", "Kumārapetavatthu"],
  ["Pv 42", "Rājaputtapetavatthu"],
  ["Pv 43", "Gūthakhādakapetavatthu"],
  ["Pv 44", "Gūthakhādakapetivatthu"],
  ["Pv 45", "Gaṇapetavatthu"],
  ["Pv 46", "Pāṭaliputtapetavatthu"],
  ["Pv 47", "Ambavanapetavatthu"],
  ["Pv 48", "Akkharukkhapetavatthu"],
  ["Pv 49", "Bhogasaṁharaṇapetavatthu"],
  ["Pv 50", "Seṭṭhiputtapetavatthu"],
  ["Pv 51", "Saṭṭhikūṭapetavatthu"],
  ["Thag 1.1", "Subhūtittheragāthā"],
  ["Thag 1.2", "Mahākoṭṭhitatheragāthā"],
  ["Thag 1.3", "Kaṅkhārevatatheragāthā"],
  ["Thag 1.4", "Puṇṇatheragāthā"],
  ["Thag 1.5", "Dabbatheragāthā"],
  ["Thag 1.6", "Sītavaniyatheragāthā"],
  ["Thag 1.7", "Bhalliyatheragāthā"],
  ["Thag 1.8", "Vīratheragāthā"],
  ["Thag 1.9", "Pilindavacchatheragāthā"],
  ["Thag 1.10", "Puṇṇamāsatheragāthā"],
  ["Thag 1.11", "Cūlavacchatheragāthā"],
  ["Thag 1.12", "Mahāvacchatheragāthā"],
  ["Thag 1.13", "Vanavacchatheragāthā"],
  ["Thag 1.14", "Vanavaccha’s Pupiltheragāthā"],
  ["Thag 1.15", "Kuṇḍadhānatheragāthā"],
  ["Thag 1.16", "Bellaṭṭhisīsatheragāthā"],
  ["Thag 1.17", "Dāsakatheragāthā"],
  ["Thag 1.18", "Siṅgālapitutheragāthā"],
  ["Thag 1.19", "Kuṇdalatheragāthā"],
  ["Thag 1.20", "Ajitatheragāthā"],
  ["Thag 1.21", "Nigrodhatheragāthā"],
  ["Thag 1.22", "Cittakatheragāthā"],
  ["Thag 1.23", "Gosālatheragāthā"],
  ["Thag 1.24", "Sugandhatheragāthā"],
  ["Thag 1.25", "Nandiyatheragāthā"],
  ["Thag 1.26", "Ubhayatheragāthā"],
  ["Thag 1.27", "Lomasakaṅgiyatheragāthā"],
  ["Thag 1.28", "Jambugāmikaputtatheragāthā"],
  ["Thag 1.29", "Hāritatheragāthā"],
  ["Thag 1.30", "Uttiyatheragāthā"],
  ["Thag 1.31", "Gabbharatīriyatheragāthā"],
  ["Thag 1.32", "Suppiyatheragāthā"],
  ["Thag 1.33", "Sopākatheragāthā"],
  ["Thag 1.34", "Posiyatheragāthā"],
  ["Thag 1.35", "Sāmaññakānitheragāthā"],
  ["Thag 1.36", "Kumāputtatheragāthā"],
  ["Thag 1.37", "Kumāputta’s Friendtheragāthā"],
  ["Thag 1.38", "Gavampatitheragāthā"],
  ["Thag 1.39", "Tissatheragāthā"],
  ["Thag 1.40", "Vaḍḍhamānatheragāthā"],
  ["Thag 1.41", "Sirivaḍḍhatheragāthā"],
  ["Thag 1.42", "Khadiravaniyatheragāthā"],
  ["Thag 1.43", "Sumaṅgalatheragāthā"],
  ["Thag 1.44", "Sānutheragāthā"],
  ["Thag 1.45", "Ramaṇīyavihāritheragāthā"],
  ["Thag 1.46", "Samiddhitheragāthā"],
  ["Thag 1.47", "Ujjayatheragāthā"],
  ["Thag 1.48", "Sañjayatheragāthā"],
  ["Thag 1.49", "Rāmaṇeyyakatheragāthā"],
  ["Thag 1.50", "Vimalatheragāthā"],
  ["Thag 1.51", "Godhikatheragāthā"],
  ["Thag 1.52", "Subāhutheragāthā"],
  ["Thag 1.53", "Valliyatheragāthā"],
  ["Thag 1.54", "Uttiyatheragāthā"],
  ["Thag 1.55", "Añjanavaniyatheragāthā"],
  ["Thag 1.56", "Kuṭivihāritheragāthā"],
  ["Thag 1.57", "Dutiya Kuṭivihāritheragāthā"],
  ["Thag 1.58", "Ramaṇiya Kuṭivihāritheragāthā"],
  ["Thag 1.59", "Kosalavihāritheragāthā"],
  ["Thag 1.60", "Sīvalitheragāthā"],
  ["Thag 1.61", "Vappatheragāthā"],
  ["Thag 1.62", "Vajjiputtatheragāthā"],
  ["Thag 1.63", "Pakkhatheragāthā"],
  ["Thag 1.64", "Vimalakoṇḍaññatheragāthā"],
  ["Thag 1.65", "Ukkhepakatavacchatheragāthā"],
  ["Thag 1.66", "Meghiyatheragāthā"],
  ["Thag 1.67", "Ekadhammasavanīyatheragāthā"],
  ["Thag 1.68", "Ekudāniyatheragāthā"],
  ["Thag 1.69", "Channatheragāthā"],
  ["Thag 1.70", "Puṇṇatheragāthā"],
  ["Thag 1.71", "Vacchapālatheragāthā"],
  ["Thag 1.72", "Ātumatheragāthā"],
  ["Thag 1.73", "Māṇavatheragāthā"],
  ["Thag 1.74", "Suyāmatheragāthā"],
  ["Thag 1.75", "Susāradatheragāthā"],
  ["Thag 1.76", "Piyañjahatheragāthā"],
  ["Thag 1.77", "Hatthārohaputtatheragāthā"],
  ["Thag 1.78", "Meṇḍasiratheragāthā"],
  ["Thag 1.79", "Rakkhitatheragāthā"],
  ["Thag 1.80", "Uggatheragāthā"],
  ["Thag 1.81", "Samatiguttatheragāthā"],
  ["Thag 1.82", "Kassapatheragāthā"],
  ["Thag 1.83", "Sīhatheragāthā"],
  ["Thag 1.84", "Nītatheragāthā"],
  ["Thag 1.85", "Sunāgatheragāthā"],
  ["Thag 1.86", "Nāgitatheragāthā"],
  ["Thag 1.87", "Paviṭṭhatheragāthā"],
  ["Thag 1.88", "Ajjunatheragāthā"],
  ["Thag 1.89", "Devasabhatheragāthā"],
  ["Thag 1.90", "Sāmidattatheragāthā"],
  ["Thag 1.91", "Paripuṇṇakatheragāthā"],
  ["Thag 1.92", "Vijayatheragāthā"],
  ["Thag 1.93", "Erakatheragāthā"],
  ["Thag 1.94", "Mettajitheragāthā"],
  ["Thag 1.95", "Cakkhupāla.theragāthā"],
  ["Thag 1.96", "Khaṇḍasumanatheragāthā"],
  ["Thag 1.97", "Tissatheragāthā"],
  ["Thag 1.98", "Abhayatheragāthā"],
  ["Thag 1.99", "Uttiyatheragāthā"],
  ["Thag 1.100", "Devasabhatheragāthā"],
  ["Thag 1.101", "Belaṭṭhakānitheragāthā"],
  ["Thag 1.102", "Setuccha.theragāthā"],
  ["Thag 1.103", "Bandhuratheragāthā"],
  ["Thag 1.104", "Khitakatheragāthā"],
  ["Thag 1.105", "Malitavambhatheragāthā"],
  ["Thag 1.106", "Suhemantatheragāthā"],
  ["Thag 1.107", "Dhammasavatheragāthā"],
  ["Thag 1.108", "Dhammasava’s Fathertheragāthā"],
  ["Thag 1.109", "Saṅgharakkhitatheragāthā"],
  ["Thag 1.110", "Usabhatheragāthā"],
  ["Thag 1.111", "Jentatheragāthā"],
  ["Thag 1.112", "Vacchagottatheragāthā"],
  ["Thag 1.113", "Vanavacchatheragāthā"],
  ["Thag 1.114", "Adhimuttatheragāthā"],
  ["Thag 1.115", "Mahānāmatheragāthā"],
  ["Thag 1.116", "Pārāsariyatheragāthā"],
  ["Thag 1.117", "Yasatheragāthā"],
  ["Thag 1.118", "Kimbilatheragāthā"],
  ["Thag 1.119", "Vajjiputtatheragāthā"],
  ["Thag 1.120", "Isidattatheragāthā"],
  ["Thag 2.1", "Uttaratheragāthā"],
  ["Thag 2.2", "Piṇḍolabhāradvājatheragāthā"],
  ["Thag 2.3", "Valliyatheragāthā"],
  ["Thag 2.4", "Gaṅgātīryatheragāthā"],
  ["Thag 2.5", "Ajinatheragāthā"],
  ["Thag 2.6", "Meḷajinatheragāthā"],
  ["Thag 2.7", "Rādhatheragāthā"],
  ["Thag 2.8", "Surādhatheragāthā"],
  ["Thag 2.9", "Gotamatheragāthā"],
  ["Thag 2.10", "Vasabhatheragāthā"],
  ["Thag 2.11", "Mahācundatheragāthā"],
  ["Thag 2.12", "Jotidāsatheragāthā"],
  ["Thag 2.13", "Heraññakānitheragāthā"],
  ["Thag 2.14", "Somamittatheragāthā"],
  ["Thag 2.15", "Sabbamittatheragāthā"],
  ["Thag 2.16", "Mahākālatheragāthā"],
  ["Thag 2.17", "Tissatheragāthā"],
  ["Thag 2.18", "Kimbilatheragāthā"],
  ["Thag 2.19", "Nandatheragāthā"],
  ["Thag 2.20", "Sirimantatheragāthā"],
  ["Thag 2.21", "Uttaratheragāthā"],
  ["Thag 2.22", "Bhaddajitheragāthā"],
  ["Thag 2.23", "Sobhitatheragāthā"],
  ["Thag 2.24", "Valliyatheragāthā"],
  ["Thag 2.25", "Vītasokatheragāthā"],
  ["Thag 2.26", "Puṇṇamāsatheragāthā"],
  ["Thag 2.27", "Nandakatheragāthā"],
  ["Thag 2.28", "Bharatatheragāthā"],
  ["Thag 2.29", "Bhāradvājatheragāthā"],
  ["Thag 2.30", "Kaṇhadinnatheragāthā"],
  ["Thag 2.31", "Migasiratheragāthā"],
  ["Thag 2.32", "Sīvakatheragāthā"],
  ["Thag 2.33", "Upavānatheragāthā"],
  ["Thag 2.34", "Isidinnatheragāthā"],
  ["Thag 2.35", "Sambulakaccānatheragāthā"],
  ["Thag 2.36", "Khitakatheragāthā"],
  ["Thag 2.37", "Soṇapoṭiriyaputtatheragathā [Selissariyatheragāthā]"],
  ["Thag 2.38", "Nisabhatheragāthā"],
  ["Thag 2.39", "Usabhatheragāthā"],
  ["Thag 2.40", "Kappaṭakuratheragāthā"],
  ["Thag 2.41", "Kumārakassapatheragāthā"],
  ["Thag 2.42", "Dhammapālatheragāthā"],
  ["Thag 2.43", "Brahmālitheragāthā"],
  ["Thag 2.44", "Mogharājatheragāthā"],
  ["Thag 2.45", "Visākha Pañcālaputtatheragāthā"],
  ["Thag 2.46", "Cūḷakatheragāthā"],
  ["Thag 2.47", "Anūpamatheragāthā"],
  ["Thag 2.48", "Vajjitatheragāthā"],
  ["Thag 2.49", "Sanbhitatheragāthā"],
  ["Thag 3.1", "Aggikabhāradvājatheragāthā"],
  ["Thag 3.2", "Paccayatheragāthā"],
  ["Thag 3.3", "Bakkulatheragāthā"],
  ["Thag 3.4", "Dhaniyatheragāthā"],
  ["Thag 3.5", "Mātaṅgaputtatheragāthā"],
  ["Thag 3.6", "Khujjasobhitatheragāthā"],
  ["Thag 3.7", "Vāraṇatheragāthā"],
  ["Thag 3.8", "Passikatheragāthā"],
  ["Thag 3.9", "Yasojatheragāthā"],
  ["Thag 3.10", "Sāṭimattiyatheragāthā"],
  ["Thag 3.11", "Upālitheragāthā"],
  ["Thag 3.12", "Uttarapālatheragāthā"],
  ["Thag 3.13", "Abhibhūtatheragāthā"],
  ["Thag 3.14", "Gotamatheragāthā"],
  ["Thag 3.15", "Hāritatheragāthā"],
  ["Thag 3.16", "Vimalatheragāthā"],
  ["Thag 4.1", "Nāgasamālatheragāthā"],
  ["Thag 4.2", "Bhagutheragāthā"],
  ["Thag 4.3", "Sabhiyatheragāthā"],
  ["Thag 4.4", "Nandakatheragāthā"],
  ["Thag 4.5", "Jambukatheragāthā"],
  ["Thag 4.6", "Senakatheragāthā"],
  ["Thag 4.7", "Sambhūtatheragāthā"],
  ["Thag 4.8", "Rāhulatheragāthā"],
  ["Thag 4.9", "Candanatheragāthā"],
  ["Thag 4.10", "Dhammikatheragāthā"],
  ["Thag 4.11", "Sappakatheragāthā"],
  ["Thag 4.12", "Muditatheragāthā"],
  ["Thag 5.1", "Rājadattatheragāthā"],
  ["Thag 5.2", "Subhūtatheragāthā"],
  ["Thag 5.3", "Grimānandatheragāthā"],
  ["Thag 5.4", "Sumanatheragāthā"],
  ["Thag 5.5", "Vaḍḍhatheragāthā"],
  ["Thag 5.6", "Nadī Kassapatheragāthā"],
  ["Thag 5.7", "Gayā Kassapatheragāthā"],
  ["Thag 5.8", "Vakkalitheragāthā"],
  ["Thag 5.9", "Vijitasenatheragāthā"],
  ["Thag 5.10", "Yasadattatheragāthā"],
  ["Thag 5.11", "Soṇa Kuṭikaṇṇatheragāthā"],
  ["Thag 5.12", "Kosiyatheragāthā"],
  ["Thag 6.1", "Uruveḷa Kassapatheragāthā"],
  ["Thag 6.2", "Tekicchakānitheragāthā"],
  ["Thag 6.3", "Mahānāgatheragāthā"],
  ["Thag 6.4", "Kullatheragāthā"],
  ["Thag 6.5", "Māluṅkyaputtatheragāthā"],
  ["Thag 6.6", "Sappadāsatheragāthā"],
  ["Thag 6.7", "Kātiyānatheragāthā"],
  ["Thag 6.8", "Migajālatheragāthā"],
  ["Thag 6.9", "Jentatheragāthā"],
  ["Thag 6.10", "Sumanatheragāthā"],
  ["Thag 6.11", "Nahātakamunitheragāthā"],
  ["Thag 6.12", "Brahmadattatheragāthā"],
  ["Thag 6.13", "Sirimaṇḍatheragāthā"],
  ["Thag 6.14", "Sabbakāmitheragāthā"],
  ["Thag 7.1", "Sundara Samuddatheragāthā"],
  ["Thag 7.2", "Lakuṇṭaka Bhaddiyatheragāthā"],
  ["Thag 7.3", "Bhaddatheragāthā"],
  ["Thag 7.4", "Sopākatheragāthā"],
  ["Thag 7.5", "Sarabhaṅgatheragāthā"],
  ["Thag 8.1", "Mahā Kaccāyanatheragāthā"],
  ["Thag 8.2", "Sirimittatheragāthā"],
  ["Thag 8.3", "Mahāpanthakatheragāthā"],
  ["Thag 9.1", "Bhūtatheragāthā"],
  ["Thag 10.1", "Kāḷudāyitheragāthā"],
  ["Thag 10.2", "Eka Vihāriyatheragāthā"],
  ["Thag 10.3", "Mahā Kappinatheragāthā"],
  ["Thag 10.4", "Cūḷapanthakatheragāthā"],
  ["Thag 10.5", "Kappatheragāthā"],
  ["Thag 10.6", "Upasenatheragāthā"],
  ["Thag 10.7", "Apara Gotamatheragāthā"],
  ["Thag 11.1", "Saṅkiccatheragāthā"],
  ["Thag 12.1", "Sīlavatheragāthā"],
  ["Thag 12.2", "Sunītatheragāthā"],
  ["Thag 13.1", "Soṇatheragāthā"],
  ["Thag 14.1", "Khadiravaniya Revatatheragāthā"],
  ["Thag 14.2", "Godattatheragāthā"],
  ["Thag 15.1", "Aññākoṇḍaññatheragāthā"],
  ["Thag 15.2", "Udāyitheragāthā"],
  ["Thag 16.1", "Adhimuttatheragāthā"],
  ["Thag 16.2", "Pārāsariyatheragāthā"],
  ["Thag 16.3", "Telakānitheragāthā"],
  ["Thag 16.4", "Raṭṭhapālatheragāthā"],
  ["Thag 16.5", "Māluṅkyaputtatheragāthā"],
  ["Thag 16.6", "Selatheragāthā"],
  ["Thag 16.7", "Bhaddiyatheragāthā"],
  ["Thag 16.8", "Aṅgulimālatheragāthā"],
  ["Thag 16.9", "Anuruddhatheragāthā"],
  ["Thag 16.10", "Pārāsariyatheragāthā"],
  ["Thag 17.1", "Phussatheragāthā"],
  ["Thag 17.2", "Sāriputtatheragāthā"],
  ["Thag 17.3", "Ānandatheragāthā"],
  ["Thag 18.1", "Mahā Kassapatheragāthā"],
  ["Thag 19.1", "Tāḷapuṭatheragāthā"],
  ["Thag 20.1", "Mahā Moggallānatheragāthā"],
  ["Thag 21.1", "Vaṅgīsatheragāthā"],
  ["Thig 1.1", "Aññatarātherīgāthā"],
  ["Thig 1.2", "Muttātherīgāthā"],
  ["Thig 1.3", "Puṇṇātherīgāthā"],
  ["Thig 1.4", "Tissātherīgāthā"],
  ["Thig 1.5", "Tissātherīgāthā"],
  ["Thig 1.6", "Dhīrātherīgāthā"],
  ["Thig 1.7", "Vīrātherīgāthā"],
  ["Thig 1.8", "Mittātherīgāthā"],
  ["Thig 1.9", "Bhadrātherīgāthā"],
  ["Thig 1.10", "Upasamātherīgāthā"],
  ["Thig 1.11", "Muttātherīgāthā"],
  ["Thig 1.12", "Dhammadinnātherīgāthā"],
  ["Thig 1.13", "Visākhātherīgāthā"],
  ["Thig 1.14", "Sumanātherīgāthā"],
  ["Thig 1.15", "Uttarātherīgāthā"],
  ["Thig 1.16", "Vuḍḍhapabbajitasumanātherīgāthā"],
  ["Thig 1.17", "Dhammātherīgāthā"],
  ["Thig 1.18", "Saṅghātherīgāthā"],
  ["Thig 2.1", "Abhirūpanandātherīgāthā"],
  ["Thig 2.2", "Jentātherīgāthā"],
  ["Thig 2.3", "Sumangalamātātherīgāthā"],
  ["Thig 2.4", "Aḍḍhakāsītherīgāthā"],
  ["Thig 2.5", "Cittātherīgāthā"],
  ["Thig 2.6", "Mettikātherīgāthā"],
  ["Thig 2.7", "Mettātherīgāthā"],
  ["Thig 2.8", "Abhayamātātherīgāthā"],
  ["Thig 2.9", "Abhayātherīgāthā"],
  ["Thig 2.10", "Sāmātherīgāthā"],
  ["Thig 3.1", "Sāmātherīgāthā"],
  ["Thig 3.2", "Uttamātherīgāthā"],
  ["Thig 3.3", "Uttamātherīgāthā"],
  ["Thig 3.4", "Dantikātherīgāthā"],
  ["Thig 3.5", "Ubbirītherīgāthā"],
  ["Thig 3.6", "Sukkātherīgāthā"],
  ["Thig 3.7", "Selātherīgāthā"],
  ["Thig 3.8", "Somātherīgāthā"],
  ["Thig 4.1", "Bhaddākāpilānītherīgāthā"],
  ["Thig 5.1", "Aññataratherīgāthā"],
  ["Thig 5.2", "Vimalātherīgāthā"],
  ["Thig 5.3", "Sīhātherīgāthā"],
  ["Thig 5.4", "Nandātherīgāthā"],
  ["Thig 5.5", "Nanduttarātherīgāthā"],
  ["Thig 5.6", "Mittakālītherīgāthā"],
  ["Thig 5.7", "Sakulātherīgāthā"],
  ["Thig 5.8", "Soṇātherīgāthā"],
  ["Thig 5.9", "Bhaddākuṇḍalakesātherīgāthā"],
  ["Thig 5.10", "Paṭācārātherīgāthā"],
  ["Thig 5.11", "Tiṁsamattātherīgāthā"],
  ["Thig 5.12", "Candātherīgāthā"],
  ["Thig 6.1", "Pañcasatamattātherīgāthā"],
  ["Thig 6.2", "Vāseṭṭhitherīgāthā"],
  ["Thig 6.3", "Khemātherīgāthā"],
  ["Thig 6.4", "Sujātātherīgāthā"],
  ["Thig 6.5", "Anupamātherīgāthā"],
  ["Thig 6.6", "MahāPajāpatīGotamītherīgāthā"],
  ["Thig 6.7", "Guttātherīgāthā"],
  ["Thig 6.8", "Vijayātherīgāthā"],
  ["Thig 7.1", "Uttarātherīgāthā"],
  ["Thig 7.2", "Cālātherīgāthā"],
  ["Thig 7.3", "Upacālātherīgāthā"],
  ["Thig 8.1", "Sisūpacālātherīgāthā"],
  ["Thig 9.1", "Vaḍḍhamātātherīgāthā"],
  ["Thig 10.1", "Kisāgotamītherīgāthā"],
  ["Thig 11.1", "Uppalavaṇṇātherīgāthā"],
  ["Thig 12.1", "Puṇṇātherīgāthā"],
  ["Thig 13.1", "Ambapālitherīgāthā"],
  ["Thig 13.2", "Rohiṇītherīgāthā"],
  ["Thig 13.3", "Cāpātherīgāthā"],
  ["Thig 13.4", "Sundarītherīgāthā"],
  ["Thig 13.5", "Subhātherīgāthā"],
  ["Thig 14.1", "Subhātherīgāthā"],
  ["Thig 15.1", "Isidāsītherīgāthā"],
  ["Thig 16.1", "Sumedhātherīgāthā"],
  ["Ja 1", "Apaṇṇakajātaka"],
  ["Ja 2", "Vaṇṇūpathajātaka"],
  ["Ja 3", "Serivāṇijajātaka"],
  ["Ja 4", "Cullakaseṭṭhijātaka"],
  ["Ja 5", "Taṇḍulanālijātaka"],
  ["Ja 6", "Devadhammajātaka"],
  ["Ja 7", "Kaṭṭhahārijātaka"],
  ["Ja 8", "Gāmanijātaka"],
  ["Ja 9", "Makhādevajātaka"],
  ["Ja 10", "Sukhavihārijātaka"],
  ["Ja 11", "Lakkhaṇajātaka"],
  ["Ja 12", "Nigrodhamigajātaka"],
  ["Ja 13", "Kaṇḍinajātaka"],
  ["Ja 14", "Vātamigajātaka"],
  ["Ja 15", "Kharādiyajātaka"],
  ["Ja 16", "Tipallatthamigajātaka"],
  ["Ja 17", "Mālutajātaka"],
  ["Ja 18", "Matakabhattajātaka"],
  ["Ja 19", "Āyācitabhattajātaka"],
  ["Ja 20", "Naḷapānajātaka"],
  ["Ja 21", "Kuruṅgamigajātaka"],
  ["Ja 22", "Kukkurajātaka"],
  ["Ja 23", "Bhojājānīyajātaka"],
  ["Ja 24", "Ājaññajātaka"],
  ["Ja 25", "Titthajātaka"],
  ["Ja 26", "Mahilāmukhajātaka"],
  ["Ja 27", "Abhiṇhajātaka"],
  ["Ja 28", "Nandivisālajātaka"],
  ["Ja 29", "Kaṇhajātaka"],
  ["Ja 30", "Muṇikajātaka"],
  ["Ja 31", "Kulāvakajātaka"],
  ["Ja 32", "Naccajātaka"],
  ["Ja 33", "Sammodamānajātaka"],
  ["Ja 34", "Macchajātaka"],
  ["Ja 35", "Vaṭṭakajātaka"],
  ["Ja 36", "Sakuṇajātaka"],
  ["Ja 37", "Tittirajātaka"],
  ["Ja 38", "Bakajātaka"],
  ["Ja 39", "Nandajātaka"],
  ["Ja 40", "Khadiraṅgārajātaka"],
  ["Ja 41", "Losakajātaka"],
  ["Ja 42", "Kapotajātaka"],
  ["Ja 43", "Veḷukajātaka"],
  ["Ja 44", "Makasajātaka"],
  ["Ja 45", "Rohiṇījātaka"],
  ["Ja 46", "Ārāmadūsakajātaka"],
  ["Ja 47", "Vāruṇijātaka"],
  ["Ja 48", "Vedabbhajātaka"],
  ["Ja 49", "Nakkhattajātaka"],
  ["Ja 50", "Dummedhajātaka"],
  ["Ja 51", "Mahāsīlavajātaka"],
  ["Ja 52", "Cullajanakajātaka"],
  ["Ja 53", "Puṇṇapātijātaka"],
  ["Ja 54", "Phalajātaka"],
  ["Ja 55", "Pañcāvudhajātaka"],
  ["Ja 56", "Kañcanakkhandhajātaka"],
  ["Ja 57", "Vānarindajātaka"],
  ["Ja 58", "Tayodhammajātaka"],
  ["Ja 59", "Bherivādajātaka"],
  ["Ja 60", "Saṅkhadhamanajātaka"],
  ["Ja 61", "Asātamantajātaka"],
  ["Ja 62", "Aṇḍabhūtajātaka"],
  ["Ja 63", "Takkajātaka"],
  ["Ja 64", "Durājānajātaka"],
  ["Ja 65", "Anabhiratijātaka"],
  ["Ja 66", "Mudulakkhaṇajātaka"],
  ["Ja 67", "Ucchaṅgajātaka"],
  ["Ja 68", "Sāketajātaka"],
  ["Ja 69", "Visavantajātaka"],
  ["Ja 70", "Kuddālajātaka"],
  ["Ja 71", "Varaṇajātaka"],
  ["Ja 72", "Sīlavahatthijātaka"],
  ["Ja 73", "Saccaṅkirajātaka"],
  ["Ja 74", "Rukkhadhammajātaka"],
  ["Ja 75", "Macchajātaka"],
  ["Ja 76", "Asaṅkiyajātaka"],
  ["Ja 77", "Mahāsupinajātaka"],
  ["Ja 78", "Illīsajātaka"],
  ["Ja 79", "Kharassarajātaka"],
  ["Ja 80", "Bhīmasenajātaka"],
  ["Ja 81", "Surāpānajātaka"],
  ["Ja 82", "Mittavindajātaka"],
  ["Ja 83", "Kālakaṇṇijātaka"],
  ["Ja 84", "Atthassadvārajātaka"],
  ["Ja 85", "Kimpakkajātaka"],
  ["Ja 86", "Sīlavīmaṁsanajātaka"],
  ["Ja 87", "Maṅgalajātaka"],
  ["Ja 88", "Sārambhajātaka"],
  ["Ja 89", "Kuhakajātaka"],
  ["Ja 90", "Akataññujātaka"],
  ["Ja 91", "Littajātaka"],
  ["Ja 92", "Mahāsārajātaka"],
  ["Ja 93", "Vissāsabhojanajātaka"],
  ["Ja 94", "Lomahaṁsajātaka"],
  ["Ja 95", "Mahāsudassanajātaka"],
  ["Ja 96", "Telapattajātaka"],
  ["Ja 97", "Nāmasiddhijātaka"],
  ["Ja 98", "Kūṭavāṇijajātaka"],
  ["Ja 99", "Parosahassajātaka"],
  ["Ja 100", "Asātarūpajātaka"],
  ["Ja 101", "Parosatajātaka"],
  ["Ja 102", "Paṇṇikajātaka"],
  ["Ja 103", "Verijātaka"],
  ["Ja 104", "Mittavindajātaka"],
  ["Ja 105", "Dubbalakaṭṭhajātaka"],
  ["Ja 106", "Udañcanijātaka"],
  ["Ja 107", "Sālittakajātaka"],
  ["Ja 108", "Bāhiyajātaka"],
  ["Ja 109", "Kuṇḍakapūvajātaka"],
  ["Ja 110", "Sabbasaṁhārakapañhajātaka"],
  ["Ja 111", "Gadrabhajātaka"],
  ["Ja 112", "Amarādevīpañha"],
  ["Ja 113", "Sigālajātaka"],
  ["Ja 114", "Mitacintijātaka"],
  ["Ja 115", "Anusāsikajātaka"],
  ["Ja 116", "Dubbacajātaka"],
  ["Ja 117", "Tittirajātaka"],
  ["Ja 118", "Vaṭṭakajātaka"],
  ["Ja 119", "Akālarāvijātaka"],
  ["Ja 120", "Bandhanamokkhajātaka"],
  ["Ja 121", "Kusanāḷijātaka"],
  ["Ja 122", "Dummedhajātaka"],
  ["Ja 123", "Naṅgalīsajātaka"],
  ["Ja 124", "Ambajātaka"],
  ["Ja 125", "Kaṭāhakajātaka"],
  ["Ja 126", "Asilakkhaṇajātaka"],
  ["Ja 127", "Kalaṇḍukajātaka"],
  ["Ja 128", "Biḷārajātaka"],
  ["Ja 129", "Aggikajātaka"],
  ["Ja 130", "Kosiyajātaka"],
  ["Ja 131", "Asampadānajātaka"],
  ["Ja 132", " Pañcagarujātaka"],
  ["Ja 133", "Ghatāsanajātaka"],
  ["Ja 134", "Jhānasodhanajātaka"],
  ["Ja 135", "Candābhajātaka"],
  ["Ja 136", "Suvaṇṇahaṁsajātaka"],
  ["Ja 137", "Babbujātaka"],
  ["Ja 138", "Godhajātaka"],
  ["Ja 139", "Ubhatobhaṭṭhajātaka"],
  ["Ja 140", "Kākajātaka"],
  ["Ja 141", "Godhajātaka"],
  ["Ja 142", "Sigālajātaka"],
  ["Ja 143", "Virocanajātaka"],
  ["Ja 144", "Naṅguṭṭhajātaka"],
  ["Ja 145", "Rādhajātaka"],
  ["Ja 146", "Kākajātaka"],
  ["Ja 147", "Puppharattajātaka"],
  ["Ja 148", "Sigālajātaka"],
  ["Ja 149", "Ekapaṇṇajātaka"],
  ["Ja 150", "Sañjīvajātaka"],
  ["Ja 151", "Rājovādajātaka"],
  ["Ja 152", "Sigālajātaka"],
  ["Ja 153", "Sūkarajātaka"],
  ["Ja 154", "Uragajātaka"],
  ["Ja 155", "Gaggajātaka"],
  ["Ja 156", "Alīnacittajātaka"],
  ["Ja 157", "Guṇajātaka"],
  ["Ja 158", "Suhanujātaka"],
  ["Ja 159", "Morajātaka"],
  ["Ja 160", "Vinīlakajātaka"],
  ["Ja 161", "Indasamānagottajātaka"],
  ["Ja 162", "Santhavajātaka"],
  ["Ja 163", "Susīmajātaka"],
  ["Ja 164", "Gijjhajātaka"],
  ["Ja 165", "Nakulajātaka"],
  ["Ja 166", "Upasāḷhajātaka"],
  ["Ja 167", "Samiddhijātaka"],
  ["Ja 168", "Sakuṇagghijātaka"],
  ["Ja 169", "Arakajātaka"],
  ["Ja 170", "Kakaṇṭakajātaka"],
  ["Ja 171", "Kalyāṇadhammajātaka"],
  ["Ja 172", "Daddarajātaka"],
  ["Ja 173", "Makkaṭajātaka"],
  ["Ja 174", "Dūbhiyamakkaṭajātaka"],
  ["Ja 175", "Ādiccupaṭṭhānajātaka"],
  ["Ja 176", "Kalāyamuṭṭhijātaka"],
  ["Ja 177", "Tiṇḍukajātaka"],
  ["Ja 178", "Kacchapajātaka"],
  ["Ja 179", "Satadhammajātaka"],
  ["Ja 180", "Duddadajātaka"],
  ["Ja 181", "Asadisajātaka"],
  ["Ja 182", "Saṅgāmāvacarajātaka"],
  ["Ja 183", "Vālodakajātaka"],
  ["Ja 184", "Giridantajātaka"],
  ["Ja 185", "Anabhiratijātaka"],
  ["Ja 186", "Dadhivāhanajātaka"],
  ["Ja 187", "Catumaṭṭajātaka "],
  ["Ja 188", "Sīhakoṭṭhukajātaka"],
  ["Ja 189", "Sīhacammajātaka"],
  ["Ja 190", "Sīlānisaṁsajātaka"],
  ["Ja 191", "Ruhakajātaka"],
  ["Ja 192", "Sirikāḷakaṇṇijātaka"],
  ["Ja 193", "Cullapadumajātaka"],
  ["Ja 194", "Maṇicorajātaka"],
  ["Ja 195", "Pabbatūpattharajātaka"],
  ["Ja 196", "Valāhassajātaka"],
  ["Ja 197", "Mittāmittajātaka"],
  ["Ja 198", "Rādhajātaka"],
  ["Ja 199", "Gahapatijātaka"],
  ["Ja 200", "Sādhusīlajātaka"],
  ["Ja 201", "Bandhanāgārajātaka"],
  ["Ja 202", "Keḷisīlajātaka"],
  ["Ja 203", "Khandhavattajātaka"],
  ["Ja 204", "Vīrakajātaka"],
  ["Ja 205", "Gaṅgeyyajātaka"],
  ["Ja 206", "Kuruṅgamigajātaka"],
  ["Ja 207", "Assakajātaka"],
  ["Ja 208", "Suṁsumārajātaka"],
  ["Ja 209", "Kakkarajātaka"],
  ["Ja 211", "Somadattajātaka"],
  ["Ja 212", "Ucchiṭṭhabhattajātaka"],
  ["Ja 213", "Bharujātaka"],
  ["Ja 214", "Puṇṇanadījātaka"],
  ["Ja 215", "Kacchapajātaka"],
  ["Ja 216", "Macchajātaka"],
  ["Ja 217", "Seggujātaka"],
  ["Ja 218", "Kūṭavāṇijajātaka"],
  ["Ja 219", "Garahitajātaka"],
  ["Ja 220", "Dhammaddhajajātaka"],
  ["Ja 221", "Kāsāvajātaka"],
  ["Ja 222", "Cullanandakajātaka"],
  ["Ja 223", "Puṭabhattajātaka"],
  ["Ja 224", "Kumbhīlajātaka"],
  ["Ja 225", "Khantivaṇṇanajātaka"],
  ["Ja 226", "Kosiyajātaka"],
  ["Ja 227", "Gūthapāṇajātaka"],
  ["Ja 228", "Kāmanītajātaka"],
  ["Ja 229", "Palāyijātaka"],
  ["Ja 230", "Dutiyapalāyijātaka"],
  ["Ja 231", "Upāhanajātaka"],
  ["Ja 232", "Vīṇāthūṇajātaka"],
  ["Ja 233", "Vikaṇṇakajātaka"],
  ["Ja 234", "Asitābhujātaka"],
  ["Ja 235", "Vacchanakhajātaka"],
  ["Ja 236", "Bakajātaka"],
  ["Ja 237", "Sāketajātaka"],
  ["Ja 238", "Ekapadajātaka"],
  ["Ja 239", "Haritamātajātaka"],
  ["Ja 240", "Mahāpiṅgalajātaka"],
  ["Ja 241", "Sabbadāṭhajātaka"],
  ["Ja 242", "Sunakhajātaka"],
  ["Ja 243", "Guttilajātaka"],
  ["Ja 244", "Vīticchajātaka"],
  ["Ja 245", "Mūlapariyāyajātaka"],
  ["Ja 246", "Telovādajātaka"],
  ["Ja 247", "Pādañjalijātaka"],
  ["Ja 248", "Kiṁsukopamajātaka"],
  ["Ja 249", "Sālakajātaka"],
  ["Ja 250", "Kapijātaka"],
  ["Ja 251", "Saṅkappajātaka"],
  ["Ja 252", "Tilamuṭṭhijātaka"],
  ["Ja 253", "Maṇikaṇṭhajātaka"],
  ["Ja 254", "Kuṇḍakakucchisindhavajātaka"],
  ["Ja 255", "Sukajātaka"],
  ["Ja 256", "Jarudapānajātaka"],
  ["Ja 257", "Gāmaṇicaṇḍajātaka"],
  ["Ja 258", "Mandhātujātaka"],
  ["Ja 259", "Tirīṭavacchajātaka"],
  ["Ja 260", "Dūtajātaka"],
  ["Ja 261", "Padumajātaka"],
  ["Ja 262", "Mudupāṇijātaka"],
  ["Ja 263", "Cullapalobhanajātaka"],
  ["Ja 264", "Mahāpanādajātaka"],
  ["Ja 265", "Khurappajātaka"],
  ["Ja 266", "Vātaggasindhavajātaka"],
  ["Ja 267", "Kakkaṭakajātaka"],
  ["Ja 268", "Ārāmadūsajātaka"],
  ["Ja 269", "Sujātajātaka"],
  ["Ja 270", "Ulūkajātaka"],
  ["Ja 271", "Udapānadūsakajātaka"],
  ["Ja 272", "Vyagghajātaka"],
  ["Ja 273", "Kacchapajātaka"],
  ["Ja 273", "Kacchapajātaka"],
  ["Ja 274", "Lolajātaka"],
  ["Ja 275", "Rucirajātaka"],
  ["Ja 276", "Kurudhammajātaka"],
  ["Ja 277", "Romakajātaka"],
  ["Ja 278", "Mahisajātaka"],
  ["Ja 279", "Satapattajātaka"],
  ["Ja 280", "Puṭadūsakajātaka"],
  ["Ja 281", "Abbhantarajātaka"],
  ["Ja 282", "Seyyajātaka"],
  ["Ja 283", "Vaḍḍhakisūkarajātaka"],
  ["Ja 284", "Sirijātaka"],
  ["Ja 285", "Maṇisūkarajātaka"],
  ["Ja 286", "Sālūkajātaka"],
  ["Ja 287", "Lābhagarahajātaka"],
  ["Ja 288", "Macchuddānajātaka"],
  ["Ja 289", "Nānāchandajātaka"],
  ["Ja 290", "Sīlavīmaṁsajātaka"],
  ["Ja 291", "Bhadraghaṭajātaka"],
  ["Ja 292", "Supattajātaka"],
  ["Ja 293", "Kāyavicchindajātaka"],
  ["Ja 294", "Jambukhādakajātaka"],
  ["Ja 295", "Antajātaka"],
  ["Ja 296", "Samuddajātaka"],
  ["Ja 297", "Kāmavilāpajātaka"],
  ["Ja 298", "Udumbarajātaka"],
  ["Ja 299", "Komāyaputtajātaka"],
  ["Ja 300", "Vakajātaka"],
  ["Ja 301", "Cullakāliṅgajātaka"],
  ["Ja 302", "Mahāassārohajātaka"],
  ["Ja 303", "Ekarājajātaka"],
  ["Ja 304", "Daddarajātaka"],
  ["Ja 305", "Sīlavīmaṁsanajātaka"],
  ["Ja 306", "Sujātajātaka"],
  ["Ja 307", "Palāsajātaka"],
  ["Ja 308", "Javasakuṇajātaka"],
  ["Ja 309", "Chavakajātaka"],
  ["Ja 310", "Sayhajātaka"],
  ["Ja 311", "Pucimandajātaka"],
  ["Ja 312", "Kassapamandiyajātaka"],
  ["Ja 313", "Khantivādijātaka"],
  ["Ja 314", "Lohakumbhijātaka"],
  ["Ja 315", "Maṁsajātaka"],
  ["Ja 316", "Sasajātaka"],
  ["Ja 317", "Matarodanajātaka"],
  ["Ja 318", "Kaṇaverajātaka"],
  ["Ja 319", "Tittirajātaka"],
  ["Ja 320", "Succajajātaka"],
  ["Ja 321", "Kuṭidūsakajātaka"],
  ["Ja 322", "Daddabhajātaka"],
  ["Ja 323", "Brahmadattajātaka"],
  ["Ja 324", "Cammasāṭakajātaka"],
  ["Ja 325", "Godhajātaka"],
  ["Ja 326", "Kakkārujātaka"],
  ["Ja 327", "Kākātijātaka"],
  ["Ja 328", "Ananusociyajātaka"],
  ["Ja 329", "Kālabāhujātaka"],
  ["Ja 330", "Sīlavīmaṁsajātaka"],
  ["Ja 331", "Kokālikajātaka"],
  ["Ja 332", "Rathalaṭṭhijātaka"],
  ["Ja 333", "Godhajātaka"],
  ["Ja 334", "Rājovādajātaka"],
  ["Ja 335", "Jambukajātaka"],
  ["Ja 336", "Brahāchattajātaka"],
  ["Ja 337", "Pīṭhajātaka"],
  ["Ja 338", "Thusajātaka"],
  ["Ja 339", "Bāverujātaka"],
  ["Ja 340", "Visayhajātaka"],
  ["Ja 341", "Kaṇḍarijātaka"],
  ["Ja 342", "Vānarajātaka"],
  ["Ja 343", "Kuntanijātaka"],
  ["Ja 344", "Ambacorajātaka"],
  ["Ja 345", "Gajakumbhajātaka"],
  ["Ja 346", "Kesavajātaka"],
  ["Ja 347", "Ayakūṭajātaka"],
  ["Ja 348", "Araññajātaka"],
  ["Ja 349", "Sandhibhedajātaka"],
  ["Ja 350", "Devatāpañhajātaka"],
  ["Ja 351", "Maṇikuṇḍalajātaka"],
  ["Ja 352", "Sujātajātaka"],
  ["Ja 353", "Dhonasākhajātaka"],
  ["Ja 354", "Uragajātaka"],
  ["Ja 355", "Ghatajātaka"],
  ["Ja 356", "Kāraṇḍiyajātaka"],
  ["Ja 357", "Laṭukikajātaka"],
  ["Ja 358", "Culladhammapālajātaka"],
  ["Ja 359", "Suvaṇṇamigajātaka"],
  ["Ja 360", "Sussondijātaka"],
  ["Ja 361", "Vaṇṇārohajātaka"],
  ["Ja 362", "Sīlavīmaṁsajātaka"],
  ["Ja 363", "Hirijātaka"],
  ["Ja 364", "Khajjopanakajātaka"],
  ["Ja 365", "Ahiguṇḍikajātaka"],
  ["Ja 366", "Gumbiyajātaka"],
  ["Ja 367", "Sāliyajātaka"],
  ["Ja 368", "Tacasārajātaka"],
  ["Ja 369", "Mittavindajātaka"],
  ["Ja 370", "Palāsajātaka"],
  ["Ja 371", "Dīghītikosalajātaka"],
  ["Ja 372", "Migapotakajātaka"],
  ["Ja 373", "Mūsikajātaka"],
  ["Ja 374", "Culladhanuggahajātaka"],
  ["Ja 375", "Kapotajātaka"],
  ["Ja 376", "Avāriyajātaka"],
  ["Ja 377", "Setaketujātaka"],
  ["Ja 378", "Darīmukhajātaka"],
  ["Ja 379", "Nerujātaka"],
  ["Ja 380", "Āsaṅkajātaka"],
  ["Ja 381", "Migālopajātaka"],
  ["Ja 382", "Sirikālakaṇṇijātaka"],
  ["Ja 383", "Kukkuṭajātaka"],
  ["Ja 384", "Dhammaddhajajātaka"],
  ["Ja 385", "Nandiyamigajātaka"],
  ["Ja 386", "Kharaputtajātaka"],
  ["Ja 387", "Sūcijātaka"],
  ["Ja 388", "Tuṇḍilajātaka"],
  ["Ja 389", "Suvaṇṇakakkaṭajātaka"],
  ["Ja 390", "Mayhakajātaka"],
  ["Ja 391", "Dhajaviheṭhajātaka"],
  ["Ja 392", "Bhisapupphajātaka"],
  ["Ja 393", "Vighāsajātaka"],
  ["Ja 394", "Vaṭṭakajātaka"],
  ["Ja 395", "Kākajātaka"],
  ["Ja 396", "Kukkujātaka"],
  ["Ja 397", "Manojajātaka"],
  ["Ja 398", "Sutanojātaka"],
  ["Ja 399", "Gijjhajātaka"],
  ["Ja 400", "Dabbhapupphajātaka"],
  ["Ja 401", "Dasaṇṇakajātaka"],
  ["Ja 402", "Sattubhastajātaka"],
  ["Ja 403", "Aṭṭhisenakajātaka"],
  ["Ja 404", "Kapijātaka"],
  ["Ja 405", "Bakabrahmajātaka"],
  ["Ja 406", "Gandhārajātaka"],
  ["Ja 407", "Mahākapijātaka"],
  ["Ja 408", "Kumbhakārajātaka"],
  ["Ja 409", "Daḷhadhammajātaka"],
  ["Ja 410", "Somadattajātaka"],
  ["Ja 411", "Susīmajātaka"],
  ["Ja 412", "Koṭisimbalijātaka"],
  ["Ja 413", "Dhūmakārijātaka"],
  ["Ja 414", "Jāgarajātaka"],
  ["Ja 415", "Kummāsapiṇḍajātaka"],
  ["Ja 416", "Parantapajātaka"],
  ["Ja 417", "Kaccānijātaka"],
  ["Ja 418", "Aṭṭhasaddajātaka"],
  ["Ja 419", "Sulasājātaka"],
  ["Ja 420", "Sumaṅgalajātaka"],
  ["Ja 421", "Gaṅgamālajātaka"],
  ["Ja 422", "Cetiyajātaka"],
  ["Ja 423", "Indriyajātaka"],
  ["Ja 424", "Ādittajātaka"],
  ["Ja 425", "Aṭṭhānajātaka"],
  ["Ja 426", "Dīpijātaka"],
  ["Ja 427", "Gijjhajātaka"],
  ["Ja 428", "Kosambījātaka"],
  ["Ja 429", "Mahāsukajātaka"],
  ["Ja 430", "Cullasukajātaka"],
  ["Ja 431", "Hāritajātaka"],
  ["Ja 432", "Padakusalamāṇavajātaka"],
  ["Ja 433", "Lomasakassapajātaka"],
  ["Ja 434", "Cakkavākajātaka"],
  ["Ja 435", "Haliddirāgajātaka"],
  ["Ja 436", "Samuggajātaka"],
  ["Ja 437", "Pūtimaṁsajātaka"],
  ["Ja 438", "Tittirajātaka"],
  ["Ja 439", "Catudvārajātaka"],
  ["Ja 440", "Kaṇhajātaka"],
  ["Ja 441", "Catuposathiyajātaka"],
  ["Ja 442", "Saṅkhajātaka"],
  ["Ja 443", "Cullabodhijātaka"],
  ["Ja 444", "Kaṇhadīpāyanajātaka"],
  ["Ja 445", "Nigrodhajātaka"],
  ["Ja 446", "Takkaḷajātaka"],
  ["Ja 447", "Mahādhammapālajātaka"],
  ["Ja 448", "Kukkuṭajātaka"],
  ["Ja 449", "Maṭṭakuṇḍalijātaka"],
  ["Ja 450", "Biḷārikosiyajātaka"],
  ["Ja 451", "Cakkavākajātaka"],
  ["Ja 452", "Bhūripañhajātaka"],
  ["Ja 453", "Mahāmaṅgalajātaka"],
  ["Ja 454", "Ghatajātaka"],
  ["Ja 455", "Mātiposakajātaka"],
  ["Ja 456", "Juṇhajātaka"],
  ["Ja 457", "Dhammajātaka"],
  ["Ja 458", "Udayajātaka"],
  ["Ja 459", "Pānīyajātaka"],
  ["Ja 460", "Yuvañjanajātaka"],
  ["Ja 461", "Dasarathajātaka"],
  ["Ja 462", "Saṁvarajātaka"],
  ["Ja 463", "Suppārakajātaka"],
  ["Ja 464", "Cullakuṇālajātaka"],
  ["Ja 465", "Bhaddasālajātaka"],
  ["Ja 466", "Samuddavāṇijajātaka"],
  ["Ja 467", "Kāmajātaka"],
  ["Ja 468", "Janasandhajātaka"],
  ["Ja 469", "Mahākaṇhajātaka"],
  ["Ja 470", "Kosiyajātaka"],
  ["Ja 471", "Meṇḍakajātaka"],
  ["Ja 472", "Mahāpadumajātaka"],
  ["Ja 473", "Mittāmittajātaka"],
  ["Ja 474", "Ambajātaka"],
  ["Ja 475", "Phandanajātaka"],
  ["Ja 476", "Javanahaṁsajātaka"],
  ["Ja 477", "Cullanāradajātaka"],
  ["Ja 478", "Dūtajātaka"],
  ["Ja 479", "Kāliṅgabodhijātaka"],
  ["Ja 480", "Akittijātaka"],
  ["Ja 481", "Takkāriyajātaka"],
  ["Ja 482", "Rurujātaka"],
  ["Ja 483", "Sarabhamigajātaka"],
  ["Ja 484", "Sālikedārajātaka"],
  ["Ja 485", "Candakinnarajātaka"],
  ["Ja 486", "Mahāukkusajātaka"],
  ["Ja 487", "Uddālakajātaka"],
  ["Ja 488", "Bhisajātaka"],
  ["Ja 489", "Surucijātaka"],
  ["Ja 490", "Pañcūposathajātaka"],
  ["Ja 491", "Mahāmorajātaka"],
  ["Ja 492", "Tacchasūkarajātaka"],
  ["Ja 493", "Mahāvāṇijajātaka"],
  ["Ja 494", "Sādhīnajātaka"],
  ["Ja 495", "Dasabrāhmaṇajātaka"],
  ["Ja 496", "Bhikkhāparamparajātaka"],
  ["Ja 497", "Mātaṅgajātaka"],
  ["Ja 498", "Cittasambhūtajātaka"],
  ["Ja 499", "Sivijātaka"],
  ["Ja 500", "Sirimandajātaka "],
  ["Ja 501", "Rohantamigajātaka"],
  ["Ja 502", "Haṁsajātaka"],
  ["Ja 503", "Sattigumbajātaka"],
  ["Ja 504", "Bhallāṭiyajātaka"],
  ["Ja 505", "Somanassajātaka"],
  ["Ja 506", "Campeyyajātaka"],
  ["Ja 507", "Mahāpalobhanajātaka"],
  ["Ja 508", "Pañcapaṇḍitajātaka"],
  ["Ja 509", "Hatthipālajātaka"],
  ["Ja 510", "Ayogharajātākaṁ"],
  ["Ja 511", "Kiṁchandajātaka"],
  ["Ja 512", "Kumbhajātaka"],
  ["Ja 513", "Jayaddisajātaka"],
  ["Ja 514", "Chaddantajātaka"],
  ["Ja 515", "Sambhavajātaka"],
  ["Ja 516", "Mahākapijātaka"],
  ["Ja 517", "Dakarakkhasajātaka"],
  ["Ja 518", "Paṇḍarajātaka"],
  ["Ja 519", "Sambulajātaka"],
  ["Ja 520", "Gaṇḍatindujātaka"],
  ["Ja 521", "Tesakuṇajātaka"],
  ["Ja 522", "Sarabhaṅgajātaka"],
  ["Ja 523", "Alambusajātaka"],
  ["Ja 524", "Saṅkhapālajātaka"],
  ["Ja 525", "Cullasutasomajātaka"],
  ["Ja 526", "Naḷinikājātaka"],
  ["Ja 527", "Ummādantījātaka"],
  ["Ja 528", "Mahābodhijātaka"],
  ["Ja 529", "Sonakajātaka"],
  ["Ja 530", "Saṅkiccajātaka"],
  ["Ja 531", "Kusajātaka"],
  ["Ja 532", "Sonanandajātaka"],
  ["Ja 533", "Cullahaṁsajātaka"],
  ["Ja 534", "Mahāhaṁsajātaka"],
  ["Ja 535", "Sudhābhojanajātaka"],
  ["Ja 536", "Kuṇālajātaka"],
  ["Ja 537", "Mahāsutasomajātaka"],
  ["Ja 538", "Mūgapakkhajātaka"],
  ["Ja 539", "Mahājanakajātaka"],
  ["Ja 540", "Sāmajātaka"],
  ["Ja 541", "Nimijātaka"],
  ["Ja 542", "Khaṇḍahālajātaka "],
  ["Ja 543", "Bhūridattajātaka"],
  ["Ja 544", "Mahānāradakassapajātaka"],
  ["Ja 545", "Vidhurajātaka"],
  ["Ja 546", "Mahā-Ummaggajātaka [Mahānipāta]"],
  ["Ja 547", "Vessantarajātaka"],
];
