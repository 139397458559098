export const allSuttasEnglish = [
  ["DN 1", "The All-embracing Net of Views"],
  ["DN 2", "The Fruits of Recluseship"],
  ["DN 3", "Ambaṭṭha Sutta"],
  ["DN 4", "Soṇadaṇḍa Sutta"],
  ["DN 5", "Kūṭtadanta Sutta"],
  ["DN 6", "Mahāli Sutta"],
  ["DN 7", "Jāliya Sutta"],
  ["DN 8", "Mahāsīhanāda Sutta"],
  ["DN 9", "Poṭṭhapāda Sutta"],
  ["DN 10", "Subha Sutta"],
  ["DN 11", "Kevaddha Sutta"],
  ["DN 12", "Lohicca Sutta"],
  ["DN 13", "Threefold Knowledge"],
  ["DN 14", "Mahāpadāna Sutta"],
  ["DN 15", "The Great Discourse on Causation"],
  ["DN 16", "The Discourse about the Great Emancipation"],
  ["DN 17", "Mahāsudassana Sutta"],
  ["DN 18", "Janavasabha Sutta"],
  ["DN 19", "Mahāgovinda Sutta"],
  ["DN 20", "The Discourse on the Great Convention"],
  ["DN 21", "Sakkapañha Sutta"],
  ["DN 22", "The Foundations of Mindfulness"],
  ["DN 23", "Payasi Sutta"],
  ["DN 24", "Pāṭika Sutta"],
  ["DN 25", "Udumbarika-Sīhanāda Sutta"],
  ["DN 26", "Cakkavatti-Sīhanāda Sutta"],
  ["DN 27", "Aggañña Sutta"],
  ["DN 28", "Serene Faith"],
  ["DN 29", "The Delightful Discourse"],
  ["DN 30", "The Marks of a Great Man"],
  ["DN 31", "The Buddha's Advice to Sigālaka"],
  ["DN 32", "The Āṭānāṭiya Discourse"],
  ["DN 33", "The Chanting Together"],
  ["DN 34", "Expanding Decades"],
  ["MN 1", "The Root of All Things "],
  ["MN 2", "All the Taints "],
  ["MN 3", "Heirs in Dhamma "],
  ["MN 4", "Fear and Dread "],
  ["MN 5", "Without Blemishes "],
  ["MN 6", "If a Bhikkhu Should Wish "],
  ["MN 7", "The Simile of the Cloth "],
  ["MN 8", "Effacement "],
  ["MN 9", "Right View "],
  ["MN 10", "The Foundations of Mindfulness "],
  ["MN 11", "The Shorter Discourse on the Lion’s Roar "],
  ["MN 12", "The Greater Discourse on the Lion’s Roar "],
  ["MN 13", "The Greater Discourse on the Mass of Suffering "],
  ["MN 14", "The Shorter Discourse on the Mass of Suffering "],
  ["MN 15", "Inference "],
  ["MN 16", "The Wilderness in the Heart "],
  ["MN 17", "Jungle Thickets "],
  ["MN 18", "The Honeyball "],
  ["MN 19", "Two Kinds of Thought "],
  ["MN 20", "The Removal of Distracting Thoughts "],
  ["MN 21", "The Simile of the Saw "],
  ["MN 22", "The Simile of the Snake "],
  ["MN 23", "The Ant-hill "],
  ["MN 24", "The Relay Chariots "],
  ["MN 25", "The Bait "],
  ["MN 26", "The Noble Search "],
  ["MN 27", "The Shorter Discourse on the Simile of the Elephant’s Footprint "],
  ["MN 28", "The Greater Discourse on the Simile of the Elephant’s Footprint "],
  ["MN 29", "The Greater Discourse on the Simile of the Heartwood "],
  ["MN 30", "The Shorter Discourse on the Simile of the Heartwood "],
  ["MN 31", "The Shorter Discourse in Gosinga "],
  ["MN 32", "The Greater Discourse in Gosinga "],
  ["MN 33", "The Greater Discourse on the Cowherd "],
  ["MN 34", "The Shorter Discourse on the Cowherd "],
  ["MN 35", "The Shorter Discourse to Saccaka "],
  ["MN 36", "The Greater Discourse to Saccaka "],
  ["MN 37", "The Shorter Discourse on the Destruction of Craving "],
  ["MN 38", "The Greater Discourse on the Destruction of Craving "],
  ["MN 39", "The Greater Discourse at Assapura "],
  ["MN 40", "The Shorter Discourse at Assapura "],
  ["MN 41", "The Brahmins of Sālā "],
  ["MN 42", "The Brahmins of Verañja "],
  ["MN 43", "The Greater Series of Questions and Answers "],
  ["MN 44", "The Shorter Series of Questions and Answers "],
  ["MN 45", "The Shorter on Ways of Undertaking Things "],
  ["MN 46", "The Greater on Ways of Undertaking Things "],
  ["MN 47", "The Inquirer "],
  ["MN 48", "The Kosambians "],
  ["MN 49", "The Invitation of a Brahmā "],
  ["MN 50", "The Rebuke to Māra "],
  ["MN 51", "To Kandaraka "],
  ["MN 52", "The Man from Aṭṭhakanāgara "],
  ["MN 53", "The Disciple in Higher Training "],
  ["MN 54", "To Potāliya "],
  ["MN 55", "To Jīvaka "],
  ["MN 56", "To Upāli "],
  ["MN 57", "The Dog-Duty Ascetic "],
  ["MN 58", "To Prince Abhaya "],
  ["MN 59", "The Many Kinds of Feeling "],
  ["MN 60", "The Incontrovertible Teaching "],
  ["MN 61", "Advice to Rāhula at Ambalaṭṭhikā "],
  ["MN 62", "The Greater Discourse of Advice to Rāhula "],
  ["MN 63", "The Shorter Discourse to Mālunkyāputta "],
  ["MN 64", "The Greater Discourse to Mālunkyāputta "],
  ["MN 65", "To Bhaddāli "],
  ["MN 66", "The Simile of the Quail "],
  ["MN 67", "At Cātumā "],
  ["MN 68", "At Naḷakapāna "],
  ["MN 69", "Gulissāni "],
  ["MN 70", "At Kīṭāgiri "],
  ["MN 71", "To Vacchagotta on the Threefold True Knowledge "],
  ["MN 72", "To Vacchagotta on Fire "],
  ["MN 73", "The Greater Discourse to Vacchagotta "],
  ["MN 74", "To Dīghanakha "],
  ["MN 75", "To Māgandiya "],
  ["MN 76", "To Sandaka "],
  ["MN 77", "The Greater Discourse to Sakuludāyin "],
  ["MN 78", "Samaṇamaṇḍikāputta "],
  ["MN 79", "The Shorter Discourse to Sakuludāyin "],
  ["MN 80", "To Vekhanassa "],
  ["MN 81", "Ghaṭīkāra the Potter "],
  ["MN 82", "On Raṭṭhapāla "],
  ["MN 83", "King Makhādeva "],
  ["MN 84", "At Madhurā "],
  ["MN 85", "To Prince Bodhi "],
  ["MN 86", "On Angulimāla "],
  ["MN 87", "Born from Those Who Are Dear "],
  ["MN 88", "The Cloak "],
  ["MN 89", "Monuments to the Dhamma "],
  ["MN 90", "At Kaṇṇakatthala "],
  ["MN 91", "Brahmāyu "],
  ["MN 92", "To Sela "],
  ["MN 93", "To Assalāyana "],
  ["MN 94", "To Ghoṭamukha "],
  ["MN 95", "With Cānkī "],
  ["MN 96", "To Esukārī "],
  ["MN 97", "To Dhānañjāni "],
  ["MN 98", "To Vāseṭṭha "],
  ["MN 99", "To Subha "],
  ["MN 100", "To Sangārava "],
  ["MN 101", "At Devadaha "],
  ["MN 102", "The Five and Three "],
  ["MN 103", "What Do You Think About Me? "],
  ["MN 104", "At Sāmagāma "],
  ["MN 105", "To Sunakkhatta "],
  ["MN 106", "The Way to the Imperturbable "],
  ["MN 107", "To Gaṇaka Moggallāna "],
  ["MN 108", "With Gopaka Moggallāna "],
  ["MN 109", "The Greater Discourse on the Full-moon Night "],
  ["MN 110", "The Shorter Discourse on the Full-Moon Night "],
  ["MN 111", "One by One As They Occurred "],
  ["MN 112", "The Sixfold Purity "],
  ["MN 113", "The True Man "],
  ["MN 114", "To Be Cultivated and Not to Be Cultivated "],
  ["MN 115", "The Many Kinds of Elements "],
  ["MN 116", "The Gulle tof the Seers "],
  ["MN 117", "The Great Forty "],
  ["MN 118", "Mindfulness of Breathing "],
  ["MN 119", "Mindfulness of the Body "],
  ["MN 120", "Reappearance by Aspiration "],
  ["MN 121", "The Shorter Discourse on Voidness "],
  ["MN 122", "The Greater Discourse on Voidness "],
  ["MN 123", "Wonderful and Marvellous "],
  ["MN 124", "Bakkula "],
  ["MN 125", "The Grade of the Tamed "],
  ["MN 126", "Bhūmija "],
  ["MN 127", "Anuruddha "],
  ["MN 128", "Imperfections "],
  ["MN 129", "Fools and Wise Men "],
  ["MN 130", "The Divine Messengers "],
  ["MN 131", "A Single Excellent Night "],
  ["MN 132", "Ānanda and a Single Excellent Night "],
  ["MN 133", "Mahā Kaccāna and a Single Excellent Night "],
  ["MN 134", "Lomasakangiya and a Single Excellent Night "],
  ["MN 135", "The Shorter Exposition of Action "],
  ["MN 136", "The Greater Exposition of Action "],
  ["MN 137", "The Exposition of the Sixfold Base "],
  ["MN 138", "The Exposition of a Summary "],
  ["MN 139", "The Exposition of Non-conflict "],
  ["MN 140", "The Exposition of the Elements "],
  ["MN 141", "The Exposition of the Truths "],
  ["MN 142", "The Exposition of Offerings "],
  ["MN 143", "Advice to Anāthapiṇḍika "],
  ["MN 144", "Advice to Channa "],
  ["MN 145", "Advice to Puṇṇa "],
  ["MN 146", "Advice from Nandaka "],
  ["MN 147", "The Shorter Discourse of Advice to Rāhula "],
  ["MN 148", "The Six Sets of Six "],
  ["MN 149", "The Great Sixfold Base "],
  ["MN 150", "To the Nagaravindans "],
  ["MN 151", "The Purification of Almsfood "],
  ["MN 152", "The Development of the Faculties "],
  ["SN 1.1", "Crossing the Flood"],
  ["SN 1.2", "Emancipation"],
  ["SN 1.3", "Reaching"],
  ["SN 1.4", "Time Flies By"],
  ["SN 1.5", "How Many Must One Cut?"],
  ["SN 1.6", "Awake"],
  ["SN 1.7", "Not Penetrated"],
  ["SN 1.8", "Utterly Muddled"],
  ["SN 1.9", "One Prone to Conceit"],
  ["SN 1.10", "Forest"],
  ["SN 1.11", "Nandana"],
  ["SN 1.12", "Delight"],
  ["SN 1.13", "None Equal to That for a Son"],
  ["SN 1.14", "The Khattiya"],
  ["SN 1.15", "Murmuring"],
  ["SN 1.16", "Drowsiness and Lethargy"],
  ["SN 1.17", "Difficult to Practise"],
  ["SN 1.18", "A Sense of Shame"],
  ["SN 1.19", "A Little Hut"],
  ["SN 1.20", "Samiddhi"],
  ["SN 1.21", "A Sword"],
  ["SN 1.22", "It Touches"],
  ["SN 1.23", "Tangle"],
  ["SN 1.24", "Reining in the Mind"],
  ["SN 1.25", "The Arahant"],
  ["SN 1.26", "Sources of Light"],
  ["SN 1.27", "Streams"],
  ["SN 1.28", "Those of Great Wealth"],
  ["SN 1.29", "Four Wheels"],
  ["SN 1.30", "Antelope Calves"],
  ["SN 1.31", "With the Good"],
  ["SN 1.32", "Stinginess"],
  ["SN 1.33", "Good"],
  ["SN 1.34", "There Are No"],
  ["SN 1.35", "Faultfinders 1"],
  ["SN 1.36", "Faith"],
  ["SN 1.37", "Concourse"],
  ["SN 1.38", "The Stone Splinter"],
  ["SN 1.39", "Pajjunna's Daughter [1]"],
  ["SN 1.40", "Pajjunna's Daughter [2]"],
  ["SN 1.41", "Ablaze"],
  ["SN 1.42", "Giving What?"],
  ["SN 1.43", "Food"],
  ["SN 1.44", "One Root"],
  ["SN 1.45", "Perfect"],
  ["SN 1.46", "Nymphs"],
  ["SN 1.47", "Planters of Groves"],
  ["SN 1.48", "Jeta's Grove"],
  ["SN 1.49", "Stingy"],
  ["SN 1.50", "Ghatikara"],
  ["SN 1.51", "Old Age"],
  ["SN 1.52", "Undecaying"],
  ["SN 1.53", "The Friend"],
  ["SN 1.54", "Support"],
  ["SN 1.55", "Produces [1]"],
  ["SN 1.56", "Produces [2]"],
  ["SN 1.57", "Produces [3]"],
  ["SN 1.58", "The Deviant Path"],
  ["SN 1.59", "Partner"],
  ["SN 1.60", "Poetry"],
  ["SN 1.61", "Name"],
  ["SN 1.62", "Mind"],
  ["SN 1.63", "Craving"],
  ["SN 1.64", "Fetter"],
  ["SN 1.65", "Bondage"],
  ["SN 1.66", "Afflicted"],
  ["SN 1.67", "Ensnared"],
  ["SN 1.68", "Shut In"],
  ["SN 1.69", "Desire"],
  ["SN 1.70", "World"],
  ["SN 1.71", "Having Slain"],
  ["SN 1.72", "Chariot"],
  ["SN 1.73", "Treasure"],
  ["SN 1.74", "Rain"],
  ["SN 1.75", "Afraid"],
  ["SN 1.76", "Does Not Decay"],
  ["SN 1.77", "Sovereignty"],
  ["SN 1.78", "Love"],
  ["SN 1.79", "Provisions for a Journey"],
  ["SN 1.80", "Source of Light"],
  ["SN 1.81", "Without Conflict"],
  ["SN 2.1", "Kassapa [1]"],
  ["SN 2.2", "Kassapa [2]"],
  ["SN 2.3", "Māgha"],
  ["SN 2.4", "Māgadha"],
  ["SN 2.5", "Dāmali"],
  ["SN 2.6", "Kāmada"],
  ["SN 2.7", "Pañcālacaṇḍa"],
  ["SN 2.8", "Tāyana"],
  ["SN 2.9", "Candimā"],
  ["SN 2.10", "Suriya"],
  ["SN 2.11", "Candimasa"],
  ["SN 2.12", "Veṇhu"],
  ["SN 2.13", "Dīghalaṭṭhi"],
  ["SN 2.14", "Nandana"],
  ["SN 2.15", "Candana"],
  ["SN 2.16", "Vasudatta"],
  ["SN 2.17", "Subrahmā"],
  ["SN 2.18", "Kakudha"],
  ["SN 2.19", "Uttara"],
  ["SN 2.20", "Anāthapiṇḍika"],
  ["SN 2.21", "Siva"],
  ["SN 2.22", "Khema"],
  ["SN 2.23", "Seri"],
  ["SN 2.24", "Ghaṭikāra"],
  ["SN 2.25", "Jantu"],
  ["SN 2.26", "Rohitassa"],
  ["SN 2.27", "Nanda"],
  ["SN 2.28", "Nandivisāla"],
  ["SN 2.29", "Susīma"],
  ["SN 2.30", "Various Sectarians"],
  ["SN 3.1", "Young"],
  ["SN 3.2", "A Person"],
  ["SN 3.3", "Aging and Death"],
  ["SN 3.4", "Dear"],
  ["SN 3.5", "Self-Protected"],
  ["SN 3.6", "Few"],
  ["SN 3.7", "The Judgement Hall"],
  ["SN 3.8", "Mallikā"],
  ["SN 3.9", "Sacrifice"],
  ["SN 3.10", "Bondage"],
  ["SN 3.11", "Seven Jaṭilas"],
  ["SN 3.12", "Five Kings"],
  ["SN 3.13", "A Bucket Measure of Food"],
  ["SN 3.14", "Battle [1] 1"],
  ["SN 3.15", "Battle [2]"],
  ["SN 3.16", "Daughter"],
  ["SN 3.17", "Diligence [1]"],
  ["SN 3.18", "Diligence [2]"],
  ["SN 3.19", "Childless [1]"],
  ["SN 3.20", "Childless [2]"],
  ["SN 3.21", "Persons"],
  ["SN 3.22", "Grandmother"],
  ["SN 3.23", "World"],
  ["SN 3.24", "Archery"],
  ["SN 3.25", "The Simile of the Mountain"],
  ["SN 4.1", "Austere Practice"],
  ["SN 4.2", "The King Elephant"],
  ["SN 4.3", "Beautiful"],
  ["SN 4.4", "Māra's Snare [1]"],
  ["SN 4.5", "Māra's Snare [2]"],
  ["SN 4.6", "Serpent"],
  ["SN 4.7", "Sleep"],
  ["SN 4.8", "He Delights"],
  ["SN 4.9", "Life Span [1]"],
  ["SN 4.10", "Life Span [2]"],
  ["SN 4.11", "The Boulder"],
  ["SN 4.12", "Lion"],
  ["SN 4.13", "The Splinter"],
  ["SN 4.14", "Suitable"],
  ["SN 4.15", "Mental"],
  ["SN 4.16", "Almsbowls"],
  ["SN 4.17", "Six Bases for Contact"],
  ["SN 4.18", "Alms"],
  ["SN 4.19", "The Farmer"],
  ["SN 4.20", "Rulership"],
  ["SN 4.21", "A Number"],
  ["SN 4.22", "Samiddhi"],
  ["SN 4.23", "Godhika"],
  ["SN 4.24", "Seven Years of Pursuit"],
  ["SN 4.25", "Māra's Daughters"],
  ["SN 5.1", "Āḷavika"],
  ["SN 5.2", "Somā"],
  ["SN 5.3", "Gotamī"],
  ["SN 5.4", "Vijayā"],
  ["SN 5.5", "Uppalavaṇṇā"],
  ["SN 5.6", "Cālā"],
  ["SN 5.7", "Upacālā"],
  ["SN 5.8", "Sāsupacālā"],
  ["SN 5.9", "Selā"],
  ["SN 5.10", "Vajirā"],
  ["SN 6.1", "Brahmā's Request"],
  ["SN 6.2", "Reverence"],
  ["SN 6.3", "Brahmadeva"],
  ["SN 6.4", "Brahmā Baka"],
  ["SN 6.5", "A Certain Brahmā (Another View)"],
  ["SN 6.6", "A Brahmā World (Negligence)"],
  ["SN 6.7", "Kokālika [1]"],
  ["SN 6.8", "Tissaka"],
  ["SN 6.9", "Brahmā Tudu"],
  ["SN 6.10", "Kokālika [2]"],
  ["SN 6.11", "Sanaṅkumāra"],
  ["SN 6.12", "Devadatta"],
  ["SN 6.13", "Andhakavinda"],
  ["SN 6.14", "Aruṇavati"],
  ["SN 6.15", "Final Nibbāna"],
  ["SN 7.1", "Dhanañjanī"],
  ["SN 7.2", "Abuse"],
  ["SN 7.3", "Asurindaka"],
  ["SN 7.4", "Bilaṅgika"],
  ["SN 7.5", "Ahiṁsaka"],
  ["SN 7.6", "Tangle"],
  ["SN 7.7", "Suddhika"],
  ["SN 7.8", "Aggika"],
  ["SN 7.9", "Sundarika"],
  ["SN 7.10", "Many Daughters"],
  ["SN 7.11", "Kasi Bhāradvāja"],
  ["SN 7.12", "Udaya"],
  ["SN 7.13", "Devahita"],
  ["SN 7.14", "The Affluent One"],
  ["SN 7.15", "Mānatthaddha"],
  ["SN 7.16", "Paccanika"],
  ["SN 7.17", "Navakammika"],
  ["SN 7.18", "The Wood Gatherers"],
  ["SN 7.19", "The Mother Supporter"],
  ["SN 7.20", "The Mendicant"],
  ["SN 7.21", "Saṅgārava"],
  ["SN 7.22", "Khomadussa"],
  ["SN 8.1", "Renounced"],
  ["SN 8.2", "Discontent"],
  ["SN 8.3", "Well Behaved"],
  ["SN 8.4", "Ānanda"],
  ["SN 8.5", "Well Spoken"],
  ["SN 8.6", "Sāriputta"],
  ["SN 8.7", "Pavāraṇā"],
  ["SN 8.8", "Over a Thousand"],
  ["SN 8.9", "Koṇḍañña"],
  ["SN 8.10", "Moggallāna"],
  ["SN 8.11", "Gaggarā"],
  ["SN 8.12", "Vaṅgīsa"],
  ["SN 9.1", "Seclusion"],
  ["SN 9.2", "Rousing"],
  ["SN 9.3", "Kassapagotta"],
  ["SN 9.4", "A Number"],
  ["SN 9.5", "ānanda"],
  ["SN 9.6", "Anuruddha"],
  ["SN 9.7", "Nāgadatta"],
  ["SN 9.8", "Family Mistress"],
  ["SN 9.9", "Vajjian Prince (or Vesāli)"],
  ["SN 9.10", "Reciting"],
  ["SN 9.11", "Unwholesome Thoughts"],
  ["SN 9.12", "Noon"],
  ["SN 9.13", "Loose in Sense Faculties"],
  ["SN 9.14", "The Thief of Scent"],
  ["SN 10.1", "Indaka"],
  ["SN 10.2", "Sakkanāmaka"],
  ["SN 10.3", "Sūciloma"],
  ["SN 10.4", "Malṇibhadda"],
  ["SN 10.5", "Sānu"],
  ["SN 10.6", "Piyaṅkara"],
  ["SN 10.7", "Punabbasu"],
  ["SN 10.8", "Sudatta"],
  ["SN 10.9", "Sukkā [1]"],
  ["SN 10.10", "Sukkā [2]"],
  ["SN 10.11", "Cirā"],
  ["SN 10.12", "Āḷavaka"],
  ["SN 11.1", "Suvīra"],
  ["SN 11.2", "Susīma"],
  ["SN 11.3", "The Crest of the Standard"],
  ["SN 11.4", "Vepacitti (or Patience)"],
  ["SN 11.5", "Victory by Well-Spoken Counsel"],
  ["SN 11.6", "The Bird Nests"],
  ["SN 11.7", "One Should Not Transgress"],
  ["SN 11.8", "Verocana, Lord of the Asuras"],
  ["SN 11.9", "Seers in a Forest"],
  ["SN 11.10", "Seers by the Ocean"],
  ["SN 11.11", "Vows"],
  ["SN 11.12", "Sakka's Names"],
  ["SN 11.13", "Mahāli"],
  ["SN 11.14", "Poor"],
  ["SN 11.15", "A Delightful Place"],
  ["SN 11.16", "Bestowing Alms"],
  ["SN 11.17", "Veneration of the Buddha"],
  ["SN 11.18", "The Worship of Householders"],
  ["SN 11.19", "The Worship of the Teacher"],
  ["SN 11.20", "The Worship of the Sangha"],
  ["SN 11.21", "Having Slain"],
  ["SN 11.22", "Ugly"],
  ["SN 11.23", "Magic"],
  ["SN 11.24", "Transgression"],
  ["SN 11.25", "Nonanger"],

  ["SN 12.1", "Dependent Origination"],
  ["SN 12.2", "Analysis of Dependent Origination"],
  ["SN 12.3", "The Two Ways"],
  ["SN 12.4", "Vipassi"],
  ["SN 12.5", "Sikhi"],
  ["SN 12.6", "Vessabhu"],
  ["SN 12.7", "Kakusandha"],
  ["SN 12.8", "Koṇāgamana"],
  ["SN 12.9", "Kassapa"],
  ["SN 12.10", "Gotama the Great Sakyan Sage"],
  ["SN 12.11", "Nutriment"],
  ["SN 12.12", "Moḷiyaphagguna"],
  ["SN 12.13", "Ascetics and Brahmins [1]"],
  ["SN 12.14", "Ascetics and Brahmins [2]"],
  ["SN 12.15", "Kaccanagotta"],
  ["SN 12.16", "A Speaker on the Dhamma"],
  ["SN 12.17", "The Naked Ascetic Kassapa"],
  ["SN 12.18", "Timbaruka"],
  ["SN 12.19", "The Wise Man and the Fool"],
  ["SN 12.20", "Conditions"],
  ["SN 12.21", "The Ten Powers [1]"],
  ["SN 12.22", "The Ten Powers [2]"],
  ["SN 12.23", "Proximate Cause"],
  ["SN 12.24", "Wanderers of Other Sects"],
  ["SN 12.25", "Bhūmija"],
  ["SN 12.26", "Upavalāṇa"],
  ["SN 12.27", "Conditions"],
  ["SN 12.28", "Bhikkhu"],
  ["SN 12.29", "Ascetics and Brahmins [1]"],
  ["SN 12.30", "Ascetics and Brahmins [2]"],
  ["SN 12.31", "What Has Come to Be"],
  ["SN 12.32", "The Kaḷāra"],
  ["SN 12.33", "Cases of Knowledge [1]"],
  ["SN 12.34", "Cases of Knowledge [2]"],
  ["SN 12.35", "With Ignorance as Condition [1]"],
  ["SN 12.36", "With Ignorance as Condition [2]"],
  ["SN 12.37", "Not Yours"],
  ["SN 12.38", "Volition [1]"],
  ["SN 12.39", "Volition [2]"],
  ["SN 12.40", "Volition [3]"],
  ["SN 12.41", "Five Fearful Animosities [1]"],
  ["SN 12.42", "Five Fearful Animosities [2]"],
  ["SN 12.43", "Suffering"],
  ["SN 12.44", "The World"],
  ["SN 12.45", "At Ñātika"],
  ["SN 12.46", "A Certain Brahmin"],
  ["SN 12.47", "Jāṇussoṇi"],
  ["SN 12.48", "A Cosmologist"],
  ["SN 12.49", "The Noble Disciple [1]"],
  ["SN 12.50", "The Noble Disciple [2]"],
  ["SN 12.51", "Thorough Investigation"],
  ["SN 12.52", "Clinging"],
  ["SN 12.53", "Fetters [1]"],
  ["SN 12.54", "Fetters [2]"],
  ["SN 12.55", "The Great Tree [1]"],
  ["SN 12.56", "The Great Tree [2]"],
  ["SN 12.57", "The Sapling"],
  ["SN 12.58", "Name-and-Form"],
  ["SN 12.59", "Consciousness"],
  ["SN 12.60", "Causation"],
  ["SN 12.61", "Uninstructed [1]"],
  ["SN 12.62", "Uninstructed [2]"],
  ["SN 12.63", "Son's Flesh"],
  ["SN 12.64", "If There Is Lust"],
  ["SN 12.65", "The City"],
  ["SN 12.66", "Exploration"],
  ["SN 12.67", "The Sheaves of Reeds"],
  ["SN 12.68", "Kosambi"],
  ["SN 12.69", "The Surge"],
  ["SN 12.70", "Susīma"],
  ["SN 12.71", "Aging-and-Death"],
  ["SN 12.72-81", "Birth"],
  ["SN 12.82", "A Teacher"],
  ["SN 12.83", "Training"],
  ["SN 12.84-93", "Exertion"],
  ["SN 13.1", "The Fingernail"],
  ["SN 13.2", "The Pond"],
  ["SN 13.3", "Water at the Confluence [1]"],
  ["SN 13.4", "Water at the Confluence [2]"],
  ["SN 13.5", "The Earth [1]"],
  ["SN 13.6", "The Earth [2]"],
  ["SN 13.7", "The Ocean [1]"],
  ["SN 13.8", "The Ocean [2]"],
  ["SN 13.9", "The Mountain [1]"],
  ["SN 13.10", "The Mountain [2]"],
  ["SN 13.11", "The Mountain [3]"],
  ["SN 14.1", "Diversity of Elements"],
  ["SN 14.2", "Diversity of Contacts"],
  ["SN 14.3", "Not Diversity of Contacts"],
  ["SN 14.4", "Diversity of Feelings [1]"],
  ["SN 14.5", "Diversity of Feelings [2]"],
  ["SN 14.6", "Diversity of External Elements"],
  ["SN 14.7", "Diversity of Perceptions"],
  ["SN 14.8", "Not Diversity of Quests"],
  ["SN 14.9", "Diversity of External Contacts [1]"],
  ["SN 14.10", "Diversity of External Contacts [2]"],
  ["SN 14.11", "Seven Elements"],
  ["SN 14.12", "With a Source"],
  ["SN 14.13", "The Brick Hall"],
  ["SN 14.14", "Inferior Disposition"],
  ["SN 14.15", "Walking Back and Forth"],
  ["SN 14.16", "With Verses"],
  ["SN 14.17", "Lacking Faith"],
  ["SN 14.18", "Rooted in those Lacking Faith"],
  ["SN 14.19", "Rooted in the Shameless"],
  ["SN 14.20", "Rooted in those Unafraid of Wrongdoing"],
  ["SN 14.21", "Rooted in the Unlearned"],
  ["SN 14.22", "Rooted in the Lazy"],
  ["SN 14.23", "Unconcentrated"],
  ["SN 14.24", "Immoral"],
  ["SN 14.25", "The Five Training Rules"],
  ["SN 14.26", "Seven Courses of Kamma"],
  ["SN 14.27", "Ten Courses of Kamma"],
  ["SN 14.28", "The Eightfold Path"],
  ["SN 14.29", "Ten Factors"],
  ["SN 14.30", "Four Elements"],
  ["SN 14.31", "Before My Enlightenment"],
  ["SN 14.32", "I Set Out"],
  ["SN 14.33", "If There Were No"],
  ["SN 14.34", "Exclusively Suffering"],
  ["SN 14.35", "Delight"],
  ["SN 14.36", "Arising"],
  ["SN 14.37", "Ascetics and Brahmins [1]"],
  ["SN 14.38", "Ascetics and Brahmins [2]"],
  ["SN 14.39", "Ascetics and Brahmins [3]"],
  ["SN 15.1", "Grass and Wood"],
  ["SN 15.2", "The Earth"],
  ["SN 15.3", "Tears"],
  ["SN 15.4", "Mother's Milk"],
  ["SN 15.5", "The Mountain"],
  ["SN 15.6", "The Mustard Seed"],
  ["SN 15.7", "Disciples"],
  ["SN 15.8", "The River Ganges"],
  ["SN 15.9", "The Stick"],
  ["SN 15.10", "Person"],
  ["SN 15.11", "Unfortunate"],
  ["SN 15.12", "Happy"],
  ["SN 15.13", "Thirty Bhikkhus"],
  ["SN 15.14-19", "Mother, Etc."],
  ["SN 15.20", "Mount Vepulla"],
  ["SN 16.1", "Content"],
  ["SN 16.2", "Unafraid of Wrongdoing"],
  ["SN 16.3", "Like the Moon"],
  ["SN 16.4", "A Visitor of Families"],
  ["SN 16.5", "Old"],
  ["SN 16.6", "Exhortation [1]"],
  ["SN 16.7", "Exhortation [2]"],
  ["SN 16.8", "Exhortation [3]"],
  ["SN 16.9", "Jhānas and Direct Knowledges"],
  ["SN 16.10", "The Bhikkhunīs' Quarters"],
  ["SN 16.11", "The Robe"],
  ["SN 16.12", "After Death"],
  ["SN 16.13", "The Counterfeit of the True Dhamma"],
  ["SN 17.1", "Dreadful"],
  ["SN 17.2", "The Hook"],
  ["SN 17.3", "The Turtle"],
  ["SN 17.4", "The Long-Haired Goat"],
  ["SN 17.5", "The Dung Beetle"],
  ["SN 17.6", "The Thunderbolt"],
  ["SN 17.7", "The Poisoned Dart"],
  ["SN 17.8", "The Jackal"],
  ["SN 17.9", "The Gale Winds"],
  ["SN 17.10", "With Verses"],
  ["SN 17.11", "Golden Bowl"],
  ["SN 17.12", "Silver Bowl"],
  ["SN 17.13-20", "Suvaṇṇanikkha, Etc."],
  ["SN 17.21", "A Woman"],
  ["SN 17.22", "The Most Beautiful Girl of the Land"],
  ["SN 17.23", "Only Son"],
  ["SN 17.24", "Only Daughter"],
  ["SN 17.25", "Ascetics and Brahmins [1]"],
  ["SN 17.26", "Ascetics and Brahmins [2]"],
  ["SN 17.27", "Ascetics and Brahmins [3]"],
  ["SN 17.28", "Skin"],
  ["SN 17.29", "The Rope"],
  ["SN 17.30", "The Bhikkhu"],
  ["SN 17.31", "Schism"],
  ["SN 17.32", "Wholesome Root"],
  ["SN 17.33", "Wholesome Nature"],
  ["SN 17.34", "Bright Nature"],
  ["SN 17.35", "Not Long After He Left"],
  ["SN 17.36", "Five Hundred Carts"],
  ["SN 17.37-43", "Mother Sutta, Etc."],
  ["SN 18.1", "The Eye, Etc."],
  ["SN 18.2", "Forms, Etc."],
  ["SN 18.3", "Consciousness"],
  ["SN 18.4", "Contact"],
  ["SN 18.5", "Feeling"],
  ["SN 18.6", "Perception"],
  ["SN 18.7", "Volition"],
  ["SN 18.8", "Craving"],
  ["SN 18.9", "Elements"],
  ["SN 18.10", "Aggregates"],
  ["SN 18.11-20", "The Eye, Etc. 697"],
  ["SN 18.21", "Underlying Tendency"],
  ["SN 18.22", "Rid Of"],
  ["SN 19.1", "The Skeleton"],
  ["SN 19.2", "The Piece of Meat"],
  ["SN 19.3", "The Lump of Meat"],
  ["SN 19.4", "The Flayed Man"],
  ["SN 19.5", "Sword Hairs"],
  ["SN 19.6", "Spear Hairs"],
  ["SN 19.7", "Arrow Hairs"],
  ["SN 19.8", "Needle Hairs [1]"],
  ["SN 19.9", "Needle Hairs [2]"],
  ["SN 19.10", "Pot Testicles"],
  ["SN 19.11", "With Head Submerged"],
  ["SN 19.12", "The Dung Eater"],
  ["SN 19.13", "The Flayed Woman"],
  ["SN 19.14", "The Ugly Woman"],
  ["SN 19.15", "The Sweltering Woman"],
  ["SN 19.16", "The Headless Trunk"],
  ["SN 19.17", "The Evil Bhikkhu"],
  ["SN 19.18", "The Evil Bhikkhuni"],
  ["SN 19.19-21", "The Evil Probationary Nun, Etc."],
  ["SN 20.1", "The Roof Peak"],
  ["SN 20.2", "The Fingernail"],
  ["SN 20.3", "3.Families"],
  ["SN 20.4", "Pots of Food"],
  ["SN 20.5", "The Spear"],
  ["SN 20.6", "The Archers"],
  ["SN 20.7", "The Drum Peg"],
  ["SN 20.8", "Blocks of Wood"],
  ["SN 20.9", "The Bull Elephant"],
  ["SN 20.10", "The Cat"],
  ["SN 20.11", "The Jackal [1]"],
  ["SN 20.12", "The Jackal [2]"],
  ["SN 21.1", "Kolita"],
  ["SN 21.2", "Upatissa"],
  ["SN 21.3", "The Barrel"],
  ["SN 21.4", "The Newly Ordained Bhikkhu"],
  ["SN 21.5", "Sujata"],
  ["SN 21.6", "Lakuṇṭaka Bhaddiya"],
  ["SN 21.7", "Visākha"],
  ["SN 21.8", "Nanda"],
  ["SN 21.9", "Tissa"],
  ["SN 21.10", "A Bhikkhu Named Elder"],
  ["SN 21.11", "Mahākappina"],
  ["SN 21.12", "Companions"],
  ["SN 22.1", "Nakulapitā"],
  ["SN 22.2", "At Devadaha"],
  ["SN 22.3", "Haliddakani [1]"],
  ["SN 22.4", "Haliddakani [2]"],
  ["SN 22.5", "Concentration"],
  ["SN 22.6", "Seclusion"],
  ["SN 22.7", "Agitation through Clinging [1]"],
  ["SN 22.8", "Agitation through Clinging [2]"],
  ["SN 22.9", "Impermanent in the Three Times"],
  ["SN 22.10", "Suffering in the Three Times"],
  ["SN 22.11", "Nonself in the Three Times"],
  ["SN 22.12", "Impermanent"],
  ["SN 22.13", "Suffering"],
  ["SN 22.14", "Nonself"],
  ["SN 22.15", "What is Impermanent"],
  ["SN 22.16", "What is Suffering"],
  ["SN 22.17", "What is Nonself"],
  ["SN 22.18", "Impermanent with Cause"],
  ["SN 22.19", "Suffering with Cause"],
  ["SN 22.20", "Nonself with Cause"],
  ["SN 22.21", "Ānanda"],
  ["SN 22.22", "The Burden"],
  ["SN 22.23", "Full Understanding"],
  ["SN 22.24", "Directly Knowing"],
  ["SN 22.25", "Desire and Lust"],
  ["SN 22.26", "Gratification [1]"],
  ["SN 22.27", "Gratification [2]"],
  ["SN 22.28", "Gratification [3]"],
  ["SN 22.29", "Delight"],
  ["SN 22.30", "Arising"],
  ["SN 22.31", "The Root of Misery"],
  ["SN 22.32", "The Fragile"],
  ["SN 22.33", "Not Yours [1]"],
  ["SN 22.34", "Not Yours [2]"],
  ["SN 22.35", "A Certain Bhikkhu [1]"],
  ["SN 22.36", "A Certain Bhikkhu [2]"],
  ["SN 22.37", "Ānanda [1]"],
  ["SN 22.38", "Ānanda [2]"],
  ["SN 22.39", "In Accordance with the Dhamma [1]"],
  ["SN 22.40", "In Accordance with the Dhamma [2]"],
  ["SN 22.41", "In Accordance with the Dhamma [3]"],
  ["SN 22.42", "In Accordance with the Dhamma [4]"],
  ["SN 22.43", "With Yourselves as an Island"],
  ["SN 22.44", "The Way"],
  ["SN 22.45", "Impermanent [1]"],
  ["SN 22.46", "Impermanent [2]"],
  ["SN 22.47", "Ways of Regarding Things"],
  ["SN 22.48", "Aggregates"],
  ["SN 22.49", "Soṇa [1]"],
  ["SN 22.50", "Soṇa [2]"],
  ["SN 22.51", "Destruction of Delight [1]"],
  ["SN 22.52", "Destruction of Delight [2]"],
  ["SN 22.53", "Engagement"],
  ["SN 22.54", "Seeds"],
  ["SN 22.55", "Inspired Utterance"],
  ["SN 22.56", "Phases of the Clinging Aggregates"],
  ["SN 22.57", "The Seven Cases"],
  ["SN 22.58", "The Perfectly Enlightened One"],
  ["SN 22.59", "The Characteristic of Nonself"],
  ["SN 22.60", "Mahāli"],
  ["SN 22.61", "Burning"],
  ["SN 22.62", "Pathways of Language"],
  ["SN 22.63", "In Clinging"],
  ["SN 22.64", "In Conceiving"],
  ["SN 22.65", "In Seeking Delight"],
  ["SN 22.66", "Impermanent"],
  ["SN 22.67", "Suffering"],
  ["SN 22.68", "Nonself"],
  ["SN 22.69", "What Does Not Belong to Self"],
  ["SN 22.70", "Whatever Appears Tantalizing"],
  ["SN 22.71", "Rādha"],
  ["SN 22.72", "Surādha"],
  ["SN 22.73", "Gratification"],
  ["SN 22.74", "Origin [1]"],
  ["SN 22.75", "Origin [2]"],
  ["SN 22.76", "Arahants [1]"],
  ["SN 22.77", "Arahants [2]"],
  ["SN 22.78", "The Lion"],
  ["SN 22.79", "Being Devoured"],
  ["SN 22.80", "Alms-Gatherer"],
  ["SN 22.81", "Pārileyya"],
  ["SN 22.82", "The Full-Moon Night"],
  ["SN 22.83", "Ānanda"],
  ["SN 22.84", "Tissa"],
  ["SN 22.85", "Yamaka"],
  ["SN 22.86", "Anuradha"],
  ["SN 22.87", "Vakkali"],
  ["SN 22.88", "Assaji"],
  ["SN 22.89", "Khemaka"],
  ["SN 22.90", "Channa"],
  ["SN 22.91", "Rāhula [1]"],
  ["SN 22.92", "Rāhula [2]"],
  ["SN 22.93", "The River"],
  ["SN 22.94", "Flowers"],
  ["SN 22.95", "A Lump of Foam"],
  ["SN 22.96", "A Lump of Cowdung"],
  ["SN 22.97", "The Fingernail"],
  ["SN 22.98", "Simple Version"],
  ["SN 22.99", "The Leash [1]"],
  ["SN 22.100", "The Leash [2]"],
  ["SN 22.101", "The Adze Handle (or The Ship)"],
  ["SN 22.102", "Perception of Impermanence"],
  ["SN 22.103", "Portions"],
  ["SN 22.104", "Suffering"],
  ["SN 22.105", "Identity"],
  ["SN 22.106", "To Be Fully Understood"],
  ["SN 22.107", "Ascetics [1]"],
  ["SN 22.108", "Ascetics [2]"],
  ["SN 22.109", "Stream-Enterer"],
  ["SN 22.110", "Arahant"],
  ["SN 22.111", "Abandoning Desire [1]"],
  ["SN 22.112", "Abandoning Desire [2]"],
  ["SN 22.113", "Ignorance"],
  ["SN 22.114", "True Knowledge"],
  ["SN 22.115", "A Speaker on the Dhamma [1]"],
  ["SN 22.116", "A Speaker on the Dhamma [2]"],
  ["SN 22.117", "Bondage"],
  ["SN 22.118", "Interrogation [1]"],
  ["SN 22.119", "Interrogation [2]"],
  ["SN 22.120", "Things That Fetter"],
  ["SN 22.121", "Things That Can Be Clung To"],
  ["SN 22.122", "Virtuous"],
  ["SN 22.123", "Instructed"],
  ["SN 22.124", "Kappa [1]"],
  ["SN 22.125", "Kappa [2]"],
  ["SN 22.126", "Subject to Arising [1]"],
  ["SN 22.127", "Subject to Arising [2]"],
  ["SN 22.128", "Subject to Arising [3]"],
  ["SN 22.129", "Gratification [1]"],
  ["SN 22.130", "Gratification [2]"],
  ["SN 22.131", "Origin [1]"],
  ["SN 22.132", "Origin [2]"],
  ["SN 22.133", "Koṭṭhita [1]"],
  ["SN 22.134", "Koṭṭhita [2]"],
  ["SN 22.135", "Koṭṭhita [3]"],
  ["SN 22.136", "Hot Embers"],
  ["SN 22.137", "Impermanent [1]"],
  ["SN 22.138", "Impermanent [2]"],
  ["SN 22.139", "Impermanent [3]"],
  ["SN 22.140", "Suffering [1]"],
  ["SN 22.141", "Suffering [2]"],
  ["SN 22.142", "Suffering [3]"],
  ["SN 22.143", "Nonself [1]"],
  ["SN 22.144", "Nonself [2]"],
  ["SN 22.145", "Nonself [3]"],
  ["SN 22.146", "Engrossed in Revulsion"],
  ["SN 22.147", "Contemplating Impermanence"],
  ["SN 22.148", "Contemplating Suffering"],
  ["SN 22.149", "Contemplating Nonself"],
  ["SN 22.150", "Internally"],
  ["SN 22.151", "This Is Mine"],
  ["SN 22.152", "The Self"],
  ["SN 22.153", "It Might Not Be For Me"],
  ["SN 22.154", "Wrong View"],
  ["SN 22.155", "Identity View"],
  ["SN 22.156", "View of Self"],
  ["SN 22.157", "Adherence [1]"],
  ["SN 22.158", "Adherence [2]"],
  ["SN 22.159", "Ānanda"],
  ["SN 23.1", "Māra"],
  ["SN 23.2", "A Being"],
  ["SN 23.3", "The Conduit to Existence"],
  ["SN 23.4-10", "To Be Fully Understood, Etc."],
  ["SN 23.11", "Māra"],
  ["SN 23.12", "Subject to Māra"],
  ["SN 23.13", "Impermanent"],
  ["SN 23.14", "Of Impermanent Nature"],
  ["SN 23.15", "Suffering"],
  ["SN 23.16", "Of Painful Nature"],
  ["SN 23.17", "Nonself"],
  ["SN 23.18", "Of Selfless Nature"],
  ["SN 23.19", "Subject to Destruction"],
  ["SN 23.20", "Subject to Vanishing"],
  ["SN 23.21", "Subject to Arising"],
  ["SN 23.22", "Subject to Cessation"],
  ["SN 23.23", "Māra"],
  ["SN 23.24-34", "Subject to Māra, Etc."],
  ["SN 23.35", "Māra"],
  ["SN 23.36-46", "Subject to Māra, Etc."],
  ["SN 24.1", "Winds"],
  ["SN 24.2", "This Is Mine"],
  ["SN 24.3", "The Self"],
  ["SN 24.4", "It Might Not Be For Me"],
  ["SN 24.5", "There Is Not"],
  ["SN 24.6", "Acting"],
  ["SN 24.7", "Cause"],
  ["SN 24.8", "The Great View"],
  ["SN 24.9", "The World Is Eternal"],
  ["SN 24.10", "The World Is Not Eternal"],
  ["SN 24.11", "The World is Finite"],
  ["SN 24.12", "The World Is Infinite"],
  ["SN 24.13", "Soul and Body Are the Same"],
  ["SN 24.14", "Soul and Body Are Different"],
  ["SN 24.15", "The Tathagata Exists"],
  ["SN 24.16", "The Tathagata Does Not Exist"],
  ["SN 24.17", "The Tathagata Both Exists and Does Not Exist"],
  ["SN 24.18", "The Tathagata Neither Exists Nor Does Not Exist"],
  ["SN 24.19", "Winds"],
  ["SN 24.20-36", "This Is Mine, Etc."],
  ["SN 24.37", "A Self Consisting of Form"],
  ["SN 24.38", "A Formless Self"],
  ["SN 24.39", "A Self Both Consisting of Form and Formless"],
  ["SN 24.40", "A Self Neither Consisting of Form nor Formless"],
  ["SN 24.41", "Exclusively Happy"],
  ["SN 24.42", "Exclusively Miserable"],
  ["SN 24.43", "Both Happy and Miserable"],
  ["SN 24.44", "Neither Happy nor Miserable"],
  ["SN 24.45", "Winds"],
  ["SN 24.46-70", "This Is Mine, Etc."],
  ["SN 24.71", "Winds"],
  ["SN 24.72-96", "This Is Mine, Etc."],
  ["SN 25.1", "The Eye"],
  ["SN 25.2", "Forms"],
  ["SN 25.3", "Consciousness"],
  ["SN 25.4", "Contact"],
  ["SN 25.5", "Feeling"],
  ["SN 25.6", "Perception"],
  ["SN 25.7", "Volition"],
  ["SN 25.8", "Craving"],
  ["SN 25.9", "Elements"],
  ["SN 25.10", "Aggregates"],
  ["SN 26.1", "The Eye"],
  ["SN 26.2", "Forms"],
  ["SN 26.3", "Consciousness"],
  ["SN 26.4", "Contact"],
  ["SN 26.5", "Feeling"],
  ["SN 26.6", "Perception"],
  ["SN 26.7", "Volition"],
  ["SN 26.8", "Craving"],
  ["SN 26.9", "Elements"],
  ["SN 26.10", "Aggregates"],
  ["SN 27.1", "The Eye"],
  ["SN 27.2", "Forms"],
  ["SN 27.3", "Consciousness"],
  ["SN 27.4", "Contact"],
  ["SN 27.5", "Feeling 1"],
  ["SN 27.6", "Perception"],
  ["SN 27.7", "Volition"],
  ["SN 27.8", "Craving"],
  ["SN 27.9", "Elements"],
  ["SN 27.10", "Aggregates"],
  ["SN 28.1", "Born of Seclusion"],
  ["SN 28.2", "Without Thought"],
  ["SN 28.3", "Rapture"],
  ["SN 28.4", "Equanimity"],
  ["SN 28.5", "The Base of the Infinity of Space"],
  ["SN 28.6", "The Base of the Infinity of Consciousness"],
  ["SN 28.7", "The Base of Nothingness"],
  ["SN 28.8", "The Base of Neither-Perception-Nor-Nonperception"],
  ["SN 28.9", "The Attainment of Cessation"],
  ["SN 28.10", "Sucimukhi"],
  ["SN 29.1", "Simple Version"],
  ["SN 29.2", "Superior"],
  ["SN 29.3", "The Uposatha [1]"],
  ["SN 29.4-6", "The Uposatha (2-4)"],
  ["SN 29.7", "He Has Heard [1]"],
  ["SN 29.8-10", "He Has Heard (2-4)"],
  ["SN 29.11-20", "With the Support of Giving [1]"],
  ["SN 29.21-40", "With the Support of Giving (2-4)"],
  ["SN 30.1", "Simple Version"],
  ["SN 30.2", "They Carry Off"],
  ["SN 30.3", "Ambivalent [1]"],
  ["SN 30.4-6", "Ambivalent (2-4)"],
  ["SN 30.7-16", "With the Support of Giving [1]"],
  ["SN 30.17-36", "With the Support of Giving (2-4)"],
  ["SN 31.1", "Simple Version"],
  ["SN 31.2", "Good Conduct"],
  ["SN 31.3", "Giver [1]"],
  ["SN 31.4-12", "Giver (2-10)"],
  ["SN 31.13-22", "With the Support of Giving [1]"],
  ["SN 31.23-112", "With the Support of Giving [2]"],
  ["SN 33.1", "Because of Not Knowing [1]"],
  ["SN 33.2", "Because of Not Knowing [2]"],
  ["SN 33.3", "Because of Not Knowing [3]"],
  ["SN 33.4", "Because of Not Knowing [4]"],
  ["SN 33.5", "Because of Not Knowing [5]"],
  ["SN 33.6-10", "Because of Not Seeing"],
  ["SN 33.11-15", "Because of Not Breaking Through"],
  ["SN 33.16-20", "Because of Not Comprehending"],
  ["SN 33.21-25", "Because of Not Penetrating"],
  ["SN 33.25-30", "Because of Not Discerning"],
  ["SN 33.31", "Because of Not Discriminating"],
  ["SN 33.36", "Because of Not Differentiating"],
  ["SN 33.41", "Because of Not Examining"],
  ["SN 33.46", "Because of Not Closely Examining"],
  ["SN 33.51-55", "Because of Not Directly Cognizing"],
  ["SN 34.1", "Attainment in relation to Concentration"],
  ["SN 34.2", "Maintenance in relation to Concentration"],
  ["SN 34.3", "Emergence in relation to Concentration"],
  ["SN 34.4", "Pliancy in relation to Concentration"],
  ["SN 34.5", "The Object in relation to Concentration"],
  ["SN 34.6", "The Range in relation to Concentration"],
  ["SN 34.7", "Resolution in relation to Concentration"],
  ["SN 34.8", "Thoroughness in relation to Concentration"],
  ["SN 34.9", "Persistence in relation to Concentration"],
  ["SN 34.10", "Suitability in relation to Concentration"],
  ["SN 34.11", "Maintenance in relation to Attainment"],
  ["SN 34.12", "Emergence in relation to Attainment"],
  ["SN 34.13", "Pliancy in relation to Attainment"],
  ["SN 34.14", "The Object in relation to Attainment"],
  ["SN 34.15", "The Range in relation to Attainment"],
  ["SN 34.16", "Resolution in relation to Attainment"],
  ["SN 34.17", "Thoroughness in relation to Attainment"],
  ["SN 34.18", "Persistence in relation to Attainment"],
  ["SN 34.19", "Suitability in relation to Attainment"],
  ["SN 34.20", "Emergence in relation to Maintenance"],
  ["SN 34.21-27", "Pliancy in relation to Maintenance, Etc."],
  ["SN 34.28", "Pliancy in relation to Emergence"],
  ["SN 34.29-34", "The Object in relation to Emergence, Etc."],
  ["SN 34.35", "The Object in relation to Pliancy"],
  ["SN 34.36-40", "The Range in relation to Pliancy, Etc."],
  ["SN 34.41", "The Range in relation to the Object"],
  ["SN 34.42-45", "Resolution in relation to the Object, Etc."],
  ["SN 34.46", "Resolution in relation to the Range"],
  ["SN 34.47-49", "Thoroughness in relation to the Range, Etc."],
  ["SN 34.50", "Thoroughness in relation to Resolution"],
  ["SN 34.51-52", "Thoroughness in relation to the Range, Etc."],
  ["SN 34.53", "Persistence in relation to Thoroughness"],
  ["SN 34.54", "Suitability in relation to Thoroughness"],
  ["SN 34.55", "Suitability in relation to Persistence"],
  ["SN 35.1", "The Internal as Impermanent"],
  ["SN 35.2", "The Internal as Suffering"],
  ["SN 35.3", "The Internal as Nonself"],
  ["SN 35.4", "The External as Impermanent"],
  ["SN 35.5", "The External as Suffering"],
  ["SN 35.6", "The External as Nonself"],
  ["SN 35.7", "The Internal as Impermanent in the Three Times"],
  ["SN 35.8", "The Internal as Suffering in the Three Times"],
  ["SN 35.9", "The Internal as Nonself in the Three Times"],
  ["SN 35.10-12", "The Ext. as Impermanent...Three Times, Etc."],
  ["SN 35.13", "Before My Enlightenment [1]"],
  ["SN 35.14", "Before My Enlightenment [2]"],
  ["SN 35.15", "Seeking Gratification [1] 1"],
  ["SN 35.16", "Seeking Gratification [2]"],
  ["SN 35.17", "If There Were No [1]"],
  ["SN 35.18", "If There Were No [2]"],
  ["SN 35.19", "Delight [1]"],
  ["SN 35.20", "Delight [2]"],
  ["SN 35.21", "Arising of Suffering [1]"],
  ["SN 35.22", "Arising of Suffering [2]"],
  ["SN 35.23", "The All"],
  ["SN 35.24", "Abandonment [1]"],
  ["SN 35.25", "Abandonment [2]"],
  ["SN 35.26", "Full Understanding [1]"],
  ["SN 35.27", "Full Understanding [2]"],
  ["SN 35.28", "Burning"],
  ["SN 35.29", "Weighed Down"],
  ["SN 35.30", "Appropriate for Uprooting"],
  ["SN 35.31", "Suitable for Uprooting [1]"],
  ["SN 35.32", "Suitable for Uprooting [2]"],
  ["SN 35.33", "Subject to Birth"],
  ["SN 35.34-42", "Subject to Aging, Etc."],
  ["SN 35.43-52", "Impermanent, Etc."],
  ["SN 35.53", "Abandoning Ignorance"],
  ["SN 35.54", "Abandoning the Fetters"],
  ["SN 35.55", "Uprooting the Fetters"],
  ["SN 35.56-59", "Abandoning the Taints, Etc."],
  ["SN 35.60", "The Full Understanding of All Clinging"],
  ["SN 35.61", "The Exhaustion of All Clinging [1]"],
  ["SN 35.62", "The Exhaustion of All Clinging [2]"],
  ["SN 35.63", "Migajāla [1]"],
  ["SN 35.64", "Migajāla [2]"],
  ["SN 35.65", "Samiddhi [1]"],
  ["SN 35.66", "Samiddhi [2]"],
  ["SN 35.67", "Samiddhi [3]"],
  ["SN 35.68", "Samiddhi [4]"],
  ["SN 35.69", "Upasena"],
  ["SN 35.70", "Upavāṇa"],
  ["SN 35.71", "The Six Bases for Contact [1]"],
  ["SN 35.72", "The Six Bases for Contact [2]"],
  ["SN 35.73", "The Six Bases for Contact [3]"],
  ["SN 35.74", "Sick [1]"],
  ["SN 35.75", "Sick [2]"],
  ["SN 35.76", "Rādha [1]"],
  ["SN 35.77", "Rādha [2]"],
  ["SN 35.78", "Rādha [3]"],
  ["SN 35.79", "Abandoning Ignorance [1]"],
  ["SN 35.80", "Abandoning Ignorance [2]"],
  ["SN 35.81", "A Number of Bhikkhus"],
  ["SN 35.82", "The World"],
  ["SN 35.83", "Phagguna"],
  ["SN 35.84", "Subject to Disintegration"],
  ["SN 35.85", "Empty Is the World"],
  ["SN 35.86", "The Dhamma in Brief"],
  ["SN 35.87", "Channa"],
  ["SN 35.88", "Puṇṇa"],
  ["SN 35.89", "Bāhiya"],
  ["SN 35.90", "Being Stirred [1]"],
  ["SN 35.91", "Being Stirred [2]"],
  ["SN 35.92", "The Dyad [1]"],
  ["SN 35.93", "The Dyad [2]"],
  ["SN 35.94", "Untamed, Unguarded"],
  ["SN 35.95", "Māluṅkyaputta"],
  ["SN 35.96", "Decline"],
  ["SN 35.97", "Dwelling Negligently"],
  ["SN 35.98", "Restraint"],
  ["SN 35.99", "Concentration"],
  ["SN 35.100", "Seclusion"],
  ["SN 35.101", "Not Yours [1]"],
  ["SN 35.102", "Not Yours [2]"],
  ["SN 35.103", "Uddaka"],
  ["SN 35.104", "Secure from Bondage"],
  ["SN 35.105", "By Clinging"],
  ["SN 35.106", "The Origin of Suffering"],
  ["SN 35.107", "The Origin of the World"],
  ["SN 35.108", "I Am Superior"],
  ["SN 35.109", "Things That Fetter"],
  ["SN 35.110", "Things That Can Be Clung To"],
  ["SN 35.111", "Fully Understanding [1]"],
  ["SN 35.112", "Fully Understanding [2]"],
  ["SN 35.113", "Listening In"],
  ["SN 35.114", "Māra's Snare [1]"],
  ["SN 35.115", "Māra's Snare [2]"],
  ["SN 35.116", "Going to the End of the World"],
  ["SN 35.117", "Cords of Sensual Pleasure"],
  ["SN 35.118", "Sakka's Question"],
  ["SN 35.119", "Pañcasikha"],
  ["SN 35.120", "Sāriputta"],
  ["SN 35.121", "Exhortation to Rāhula"],
  ["SN 35.122", "Things That Fetter"],
  ["SN 35.123", "Things That Can Be Clung To"],
  ["SN 35.124", "At Vesālī"],
  ["SN 35.125", "Among the Vajjians"],
  ["SN 35.126", "At Nālandā"],
  ["SN 35.127", "Bhāradvāja"],
  ["SN 35.128", "Soṇa"],
  ["SN 35.129", "Ghosita"],
  ["SN 35.130", "Hāliddakāni"],
  ["SN 35.131", "Nakulapitā"],
  ["SN 35.132", "Lohicca"],
  ["SN 35.133", "Verahaccāni"],
  ["SN 35.134", "At Devadaha"],
  ["SN 35.135", "The Opportunity"],
  ["SN 35.136", "Delight in Forms [1]"],
  ["SN 35.137", "Delight in Forms [2]"],
  ["SN 35.138", "Not Yours [1]"],
  ["SN 35.139", "Not Yours [2]"],
  ["SN 35.140", "Impermanent with Cause (Internal)"],
  ["SN 35.141", "Suffering with Cause (Internal)"],
  ["SN 35.142", "Nonself with Cause (Internal)"],
  ["SN 35.143-45", " Impermanent with Cause, Etc. (External)"],
  ["SN 35.146", "Kamma"],
  ["SN 35.147", "Suitable for Attaining Nibbana [1]"],
  ["SN 35.148", "Suitable for Attaining Nibbāna (2-3)"],
  ["SN 35.150", "Suitable for Attaining Nibbāna [4]"],
  ["SN 35.151", "A Student"],
  ["SN 35.152", "For What Purpose the Holy Life?"],
  ["SN 35.153", "Is There a Method?"],
  ["SN 35.154", "Equipped with Faculties"],
  ["SN 35.155", "A Speaker on the Dhamma"],
  ["SN 35.156", "The Destruction of Delight [1]"],
  ["SN 35.157", "The Destruction of Delight [2]"],
  ["SN 35.158", "The Destruction of Delight [3]"],
  ["SN 35.159", "The Destruction of Delight [4]"],
  ["SN 35.160", "Jivaka's Mango Grove [1]"],
  ["SN 35.161", "Jivaka's Mango Grove [2]"],
  ["SN 35.162", "Koṭṭhita [1]"],
  ["SN 35.163", "Koṭṭhita [2]"],
  ["SN 35.164", "Koṭṭhita [3]"],
  ["SN 35.165", "Abandoning Wrong View"],
  ["SN 35.166", "Abandoning Identity View"],
  ["SN 35.167", "Abandoning the View of Self"],
  ["SN 35.168", "Desire for the Impermanent (Internal)"],
  ["SN 35.169", "Lust for the Impermanent (Internal)"],
  ["SN 35.170", "Desire and Lust for the Impermanent (Internal)"],
  ["SN 35.171-73", "Desire for Suffering (Internal), Etc."],
  ["SN 35.174-76", "Desire for Nonself (Internal), Etc."],
  ["SN 35.177-79", "Desire for the Impermanent (Ext.), Etc."],
  ["SN 35.180-82", "Desire for Suffering (External), Etc."],
  ["SN 35.183-85", "Desire for Nonself (External), Etc."],
  ["SN 35.186", "The Past as Impermanent (Internal)"],
  ["SN 35.187", "The Future as Impermanent (Internal)"],
  ["SN 35.188", "The Present as Impermanent (Internal)"],
  ["SN 35.189-91", "The Past, Etc., as Suffering (Internal)"],
  ["SN 35.192-94", "The Past, Etc., as Nonself (Internal)"],
  ["SN 35.195-97", "The Past, Etc., as Impermanent (Ext.)"],
  ["SN 35.198-200", "The Past, Etc., as Suffering (External)"],
  ["SN 35.201-3", "The Past, Etc., as Nonself (External)"],
  ["SN 35.204", "What Is Impermanent of the Past (Internal)"],
  ["SN 35.205", "What Is Impermanent of the Future (Internal)"],
  ["SN 35.206", "What Is Impermanent of the Present (Internal)"],
  ["SN 35.207-9", "What Is Suffering of the Past, Etc. (In.)"],
  ["SN 35.210-12", "What Is Nonself of the Past, Etc. (In.)"],
  ["SN 35.213-15", "What Is Impermanent ... Past, Etc. (Ext)"],
  ["SN 35.216-18", "What Is Suffering of the Past, Etc. (Ext.)"],
  ["SN 35.219-21", "What Is Nonself of the Past, Etc. (Ext.)"],
  ["SN 35.222", "The Bases as Impermanent (Internal)"],
  ["SN 35.223", "The Bases as Suffering (Internal)"],
  ["SN 35.224", "The Bases as Nonself (Internal)"],
  ["SN 35.225", "The Bases as Impermanent (External)"],
  ["SN 35.226", "The Bases as Suffering (External)"],
  ["SN 35.227", "The Bases as Nonself (External)"],
  ["SN 35.228", "The Ocean [1]"],
  ["SN 35.229", "The Ocean [2]"],
  ["SN 35.230", "The Fisherman Simile"],
  ["SN 35.231", "The Milk-Sap Tree"],
  ["SN 35.232", "Koṭṭhita"],
  ["SN 35.233", "Kāmabhū"],
  ["SN 35.234", "Udāyi"],
  ["SN 35.235", "The Exposition on Burning"],
  ["SN 35.236", "The Simile of Hands and Feet [1]"],
  ["SN 35.237", "The Simile of Hands and Feet [2]"],
  ["SN 35.238", "The Simile of the Vipers"],
  ["SN 35.239", "The Simile of the Chariot"],
  ["SN 35.240", "The Simile of the Tortoise"],
  ["SN 35.241", "The Simile of the Great Log [1]"],
  ["SN 35.242", "The Simile of the Great Log [2]"],
  ["SN 35.243", "Exposition on the Corrupted"],
  ["SN 35.244", "States That Entail Suffering"],
  ["SN 35.245", "The Kṁsuka Tree"],
  ["SN 35.246", "The Simile of the Lute"],
  ["SN 35.247", "The Simile of the Six Animals"],
  ["SN 35.248", "The Sheaf of Barley"],
  ["SN 36.1", "Concentration"],
  ["SN 36.2", "Pleasure"],
  ["SN 36.3", "Abandonment"],
  ["SN 36.4", "The Bottomless Abyss"],
  ["SN 36.5", "Should Be Seen"],
  ["SN 36.6", "The Dart"],
  ["SN 36.7", "The Sick Ward [1]"],
  ["SN 36.8", "The Sick Ward [2]"],
  ["SN 36.9", "Impermanent"],
  ["SN 36.10", "Rooted in Contact"],
  ["SN 36.11", "Alone"],
  ["SN 36.12", "The Sky [1]"],
  ["SN 36.13", "The Sky [2]"],
  ["SN 36.14", "The Guest House"],
  ["SN 36.15", "Ānanda [1]"],
  ["SN 36.16", "Ānanda [2]"],
  ["SN 36.17", "A Number of Bhikkhus"],
  ["SN 36.19", "Pañcakanga"],
  ["SN 36.20", "Bhikkhus"],
  ["SN 36.21", "Sīvaka"],
  ["SN 36.22", "The Theme of the Hundred and Eight"],
  ["SN 36.23", "A Certain Bhikkhu"],
  ["SN 36.24", "Before"],
  ["SN 36.25", "Knowledge"],
  ["SN 36.26", "A Number of Bhikkhus"],
  ["SN 36.27", "Ascetics and Brahmins [1]"],
  ["SN 36.28", "Ascetics and Brahmins [2]"],
  ["SN 36.29", "Ascetics and Brahmins [3]"],
  ["SN 36.30", "Simple Version"],
  ["SN 36.31", "Spiritual"],
  ["SN 37.1", "Agreeable and Disagreeable [1]"],
  ["SN 37.2", "Agreeable and Disagreeable [2]"],
  ["SN 37.3", "Peculiar"],
  ["SN 37.4", "Three Qualities"],
  ["SN 37.5", "Angry"],
  ["SN 37.6-13", "Malicious, Etc."],
  ["SN 37.14", "The Five"],
  ["SN 37.15", "Without Anger"],
  ["SN 37.16-23", "Without Malice, Etc."],
  ["SN 37.24", "The Five Precepts"],
  ["SN 37.25", "Confident"],
  ["SN 37.26", "Having Won Over"],
  ["SN 37.27", "Under Her Control"],
  ["SN 37.28", "One"],
  ["SN 37.29", "In That Respect"],
  ["SN 37.30", "They Expel"],
  ["SN 37.31", "The Cause"],
  ["SN 37.32", "Situations"],
  ["SN 37.33", "Confident"],
  ["SN 37.34", "Growth"],
  ["SN 38.1", "A Question on Nibbana"],
  ["SN 38.2", "Arahantship"],
  ["SN 38.3", "Proponents of Dhamma"],
  ["SN 38.4", "For What Purpose?"],
  ["SN 38.5", "Consolation"],
  ["SN 38.6", "Supreme Consolation"],
  ["SN 38.7", "Feeling"],
  ["SN 38.8", "Taints"],
  ["SN 38.9", "Ignorance"],
  ["SN 38.10", "Craving"],
  ["SN 38.11", "Floods"],
  ["SN 38.12", "Clinging"],
  ["SN 38.13", "Existence"],
  ["SN 38.14", "Suffering"],
  ["SN 38.15", "Identity"],
  ["SN 38.16", "Difficult to Do"],
  ["SN 39.1-16", "A Question on Nibbana, Etc."],
  ["SN 40.1", "The First Jhāna"],
  ["SN 40.2", "The Second Jhāna"],
  ["SN 40.3", "The Third Jhāna"],
  ["SN 40.4", "The Fourth Jhāna"],
  ["SN 40.5", "The Base of the Infinity of Space"],
  ["SN 40.6", "The Base of the Infinity of Consciousness"],
  ["SN 40.7", "The Base of Nothingness"],
  ["SN 40.8", "The Base of Neither-Perception-Nor-Nonperception"],
  ["SN 40.9", "The Signless"],
  ["SN 40.10", "Sakka"],
  ["SN 40.11", "Candana"],
  ["SN 41.1", "The Fetter"],
  ["SN 41.2", "Isidatta [1]"],
  ["SN 41.3", "Isidatta [2]"],
  ["SN 41.4", "Mahaka's Miracle"],
  ["SN 41.5", "Kamabhu [1]"],
  ["SN 41.6", "Kamabhu [2]"],
  ["SN 41.7", "Godatta"],
  ["SN 41.8", "Nigaṇṭha Nātaputta"],
  ["SN 41.9", "The Naked Ascetic Kassapa"],
  ["SN 41.10", "Seeing the Sick"],
  ["SN 42.1", "Caṇḍa"],
  ["SN 42.2", "Talaputa"],
  ["SN 42.3", "Yodhājīva"],
  ["SN 42.4", "Hatthāroha"],
  ["SN 42.5", "Assāroha"],
  ["SN 42.6", "Asibandhakaputta"],
  ["SN 42.7", "The Simile of the Field"],
  ["SN 42.8", "The Conch Blower"],
  ["SN 42.9", "Families"],
  ["SN 42.10", "Maṇicūḷaka"],
  ["SN 42.11", "Bhadraka"],
  ["SN 42.12", "Rāsiya"],
  ["SN 42.13", "Pāṭaliya"],
  ["SN 43.1", "Mindfulness Directed to the Body"],
  ["SN 43.2", "Serenity and Insight"],
  ["SN 43.3", "With Thought and Examination"],
  ["SN 43.4", "Emptiness Concentration"],
  ["SN 43.5", "Establishments of Mindfulness"],
  ["SN 43.6", "Right Strivings"],
  ["SN 43.7", "Bases for Spiritual Power"],
  ["SN 43.8", "Spiritual Faculties"],
  ["SN 43.9", "Powers"],
  ["SN 43.10", "Factors of Enlightenment"],
  ["SN 43.11", "The Eightfold Path"],
  ["SN 43.12", "The Unconditioned"],
  ["SN 43.13", "The Uninclined"],
  ["SN 43.14-43", "The Taintless, Etc."],
  ["SN 44.1", "Khemā"],
  ["SN 44.2", "Anurādha"],
  ["SN 44.3", "Sāriputta and Koṭṭhlta [1]"],
  ["SN 44.4", "Sāriputta and Koṭṭhlta [2]"],
  ["SN 44.5", "Sāriputta and Koṭṭhita [3]"],
  ["SN 44.6", "Sāriputta and Koṭṭhita [4]"],
  ["SN 44.7", "Moggallāna"],
  ["SN 44.8", "Vacchagotta"],
  ["SN 44.9", "The Debating Hall"],
  ["SN 44.10", "Ānanda (Is There a Self?)"],
  ["SN 44.11", "Sabhiya Kaccāna"],
  ["SN 45.1", "Ignorance"],
  ["SN 45.2", "Half the Holy Life"],
  ["SN 45.3", "Sariputta"],
  ["SN 45.4", "The Brahmin"],
  ["SN 45.5", "For What Purpose?"],
  ["SN 45.6", "A Certain Bhikkhu [1]"],
  ["SN 45.7", "A Certain Bhikkhu [2]"],
  ["SN 45.8", "Analysis"],
  ["SN 45.9", "The Spike"],
  ["SN 45.10", "Nandiya"],
  ["SN 45.11", "Dwelling [1]"],
  ["SN 45.12", "Dwelling [2]"],
  ["SN 45.13", "A Trainee"],
  ["SN 45.14", "Arising [1]"],
  ["SN 45.15", "Arising [2]"],
  ["SN 45.16", "Purified [1]"],
  ["SN 45.17", "Purified [2]"],
  ["SN 45.18", "The Cock's Park [1]"],
  ["SN 45.19", "The Cock's Park [2]"],
  ["SN 45.20", "The Cock's Park [3]"],
  ["SN 45.21", "Wrongness"],
  ["SN 45.22", "Unwholesome States"],
  ["SN 45.23", "The Way [1]"],
  ["SN 45.24", "The Way [2]"],
  ["SN 45.25", "The Inferior Person [1]"],
  ["SN 45.26", "The Inferior Person [2]"],
  ["SN 45.27", "The Pot"],
  ["SN 45.28", "Concentration"],
  ["SN 45.29", "Feeling"],
  ["SN 45.30", "Uttiya"],
  ["SN 45.31", "Practice [1]"],
  ["SN 45.32", "Practice [2]"],
  ["SN 45.33", "Neglected"],
  ["SN 45.34", "Going Beyond"],
  ["SN 45.35", "Asceticism [1]"],
  ["SN 45.36", "Asceticism [2]"],
  ["SN 45.37", "Brahminhood [1]"],
  ["SN 45.38", "Brahminhood [2]"],
  ["SN 45.39", "The Holy Life [1]"],
  ["SN 45.40", "The Holy Life [2]"],
  ["SN 45.41", "The Fading Away of Lust"],
  ["SN 45.42-48", "The Abandoning of the Fetters, Etc."],
  ["SN 45.49", "Good Friend"],
  ["SN 45.50-55", "Accomplishment in Virtue, Etc."],
  ["SN 45.56", "Good Friend"],
  ["SN 45.57-62", "Accomplishment in Virtue, Etc."],
  ["SN 45.63", "Good Friend"],
  ["SN 45.64-69", "Accomplishment in Virtue, Etc."],
  ["SN 45.70", "Good Friend"],
  ["SN 45.71-76", "Accomplishment in Virtue, Etc."],
  ["SN 45.77", "Good Friend"],
  ["SN 45.78-83", "Accomplishment in Virtue, Etc."],
  ["SN 45.84", "Good Friend"],
  ["SN 45.85-90", "Accomplishment in Virtue, Etc."],
  ["SN 45.91", "Slanting to the East [1]"],
  ["SN 45.92-96", "Slanting to the East (2-6)"],
  ["SN 45.97-102", "The Ocean"],
  ["SN 45.103-108", "Slanting to the East"],
  ["SN 45.109-114", "The Ocean"],
  ["SN 45.115-20", "Slanting to the East"],
  ["SN 45.121-26", "The Ocean"],
  ["SN 45.127-32", "Slanting to the East"],
  ["SN 45.133-38", "The Ocean"],
  ["SN 45.139", "The Tathagata"],
  ["SN 45.140", "The Footprint"],
  ["SN 45.141", "The Roof Peak"],
  ["SN 45.142", "Roots"],
  ["SN 45.143", "Heartwood"],
  ["SN 45.144", "Jasmine"],
  ["SN 45.145", "Monarch"],
  ["SN 45.146", "The Moon"],
  ["SN 45.147", "The Sun"],
  ["SN 45.148", "The Cloth"],
  ["SN 45.149", "Strenuous"],
  ["SN 45.150", "Seeds"],
  ["SN 45.151", "Nagas"],
  ["SN 45.152", "The Tree"],
  ["SN 45.153", "The Pot"],
  ["SN 45.154", "The Spike"],
  ["SN 45.155", "The Sky"],
  ["SN 45.156", "The Rain Cloud [1]"],
  ["SN 45.157", "The Rain Cloud [2]"],
  ["SN 45.158", "The Ship"],
  ["SN 45.159", "The Guest House"],
  ["SN 45.160", "The River"],
  ["SN 45.161", "Searches"],
  ["SN 45.162", "Discriminations"],
  ["SN 45.163", "Taints"],
  ["SN 45.164", "Existence"],
  ["SN 45.165", "Suffering"],
  ["SN 45.166", "Barrenness"],
  ["SN 45.167", "Stains"],
  ["SN 45.168", "Troubles"],
  ["SN 45.169", "Feelings"],
  ["SN 45.170", "Cravings"],
  ["SN 45.171", "Floods"],
  ["SN 45.172", "Bonds"],
  ["SN 45.173", "Clinging"],
  ["SN 45.174", "Knots"],
  ["SN 45.175", "Underlying Tendencies"],
  ["SN 45.176", "Cords of Sensual Pleasure"],
  ["SN 45.177", "Hindrances"],
  ["SN 45.178", "Aggregates Subject to Clinging"],
  ["SN 45.179", "Lower Fetters"],
  ["SN 45.180", "Higher Fetters"],
  ["SN 46.1", "The Himalayas"],
  ["SN 46.2", "The Body"],
  ["SN 46.3", "Virtue"],
  ["SN 46.4", "Clothes"],
  ["SN 46.5", "A Bhikkhu 157"],
  ["SN 46.6", "Kul).<;ialiya"],
  ["SN 46.7", "The Peaked House"],
  ["SN 46.8", "Upaval).a"],
  ["SN 46.9", "[1]"],
  ["SN 46.10", "[2]"],
  ["SN 46.11", "Living Beings"],
  ["SN 46.12", "The Simile of the Sun [1]"],
  ["SN 46.13", "The Simile of the Sun [2]"],
  ["SN 46.14", "Ill [1]"],
  ["SN 46.15", "Ill [2]"],
  ["SN 46.16", "Ill [3]"],
  ["SN 46.17", "Going Beyond"],
  ["SN 46.18", "Neglected"],
  ["SN 46.19", "Noble"],
  ["SN 46.20", "Revulsion"],
  ["SN 46.21", "To Enlightenment"],
  ["SN 46.22", "A Teaching"],
  ["SN 46.23", "A Basis"],
  ["SN 46.24", "Careless Attention"],
  ["SN 46.25", "Nondecline"],
  ["SN 46.26", "The Destruction of Craving"],
  ["SN 46.27", "The Cessation of Craving"],
  ["SN 46.28", "Partaking of Penetration"],
  ["SN 46.29", "One Thing"],
  ["SN 46.30", "Udayi"],
  ["SN 46.31", "Wholesome [1]"],
  ["SN 46.32", "Wholesome [2]"],
  ["SN 46.33", "Corruptions"],
  ["SN 46.34", "Noncorruptions"],
  ["SN 46.35", "Careful Attention"],
  ["SN 46.36", "Growth"],
  ["SN 46.37", "Obstructions"],
  ["SN 46.38", "Without Hindrances"],
  ["SN 46.39", "Trees"],
  ["SN 46.40", "Hindrances"],
  ["SN 46.41", "Discriminations"],
  ["SN 46.42", "Wheel-Turning Monarch"],
  ["SN 46.43", "Mara"],
  ["SN 46.44", "Unwise"],
  ["SN 46.45", "Wise"],
  ["SN 46.46", "Poor_"],
  ["SN 46.47", "Prosperous"],
  ["SN 46.48", "The Sun"],
  ["SN 46.49", "Internal Factor"],
  ["SN 46.50", "External Factor"],
  ["SN 46.51", "Nutriment"],
  ["SN 46.52", "A Method of Exposition"],
  ["SN 46.53", "Fire"],
  ["SN 46.54", "Accompanied by Lovingkindness"],
  ["SN 46.55", "Sari.garava"],
  ["SN 46.56", "Abhaya"],
  ["SN 46.57", "The Skeleton"],
  ["SN 46.58", "The Worm-Infested"],
  ["SN 46.59", "The Livid"],
  ["SN 46.60", "The Fissured"],
  ["SN 46.61", "The Bloated"],
  ["SN 46.62", "Lovingkindness"],
  ["SN 46.63", "Compassion"],
  ["SN 46.64", "Altruistic Joy"],
  ["SN 46.65", "Equanimity"],
  ["SN 46.66", "Breathing"],
  ["SN 46.67", "Foulness"],
  ["SN 46.68", "Death"],
  ["SN 46.69", "Repulsiveness of Food"],
  ["SN 46.70", "Nondelight"],
  ["SN 46.71", "Impermanence"],
  ["SN 46.72", "Suffering"],
  ["SN 46.73", "Nonself"],
  ["SN 46.74", "Abandonment"],
  ["SN 46.75", "Dispassion"],
  ["SN 46.76", "Cessation"],
  ["SN 46.77-88", "The River Ganges-Eastward, Etc."],
  ["SN 46.89-98", "The Tathagata, Etc."],
  ["SN 46.99-110", "Strenuous, Etc."],
  ["SN 46.111-120", "Searches, Etc."],
  ["SN 46.121-129", "Floods, Etc."],
  ["SN 46.130", "Higher Fetters"],
  ["SN 46.131", "The River Ganges-Eastward"],
  ["SN 46.132-42", "Slanting to the East, Etc."],
  ["SN 46.143-52", "The Tathagata , Etc."],
  ["SN 46.153-64", "Strenuous, Etc."],
  ["SN 46.165-74", "Searches, Etc."],
  ["SN 46.175-83", "Floods, Etc."],
  ["SN 46.184", "Higher Fetters 1"],
  ["SN 47.1", "Ambapāli"],
  ["SN 47.2", "Mindful"],
  ["SN 47.3", "A Bhikkhu"],
  ["SN 47.4", "At Sala"],
  ["SN 47.5", "A Heap of the Wholesome"],
  ["SN 47.6", "The Hawk"],
  ["SN 47.7", "The Monkey"],
  ["SN 47.8", "The Cook"],
  ["SN 47.9", "Ill"],
  ["SN 47.10", "The Bhikkhunis' Quarter"],
  ["SN 47.11", "A Great Man"],
  ["SN 47.12", "Nalanda"],
  ["SN 47.13", "Cunda"],
  ["SN 47.14", "Ukkacela"],
  ["SN 47.15", "Bahiya"],
  ["SN 47.16", "Uttiya"],
  ["SN 47.17", "Noble"],
  ["SN 47.18", "Brahma"],
  ["SN 47.19", "Sedaka"],
  ["SN 47.20", "The Most Beautiful Girl of the Land 1"],
  ["SN 47.21", "Virtue"],
  ["SN 47.22", "Duration"],
  ["SN 47.23", "Decline"],
  ["SN 47.24", "Simple Version"],
  ["SN 47.25", "A Certain Brahmin"],
  ["SN 47.26", "Partly"],
  ["SN 47.27", "Completely"],
  ["SN 47.28", "The World"],
  ["SN 47.29", "Sirivaḍḍha"],
  ["SN 47.30", "Manadinna"],
  ["SN 47.31", "Unheard Before"],
  ["SN 47.32", "Dispassion"],
  ["SN 47.33", "Neglected"],
  ["SN 47.34", "Development"],
  ["SN 47.35", "Mindful"],
  ["SN 47.36", "Final Knowledge"],
  ["SN 47.37", "Desire"],
  ["SN 47.38", "Full Understanding"],
  ["SN 47.39", "Development"],
  ["SN 47.40", "Analysis 1"],
  ["SN 47.41", "The Deathless"],
  ["SN 47.42", "Origination"],
  ["SN 47.43", "The Path"],
  ["SN 47.44", "Mindful"],
  ["SN 47.45", "A Heap of the Wholesome"],
  ["SN 47.46", "The Restraint of the Patimokkha"],
  ["SN 47.47", "Misconduct"],
  ["SN 47.48", "Friends"],
  ["SN 47.49", "Feelings"],
  ["SN 47.50", "Taints"],
  ["SN 47.51-62", "The River Ganges-Eastward, Etc."],
  ["SN 47.63-72", "The Tathagata, Etc."],
  ["SN 47.73-84", "Strenuous, Etc."],
  ["SN 47.85-94", "Searches, Etc."],
  ["SN 47.95-103", "Floods, Etc."],
  ["SN 47.104", "Higher Fetters"],
  ["SN 48.1", "Simple Version"],
  ["SN 48.2", "Stream-Enterer [1]"],
  ["SN 48.3", "Stream-Enterer [2]"],
  ["SN 48.4", "Arahant [1]"],
  ["SN 48.5", "Arahant [2]"],
  ["SN 48.6", "Ascetics and Brahmins [1]"],
  ["SN 48.7", "Ascetics and Brahmins [2]"],
  ["SN 48.8", "To Be Seen"],
  ["SN 48.9", "Analysis [1]"],
  ["SN 48.10", "Analysis [2]"],
  ["SN 48.11", "Obtainment"],
  ["SN 48.12", "In Brief [1]"],
  ["SN 48.13", "In Brief [2]"],
  ["SN 48.14", "In Brief [3]"],
  ["SN 48.15", "In Detail [1]"],
  ["SN 48.16", "In Detail [2]"],
  ["SN 48.17", "In Detail [3]"],
  ["SN 48.18", "Practising"],
  ["SN 48.19", "Equipped"],
  ["SN 48.20", "Destruction of the Taints"],
  ["SN 48.21", "Renewed Existence 1"],
  ["SN 48.22", "The Life Faculty"],
  ["SN 48.23", "The Faculty of Final Knowledge"],
  ["SN 48.24", "One-Seeder"],
  ["SN 48.25", "Simple Version"],
  ["SN 48.26", "Stream-Enterer"],
  ["SN 48.27", "Arahant"],
  ["SN 48.28", "Buddha"],
  ["SN 48.29", "Ascetics and Brahmins [1]"],
  ["SN 48.30", "Ascetics and Brahmins [2]"],
  ["SN 48.31", "Simple Version"],
  ["SN 48.32", "Stream-Enterer"],
  ["SN 48.33", "Arahant"],
  ["SN 48.34", "Ascetics and Brahmins [1]"],
  ["SN 48.35", "Ascetics and Brahmins [2]"],
  ["SN 48.36", "Analysis [1]"],
  ["SN 48.37", "Analysis [2]"],
  ["SN 48.38", "Analysis [3]"],
  ["SN 48.39", "The Simile of the Fire-Sticks"],
  ["SN 48.40", "Irregular Order"],
  ["SN 48.41", "Subject to Aging"],
  ["SN 48.42", "The Brahmin Unnabha"],
  ["SN 48.43", "Saketa"],
  ["SN 48.44", "The Eastern Gatehouse 1"],
  ["SN 48.45", "The Eastern Park [1]"],
  ["SN 48.46", "The Eastern Park [2]"],
  ["SN 48.47", "The Eastern Park [3]"],
  ["SN 48.48", "The Eastern Park [4]"],
  ["SN 48.49", "Pindola"],
  ["SN 48.50", "At Apaṇa"],
  ["SN 48.51", "Sala"],
  ["SN 48.52", "Mallikas"],
  ["SN 48.53", "A Trainee"],
  ["SN 48.54", "Footprints"],
  ["SN 48.55", "Heartwood"],
  ["SN 48.56", "Established"],
  ["SN 48.57", "Brahma Sahampati"],
  ["SN 48.58", "The Boar's Cave"],
  ["SN 48.59", "Arising [1]"],
  ["SN 48.60", "Arising [2]"],
  ["SN 48.61", "Fetters"],
  ["SN 48.62", "Underlying Tendencies"],
  ["SN 48.63", "Full Understanding"],
  ["SN 48.64", "The Destruction of the Taints"],
  ["SN 48.65", "Two Fruits"],
  ["SN 48.66", "Seven Benefits"],
  ["SN 48.67", "The Tree [1]"],
  ["SN 48.68", "The Tree [2]"],
  ["SN 48.69", "The Tree [3]"],
  ["SN 48.70", "The Tree [4]"],
  ["SN 48.71-12", "The River Ganges-Eastward, Etc."],
  ["SN 48.83-92", "The Tathagata, Etc."],
  ["SN 48.93-104", "Strenuous, Etc."],
  ["SN 48.105-14", "Searches, Etc."],
  ["SN 48.115-23", "Floods, Etc."],
  ["SN 48.124", "Higher Fetters"],
  ["SN 48.125-36", "The River Ganges-Eastward, Etc."],
  ["SN 48.137-46", "The Tathagata, Etc."],
  ["SN 48.147-58", "Strenuous, Etc."],
  ["SN 48.159-68", "Searches, Etc."],
  ["SN 48.169-77", "Floods, Etc. 1"],
  ["SN 48.178", "Higher Fetters"],
  ["SN 49.1-12", "The River Ganges-Eastward, Etc."],
  ["SN 49.13-22", "The Tathagata, Etc."],
  ["SN 49.23-34", "Strenuous, Etc."],
  ["SN 49.35-44", "Searches, Etc."],
  ["SN 49.45-53", "Floods, Etc."],
  ["SN 49.54", "Higher Fetters"],
  ["SN 50.1-12", "The River Ganges-Eastward, Etc."],
  ["SN 50.13-22", "The Tathagata, Etc."],
  ["SN 50.23-34", "Strenuous, Etc."],
  ["SN 50.35-44", "Searches, Etc."],
  ["SN 50.45-53", "Floods, Etc."],
  ["SN 50.54", "Higher Fetters"],
  ["SN 50.55-66", "The River Ganges-Eastward, Etc."],
  ["SN 50.67-76", "The Tathagata, Etc."],
  ["SN 50.77-88", "Strenuous, Etc."],
  ["SN 50.89-98", "Searches, Etc."],
  ["SN 50.99-107", "Floods, Etc."],
  ["SN 50.108", "Higher Fetters"],
  ["SN 51.1", "From the Near Shore"],
  ["SN 51.2", "Neglected"],
  ["SN 51.3", "Noble"],
  ["SN 51.4", "Revulsion"],
  ["SN 51.5", "In Part"],
  ["SN 51.6", "Completely"],
  ["SN 51.7", "Bhikkhus"],
  ["SN 51.8", "Buddha"],
  ["SN 51.9", "Knowledge"],
  ["SN 51.10", "The Shrine"],
  ["SN 51.11", "Before"],
  ["SN 51.12", "Of Great Fruit"],
  ["SN 51.13", "Concentration due to Desire"],
  ["SN 51.14", "Moggallana"],
  ["SN 51.15", "The Brahmin Uṇṇābha"],
  ["SN 51.16", "Ascetics and Brahmins [1]"],
  ["SN 51.17", "Ascetics and Brahmins [2]"],
  ["SN 51.18", "A Bhikkhu"],
  ["SN 51.19", "A Teaching"],
  ["SN 51.20", "Analysis"],
  ["SN 51.21", "The Path"],
  ["SN 51.22", "The Iron Ball"],
  ["SN 51.23", "A Bhikkhu"],
  ["SN 51.24", "Simple Version"],
  ["SN 51.25", "Fruits [1]"],
  ["SN 51.26", "Fruits [2]"],
  ["SN 51.27", "Ananda [1]"],
  ["SN 51.28", "Ananda [2]"],
  ["SN 51.29", "A Number of Bhikkhus [1]"],
  ["SN 51.30", "A Number of Bhikkhus [2]"],
  ["SN 51.31", "Moggallana"],
  ["SN 51.32", "The Tathagata"],
  ["SN 51.33-44", "The River Ganges-Eastward, Etc."],
  ["SN 51.45-54", "The Tathagata, Etc."],
  ["SN 51.55-66", "Strenuous, Etc."],
  ["SN 51.67-76", "Searches, Etc."],
  ["SN 51.77-85", "Floods, Etc."],
  ["SN 51.86", "Higher Fetters"],
  ["SN 52.1", "Alone [1]"],
  ["SN 52.2", "Alone [2]"],
  ["SN 52.3", "Sutanu"],
  ["SN 52.4", "The Thombush Grove [1]"],
  ["SN 52.5", "The Thombush Grove [2]"],
  ["SN 52.6", "The Thombush Grove [3]"],
  ["SN 52.7", "The Destruction of Craving"],
  ["SN 52.8", "The Sala[a-Tree Hut"],
  ["SN 52.9", "All, or Ambapali's Grove"],
  ["SN 52.10", "Gravely Ill"],
  ["SN 52.11", "A Thousand Aeons"],
  ["SN 52.12", "Spiritual Power"],
  ["SN 52.13", "The Divine Ear"],
  ["SN 52.14", "Encompassing the Mind"],
  ["SN 52.15", "The Possible"],
  ["SN 52.16", "The Undertaking of Kamma"],
  ["SN 52.17", "Leading Everywhere"],
  ["SN 52.18", "Diverse Elements"],
  ["SN 52.19", "Diverse Dispositions"],
  ["SN 52.20", "Degrees of the Faculties"],
  ["SN 52.21", "The Jhanas, Etc."],
  ["SN 52.22", "Past Abodes"],
  ["SN 52.23", "The Divine Eye"],
  ["SN 52.24", "The Destruction of the Taints"],
  ["SN 53.1-12", "The River Ganges-Eastward, Etc."],
  ["SN 53.13-22", "The Tathagata, Etc."],
  ["SN 53.23", "Strenuous, Etc."],
  ["SN 53.35-44", "Searches, Etc."],
  ["SN 53.45-53", "Floods, Etc."],
  ["SN 53.54", "Higher Fetters"],
  ["SN 54.1", "One Thing"],
  ["SN 54.2", "Factors of Enlightenment"],
  ["SN 54.3", "Simple Version"],
  ["SN 54.4", "Fruits [1]"],
  ["SN 54.5", "Fruits [2]"],
  ["SN 54.6", "Arṭṭha"],
  ["SN 54.7", "Mahākappina"],
  ["SN 54.8", "The Simile of the Lamp"],
  ["SN 54.9", "At Vesāli"],
  ["SN 54.10", "Kimbila"],
  ["SN 54.11", "At Icchanaitgala"],
  ["SN 54.12", "In Perplexity"],
  ["SN 54.13", "Ananda [1]"],
  ["SN 54.14", "Ananda [2]"],
  ["SN 54.15", "Bhikkhus [1]"],
  ["SN 54.16", "Bhikkhus [2]"],
  ["SN 54.17", "The Fetters"],
  ["SN 54.18", "The Underlying Tendencies"],
  ["SN 54.19", "The Course"],
  ["SN 54.20", "The Destruction of the Taints"],
  ["SN 55.1", "Wheel-Turning Monarch"],
  ["SN 55.2", "Grounded"],
  ["SN 55.3", "Dighavu"],
  ["SN 55.4", "Sariputta [1]"],
  ["SN 55.5", "Sariputta [2]"],
  ["SN 55.6", "The Chamberlains"],
  ["SN 55.7", "The People of Bamboo Gate"],
  ["SN 55.8", "The Brick Hall [1]"],
  ["SN 55.9", "The Brick Hall [2]"],
  ["SN 55.10", "The Brick Hall [3] 1"],
  ["SN 55.11", "The Thousand"],
  ["SN 55.12", "The Brahmins"],
  ["SN 55.13", "Ananda 1"],
  ["SN 55.14", "Bad Destination [1]"],
  ["SN 55.15", "Bad Destination [2]"],
  ["SN 55.16", "Friends and Colleagues (I)"],
  ["SN 55.17", "Friends and Colleagues [2]"],
  ["SN 55.18", "Visiting the Devas 1)"],
  ["SN 55.19", "Visiting the Devas [2]"],
  ["SN 55.20", "Visiting the Devas [3]"],
  ["SN 55.21", "Mahanama [1]"],
  ["SN 55.22", "Mahanama [2]"],
  ["SN 55.23", "Godha"],
  ["SN 55.24", "Sarakani [1]"],
  ["SN 55.25", "Sarakani [2]"],
  ["SN 55.26", "Anāthapiṇḍika [1]"],
  ["SN 55.27", "Anathapiry9-ika [2]"],
  ["SN 55.28", "[or Anāthapiṇḍika (3)]"],
  ["SN 55.29", "Fearful Animosities [2]"],
  ["SN 55.30", "The Licchavi"],
  ["SN 55.31", "Streams [1]"],
  ["SN 55.32", "Streams [2]"],
  ["SN 55.33", "Streams [3]"],
  ["SN 55.34", "Divine Tracks [1]"],
  ["SN 55.35", "Divine Tracks [2]"],
  ["SN 55.36", "Similar to the Devas"],
  ["SN 55.37", "Mahānāma"],
  ["SN 55.38", "Rain"],
  ["SN 55.39", "Kāḷigodhā"],
  ["SN 55.40", "Nandiya"],
  ["SN 55.41", "Streams of Merit [1]"],
  ["SN 55.42", "Streams of Merit 2)"],
  ["SN 55.43", "Streams of Merit [3]"],
  ["SN 55.44", "Rich [1]"],
  ["SN 55.45", "Rich [2]"],
  ["SN 55.46", "Simple Version"],
  ["SN 55.47", "Nandiya"],
  ["SN 55.48", "Bhaddiya"],
  ["SN 55.49", "Mahanama"],
  ["SN 55.50", "Factors"],
  ["SN 55.51", "With Verses"],
  ["SN 55.52", "One Who Spent the Rains"],
  ["SN 55.53", "Dhammadinna"],
  ["SN 55.54", "Ill"],
  ["SN 55.55", "The Fruit of Stream-Entry"],
  ["SN 55.56", "The Fruit of Once-Returning"],
  ["SN 55.57", "The Fruit of Nonreturning"],
  ["SN 55.58", "The Fruit of Arahantship"],
  ["SN 55.59", "The Obtaining of Wisdom"],
  ["SN 55.60", "The Growth of Wisdom"],
  ["SN 55.61", "The Expansion of Wisdom"],
  ["SN 55.62", "Greatness of Wisdom"],
  ["SN 55.63-74", "Extensiveness of Wisdom, Etc."],
  ["SN 56.1", "Concentration"],
  ["SN 56.2", "Seclusion"],
  ["SN 56.3", "Clansmen [1]"],
  ["SN 56.4", "Clansmen [2]"],
  ["SN 56.5", "Ascetics and Brahmins [1]"],
  ["SN 56.6", "Ascetics and Brahmins [2]"],
  ["SN 56.7", "Thoughts"],
  ["SN 56.8", "Reflection"],
  ["SN 56.9", "Disputatious Talk"],
  ["SN 56.10", "Pointless Talk"],
  ["SN 56.11", "Setting in Motion the Wheel of the Dhamma"],
  ["SN 56.12", "Tathagatas"],
  ["SN 56.13", "Aggregates"],
  ["SN 56.14", "Internal Sense Bases"],
  ["SN 56.15", "Remembrance [1]"],
  ["SN 56.16", "Remembrance [2]"],
  ["SN 56.17", "Ignorance"],
  ["SN 56.18", "True Knowledge"],
  ["SN 56.19", "Implications"],
  ["SN 56.20", "Actual"],
  ["SN 56.21", "Koṭigāma [1]"],
  ["SN 56.22", "Koṭigāma [2]"],
  ["SN 56.23", "The Perfectly Enlightened One"],
  ["SN 56.24", "Arahants"],
  ["SN 56.25", "The Destruction of the Taints"],
  ["SN 56.26", "Friends"],
  ["SN 56.27", "Actual"],
  ["SN 56.28", "The World"],
  ["SN 56.29", "To Be Fully Understood"],
  ["SN 56.30", "Gavampati"],
  ["SN 56.31", "The Siṁsapā Grove"],
  ["SN 56.32", "Acacia"],
  ["SN 56.33", "Stick"],
  ["SN 56.34", "Clothes"],
  ["SN 56.35", "A Hundred Spears"],
  ["SN 56.36", "Creatures"],
  ["SN 56.37", "The Sun [1]"],
  ["SN 56.38", "The Sun [2]"],
  ["SN 56.39", "lndra's Pillar"],
  ["SN 56.40", "Seeking an Argument"],
  ["SN 56.41", "Reflection about the World"],
  ["SN 56.42", "The Precipice"],
  ["SN 56.43", "The Great Conflagration"],
  ["SN 56.44", "Peaked House"],
  ["SN 56.45", "The Hair"],
  ["SN 56.46", "Darkness"],
  ["SN 56.47", "Yoke with a Hole [1]"],
  ["SN 56.48", "Yoke with a Hole [2]"],
  ["SN 56.49", "Sineru [1]"],
  ["SN 56.50", "Sineru [2]"],
  ["SN 56.51", "The Fingernail"],
  ["SN 56.52", "The Pond"],
  ["SN 56.53", "Water at the Confluence [1]"],
  ["SN 56.54", "Water at the Confluence [2]"],
  ["SN 56.55", "The Earth [1]"],
  ["SN 56.56", "The Earth [2]"],
  ["SN 56.57", "The Ocean [1]"],
  ["SN 56.58", "The Ocean [2]"],
  ["SN 56.59", "The Mountain [1]"],
  ["SN 56.60", "The Mountain [2]"],
  ["SN 56.61", "Elsewhere"],
  ["SN 56.62", "Outlying Countries"],
  ["SN 56.63", "Wisdom"],
  ["SN 56.64", "Wines and Liquors"],
  ["SN 56.65", "Water-Born"],
  ["SN 56.66", "Who Honour Mother"],
  ["SN 56.67", "Who Honour Father"],
  ["SN 56.68", "Who Honour Ascetics"],
  ["SN 56.69", "Who Honour Brahmins"],
  ["SN 56.70", "Who Respect Elders"],
  ["SN 56.71", "Killing Living Beings"],
  ["SN 56.72", "Taking What Is Not Given"],
  ["SN 56.73", "Sexual Misconduct"],
  ["SN 56.74", "False Speech"],
  ["SN 56.75", "Divisive Speech"],
  ["SN 56.76", "Harsh Speech"],
  ["SN 56.77", "Idle Chatter"],
  ["SN 56.78", "Seed Life"],
  ["SN 56.79", "Improper Times"],
  ["SN 56.80", "Scents and Unguents"],
  ["SN 56.81", "Dancing and Singing"],
  ["SN 56.82", "High Beds"],
  ["SN 56.83", "Gold and Silver"],
  ["SN 56.84", "Raw Grain"],
  ["SN 56.85", "Raw Meat"],
  ["SN 56.86", "Girls"],
  ["SN 56.87", "Slaves"],
  ["SN 56.88", "Goats and Sheep"],
  ["SN 56.89", "Fowl and Swine"],
  ["SN 56.90", "Elephants"],
  ["SN 56.91", "Fields"],
  ["SN 56.92", "Buying and Selling"],
  ["SN 56.93", "Messages"],
  ["SN 56.94", "False Weights"],
  ["SN 56.95", "Bribery"],
  ["SN 56.96", "Mutilating, Etc."],
  ["SN 56.102", "Passing Away as Humans [1]"],
  ["SN 56.103", "Passing Away as Humans [2]"],
  ["SN 56.104", "Passing Away as Humans [3]"],
  ["SN 56.105-7", "Passing Away as Humans (4-6)"],
  ["SN 56.108-10", "Passing Away as Devas (1-3)"],
  ["SN 56.111-13", "Passing Away as Devas (4-6)"],
  ["SN 56.114-16", "Passing Away from Hell (1-3)"],
  ["SN 56.117-19", "Passing Away from Hell (4-6)"],
  ["SN 56.120-22", "Passing Away ... Animal Realm (1-3)"],
  ["SN 56.123-25", "Passing Away ... Animal Realm (4-6)"],
  ["SN 56.126-28", "Passing Away .... Domain of Ghosts (1-3)"],
  ["SN 56.129", "Passing Away from the Domain of Ghosts [4]"],
  ["SN 56.130", "Passing Away from the Domain of Ghosts [5]"],
  ["SN 56.131", "Passing Away from the Domain of Ghosts [6]"],
  ["AN 1.1-10", "Obsession of the Mind"],
  ["AN 1.11-20", "Abandoning the Hindrances"],
  ["AN 1.21-30", "Unwieldy"],
  ["AN 1.31-40", "Untamed"],
  ["AN 1.41-50", "A Spike"],
  ["AN 1.51-60", "Luminous"],
  ["AN 1.61-70", "Arousal of Energy"],
  ["AN 1.71-81", "Good Friendship"],
  ["AN 1.82-97", "Heedlessness"],
  ["AN 1.98-139", "Internal"],
  ["AN 1.140-149", "Non-Dhamma"],
  ["AN 1.150-169", "Not an Offense"],
  ["AN 1.170-187", "One Person"],
  ["AN 1.188-267", "Foremost"],
  ["AN 1.268-295", "Impossible"],
  ["AN 1.296-377", "One Thing"],
  ["AN 1.378-393", "Qualities Engendering Confidence"],
  ["AN 1.394-574", "Finger Snap"],
  ["AN 1.575-615", "Mindfulness Directed to the Body"],
  ["AN 1.616-627", "The Deathless"],
  ["AN 2.1-10", "Entering upon the Rains"],
  ["AN 2.11-20", "Disciplinary Issues"],
  ["AN 2.21-31", "Fools"],
  ["AN 2.32-41", "Same-Minded"],
  ["AN 2.42-51", "Assemblies"],
  ["AN 2.52-63", "People"],
  ["AN 2.64-76", "Happiness"],
  ["AN 2.77-86", "With a Basis"],
  ["AN 2.87-97", "Dhamma"],
  ["AN 2.98-117", "Fools"],
  ["AN 2.118-129", "Desires"],
  ["AN 2.130-140", "Aspiring"],
  ["AN 2.141-150", "Gifts"],
  ["AN 2.151-162", "Munificence"],
  ["AN 2.163-179", "Meditative Attainment"],
  ["AN 2.180-229", "Anger"],
  ["AN 2.230-279", "Unwholesome Repetition Series"],
  ["AN 2.280-309", "Discipline Repetition Series"],
  ["AN 2.310-479", "Lust and So Forth Repetition Series"],
  ["AN 3.1", "Peril"],
  ["AN 3.2", "Characteristics"],
  ["AN 3.3", "Thinking"],
  ["AN 3.4", "Transgression"],
  ["AN 3.5", "Carelessly"],
  ["AN 3.6", "Unwholesome"],
  ["AN 3.7", "Blameworthy"],
  ["AN 3.8", "Afflictive"],
  ["AN 3.9", "Maimed"],
  ["AN 3.10", "Stains"],
  ["AN 3.11", "Well Known"],
  ["AN 3.12", "To Be Remembered"],
  ["AN 3.13", "A Bhikkhu"],
  ["AN 3.14", "Wheel-Turning"],
  ["AN 3.15", "Pacetana"],
  ["AN 3.16", "The Unmistaken"],
  ["AN 3.17", "Oneself"],
  ["AN 3.18", "Deva"],
  ["AN 3.19", "Shopkeeper (1)"],
  ["AN 3.20", "Shopkeeper (2)"],
  ["AN 3.21", "Saviṭṭha"],
  ["AN 3.22", "Patients"],
  ["AN 3.23", "Volitional Activities"],
  ["AN 3.24", "Helpful"],
  ["AN 3.25", "Diamond"],
  ["AN 3.26", "To Be Associated With"],
  ["AN 3.27", "Disgust"],
  ["AN 3.28", "Speech Like Dung"],
  ["AN 3.29", "Blind"],
  ["AN 3.30", "Inverted"],
  ["AN 3.31", "Brahmā"],
  ["AN 3.32", "Ānanda"],
  ["AN 3.33", "Sāriputta"],
  ["AN 3.34", "Causes"],
  ["AN 3.35", "Hatthaka"],
  ["AN 3.36", "Messengers"],
  ["AN 3.37", "Kings (1)"],
  ["AN 3.38", "Kings (2)"],
  ["AN 3.39", "Delicate"],
  ["AN 3.40", "Authorities"],
  ["AN 3.41", "Present"],
  ["AN 3.42", "Cases"],
  ["AN 3.43", "Advantages"],
  ["AN 3.44", "Smooth Flow"],
  ["AN 3.45", "The Wise"],
  ["AN 3.46", "Virtuous"],
  ["AN 3.47", "Conditioned"],
  ["AN 3.48", "Mountains"],
  ["AN 3.49", "Ardor"],
  ["AN 3.50", "A Master Thief"],
  ["AN 3.51", "Two Brahmins (1)"],
  ["AN 3.52", "Two Brahmins (2)"],
  ["AN 3.53", "A Certain Brahmin"],
  ["AN 3.54", "A Wanderer"],
  ["AN 3.55", "Nibbāna"],
  ["AN 3.56", "Depopulation"],
  ["AN 3.57", "Vaccha"],
  ["AN 3.58", "Tikaṇṇa"],
  ["AN 3.59", "Jāṇussoṇī"],
  ["AN 3.60", "Saṅgārava"],
  ["AN 3.61", "Sectarian"],
  ["AN 3.62", "Perils"],
  ["AN 3.63", "Venāga"],
  ["AN 3.64", "Sarabha"],
  ["AN 3.65", "Kesaputtiya"],
  ["AN 3.66", "Sāḷha"],
  ["AN 3.67", "Bases of Talk"],
  ["AN 3.68", "Other Sects"],
  ["AN 3.69", "Roots"],
  ["AN 3.70", "Uposatha"],
  ["AN 3.71", "Channa"],
  ["AN 3.72", "Ājīvaka"],
  ["AN 3.73", "The Sakyan"],
  ["AN 3.74", "The Nigaṇṭha"],
  ["AN 3.75", "Should Be Encouraged"],
  ["AN 3.76", "Existence"],
  ["AN 3.77", "Volition and Aspiration"],
  ["AN 3.78", "Setting Up"],
  ["AN 3.79", "Fragrance"],
  ["AN 3.80", "Abhibhū"],
  ["AN 3.81", "Ascetics"],
  ["AN 3.82", "The Donkey"],
  ["AN 3.83", "The Field"],
  ["AN 3.84", "The Young Vajji"],
  ["AN 3.85", "A Trainee"],
  ["AN 3.86", "The Process of Training (1)"],
  ["AN 3.87", "The Process of Training (2)"],
  ["AN 3.88", "The Process of Training (3)"],
  ["AN 3.89", "The Trainings (1)"],
  ["AN 3.90", "The Trainings (2)"],
  ["AN 3.91", "Paṅkadhā"],
  ["AN 3.92", "Urgent"],
  ["AN 3.93", "Solitude"],
  ["AN 3.94", "Autumn"],
  ["AN 3.95", "Assemblies"],
  ["AN 3.96", "Thoroughbred (1)"],
  ["AN 3.97", "Thoroughbred (2)"],
  ["AN 3.98", "Thoroughbred (3)"],
  ["AN 3.99", "Bark Fabric"],
  ["AN 3.100", "A Lump of Salt"],
  ["AN 3.101", "The Soil Remover"],
  ["AN 3.102", "A Goldsmith"],
  ["AN 3.103", "Before"],
  ["AN 3.104", "Gratification (1)"],
  ["AN 3.105", "Gratification (2)"],
  ["AN 3.106", "Ascetics"],
  ["AN 3.107", "Wailing"],
  ["AN 3.108", "No Satiation"],
  ["AN 3.109", "Peaked Roof (1)"],
  ["AN 3.110", "Peaked Roof (2)"],
  ["AN 3.111", "Causes (1)"],
  ["AN 3.112", "Causes (2)"],
  ["AN 3.113", "Bound for the Plane of Misery"],
  ["AN 3.114", "Rare"],
  ["AN 3.115", "Immeasurable"],
  ["AN 3.116", "Imperturbable"],
  ["AN 3.117", "Failures and Accomplishments"],
  ["AN 3.118", "Dice"],
  ["AN 3.119", "Activity"],
  ["AN 3.120", "Purity (1)"],
  ["AN 3.121", "Purity (2)"],
  ["AN 3.122", "Sagacity"],
  ["AN 3.123", "Kusinārā"],
  ["AN 3.124", "Arguments"],
  ["AN 3.125", "Gotamaka"],
  ["AN 3.126", "Bharaṇḍu"],
  ["AN 3.127", "Hatthaka"],
  ["AN 3.128", "Pollution"],
  ["AN 3.129", "Anuruddha (1)"],
  ["AN 3.130", "Anuruddha (2)"],
  ["AN 3.131", "Concealed"],
  ["AN 3.132", "Line Etched in Stone"],
  ["AN 3.133", "A Warrior"],
  ["AN 3.134", "Assemblies"],
  ["AN 3.135", "A Friend"],
  ["AN 3.136", "Arising"],
  ["AN 3.137", "A Hair Blanket"],
  ["AN 3.138", "Accomplishment"],
  ["AN 3.139", "Growth"],
  ["AN 3.140", "Horses (1)"],
  ["AN 3.141", "Horses (2)"],
  ["AN 3.142", "Horses (3)"],
  ["AN 3.143", "The Peacock Sanctuary (1)"],
  ["AN 3.144", "The Peacock Sanctuary (2)"],
  ["AN 3.145", "The Peacock Sanctuary (3)"],
  ["AN 3.146", "Unwholesome"],
  ["AN 3.147", "Blameworthy"],
  ["AN 3.148", "Unrighteous"],
  ["AN 3.149", "Impure"],
  ["AN 3.150", "Maimed (1)"],
  ["AN 3.151", "Maimed (2)"],
  ["AN 3.152", "Maimed (3)"],
  ["AN 3.153", "Maimed (4)"],
  ["AN 3.154", "Homage"],
  ["AN 3.155", "A Good Morning"],
  ["AN 3.156", "[Establishments of Mindfulness]"],
  ["AN 3.157-162", "[Right Strivings"],
  ["AN 4.1", "Understood"],
  ["AN 4.2", "Fallen"],
  ["AN 4.3", "Maimed (1)"],
  ["AN 4.4", "Maimed (2)"],
  ["AN 4.5", "Along with the Stream"],
  ["AN 4.6", "One of Little Learning"],
  ["AN 4.7", "They Adorn"],
  ["AN 4.8", "Self-Confidence"],
  ["AN 4.9", "Craving"],
  ["AN 4.10", "Bonds"],
  ["AN 4.11", "Walking"],
  ["AN 4.12", "Virtuous Behavior"],
  ["AN 4.13", "Striving"],
  ["AN 4.14", "Restraint"],
  ["AN 4.15", "Proclamations"],
  ["AN 4.16", "Exquisiteness"],
  ["AN 4.17", "Wrong Courses (1)"],
  ["AN 4.18", "Wrong Courses (2)"],
  ["AN 4.19", "Wrong Courses (3)"],
  ["AN 4.20", "An Assigner of Meals"],
  ["AN 4.21", "Uruvelā (1)"],
  ["AN 4.22", "Uruvelā (2)"],
  ["AN 4.23", "The World"],
  ["AN 4.24", "Kāḷaka"],
  ["AN 4.25", "The Spiritual Life"],
  ["AN 4.26", "Deceivers"],
  ["AN 4.27", "Contentment"],
  ["AN 4.28", "Noble Lineages"],
  ["AN 4.29", "Dhamma Factors"],
  ["AN 4.30", "Wanderers"],
  ["AN 4.31", "The Wheel"],
  ["AN 4.32", "Sustaining"],
  ["AN 4.33", "The Lion"],
  ["AN 4.34", "Confidence"],
  ["AN 4.35", "Vassakāra"],
  ["AN 4.36", "Doṇa"],
  ["AN 4.37", "Non-Decline"],
  ["AN 4.38", "Drawn Back"],
  ["AN 4.39", "Ujjaya"],
  ["AN 4.40", "Udāyī"],
  ["AN 4.41", "Concentration"],
  ["AN 4.42", "Questions"],
  ["AN 4.43", "Anger (1)"],
  ["AN 4.44", "Anger (2)"],
  ["AN 4.45", "Rohitassa (1)"],
  ["AN 4.46", "Rohitassa (2)"],
  ["AN 4.47", "Far Apart"],
  ["AN 4.48", "Visākha"],
  ["AN 4.49", "Inversions"],
  ["AN 4.50", "Defilements"],
  ["AN 4.51", "Streams of Merit (1)"],
  ["AN 4.52", "Streams of Merit (2)"],
  ["AN 4.53", "Living Together (1)"],
  ["AN 4.54", "Living Together (2)"],
  ["AN 4.55", "The Same in Living (1)"],
  ["AN 4.56", "The Same in Living (2)"],
  ["AN 4.57", "Suppavāsā"],
  ["AN 4.58", "Sudatta"],
  ["AN 4.59", "Food"],
  ["AN 4.60", "The Layperson’s Proper Practice"],
  ["AN 4.61", "Worthy Deeds"],
  ["AN 4.62", "Freedom from Debt"],
  ["AN 4.63", "With Brahmā"],
  ["AN 4.64", "Hell"],
  ["AN 4.65", "Form"],
  ["AN 4.66", "Lustful"],
  ["AN 4.67", "Snakes"],
  ["AN 4.68", "Devadatta"],
  ["AN 4.69", "Striving"],
  ["AN 4.70", "Unrighteous"],
  ["AN 4.71", "Striving"],
  ["AN 4.72", "View"],
  ["AN 4.73", "The Bad Person: The Bride"],
  ["AN 4.74", "Foremost (1)"],
  ["AN 4.75", "Foremost"],
  ["AN 4.76", "Kusinārā"],
  ["AN 4.77", "Inconceivable Matters"],
  ["AN 4.78", "Offerings"],
  ["AN 4.79", "Business"],
  ["AN 4.80", "Kamboja"],
  ["AN 4.81", "The Destruction of Life"],
  ["AN 4.82", "False Speech"],
  ["AN 4.83", "Dispraise"],
  ["AN 4.84", "Anger"],
  ["AN 4.85", "Darkness"],
  ["AN 4.86", "Bent Down"],
  ["AN 4.87", "The Son"],
  ["AN 4.88", "Fetters"],
  ["AN 4.89", "View"],
  ["AN 4.90", "Aggregates"],
  ["AN 4.91", "Asuras"],
  ["AN 4.92", "Concentration (1)"],
  ["AN 4.93", "Concentration (2)"],
  ["AN 4.94", "Concentration (3)• 95 Cremation Brand"],
  ["AN 4.96", "Lust"],
  ["AN 4.97", "Quick-Witted"],
  ["AN 4.98", "One’s Own Welfare"],
  ["AN 4.99", "Training Rules"],
  ["AN 4.100", "Potaliya"],
  ["AN 4.101", "Clouds (1)"],
  ["AN 4.102", "Clouds (2)"],
  ["AN 4.103", "Pots"],
  ["AN 4.104", "Pools of Water"],
  ["AN 4.105", "Mangoes"],
  ["AN 4.106", "[Mangoes]"],
  ["AN 4.107", "Mice"],
  ["AN 4.108", "Bulls"],
  ["AN 4.109", "Trees"],
  ["AN 4.110", "Vipers"],
  ["AN 4.111", "Kesi"],
  ["AN 4.112", "Speed"],
  ["AN 4.113", "Goad"],
  ["AN 4.114", "Bull Elephant"],
  ["AN 4.115", "Deeds"],
  ["AN 4.116", "Heedfulness"],
  ["AN 4.117", "Guarding"],
  ["AN 4.118", "Inspiring"],
  ["AN 4.119", "Perils (1)"],
  ["AN 4.120", "Perils (2)"],
  ["AN 4.121", "Self-Reproach"],
  ["AN 4.122", "Waves"],
  ["AN 4.123", "Difference (1)"],
  ["AN 4.124", "Difference (2)"],
  ["AN 4.125", "Loving-Kindness (1)"],
  ["AN 4.126", "Loving-Kindness (2)"],
  ["AN 4.127", "Astounding (1)"],
  ["AN 4.128", "Astounding (2)"],
  ["AN 4.129", "Astounding (3)"],
  ["AN 4.130", "Astounding (4)"],
  ["AN 4.131", "Fetters"],
  ["AN 4.132", "Discernment"],
  ["AN 4.133", "Of Quick Understanding"],
  ["AN 4.134", "Effort"],
  ["AN 4.135", "Blameworthy"],
  ["AN 4.136", "Virtuous Behavior (1)"],
  ["AN 4.137", "Virtuous Behavior (2)"],
  ["AN 4.138", "Retreat"],
  ["AN 4.139", "Dhamma Speakers"],
  ["AN 4.140", "Speakers"],
  ["AN 4.141", "Splendors"],
  ["AN 4.142", "Radiances"],
  ["AN 4.143", "Lights"],
  ["AN 4.144", "Lusters"],
  ["AN 4.145", "Luminaries"],
  ["AN 4.146", "Times (1)"],
  ["AN 4.147", "Times (2)"],
  ["AN 4.148", "Conduct (1)"],
  ["AN 4.149", "Conduct (2)"],
  ["AN 4.150", "Cores"],
  ["AN 4.151", "Faculties"],
  ["AN 4.152", "Faith"],
  ["AN 4.153", "Wisdom"],
  ["AN 4.154", "Mindfulness"],
  ["AN 4.155", "Reflection"],
  ["AN 4.156", "Eon"],
  ["AN 4.157", "Illness"],
  ["AN 4.158", "Decline"],
  ["AN 4.159", "The Bhikkhunī"],
  ["AN 4.160", "A Fortunate One"],
  ["AN 4.161", "In Brief"],
  ["AN 4.162", "In Detail"],
  ["AN 4.163", "Unattractiveness"],
  ["AN 4.164", "Patient (1)"],
  ["AN 4.165", "Patient (2)"],
  ["AN 4.166", "Both"],
  ["AN 4.167", "Moggallāna"],
  ["AN 4.168", "Sāriputta"],
  ["AN 4.169", "Through Exertion"],
  ["AN 4.170", "In Conjunction"],
  ["AN 4.171", "Volition"],
  ["AN 4.172", "Analysis"],
  ["AN 4.173", "Koṭṭhita"],
  ["AN 4.174", "Ānanda"],
  ["AN 4.175", "Upavāṇa"],
  ["AN 4.176", "Aspiring"],
  ["AN 4.177", "Rāhula"],
  ["AN 4.178", "The Reservoir"],
  ["AN 4.179", "Nibbāna"],
  ["AN 4.180", "The Great References"],
  ["AN 4.181", "Warrior"],
  ["AN 4.182", "Guarantor"],
  ["AN 4.183", "Heard"],
  ["AN 4.184", "Fearless"],
  ["AN 4.185", "Brahmin Truths"],
  ["AN 4.186", "Acumen"],
  ["AN 4.187", "Vassakāra"],
  ["AN 4.188", "Upaka"],
  ["AN 4.189", "Realization"],
  ["AN 4.190", "Uposatha"],
  ["AN 4.191", "Followed by Ear"],
  ["AN 4.192", "Facts"],
  ["AN 4.193", "Bhaddiya"],
  ["AN 4.194", "Sāpūga"],
  ["AN 4.195", "Vappa"],
  ["AN 4.196", "Sāḷha"],
  ["AN 4.197", "Mallikā"],
  ["AN 4.198", "Self-Torment"],
  ["AN 4.199", "Craving"],
  ["AN 4.200", "Affection"],
  ["AN 4.201", "Training Rules"],
  ["AN 4.202", "Devoid of Faith"],
  ["AN 4.203", "Seven Actions"],
  ["AN 4.204", "Ten Actions"],
  ["AN 4.205", "Eightfold"],
  ["AN 4.206", "Tenfold Path"],
  ["AN 4.207", "Bad Character (1)"],
  ["AN 4.208", "Bad Character (2)"],
  ["AN 4.209", "Bad Character (3)"],
  ["AN 4.210", "Bad Character (4)"],
  ["AN 4.211", "Assembly"],
  ["AN 4.212", "View"],
  ["AN 4.213", "Ingratitude"],
  ["AN 4.214", "Destruction of Life"],
  ["AN 4.215", "Path (1)"],
  ["AN 4.216", "Path (2)"],
  ["AN 4.217", "Courses of Expression (1)"],
  ["AN 4.218", "Courses of Expression (2)"],
  ["AN 4.219", "Morally Shameless"],
  ["AN 4.220", "Unwise"],
  ["AN 4.221", "Misconduct"],
  ["AN 4.222", "View"],
  ["AN 4.223", "Ingratitude"],
  ["AN 4.224", "Destruction of Life"],
  ["AN 4.225", "Path (1)"],
  ["AN 4.226", "Path (2)"],
  ["AN 4.227", "Courses of Expression (1)"],
  ["AN 4.228", "Courses of Expression (2)"],
  ["AN 4.229", "Morally Shameless"],
  ["AN 4.230", "Unwise"],
  ["AN 4.231", "Poets"],
  ["AN 4.232", "In Brief"],
  ["AN 4.233", "In Detail"],
  ["AN 4.234", "Soṇakāyana"],
  ["AN 4.235", "Training Rules (1)"],
  ["AN 4.236", "Training Rules (2)"],
  ["AN 4.237", "Noble Path"],
  ["AN 4.238", "Enlightenment Factors"],
  ["AN 4.239", "Blameworthy"],
  ["AN 4.240", "Non-Afflictive"],
  ["AN 4.241", "Ascetics"],
  ["AN 4.242", "Benefits of a Good Person"],
  ["AN 4.243", "Schism"],
  ["AN 4.244", "Offenses"],
  ["AN 4.245", "Training"],
  ["AN 4.246", "Lying Down"],
  ["AN 4.247", "Worthy of a Stūpa"],
  ["AN 4.248", "The Growth of Wisdom"],
  ["AN 4.249", "Helpful"],
  ["AN 4.250", "Declarations (1)"],
  ["AN 4.251", "Declarations (2)"],
  ["AN 4.252", "Declarations (3)"],
  ["AN 4.253", "Declarations (4)"],
  ["AN 4.254", "Direct Knowledge"],
  ["AN 4.255", "Quests"],
  ["AN 4.256", "Sustaining"],
  ["AN 4.257", "Māluṅkyāputta"],
  ["AN 4.258", "Families"],
  ["AN 4.259", "Thoroughbred (1)"],
  ["AN 4.260", "Thoroughbred (2)"],
  ["AN 4.261", "Powers"],
  ["AN 4.262", "Forest"],
  ["AN 4.263", "Action"],
  ["AN 4.264", "The Destruction of Life"],
  ["AN 4.265-273", "Taking What Is Not Given"],
  ["AN 5.1", "In Brief"],
  ["AN 5.2", "In Detail"],
  ["AN 5.3", "Suffering"],
  ["AN 5.4", "As If Brought There"],
  ["AN 5.5", "Training"],
  ["AN 5.6", "Entering"],
  ["AN 5.7", "Sensual Pleasures"],
  ["AN 5.8", "Falling Away (1)"],
  ["AN 5.9", "Falling Away (2)"],
  ["AN 5.10", "Irreverent"],
  ["AN 5.11", "Not Heard Before"],
  ["AN 5.12", "Peak"],
  ["AN 5.13", "In Brief"],
  ["AN 5.14", "In Detail"],
  ["AN 5.15", "To Be Seen"],
  ["AN 5.16", "Peak"],
  ["AN 5.17", "Welfare (1)"],
  ["AN 5.18", "Welfare (2)"],
  ["AN 5.19", "Welfare (3)"],
  ["AN 5.20", "Welfare (4)"],
  ["AN 5.21", "Irreverent (1)"],
  ["AN 5.22", "Irreverent (2)"],
  ["AN 5.23", "Defilements"],
  ["AN 5.24", "Immoral"],
  ["AN 5.25", "Assisted"],
  ["AN 5.26", "Liberation"],
  ["AN 5.27", "Concentration"],
  ["AN 5.28", "Five-Factored"],
  ["AN 5.29", "Walking Meditation"],
  ["AN 5.30", "Nāgita"],
  ["AN 5.31", "Sumanā"],
  ["AN 5.32", "Cundī"],
  ["AN 5.33", "Uggaha"],
  ["AN 5.34", "Sīha"],
  ["AN 5.35", "The Benefits of Giving"],
  ["AN 5.36", "Timely"],
  ["AN 5.37", "Food"],
  ["AN 5.38", "Faith"],
  ["AN 5.39", "Son"],
  ["AN 5.40", "Sal Trees"],
  ["AN 5.41", "Utilization"],
  ["AN 5.42", "The Good Person"],
  ["AN 5.43", "Wished For"],
  ["AN 5.44", "The Giver of the Agreeable"],
  ["AN 5.45", "Streams"],
  ["AN 5.46", "Accomplishments"],
  ["AN 5.47", "Wealth"],
  ["AN 5.48", "Situations"],
  ["AN 5.49", "Kosala"],
  ["AN 5.50", "Nārada 623"],
  ["AN 5.51", "Obstructions"],
  ["AN 5.52", "A Heap"],
  ["AN 5.53", "Factors"],
  ["AN 5.54", "Occasions"],
  ["AN 5.55", "Mother and Son"],
  ["AN 5.56", "Preceptor"],
  ["AN 5.57", "Themes"],
  ["AN 5.58", "Licchavi Youths"],
  ["AN 5.59", "Gone Forth in Old Age (1)"],
  ["AN 5.60", "Gone Forth in Old Age (2)"],
  ["AN 5.61", "Perceptions (1)"],
  ["AN 5.62", "Perceptions (2)"],
  ["AN 5.63", "Growth (1)"],
  ["AN 5.64", "Growth (2)"],
  ["AN 5.65", "Discussion"],
  ["AN 5.66", "Way of Life"],
  ["AN 5.67", "Bases for Psychic Potency (1)"],
  ["AN 5.68", "Bases for Psychic Potency (2)"],
  ["AN 5.69", "Disenchantment"],
  ["AN 5.70", "Destruction of the Taints"],
  ["AN 5.71", "Liberation of Mind (1)"],
  ["AN 5.72", "Liberation of Mind (2)"],
  ["AN 5.73", "One Who Dwells in the Dhamma (1)"],
  ["AN 5.74", "One Who Dwells in the Dhamma (2)"],
  ["AN 5.75", "Warriors (1)"],
  ["AN 5.76", "Warriors (2)"],
  ["AN 5.77", "Future Perils (1)"],
  ["AN 5.78", "Future Perils (2)"],
  ["AN 5.79", "Future Perils (3)"],
  ["AN 5.80", "Future Perils (4)"],
  ["AN 5.81", "Provoking Lust"],
  ["AN 5.82", "Devoid of Lust"],
  ["AN 5.83", "A Schemer"],
  ["AN 5.84", "Devoid of Faith"],
  ["AN 5.85", "Cannot Patiently Endure"],
  ["AN 5.86", "Analytical Knowledges"],
  ["AN 5.87", "Virtuous"],
  ["AN 5.88", "An Elder"],
  ["AN 5.89", "A Trainee (1)"],
  ["AN 5.90", "A Trainee (2)"],
  ["AN 5.91", "Accomplishments (1)"],
  ["AN 5.92", "Accomplishments (2)"],
  ["AN 5.93", "Declarations"],
  ["AN 5.94", "Dwelling at Ease"],
  ["AN 5.95", "The Unshakable"],
  ["AN 5.96", "What One Has Learned"],
  ["AN 5.97", "Talk"],
  ["AN 5.98", "A Forest Dweller"],
  ["AN 5.99", "The Lion"],
  ["AN 5.100", "Kakudha"],
  ["AN 5.101", "Timidity"],
  ["AN 5.102", "Suspected"],
  ["AN 5.103", "A Thief"],
  ["AN 5.104", "Delicate"],
  ["AN 5.105", "At Ease"],
  ["AN 5.106", "Ānanda"],
  ["AN 5.107", "Virtuous Behavior"],
  ["AN 5.108", "One Beyond Training"],
  ["AN 5.109", "At Home in the Four Quarters"],
  ["AN 5.110", "Forest"],
  ["AN 5.111", "A Visitor of Families"],
  ["AN 5.112", "An Attendant Monk"],
  ["AN 5.113", "Concentration"],
  ["AN 5.114", "Andhakavinda"],
  ["AN 5.115", "Miserly"],
  ["AN 5.116", "Praise"],
  ["AN 5.117", "Envious"],
  ["AN 5.118", "View"],
  ["AN 5.119", "Speech"],
  ["AN 5.120", "Effort"],
  ["AN 5.121", "Sick"],
  ["AN 5.122", "Establishment of Mindfulness"],
  ["AN 5.123", "An Attendant (1)"],
  ["AN 5.124", "An Attendant (2)"],
  ["AN 5.125", "Vitality (1)"],
  ["AN 5.126", "Vitality (2)"],
  ["AN 5.127", "Living Apart"],
  ["AN 5.128", "An Ascetic’s Happiness"],
  ["AN 5.129", "Lesions"],
  ["AN 5.130", "Accomplishments"],
  ["AN 5.131", "Continuing to Turn the Wheel (1)"],
  ["AN 5.132", "Continuing to Turn the Wheel (2)"],
  ["AN 5.133", "The King"],
  ["AN 5.134", "In Whatever Quarter"],
  ["AN 5.135", "Yearning (1)"],
  ["AN 5.136", "Yearning (2)"],
  ["AN 5.137", "Little Sleep"],
  ["AN 5.138", "A Consumer of Food"],
  ["AN 5.139", "Cannot Patiently Endure"],
  ["AN 5.140", "One Who Listens"],
  ["AN 5.141", "Having Given"],
  ["AN 5.142", "One Violates"],
  ["AN 5.143", "Sārandada"],
  ["AN 5.144", "Tikaṇḍakī"],
  ["AN 5.145", "Hell"],
  ["AN 5.146", "Friend"],
  ["AN 5.147", "A Bad Person"],
  ["AN 5.148", "A Good Person"],
  ["AN 5.149", "Temporarily Liberated (1)"],
  ["AN 5.150", "Temporarily Liberated (2)"],
  ["AN 5.151", "The Fixed Course of Rightness (1)"],
  ["AN 5.152", "The Fixed Course of Rightness (2)"],
  ["AN 5.153", "The Fixed Course of Rightness (3)"],
  ["AN 5.154", "Decline of the Good Dhamma (1)"],
  ["AN 5.155", "Decline of the Good Dhamma (2)"],
  ["AN 5.156", "Decline of the Good Dhamma (3)"],
  ["AN 5.157", "Wrongly Addressed Talk"],
  ["AN 5.158", "Timidity"],
  ["AN 5.159", "Udāyī"],
  ["AN 5.160", "Hard to Dispel"],
  ["AN 5.161", "Removing Resentment (1)"],
  ["AN 5.162", "Removing Resentment (2)"],
  ["AN 5.163", "Discussions"],
  ["AN 5.164", "Way of Life"],
  ["AN 5.165", "Asking Questions"],
  ["AN 5.166", "Cessation"],
  ["AN 5.167", "Reproving"],
  ["AN 5.168", "Virtuous Behavior"],
  ["AN 5.169", "Of Quick Apprehension"],
  ["AN 5.170", "Bhaddaji"],
  ["AN 5.171", "Timidity"],
  ["AN 5.172", "Self-Confidence"],
  ["AN 5.173", "Hell"],
  ["AN 5.174", "Enmities"],
  ["AN 5.175", "Caṇḍāla"],
  ["AN 5.176", "Rapture"],
  ["AN 5.177", "Trades"],
  ["AN 5.178", "Kings"],
  ["AN 5.179", "A Layman"],
  ["AN 5.180", "Gavesī"],
  ["AN 5.181", "Forest Dwellers"],
  ["AN 5.182-190", "Wearers of Rag-Robes"],
  ["AN 5.191", "Dogs"],
  ["AN 5.192", "Doṇa"],
  ["AN 5.193", "Saṅgārava"],
  ["AN 5.194", "Kāraṇapālī"],
  ["AN 5.195", "Piṅgiyānī"],
  ["AN 5.196", "Dreams"],
  ["AN 5.197", "Rain"],
  ["AN 5.198", "Speech"],
  ["AN 5.199", "Families"],
  ["AN 5.200", "Escape"],
  ["AN 5.201", "Kimbila"],
  ["AN 5.202", "Listening to the Dhamma"],
  ["AN 5.203", "Thoroughbred"],
  ["AN 5.204", "Powers"],
  ["AN 5.205", "Barrenness"],
  ["AN 5.206", "Bondages"],
  ["AN 5.207", "Rice Porridge"],
  ["AN 5.208", "Brushing"],
  ["AN 5.209", "Intonation"],
  ["AN 5.210", "With a Muddled Mind"],
  ["AN 5.211", "One Who Insults"],
  ["AN 5.212", "Arguments"],
  ["AN 5.213", "Virtuous Behavior"],
  ["AN 5.214", "Speaking Much"],
  ["AN 5.215", "Impatience (1)"],
  ["AN 5.216", "Impatience (2)"],
  ["AN 5.217", "Not Inspiring Confidence (1)"],
  ["AN 5.218", "Not Inspiring Confidence (2)"],
  ["AN 5.219", "Fire"],
  ["AN 5.220", "Madhurā"],
  ["AN 5.221", "Lengthy Wandering (1)"],
  ["AN 5.222", "Lengthy Wandering (2)"],
  ["AN 5.223", "Residing Too Long"],
  ["AN 5.224", "Miserly"],
  ["AN 5.225", "One Who Visits Families (1)"],
  ["AN 5.226", "One Who Visits Families (2)"],
  ["AN 5.227", "Wealth"],
  ["AN 5.228", "A Meal"],
  ["AN 5.229", "Snake (1)"],
  ["AN 5.230", "Snake (2)"],
  ["AN 5.231", "Not to Be Esteemed"],
  ["AN 5.232", "Pleasing"],
  ["AN 5.233", "Beautifying"],
  ["AN 5.234", "Very Helpful"],
  ["AN 5.235", "Compassionate"],
  ["AN 5.236", "One Who Deserves Dispraise (1)"],
  ["AN 5.237", "One Who Deserves Dispraise (2)"],
  ["AN 5.238", "One Who Deserves Dispraise (3)"],
  ["AN 5.239", "Miserliness (1)"],
  ["AN 5.240", "Miserliness (2)"],
  ["AN 5.241", "Misconduct"],
  ["AN 5.242", "Bodily Misconduct"],
  ["AN 5.243", "Verbal Misconduct"],
  ["AN 5.244", "Mental Misconduct"],
  ["AN 5.245", "Another on Misconduct"],
  ["AN 5.246", "Another on Bodily Misconduct"],
  ["AN 5.247", "Another on Verbal Misconduct"],
  ["AN 5.248", "Another on Mental Misconduct"],
  ["AN 5.249", "A Charnel Ground"],
  ["AN 5.250", "Confidence in a Person"],
  ["AN 5.251", "Who May Give Full Ordination"],
  ["AN 5.252", "Dependence"],
  ["AN 5.253", "Novice"],
  ["AN 5.254", "Miserliness"],
  ["AN 5.255", "Abandoning Miserliness"],
  ["AN 5.256", "First Jhāna"],
  ["AN 5.257-263", "Second Jhāna"],
  ["AN 5.264", "Another on the First Jhāna"],
  ["AN 5.265-271", "Another on the Second Jhāna"],
  ["AN 6.1", "Worthy of Gifts (1)"],
  ["AN 6.2", "Worthy of Gifts (2)"],
  ["AN 6.3", "Faculties"],
  ["AN 6.4", "Powers"],
  ["AN 6.5", "Thoroughbred (1)"],
  ["AN 6.6", "Thoroughbred (2)"],
  ["AN 6.7", "Thoroughbred (3)"],
  ["AN 6.8", "Unsurpassed Things"],
  ["AN 6.9", "Subjects of Recollection"],
  ["AN 6.10", "Mahānāma"],
  ["AN 6.11", "Cordiality (1)"],
  ["AN 6.12", "Cordiality (2)"],
  ["AN 6.13", "Escape"],
  ["AN 6.14", "A Good Death"],
  ["AN 6.15", "Regret"],
  ["AN 6.16", "Nakula"],
  ["AN 6.17", "Wholesome"],
  ["AN 6.18", "The Fish Dealer"],
  ["AN 6.19", "Mindfulness of Death (1)"],
  ["AN 6.20", "Mindfulness of Death (2)"],
  ["AN 6.21", "Sāmaka"],
  ["AN 6.22", "Non-Decline"],
  ["AN 6.23", "Peril"],
  ["AN 6.24", "Himalayas"],
  ["AN 6.25", "Recollection"],
  ["AN 6.26", "Kaccāna"],
  ["AN 6.27", "Occasion (1)"],
  ["AN 6.28", "Occasion (2)"],
  ["AN 6.29", "Udāyī"],
  ["AN 6.30", "Unsurpassed Things"],
  ["AN 6.31", "Trainee"],
  ["AN 6.32", "Non-Decline (1)"],
  ["AN 6.33", "Non-Decline (2)"],
  ["AN 6.34", "Moggallāna"],
  ["AN 6.35", "Pertain to True Knowledge"],
  ["AN 6.36", "Disputes"],
  ["AN 6.37", "Giving"],
  ["AN 6.38", "Self-Initiative"],
  ["AN 6.39", "Origination"],
  ["AN 6.40", "Kimbila"],
  ["AN 6.41", "A Block of Wood"],
  ["AN 6.42", "Nāgita"],
  ["AN 6.43", "The Nāga"],
  ["AN 6.44", "Migasālā"],
  ["AN 6.45", "Debt"],
  ["AN 6.46", "Cunda"],
  ["AN 6.47", "Directly Visible (1)"],
  ["AN 6.48", "Directly Visible (2)"],
  ["AN 6.49", "Khema"],
  ["AN 6.50", "Sense Faculties"],
  ["AN 6.51", "Ānanda"],
  ["AN 6.52", "Khattiya"],
  ["AN 6.53", "Heedfulness"],
  ["AN 6.54", "Dhammika"],
  ["AN 6.55", "Soṇa"],
  ["AN 6.56", "Phagguṇa"],
  ["AN 6.57", "Six Classes"],
  ["AN 6.58", "Taints"],
  ["AN 6.59", "Dārukammika"],
  ["AN 6.60", "Hatthi"],
  ["AN 6.61", "Middle"],
  ["AN 6.62", "Knowledge"],
  ["AN 6.63", "Penetrative"],
  ["AN 6.64", "Lion’s Roar"],
  ["AN 6.65", "Non-Returner"],
  ["AN 6.66", "Arahant"],
  ["AN 6.67", "Friends"],
  ["AN 6.68", "Delight in Company"],
  ["AN 6.69", "A Deity"],
  ["AN 6.70", "Concentration"],
  ["AN 6.71", "Capable of Realizing"],
  ["AN 6.72", "Strength"],
  ["AN 6.73", "First Jhāna (1)"],
  ["AN 6.74", "First Jhāna (2)"],
  ["AN 6.75", "In Suffering"],
  ["AN 6.76", "Arahantship"],
  ["AN 6.77", "Superior"],
  ["AN 6.78", "Happiness"],
  ["AN 6.79", "Achievement"],
  ["AN 6.80", "Greatness"],
  ["AN 6.81", "Hell (1)"],
  ["AN 6.82", "Hell (2)"],
  ["AN 6.83", "The Foremost State"],
  ["AN 6.84", "Nights"],
  ["AN 6.85", "Coolness"],
  ["AN 6.86", "Obstructions"],
  ["AN 6.87", "A Murderer"],
  ["AN 6.88", "One Wishes to Listen"],
  ["AN 6.89", "Without Having Abandoned"],
  ["AN 6.90", "Abandoned"],
  ["AN 6.91", "Incapable"],
  ["AN 6.92", "Cases (1)"],
  ["AN 6.93", "Cases (2)"],
  ["AN 6.94", "Cases (3)"],
  ["AN 6.95", "Cases (4)"],
  ["AN 6.96", "Manifestation"],
  ["AN 6.97", "Benefits"],
  ["AN 6.98", "Impermanent"],
  ["AN 6.99", "Suffering"],
  ["AN 6.100", "Non-Self"],
  ["AN 6.101", "Nibbāna"],
  ["AN 6.102", "Unlasting"],
  ["AN 6.103", "Uplifted Dagger"],
  ["AN 6.104", "Without Identification"],
  ["AN 6.105", "Existence"],
  ["AN 6.106", "Craving Chapters"],
  ["AN 6.107", "Lust"],
  ["AN 6.108", "Misconduct"],
  ["AN 6.109", "Thoughts"],
  ["AN 6.110", "Perceptions"],
  ["AN 6.111", "Elements"],
  ["AN 6.112", "Gratification"],
  ["AN 6.113", "Discontent"],
  ["AN 6.114", "Contentment"],
  ["AN 6.115", "Difficult to Correct"],
  ["AN 6.116", "Restlessness"],
  ["AN 6.117", "Contemplating the Body"],
  ["AN 6.118", "Contemplating the Body Internally"],
  ["AN 6.119", "Tapussa"],
  ["AN 6.120-139", "Bhallika"],
  ["AN 7.1", "Pleasing (1)"],
  ["AN 7.2", "Pleasing (2)"],
  ["AN 7.3", "Powers in Brief"],
  ["AN 7.4", "Powers in Detail"],
  ["AN 7.5", "Wealth in Brief"],
  ["AN 7.6", "Wealth in Detail"],
  ["AN 7.7", "Ugga"],
  ["AN 7.8", "Fetters"],
  ["AN 7.9", "Abandoning"],
  ["AN 7.10", "Miserliness"],
  ["AN 7.11", "Underlying Tendencies (1)"],
  ["AN 7.12", "Underlying Tendencies (2)"],
  ["AN 7.13", "Families"],
  ["AN 7.14", "Persons"],
  ["AN 7.15", "Similar to Those in Water"],
  ["AN 7.16", "Impermanence"],
  ["AN 7.17", "Suffering"],
  ["AN 7.18", "Non-Self"],
  ["AN 7.19", "Happiness"],
  ["AN 7.20", "Bases for [Being] “Ten-less”"],
  ["AN 7.21", "Sārandada"],
  ["AN 7.22", "Vassakāra"],
  ["AN 7.23", "Non-Decline (1)"],
  ["AN 7.24", "Non-Decline (2)"],
  ["AN 7.25", "Non-Decline (3)"],
  ["AN 7.26", "Non-Decline (4)"],
  ["AN 7.27", "Non-Decline (5)"],
  ["AN 7.28", "A Trainee"],
  ["AN 7.29", "Decline"],
  ["AN 7.30", "Failure"],
  ["AN 7.31", "Ruin"],
  ["AN 7.32", "Heedfulness"],
  ["AN 7.33", "Moral Shame"],
  ["AN 7.34", "Easy to Correct (1)"],
  ["AN 7.35", "Easy to Correct (2)"],
  ["AN 7.36", "A Friend (1)"],
  ["AN 7.37", "A Friend (2)"],
  ["AN 7.38", "Analytical Knowledges (1)"],
  ["AN 7.39", "Analytical Knowledges (2)"],
  ["AN 7.40", "Mastery (1)"],
  ["AN 7.41", "Mastery (2)"],
  ["AN 7.42", "Bases for [Being] “Ten-less” (1)"],
  ["AN 7.43", "Bases for [Being] “Ten-less” (2)"],
  ["AN 7.44", "Stations"],
  ["AN 7.45", "Accessories"],
  ["AN 7.46", "Fires"],
  ["AN 7.47", "Sacrifice"],
  ["AN 7.48", "Perceptions (1)"],
  ["AN 7.49", "Perceptions (2)"],
  ["AN 7.50", "Sexual Intercourse"],
  ["AN 7.51", "Union"],
  ["AN 7.52", "Giving"],
  ["AN 7.53", "Nandamātā"],
  ["AN 7.54", "Undeclared"],
  ["AN 7.55", "Destinations of Persons"],
  ["AN 7.56", "Tissa"],
  ["AN 7.57", "Sīha"],
  ["AN 7.58", "No Need to Hide"],
  ["AN 7.59", "Kimbila"],
  ["AN 7.60", "Seven Qualities"],
  ["AN 7.61", "Dozing"],
  ["AN 7.62", "Do Not Be Afraid of Merit"],
  ["AN 7.63", "Wives"],
  ["AN 7.64", "Anger"],
  ["AN 7.65", "Moral Shame"],
  ["AN 7.66", "Seven Suns"],
  ["AN 7.67", "Simile of the Fortress"],
  ["AN 7.68", "One Who Knows the Dhamma"],
  ["AN 7.69", "Pāricchattaka"],
  ["AN 7.70", "Honor"],
  ["AN 7.71", "Development"],
  ["AN 7.72", "Fire"],
  ["AN 7.73", "Sunetta"],
  ["AN 7.74", "Araka"],
  ["AN 7.75", "An Expert in the Discipline (1)"],
  ["AN 7.76", "An Expert in the Discipline (2)"],
  ["AN 7.77", "An Expert in the Discipline (3)"],
  ["AN 7.78", "An Expert in the Discipline (4)"],
  ["AN 7.79", "An Expert in the Discipline Is Resplendent (1)"],
  ["AN 7.80", "An Expert in the Discipline Is Resplendent (2)"],
  ["AN 7.81", "An Expert in the Discipline Is Resplendent (3)"],
  ["AN 7.82", "An Expert in the Discipline Is Resplendent (4)"],
  ["AN 7.83", "The Teaching"],
  ["AN 7.84", "Settlement"],
  ["AN 7.85", "A Bhikkhu"],
  ["AN 7.86", "An Ascetic"],
  ["AN 7.87", "A Brahmin"],
  ["AN 7.88", "A Scholar"],
  ["AN 7.89", "Washed"],
  ["AN 7.90", "A Master of Vedic Knowledge"],
  ["AN 7.91", "A Noble One"],
  ["AN 7.92", "An Arahant"],
  ["AN 7.93", "Character (1)"],
  ["AN 7.94", "Character (2)"],
  ["AN 7.95", "Contemplating Impermanence in the Eye"],
  ["AN 7.96-102", "Contemplating Suffering in the Eye"],
  ["AN 7.103-614", "Impermanence in the Ear"],
  ["AN 8.1", "Loving-Kindness"],
  ["AN 8.2", "Wisdom"],
  ["AN 8.3", "Pleasing (1)"],
  ["AN 8.4", "Pleasing (2)"],
  ["AN 8.5", "World (1)"],
  ["AN 8.6", "World (2)"],
  ["AN 8.7", "Devadatta’s Failing"],
  ["AN 8.8", "Uttara on Failing"],
  ["AN 8.9", "Nanda"],
  ["AN 8.10", "Trash"],
  ["AN 8.11", "Verañjā"],
  ["AN 8.12", "Sīha"],
  ["AN 8.13", "Thoroughbred"],
  ["AN 8.14", "Wild Colts"],
  ["AN 8.15", "Stains"],
  ["AN 8.16", "Mission"],
  ["AN 8.17", "Bondage (1)"],
  ["AN 8.18", "Bondage (2)"],
  ["AN 8.19", "Pahārāda"],
  ["AN 8.20", "Uposatha"],
  ["AN 8.21", "Ugga (1)"],
  ["AN 8.22", "Ugga (2)"],
  ["AN 8.23", "Hatthaka (1)"],
  ["AN 8.24", "Hatthaka (2)"],
  ["AN 8.25", "Mahānāma"],
  ["AN 8.26", "Jīvaka"],
  ["AN 8.27", "Powers (1)"],
  ["AN 8.28", "Powers (2)"],
  ["AN 8.29", "Inopportune Moments"],
  ["AN 8.30", "Anuruddha"],
  ["AN 8.31", "Giving (1)"],
  ["AN 8.32", "Giving (2)"],
  ["AN 8.33", "Grounds"],
  ["AN 8.34", "The Field"],
  ["AN 8.35", "Rebirth on Account of Giving"],
  ["AN 8.36", "Activity"],
  ["AN 8.37", "The Good Person’s Gifts"],
  ["AN 8.38", "The Good Person"],
  ["AN 8.39", "Streams"],
  ["AN 8.40", "Conducive"],
  ["AN 8.41", "In Brief"],
  ["AN 8.42", "In Detail"],
  ["AN 8.43", "Visākhā (1)"],
  ["AN 8.44", "Vāseṭṭha"],
  ["AN 8.45", "Bojjhā"],
  ["AN 8.46", "Anuruddha"],
  ["AN 8.47", "Visākhā (2)"],
  ["AN 8.48", "Nakula"],
  ["AN 8.49", "The Present World (1)"],
  ["AN 8.50", "The Present World (2)"],
  ["AN 8.51", "Gotamī"],
  ["AN 8.52", "Exhortation"],
  ["AN 8.53", "Brief"],
  ["AN 8.54", "Dīghajāṇu"],
  ["AN 8.55", "Ujjaya"],
  ["AN 8.56", "Peril"],
  ["AN 8.57", "Worthy of Offerings (1)"],
  ["AN 8.58", "Worthy of Offerings (2)"],
  ["AN 8.59", "Eight Persons (1)"],
  ["AN 8.60", "Eight Persons (2)"],
  ["AN 8.61", "Desire"],
  ["AN 8.62", "Able"],
  ["AN 8.63", "In Brief"],
  ["AN 8.64", "Gayā"],
  ["AN 8.65", "Overcoming"],
  ["AN 8.66", "Emancipations"],
  ["AN 8.67", "Declarations (1)"],
  ["AN 8.68", "Declarations (2)"],
  ["AN 8.69", "Assemblies"],
  ["AN 8.70", "Earthquakes"],
  ["AN 8.71", "Faith (1)"],
  ["AN 8.72", "Faith (2)"],
  ["AN 8.73", "Mindfulness of Death (1)"],
  ["AN 8.74", "Mindfulness of Death (2)"],
  ["AN 8.75", "Accomplishments (1)"],
  ["AN 8.76", "Accomplishments (2)"],
  ["AN 8.77", "Desire"],
  ["AN 8.78", "Able"],
  ["AN 8.79", "Decline"],
  ["AN 8.80", "Grounds for Laziness and Arousing Energy"],
  ["AN 8.81", "Mindfulness"],
  ["AN 8.82", "Puṇṇiya"],
  ["AN 8.83", "Rooted"],
  ["AN 8.84", "A Thief"],
  ["AN 8.85", "Designations"],
  ["AN 8.86", "Nāgita"],
  ["AN 8.87", "Almsbowl"],
  ["AN 8.88", "Lack of Confidence"],
  ["AN 8.89", "Reconciliation"],
  ["AN 8.90", "Behavior"],
  ["AN 9.1", "Enlightenment"],
  ["AN 9.2", "Support"],
  ["AN 9.3", "Meghiya"],
  ["AN 9.4", "Nandaka"],
  ["AN 9.5", "Powers"],
  ["AN 9.6", "Association"],
  ["AN 9.7", "Sutavā"],
  ["AN 9.8", "Sajjha"],
  ["AN 9.9", "Persons"],
  ["AN 9.10", "Worthy of Gifts"],
  ["AN 9.11", "Lion’s Roar"],
  ["AN 9.12", "With Residue Remaining"],
  ["AN 9.13", "Koṭṭhita"],
  ["AN 9.14", "Samiddhi"],
  ["AN 9.15", "Boil"],
  ["AN 9.16", "Perceptions"],
  ["AN 9.17", "Families"],
  ["AN 9.18", "Loving-Kindness"],
  ["AN 9.19", "Deities"],
  ["AN 9.20", "Velāma"],
  ["AN 9.21", "Respects"],
  ["AN 9.22", "Wild Colts"],
  ["AN 9.23", "Craving"],
  ["AN 9.24", "Beings"],
  ["AN 9.25", "Wisdom"],
  ["AN 9.26", "The Stone Pillar"],
  ["AN 9.27", "Enmity (1)"],
  ["AN 9.28", "Enmity (2)"],
  ["AN 9.29", "Resentment (1)"],
  ["AN 9.30", "Resentment (2)"],
  ["AN 9.31", "Progressive Cessation"],
  ["AN 9.32", "Dwellings (1)"],
  ["AN 9.33", "Dwellings (2)"],
  ["AN 9.34", "Nibbāna"],
  ["AN 9.35", "The Cow"],
  ["AN 9.36", "Jhāna"],
  ["AN 9.37", "Ānanda"],
  ["AN 9.38", "The Brahmins"],
  ["AN 9.39", "The Devas"],
  ["AN 9.40", "A Bull Elephant"],
  ["AN 9.41", "Tapussa"],
  ["AN 9.42", "Confinement"],
  ["AN 9.43", "Body Witness"],
  ["AN 9.44", "Wisdom"],
  ["AN 9.45", "Both Respects"],
  ["AN 9.46", "Directly Visible (1)"],
  ["AN 9.47", "Directly Visible (2)"],
  ["AN 9.48", "Nibbāna"],
  ["AN 9.49", "Final Nibbāna"],
  ["AN 9.50", "That Particular Respect"],
  ["AN 9.51", "In This Very Life"],
  ["AN 9.52", "Security (1)"],
  ["AN 9.53", "Security (2)"],
  ["AN 9.54", "The Deathless (1)"],
  ["AN 9.55", "The Deathless (2)"],
  ["AN 9.56", "The Fearless (1)"],
  ["AN 9.57", "The Fearless (2)"],
  ["AN 9.58", "Tranquility (1)"],
  ["AN 9.59", "Tranquility (2)"],
  ["AN 9.60", "Cessation"],
  ["AN 9.61", "Progressive Cessation"],
  ["AN 9.62", "Possible and Impossible"],
  ["AN 9.63", "The Training"],
  ["AN 9.64", "Hindrances"],
  ["AN 9.65", "Sensual Pleasure"],
  ["AN 9.66", "Aggregates"],
  ["AN 9.67", "Lower Fetters"],
  ["AN 9.68", "Destinations"],
  ["AN 9.69", "Miserliness"],
  ["AN 9.70", "Higher Fetters"],
  ["AN 9.71", "Mental Barrenness"],
  ["AN 9.72", "Bondage"],
  ["AN 9.73", "The Training"],
  ["AN 9.74-82", "Hindrances"],
  ["AN 9.83", "The Training"],
  ["AN 9.84-92", "Hindrances"],
  ["AN 10.1", "What Purpose?"],
  ["AN 10.2", "Volition"],
  ["AN 10.3", "Virtuous Behavior"],
  ["AN 10.4", "Proximate Cause"],
  ["AN 10.5", "Ānanda"],
  ["AN 10.6", "Concentration"],
  ["AN 10.7", "Sāriputta"],
  ["AN 10.8", "Faith"],
  ["AN 10.9", "Peaceful"],
  ["AN 10.10", "True Knowledges"],
  ["AN 10.11", "Lodging"],
  ["AN 10.12", "Five Factors"],
  ["AN 10.13", "Fetters"],
  ["AN 10.14", "Mental Barrenness"],
  ["AN 10.15", "Heedfulness"],
  ["AN 10.16", "Worthy of Gifts"],
  ["AN 10.17", "Protector (1)"],
  ["AN 10.18", "Protector (2)"],
  ["AN 10.19", "Abodes of the Noble Ones (1)"],
  ["AN 10.20", "Abodes of the Noble Ones (2)"],
  ["AN 10.21", "The Lion"],
  ["AN 10.22", "Doctrinal Principles"],
  ["AN 10.23", "Body"],
  ["AN 10.24", "Cunda"],
  ["AN 10.25", "Kasiṇas"],
  ["AN 10.26", "Kāḷī"],
  ["AN 10.27", "Great Questions (1)"],
  ["AN 10.28", "Great Questions (2)"],
  ["AN 10.29", "Kosala (1)"],
  ["AN 10.30", "Kosala (2)"],
  ["AN 10.31", "Upāli"],
  ["AN 10.32", "Suspending"],
  ["AN 10.33", "Adjudication"],
  ["AN 10.34", "Full Ordination"],
  ["AN 10.35", "Dependence"],
  ["AN 10.36", "Novice"],
  ["AN 10.37", "Schism (1)"],
  ["AN 10.38", "Schism (2)"],
  ["AN 10.39", "Ānanda (1)"],
  ["AN 10.40", "Ānanda (2)"],
  ["AN 10.41", "Disputes"],
  ["AN 10.42", "Roots (1)"],
  ["AN 10.43", "Roots (2)"],
  ["AN 10.44", "Kusinārā"],
  ["AN 10.45", "Entering"],
  ["AN 10.46", "Sakyans"],
  ["AN 10.47", "Mahāli"],
  ["AN 10.48", "Things"],
  ["AN 10.49", "Subsisting through the Body"],
  ["AN 10.50", "Arguments"],
  ["AN 10.51", "One’s Own Mind"],
  ["AN 10.52", "Sāriputta"],
  ["AN 10.53", "Standstill"],
  ["AN 10.54", "Serenity"],
  ["AN 10.55", "Decline"],
  ["AN 10.56", "Perceptions (1)"],
  ["AN 10.57", "Perceptions (2)"],
  ["AN 10.58", "Roots"],
  ["AN 10.59", "Going Forth"],
  ["AN 10.60", "Girimānanda"],
  ["AN 10.61", "Ignorance"],
  ["AN 10.62", "Craving"],
  ["AN 10.63", "Certainty"],
  ["AN 10.64", "Unwavering"],
  ["AN 10.65", "Happiness (1)"],
  ["AN 10.66", "Happiness (2)"],
  ["AN 10.67", "Naḷakapāna (1)"],
  ["AN 10.68", "Naḷakapāna (2)"],
  ["AN 10.69", "Topics of Discussion (1)"],
  ["AN 10.70", "Topics of Discussion (2)"],
  ["AN 10.71", "Wish"],
  ["AN 10.72", "Thorns"],
  ["AN 10.73", "Wished For"],
  ["AN 10.74", "Growth"],
  ["AN 10.75", "Migasālā"],
  ["AN 10.76", "Incapable"],
  ["AN 10.77", "The Crow"],
  ["AN 10.78", "The Nigaṇṭhas"],
  ["AN 10.79", "Grounds (1)"],
  ["AN 10.80", "Grounds (2)"],
  ["AN 10.81", "Bāhuna"],
  ["AN 10.82", "Ānanda"],
  ["AN 10.83", "Puṇṇiya"],
  ["AN 10.84", "Declaration"],
  ["AN 10.85", "The Boaster"],
  ["AN 10.86", "Final Knowledge"],
  ["AN 10.87", "Disciplinary Issues"],
  ["AN 10.88", "One Who Insults"],
  ["AN 10.89", "Kokālika"],
  ["AN 10.90", "Powers"],
  ["AN 10.91", "One Who Enjoys Sensual Pleasures"],
  ["AN 10.92", "Enmity"],
  ["AN 10.93", "View"],
  ["AN 10.94", "Vajjiyamāhita"],
  ["AN 10.95", "Uttiya"],
  ["AN 10.96", "Kokanada"],
  ["AN 10.97", "Worthy of Gifts"],
  ["AN 10.98", "An Elder"],
  ["AN 10.99", "Upāli"],
  ["AN 10.100", "Incapable"],
  ["AN 10.101", "An Ascetic’s Perceptions"],
  ["AN 10.102", "Factors of Enlightenment"],
  ["AN 10.103", "The Wrong Course"],
  ["AN 10.104", "A Seed"],
  ["AN 10.105", "True Knowledge"],
  ["AN 10.106", "Wearing Away"],
  ["AN 10.107", "Dhovana"],
  ["AN 10.108", "Physicians"],
  ["AN 10.109", "Emetic"],
  ["AN 10.110", "Ejected"],
  ["AN 10.111", "One Beyond Training (1)"],
  ["AN 10.112", "One Beyond Training (2)"],
  ["AN 10.113", "Non-Dhamma (1)"],
  ["AN 10.114", "Non-Dhamma (2)"],
  ["AN 10.115", "Non-Dhamma (3)"],
  ["AN 10.116", "Ajita"],
  ["AN 10.117", "Saṅgārava"],
  ["AN 10.118", "Near"],
  ["AN 10.119", "Paccorohaṇī (1)"],
  ["AN 10.120", "Paccorohaṇī (2)"],
  ["AN 10.121", "Forerunner"],
  ["AN 10.122", "Taints"],
  ["AN 10.123", "First"],
  ["AN 10.124", "Second"],
  ["AN 10.125", "Third"],
  ["AN 10.126", "Fourth"],
  ["AN 10.127", "Fifth"],
  ["AN 10.128", "Sixth"],
  ["AN 10.129", "Seventh"],
  ["AN 10.130", "Eighth"],
  ["AN 10.131", "Ninth"],
  ["AN 10.132", "Tenth"],
  ["AN 10.133", "Eleventh"],
  ["AN 10.134", "Good"],
  ["AN 10.135-144", "The Noble Dhamma"],
  ["AN 10.145", "The Noble Path"],
  ["AN 10.146-154", "The Dark Path"],
  ["AN 10.155", "Associate With"],
  ["AN 10.156-166", "Resort"],
  ["AN 10.167", "Paccorohaṇī (1)"],
  ["AN 10.168", "Paccorohaṇī (2)"],
  ["AN 10.169", "Saṅgārava"],
  ["AN 10.170", "The Near Shore"],
  ["AN 10.171", "Non-Dhamma (1)"],
  ["AN 10.172", "Non-Dhamma (2)"],
  ["AN 10.173", "Non-Dhamma (3)"],
  ["AN 10.174", "Causes of Kamma"],
  ["AN 10.175", "Avoidance"],
  ["AN 10.176", "Cunda"],
  ["AN 10.177", "Jāṇussoṇī"],
  ["AN 10.178", "Good"],
  ["AN 10.179-188", "The Noble Dhamma"],

  ["Kp 1", "The Three Refuges"],
  ["Kp 2", "The Ten Precepts"],
  ["Kp 3", "The Thirty-two Parts of the Body"],
  ["Kp 4", "The Boy’s Questions"],
  ["Kp 5", "True Blessings"],
  ["Kp 6", "Gems"],
  ["Kp 7", "Without the Walls"],
  ["Kp 8", "Storing Your Savings"],
  ["Kp 9", "The Teaching on Love"],

  ["Dhp 1-20", "Pairs "],
  ["Dhp 21-32", "Diligence "],
  ["Dhp 33-43", "The Mind"],
  ["Dhp 44-59", "Flowers "],
  ["Dhp 60-75", "Fools"],
  ["Dhp 76-89", "The Astute"],
  ["Dhp 90-99", "The Perfected Ones "],
  ["Dhp 100-115", "The Thousands"],
  ["Dhp 116-128", "Wickedness"],
  ["Dhp 129-145", "The Rod"],
  ["Dhp 146-156", "Old Age "],
  ["Dhp 157-166", "The Self"],
  ["Dhp 167-178", "The World"],
  ["Dhp 179-196", "The Buddhas "],
  ["Dhp 197-208", "Happiness"],
  ["Dhp 209-220", "The Beloved"],
  ["Dhp 221-234", "Anger"],
  ["Dhp 235-255", "Stains"],
  ["Dhp 256-272", "The Just"],
  ["Dhp 273-289", "The Path"],
  ["Dhp 290-305", "Miscellaneous"],
  ["Dhp 306-319", "Hell"],
  ["Dhp 320-333", "Dragons "],
  ["Dhp 334-359", "Craving"],
  ["Dhp 360-382", "Mendicants"],
  ["Dhp 383-423", "Brahmins "],

  ["Ud 1.1", "Upon Awakening 1"],
  ["Ud 1.2", "Upon Awakening 2"],
  ["Ud 1.3", "Upon Awakening 3"],
  ["Ud 1.4", "Whiny"],
  ["Ud 1.5", "The Brahmin"],
  ["Ud 1.6", "With Mahākassapa"],
  ["Ud 1.7", "At Ajakalāpaka"],
  ["Ud 1.8", "With Saṅgāmaji"],
  ["Ud 1.9", "Dreadlocked Ascetics"],
  ["Ud 1.10", "With Bāhiya"],
  ["Ud 2.1", "With Mucalinda"],
  ["Ud 2.2", "Kings"],
  ["Ud 2.3", "A Stick"],
  ["Ud 2.4", "Esteem"],
  ["Ud 2.5", "A Lay Follower"],
  ["Ud 2.6", "The Pregnant Woman"],
  ["Ud 2.7", "An Only Son"],
  ["Ud 2.8", "Suppavāsā"],
  ["Ud 2.9", "With Visākhā"],
  ["Ud 2.10", "With Bhaddiya"],
  ["Ud 3.1", "Born of the Fruits of deeds"],
  ["Ud 3.2", "With Nanda"],
  ["Ud 3.3", "With Yasoja"],
  ["Ud 3.4", "With Sāriputta"],
  ["Ud 3.5", "With Mahāmoggallāna"],
  ["Ud 3.6", "With Pilindavaccha"],
  ["Ud 3.7", "Sakka’s Heartfelt Saying"],
  ["Ud 3.8", "One Who Eats Only Almsfood"],
  ["Ud 3.9", "Professions"],
  ["Ud 3.10", "The World"],
  ["Ud 4.1", "With Meghiya"],
  ["Ud 4.2", "Restless"],
  ["Ud 4.3", "The Cowherd"],
  ["Ud 4.4", "The Spirit’s Blow"],
  ["Ud 4.5", "A Bull Elephant"],
  ["Ud 4.6", "With Piṇḍola"],
  ["Ud 4.7", "With Sāriputta"],
  ["Ud 4.8", "With Sundarī"],
  ["Ud 4.9", "With Upasena"],
  ["Ud 4.10", "The Peacefulness of Sāriputta"],
  ["Ud 5.1", "Who Is More Dear?"],
  ["Ud 5.2", "Short-lived"],
  ["Ud 5.3", "With Suppabuddha the Leper"],
  ["Ud 5.4", "The Boys"],
  ["Ud 5.5", "Sabbath"],
  ["Ud 5.6", "With Soṇa"],
  ["Ud 5.7", "With Revata the Doubter"],
  ["Ud 5.8", "Schism in the Saṅgha"],
  ["Ud 5.9", "Teasing"],
  ["Ud 5.10", "With Cūḷapanthaka"],
  ["Ud 6.1", "Surrendering the Life Force"],
  ["Ud 6.2", "Seven Matted-Hair Ascetics"],
  ["Ud 6.3", "The Buddha’s Reviewing"],
  ["Ud 6.4", "Followers of Other Paths 1"],
  ["Ud 6.5", "Followers of Other Paths 2"],
  ["Ud 6.6", "Followers of Other Paths 3"],
  ["Ud 6.7", "With Subhūti"],
  ["Ud 6.8", "The Courtesan"],
  ["Ud 6.9", "Hastening By"],
  ["Ud 6.10", "Arising"],
  ["Ud 7.1", "Bhaddiya the Dwarf 1"],
  ["Ud 7.2", "Bhaddiya the Dwarf 2"],
  ["Ud 7.3", "Clinging 1"],
  ["Ud 7.4", "Clinging 2"],
  ["Ud 7.5", "Another Discourse with Bhaddiya the Dwarf"],
  ["Ud 7.6", "The Ending of Craving"],
  ["Ud 7.7", "The Ending of Proliferation"],
  ["Ud 7.8", "Kaccāna"],
  ["Ud 7.9", "The Well"],
  ["Ud 7.10", "King Udena"],
  ["Ud 8.1", "About Extinguishment 1"],
  ["Ud 8.2", "About Extinguishment 2"],
  ["Ud 8.3", "About Extinguishment 3"],
  ["Ud 8.4", "About Extinguishment 4"],
  ["Ud 8.5", "With Cunda"],
  ["Ud 8.6", "The Layfolk of Pāṭali Village"],
  ["Ud 8.7", "A Fork in the Road"],
  ["Ud 8.8", "With Visākhā"],
  ["Ud 8.9", "With Dabba 1"],
  ["Ud 8.10", "Dabba 2"],

  ["Iti 1", "Greed"],
  ["Iti 2", "Hate"],
  ["Iti 3", "Delusion"],
  ["Iti 4", "Anger"],
  ["Iti 5", "Disdain"],
  ["Iti 6", "Conceit"],
  ["Iti 7", "Complete Understanding of All"],
  ["Iti 8", "Complete Understanding of Conceit"],
  ["Iti 9", "Complete Understanding of Greed"],
  ["Iti 10", "Complete Understanding of Hate"],
  ["Iti 11", "Complete Understanding of Delusion"],
  ["Iti 12", "Complete Understanding of Anger"],
  ["Iti 13", "Complete Understanding of Disdain"],
  ["Iti 14", "The Hindrance of Ignorance"],
  ["Iti 15", "The Fetter of Craving"],
  ["Iti 16", "A Trainee (1st) "],
  ["Iti 17", "A Trainee (2nd) "],
  ["Iti 18", "Schism in the Saṅgha"],
  ["Iti 19", "Harmony in the Saṅgha"],
  ["Iti 20", "A Corrupted Mind"],
  ["Iti 21", "A Pure Mind"],
  ["Iti 22", "The Benefits of Love "],
  ["Iti 23", "Both Kinds of Benefit"],
  ["Iti 24", "A Heap of Bones"],
  ["Iti 25", "Lying "],
  ["Iti 26", "Giving"],
  ["Iti 27", "The Meditation on Love"],
  ["Iti 28", "Living in Suffering"],
  ["Iti 29", "Living in Happiness"],
  ["Iti 30", "Mortifying "],
  ["Iti 31", "Not Mortifying "],
  ["Iti 32", "Ethics (1st) "],
  ["Iti 33", "Ethics (2nd) "],
  ["Iti 34", "Keen"],
  ["Iti 35", "Deceit and Flattery (1st)"],
  ["Iti 36", "Deceit and Flattery (2nd)"],
  ["Iti 37", "Happiness"],
  ["Iti 38", "Thoughts "],
  ["Iti 39", "Teaching"],
  ["Iti 40", "Knowledge "],
  ["Iti 41", "Bereft of Wisdom"],
  ["Iti 42", "Bright Things"],
  ["Iti 43", "Unborn"],
  ["Iti 44", "Facets of Quenching"],
  ["Iti 45", "Retreat"],
  ["Iti 46", "The Benefits of Training "],
  ["Iti 47", "Wake Up"],
  ["Iti 48", "Bound for Loss "],
  ["Iti 49", "Misconceptions"],
  ["Iti 50", "Roots"],
  ["Iti 51", "Elements "],
  ["Iti 52", "Feelings (1st)"],
  ["Iti 53", "Feelings (2nd)"],
  ["Iti 54", "Searches (1st)"],
  ["Iti 55", "Searches (2nd)"],
  ["Iti 56", "Defilements (1st)"],
  ["Iti 57", "Defilements (2nd)"],
  ["Iti 58", "Craving "],
  ["Iti 59", "Māra’s Sway"],
  ["Iti 60", "Grounds for Making Merit "],
  ["Iti 61", "The Eye "],
  ["Iti 62", "Faculties "],
  ["Iti 63", "Periods "],
  ["Iti 64", "Bad Conduct "],
  ["Iti 65", "Good Conduct "],
  ["Iti 66", "Purity"],
  ["Iti 67", "Sagacity "],
  ["Iti 68", "Greed (1st)"],
  ["Iti 69", "Greed (2nd)"],
  ["Iti 70", "Having Wrong View "],
  ["Iti 71", "Having Right View"],
  ["Iti 72", "Elements of Escape "],
  ["Iti 73", "More Peaceful"],
  ["Iti 74", "A Child "],
  ["Iti 75", "A Rainless Cloud"],
  ["Iti 76", "Wishing for Happiness"],
  ["Iti 77", "Fragile"],
  ["Iti 78", "Converging Elements"],
  ["Iti 79", "Decline "],
  ["Iti 80", "Thoughts "],
  ["Iti 81", "Esteem"],
  ["Iti 82", "The Cry of the Gods"],
  ["Iti 83", "Five Warning Signs"],
  ["Iti 84", "For the Welfare of the People"],
  ["Iti 85", "Observing Ugliness"],
  ["Iti 86", "Practicing In Line With the Teaching"],
  ["Iti 87", "Destroyers of Sight"],
  ["Iti 88", "Inner Stains"],
  ["Iti 89", "About Devadatta "],
  ["Iti 90", "The Best Kinds of Confidence "],
  ["Iti 91", "Lifestyle"],
  ["Iti 92", "The Corner of the Cloak"],
  ["Iti 93", "Fire "],
  ["Iti 94", "Examination"],
  ["Iti 95", "Provided With Pleasure"],
  ["Iti 96", "Attached to Sensual Pleasures"],
  ["Iti 97", "Good Morals"],
  ["Iti 98", "Giving"],
  ["Iti 99", "The Three Knowledges"],
  ["Iti 100", "The Holy Offering of the Teaching"],
  ["Iti 101", "Easy to Find"],
  ["Iti 102", "The Ending of Defilements "],
  ["Iti 103", "Ascetics and Brahmins "],
  ["Iti 104", "Accomplished in Ethics "],
  ["Iti 105", "The Arising of Craving "],
  ["Iti 106", "With Brahmā "],
  ["Iti 107", "Very Helpful"],
  ["Iti 108", "Deceivers "],
  ["Iti 109", "A River "],
  ["Iti 110", "Walking "],
  ["Iti 111", "Accomplishment in Ethics"],
  ["Iti 112", "The World "],

  ["Snp 1.1", "The Snake"],
  ["Snp 1.2", "With Dhaniya the Cowherd"],
  ["Snp 1.3", "The Rhinceros Horn"],
  ["Snp 1.4", "With Bhāradvāja the Farmer "],
  ["Snp 1.5", "With the Smith Cunda "],
  ["Snp 1.6", "Downfalls for a Lay Follower "],
  ["Snp 1.7", "The Lowlife"],
  ["Snp 1.8", "The Discourse on Love "],
  ["Snp 1.9", "The Buddha Teaches Sātāgira and Hemavata the Native Spirits"],
  ["Snp 1.10", "Āḷavaka the Native Spirit  "],
  ["Snp 1.11", "Victory"],
  ["Snp 1.12", "The Sage"],
  ["Snp 2.1", "Gems"],
  ["Snp 2.2", "Carrion"],
  ["Snp 2.3", "Conscience "],
  ["Snp 2.4", "True Blessings"],
  ["Snp 2.5", "Spiky the Native Spirit"],
  ["Snp 2.6", "A Righteous Life"],
  ["Snp 2.7", "Brahmanical Traditions"],
  ["Snp 2.8", "The Boat"],
  ["Snp 2.9", "What Morality?"],
  ["Snp 2.10", "Get up! Make an Effort!"],
  ["Snp 2.11", "With Rāhula "],
  ["Snp 2.12", "Vaṅgīsa and his Mentor Nigrodhakappa"],
  ["Snp 2.13", "The Right Way to Wander"],
  ["Snp 2.14", "With Dhammika "],
  ["Snp 3.1", "The Going Forth "],
  ["Snp 3.2", "Striving"],
  ["Snp 3.3", "Well-Spoken Words "],
  ["Snp 3.4", "With Bhāradvāja of Sundarika on the Sacrificial Cake"],
  ["Snp 3.5", "With Māgha on Giving"],
  ["Snp 3.6", "Sabhiya’s Questions"],
  ["Snp 3.7", "With Sela "],
  ["Snp 3.8", "The Dart"],
  ["Snp 3.9", "With Vāseṭṭha "],
  ["Snp 3.10", "To Kokāliya on the Results of Slander"],
  ["Snp 3.11", "The Sages Asita and Nālaka"],
  ["Snp 3.12", "Contemplating Pairs"],
  ["Snp 4.1", "Sensual Pleasures "],
  ["Snp 4.2", "Eight on the Cave"],
  ["Snp 4.3", "Eight on Malice"],
  ["Snp 4.4", "Eight on the Pure"],
  ["Snp 4.5", "Eight on the Ultimate"],
  ["Snp 4.6", "Old Age "],
  ["Snp 4.7", "To Tissametteyya on the Dangers of Sex"],
  ["Snp 4.8", "To Pasūra on Debate"],
  ["Snp 4.9", "With Māgaṇḍiya "],
  ["Snp 4.10", "Before the Breakup"],
  ["Snp 4.11", "Quarrels and Disputes"],
  ["Snp 4.12", "The Shorter Discourse on Arrayed For Battle"],
  ["Snp 4.13", "The Longer Discourse on Arrayed For Battle"],
  ["Snp 4.14", "Speedy"],
  ["Snp 4.15", "Taking Up Arms"],
  ["Snp 4.16", "The Questions of Sāriputta"],
  ["Snp 5.1", "Introductory Verses"],
  ["Snp 5.2", "The Questions of the Student Ajita"],
  ["Snp 5.3", "The Questions of the Student Tissametteyya"],
  ["Snp 5.4", "The Questions of the Student Puṇṇaka"],
  ["Snp 5.5", "The Questions of the Student Mettagū"],
  ["Snp 5.6", "The Questions of the Student Dhotaka"],
  ["Snp 5.7", "The Questions of the Student Upasīva"],
  ["Snp 5.8", "The Questions of the Student Nanda"],
  ["Snp 5.9", "The Questions of the Student Hemaka"],
  ["Snp 5.10", "The Questions of the Student Todeyya"],
  ["Snp 5.11", "The Questions of the Student Kappa"],
  ["Snp 5.12", "The Questions of the Student Jatukaṇṇī"],
  ["Snp 5.13", "The Questions of the Student Bhadrāvudha"],
  ["Snp 5.14", "The Questions of the Student Udaya"],
  ["Snp 5.15", "The Questions of the Student Posala"],
  ["Snp 5.16", "The Questions of the Student Mogharāja"],
  ["Snp 5.17", "The Questions of the Student Piṅgiya"],
  ["Snp 5.18", "Homage to the Way to the Beyond"],
  ["Snp 5.19", "Preserving the Way to the Beyond"],
  ["Vv 1", "Throne Mansion"],
  ["Vv 2", "Second Throne Mansion"],
  ["Vv 3", "Third Throne Mansion"],
  ["Vv 4", "Fourth Throne Mansion"],
  ["Vv 5", "Elephant Mansion"],
  ["Vv 6", "Ship Mansion"],
  ["Vv 7", "Second Ship Mansion"],
  ["Vv 8", "Third Ship Mansion"],
  ["Vv 9", "Lamp Mansion"],
  ["Vv 10", "Sesame-Gift Mansion"],
  ["Vv 11", "Honest Wife Mansion"],
  ["Vv 12", "Second Honest Wife Mansion"],
  ["Vv 13", "Daughter-in-Law Mansion"],
  ["Vv 14", "Second Daughter-in-Law Mansion"],
  ["Vv 15", "Uttarā’s Mansion"],
  ["Vv 16", "Sirimā’s Mansion"],
  ["Vv 17", "Kesakārī’s Mansion"],
  ["Vv 18", "Servant Girl’s Mansion"],
  ["Vv 19", "Lakhumā’s Mansion"],
  ["Vv 20", "Rice-Crust Giver’s Mansion"],
  ["Vv 21", "Low-Caste Woman’s Mansion"],
  ["Vv 22", "Lovely Lady’s Mansion"],
  ["Vv 23", "Soṇadinnā’s Mansion"],
  ["Vv 24", "Uposathā’s Mansion"],
  ["Vv 25", "Niddā’s mansion"],
  ["Vv 26", "Suniddā’s Mansion"],
  ["Vv 27", "Alms Giver’s Mansion"],
  ["Vv 28", "Second Alms Giver’s Mansion"],
  ["Vv 29", "Splendid Mansion"],
  ["Vv 30", "Sugar-Cane Mansion"],
  ["Vv 31", "Couch Mansion"],
  ["Vv 32", "Latā’s Mansion"],
  ["Vv 33", "Guttila’s Mansion"],
  ["Vv 34", "Dazzling Mansion"],
  ["Vv 35", "Pesavatī’s Mansion"],
  ["Vv 36", "Mallikā’s Mansion"],
  ["Vv 37", "Mansion of the Beautiful-Eyed Goddess"],
  ["Vv 38", "Coral Tree Mansion"],
  ["Vv 39", "Crimson Mansion"],
  ["Vv 40", "Shining Mansion"],
  ["Vv 41", "Elephant Mansion"],
  ["Vv 42", "Aloma’s Mansion"],
  ["Vv 43", "Rice-Gruel-Giver’s Mansion"],
  ["Vv 44", "Monastery Mansion"],
  ["Vv 45", "Four Women Mansion"],
  ["Vv 46", "Mango Mansion"],
  ["Vv 47", "Mansion of Yellow Flowers"],
  ["Vv 48", "Sugar Cane Mansion"],
  ["Vv 49", "Honoring Mansion"],
  ["Vv 50", "Rajjumālā’s Mansion"],
  ["Vv 51", "Frog-Deva’s Mansion"],
  ["Vv 52", "Revatī’s Mansion"],
  ["Vv 53", "The Boy Chatta’s Mansion"],
  ["Vv 54", "Crab-Soup Giver’s Mansion"],
  ["Vv 55", "Doorkeeper’s Mansion"],
  ["Vv 56", "Should-Be-Done Mansion"],
  ["Vv 57", "Second Should-Be-Done Mansion"],
  ["Vv 58", "Needle Mansion"],
  ["Vv 59", "Second Needle Mansion"],
  ["Vv 60", "Elephant Mansion"],
  ["Vv 61", "Second Elephant Mansion"],
  ["Vv 62", "Third Elephant Mansion"],
  ["Vv 63", "Small Chariot Mansion"],
  ["Vv 64", "Great Chariot Mansion"],
  ["Vv 65", "Householder’s Mansion"],
  ["Vv 66", "Second Householder’s Mansion"],
  ["Vv 67", "Fruit Giver’s Mansion"],
  ["Vv 68", "Lodging Giver’s Mansion"],
  ["Vv 69", "Second Lodging Giver’s Mansion"],
  ["Vv 70", "Almsfood Giver’s Mansion"],
  ["Vv 71", "Barley-Warden’s Mansion"],
  ["Vv 72", "Deity With Decorated Hair Mansion"],
  ["Vv 73", "Second Deity With Decorated Hair Mansion"],
  ["Vv 74", "Uttara Pāyāsi’s Mansion"],
  ["Vv 75", "The Creeper Mansion"],
  ["Vv 76", "Nandana Mansion"],
  ["Vv 77", "Mansion with Pillars of Gems"],
  ["Vv 78", "Golden Mansion"],
  ["Vv 79", "Mango Mansion"],
  ["Vv 80", "Cow Herder’s Mansion"],
  ["Vv 81", "Kaṇḍaka’s Mansion"],
  ["Vv 82", "Mansion of Many Colors"],
  ["Vv 83", "Maṭṭhakuṇḍalī’s Mansion"],
  ["Vv 84", "Serīsaka’s Mansion"],
  ["Vv 85", "Sunikkhitta’s Mansion"],

  ["Pv 1", "The Simile of a Field"],
  ["Pv 2", "The Story of a Ghost With a Pig’s Mouth"],
  ["Pv 3", "The Story of a Ghost With a Stinky Mouth"],
  ["Pv 4", "Advice to a Daughter "],
  ["Pv 5", "Without the Walls"],
  ["Pv 6", "Eater of Five Sons "],
  ["Pv 7", "Eater of Seven Sons"],
  ["Pv 8", "The Ox"],
  ["Pv 9", "Master Weaver"],
  ["Pv 10", "Dressed in Hair "],
  ["Pv 11", "The Elephant "],
  ["Pv 12", "The Snake "],
  ["Pv 13", "Sāriputta Helps a Ghost "],
  ["Pv 14", "Sāriputta’s Mother "],
  ["Pv 15", "The Ghost Matta "],
  ["Pv 16", "The Ghost Nanda "],
  ["Pv 17", "With Maṭṭhakuṇḍalī"],
  ["Pv 18", "King Kaṇha"],
  ["Pv 19", "The Rich Man Dhanapāla"],
  ["Pv 20", "The Ghost Cūḷaseṭṭhi"],
  ["Pv 21", "The Merchant Aṅkura "],
  ["Pv 22", "Uttara’s Mother "],
  ["Pv 23", "The Thread Offering "],
  ["Pv 24", "The Dog with the Torn Ears "],
  ["Pv 25", "Queen Ubbari "],
  ["Pv 26", "Without Rippling "],
  ["Pv 27", "The Monk from Mount Sāṇavāsī "],
  ["Pv 28", "The Ghost of Rathakāra Lake "],
  ["Pv 29", "The Hay Ghost "],
  ["Pv 30", "The Abandoned Baby "],
  ["Pv 31", "The Ghost Seriṇī"],
  ["Pv 32", "The Deer-Hunter (1st)"],
  ["Pv 33", "The Deer-Hunter (2nd)"],
  ["Pv 34", "The Back Biter "],
  ["Pv 35", "Insulting the Worship of Relics"],
  ["Pv 36", "King Ambasakkhara "],
  ["Pv 37", "The God Serīsaka "],
  ["Pv 38", "The Ghost Nandaka "],
  ["Pv 39", "The Ghost Revatī "],
  ["Pv 40", "The Sugar Cane Ghost "],
  ["Pv 41", "The Prince"],
  ["Pv 42", "The Ghost Princes"],
  ["Pv 43", "The Excrement Eating Male Ghost "],
  ["Pv 44", "The Excrement Eating Female Ghost "],
  ["Pv 45", "A Large Group of Ghosts "],
  ["Pv 46", "The Woman from Pāṭaliputta"],
  ["Pv 47", "The Mango Ghost "],
  ["Pv 48", "The Axle "],
  ["Pv 49", "Accumulation of Wealth "],
  ["Pv 50", "Wealthy Sons of Wealthy Merchants "],
  ["Pv 51", "Hit by Sixty Thousand Hammers "],

  ["Thag 1.2", "Mahākoṭṭhita"],
  ["Thag 1.3", "Kaṅkhārevata"],
  ["Thag 1.4", "Puṇṇa"],
  ["Thag 1.5", "Dabba"],
  ["Thag 1.6", "Sītavaniya"],
  ["Thag 1.7", "Bhalliya"],
  ["Thag 1.8", "Vīra"],
  ["Thag 1.9", "Pilindavaccha"],
  ["Thag 1.10", "Puṇṇamāsa"],
  ["Thag 1.11", "Cūlavaccha"],
  ["Thag 1.12", "Mahāvaccha"],
  ["Thag 1.13", "Vanavaccha"],
  ["Thag 1.14", "Vanavaccha’s Pupil"],
  ["Thag 1.15", "Kuṇḍadhāna"],
  ["Thag 1.16", "Bellaṭṭhisīsa"],
  ["Thag 1.17", "Dāsaka"],
  ["Thag 1.18", "Siṅgālapitu"],
  ["Thag 1.19", "Kuṇdala"],
  ["Thag 1.20", "Ajita"],
  ["Thag 1.21", "Nigrodha"],
  ["Thag 1.22", "Cittaka"],
  ["Thag 1.23", "Gosāla"],
  ["Thag 1.24", "Sugandha"],
  ["Thag 1.25", "Nandiya"],
  ["Thag 1.26", "Ubhaya"],
  ["Thag 1.27", "Lomasakaṅgiya"],
  ["Thag 1.28", "Jambugāmikaputta"],
  ["Thag 1.29", "Hārita"],
  ["Thag 1.30", "Uttiya"],
  ["Thag 1.31", "Gabbharatīriya"],
  ["Thag 1.32", "Suppiya"],
  ["Thag 1.33", "Sopāka"],
  ["Thag 1.34", "Posiya"],
  ["Thag 1.35", "Sāmaññakāni"],
  ["Thag 1.36", "Kumāputta"],
  ["Thag 1.37", "Kumāputta’s Friend"],
  ["Thag 1.38", "Gavampati"],
  ["Thag 1.39", "Tissa"],
  ["Thag 1.40", "Vaḍḍhamāna"],
  ["Thag 1.41", "Sirivaḍḍha"],
  ["Thag 1.42", "Khadiravaniya"],
  ["Thag 1.43", "Sumaṅgala"],
  ["Thag 1.44", "Sānu"],
  ["Thag 1.45", "Ramaṇīyavihāri"],
  ["Thag 1.46", "Samiddhi"],
  ["Thag 1.47", "Ujjaya"],
  ["Thag 1.48", "Sañjaya"],
  ["Thag 1.49", "Rāmaṇeyyaka"],
  ["Thag 1.50", "Vimala"],
  ["Thag 1.51", "Godhika"],
  ["Thag 1.52", "Subāhu"],
  ["Thag 1.53", "Valliya"],
  ["Thag 1.54", "Uttiya"],
  ["Thag 1.55", "Añjanavaniya"],
  ["Thag 1.56", "Kuṭivihāri"],
  ["Thag 1.57", "Dutiya Kuṭivihāri"],
  ["Thag 1.58", "Ramaṇiya Kuṭivihāri"],
  ["Thag 1.59", "Kosalavihāri"],
  ["Thag 1.60", "Sīvali"],
  ["Thag 1.61", "Vappa"],
  ["Thag 1.62", "Vajjiputta"],
  ["Thag 1.63", "Pakkha"],
  ["Thag 1.64", "Vimalakoṇḍañña"],
  ["Thag 1.65", "Ukkhepakatavaccha"],
  ["Thag 1.66", "Meghiya"],
  ["Thag 1.67", "Ekadhammasavanīya"],
  ["Thag 1.68", "Ekudāniya"],
  ["Thag 1.69", "Channa"],
  ["Thag 1.70", "Puṇṇa"],
  ["Thag 1.71", "Vacchapāla"],
  ["Thag 1.72", "Ātuma"],
  ["Thag 1.73", "Māṇava"],
  ["Thag 1.74", "Suyāma"],
  ["Thag 1.75", "Susārada"],
  ["Thag 1.76", "Piyañjaha"],
  ["Thag 1.77", "Hatthārohaputta"],
  ["Thag 1.78", "Meṇḍasira"],
  ["Thag 1.79", "Rakkhita"],
  ["Thag 1.80", "Ugga"],
  ["Thag 1.81", "Samatigutta"],
  ["Thag 1.82", "Kassapa"],
  ["Thag 1.83", "Sīha"],
  ["Thag 1.84", "Nīta"],
  ["Thag 1.85", "Sunāga"],
  ["Thag 1.86", "Nāgita"],
  ["Thag 1.87", "Paviṭṭha"],
  ["Thag 1.88", "Ajjuna"],
  ["Thag 1.89", "Devasabha"],
  ["Thag 1.90", "Sāmidatta"],
  ["Thag 1.91", "Paripuṇṇaka"],
  ["Thag 1.92", "Vijaya"],
  ["Thag 1.93", "Eraka"],
  ["Thag 1.94", "Mettaji"],
  ["Thag 1.95", "Cakkhupāla."],
  ["Thag 1.96", "Khaṇḍasumana"],
  ["Thag 1.97", "Tissa"],
  ["Thag 1.98", "Abhaya"],
  ["Thag 1.99", "Uttiya"],
  ["Thag 1.100", "Devasabha"],
  ["Thag 1.101", "Belaṭṭhakāni"],
  ["Thag 1.102", "Setuccha."],
  ["Thag 1.103", "Bandhura"],
  ["Thag 1.104", "Khitaka"],
  ["Thag 1.105", "Malitavambha"],
  ["Thag 1.106", "Suhemanta"],
  ["Thag 1.107", "Dhammasava"],
  ["Thag 1.108", "Dhammasava’s Father"],
  ["Thag 1.109", "Saṅgharakkhita"],
  ["Thag 1.110", "Usabha"],
  ["Thag 1.111", "Jenta"],
  ["Thag 1.112", "Vacchagotta"],
  ["Thag 1.113", "Vanavaccha"],
  ["Thag 1.114", "Adhimutta"],
  ["Thag 1.115", "Mahānāma"],
  ["Thag 1.116", "Pārāsariya"],
  ["Thag 1.117", "Yasa"],
  ["Thag 1.118", "Kimbila"],
  ["Thag 1.119", "Vajjiputta"],
  ["Thag 1.120", "Isidatta"],
  ["Thag 2.1", "Uttara"],
  ["Thag 2.2", "Piṇḍolabhāradvāja"],
  ["Thag 2.3", "Valliya"],
  ["Thag 2.4", "Gaṅgātīrya"],
  ["Thag 2.5", "Ajina"],
  ["Thag 2.6", "Meḷajina"],
  ["Thag 2.7", "Rādha"],
  ["Thag 2.8", "Surādha"],
  ["Thag 2.9", "Gotama"],
  ["Thag 2.10", "Vasabha"],
  ["Thag 2.11", "Mahācunda"],
  ["Thag 2.12", "Jotidāsa"],
  ["Thag 2.13", "Heraññakāni"],
  ["Thag 2.14", "Somamitta"],
  ["Thag 2.15", "Sabbamitta"],
  ["Thag 2.16", "Mahākāla"],
  ["Thag 2.17", "Tissa"],
  ["Thag 2.18", "Kimbila"],
  ["Thag 2.19", "Nanda"],
  ["Thag 2.20", "Sirimanta"],
  ["Thag 2.21", "Uttara"],
  ["Thag 2.22", "Bhaddaji"],
  ["Thag 2.23", "Sobhita"],
  ["Thag 2.24", "Valliya"],
  ["Thag 2.25", "Vītasoka"],
  ["Thag 2.26", "Puṇṇamāsa"],
  ["Thag 2.27", "Nandaka"],
  ["Thag 2.28", "Bharata"],
  ["Thag 2.29", "Bhāradvāja"],
  ["Thag 2.30", "Kaṇhadinna"],
  ["Thag 2.31", "Migasira"],
  ["Thag 2.32", "Sīvaka"],
  ["Thag 2.33", "Upavāna"],
  ["Thag 2.34", "Isidinna"],
  ["Thag 2.35", "Sambulakaccāna"],
  ["Thag 2.36", "Khitaka"],
  ["Thag 2.37", "Selissariya"],
  ["Thag 2.38", "Nisabha"],
  ["Thag 2.39", "Usabha"],
  ["Thag 2.40", "Kappaṭakura"],
  ["Thag 2.41", "Kumārakassapa"],
  ["Thag 2.42", "Dhammapāla"],
  ["Thag 2.43", "Brahmāli"],
  ["Thag 2.44", "Mogharāja"],
  ["Thag 2.45", "Visākha Pañcālaputta"],
  ["Thag 2.46", "Cūḷaka"],
  ["Thag 2.47", "Anūpama"],
  ["Thag 2.48", "Vajjita"],
  ["Thag 2.49", "Sanbhita"],
  ["Thag 3.1", "Aggikabhāradvāja"],
  ["Thag 3.2", "Paccaya"],
  ["Thag 3.3", "Bakkula"],
  ["Thag 3.4", "Dhaniya"],
  ["Thag 3.5", "Mātaṅgaputta"],
  ["Thag 3.6", "Khujjasobhita"],
  ["Thag 3.7", "Vāraṇa"],
  ["Thag 3.8", "Passika"],
  ["Thag 3.9", "Yasoja"],
  ["Thag 3.10", "Sāṭimattiya"],
  ["Thag 3.11", "Upāli"],
  ["Thag 3.12", "Uttarapāla"],
  ["Thag 3.13", "Abhibhūta"],
  ["Thag 3.14", "Gotama"],
  ["Thag 3.15", "Hārita"],
  ["Thag 3.16", "Vimala"],
  ["Thag 4.1", "Nāgasamāla"],
  ["Thag 4.2", "Bhagu"],
  ["Thag 4.3", "Sabhiya"],
  ["Thag 4.4", "Nandaka"],
  ["Thag 4.5", "Jambuka"],
  ["Thag 4.6", "Senaka"],
  ["Thag 4.7", "Sambhūta"],
  ["Thag 4.8", "Rāhula"],
  ["Thag 4.9", "Candana"],
  ["Thag 4.10", "Dhammika"],
  ["Thag 4.11", "Sappaka"],
  ["Thag 4.12", "Mudita"],
  ["Thag 5.1", "Rājadatta"],
  ["Thag 5.2", "Subhūta"],
  ["Thag 5.3", "Grimānanda"],
  ["Thag 5.4", "Sumana"],
  ["Thag 5.5", "Vaḍḍha"],
  ["Thag 5.6", "Nadī Kassapa"],
  ["Thag 5.7", "Gayā Kassapa"],
  ["Thag 5.8", "Vakkali"],
  ["Thag 5.9", "Vijitasena"],
  ["Thag 5.10", "Yasadatta"],
  ["Thag 5.11", "Soṇa Kuṭikaṇṇa"],
  ["Thag 5.12", "Kosiya"],
  ["Thag 6.1", "Uruveḷa Kassapa"],
  ["Thag 6.2", "Tekicchakāni"],
  ["Thag 6.3", "Mahānāga"],
  ["Thag 6.4", "Kulla"],
  ["Thag 6.5", "Māluṅkyaputta"],
  ["Thag 6.6", "Sappadāsa"],
  ["Thag 6.7", "Kātiyāna"],
  ["Thag 6.8", "Migajāla"],
  ["Thag 6.9", "Jenta"],
  ["Thag 6.10", "Sumana"],
  ["Thag 6.11", "Nahātakamuni"],
  ["Thag 6.12", "Brahmadatta"],
  ["Thag 6.13", "Sirimaṇḍa"],
  ["Thag 6.14", "Sabbakāmi"],
  ["Thag 7.1", "Sundara Samudda"],
  ["Thag 7.2", "Lakuṇṭaka Bhaddiya"],
  ["Thag 7.3", "Bhadda"],
  ["Thag 7.4", "Sopāka"],
  ["Thag 7.5", "Sarabhaṅga"],
  ["Thag 8.1", "Mahā Kaccāyana"],
  ["Thag 8.2", "Sirimitta"],
  ["Thag 8.3", "Mahāpanthaka"],
  ["Thag 9.1", "Bhūta"],
  ["Thag 10.1", "Kāḷudāyi"],
  ["Thag 10.2", "Eka Vihāriya"],
  ["Thag 10.3", "Mahā Kappina"],
  ["Thag 10.4", "Cūḷapanthaka"],
  ["Thag 10.5", "Kappa"],
  ["Thag 10.6", "Upasena"],
  ["Thag 10.7", "Apara Gotama"],
  ["Thag 11.1", "Saṅkicca"],
  ["Thag 12.1", "Sīlava"],
  ["Thag 12.2", "Sunīta"],
  ["Thag 13.1", "Soṇa"],
  ["Thag 14.1", "Khadiravaniya Revata"],
  ["Thag 14.2", "Godatta"],
  ["Thag 15.1", "Aññākoṇḍañña"],
  ["Thag 15.2", "Udāyi"],
  ["Thag 16.1", "Adhimutta"],
  ["Thag 16.2", "Pārāsariya"],
  ["Thag 16.3", "Telakāni"],
  ["Thag 16.4", "Raṭṭhapāla"],
  ["Thag 16.5", "Māluṅkyaputta"],
  ["Thag 16.6", "Sela"],
  ["Thag 16.7", "Bhaddiya"],
  ["Thag 16.8", "Aṅgulimāla"],
  ["Thag 16.9", "Anuruddha"],
  ["Thag 16.10", "Pārāsariya"],
  ["Thag 17.1", "Phussa"],
  ["Thag 17.2", "Sāriputta"],
  ["Thag 17.3", "Ānanda"],
  ["Thag 18.1", "Mahā Kassapa"],
  ["Thag 19.1", "Tāḷapuṭa"],
  ["Thag 20.1", "Mahā Moggallāna"],
  ["Thag 21.1", "Vaṅgīsa"],
  ["Thig 1.1", "Aññatarā"],
  ["Thig 1.2", "Muttā"],
  ["Thig 1.3", "Puṇṇā"],
  ["Thig 1.4", "Tissā"],
  ["Thig 1.5", "Tissā"],
  ["Thig 1.6", "Dhīrā"],
  ["Thig 1.7", "Vīrā"],
  ["Thig 1.8", "Mittā"],
  ["Thig 1.9", "Bhadrā"],
  ["Thig 1.10", "Upasamā"],
  ["Thig 1.11", "Muttā"],
  ["Thig 1.12", "Dhammadinnā"],
  ["Thig 1.13", "Visākhā"],
  ["Thig 1.14", "Sumanā"],
  ["Thig 1.15", "Uttarā"],
  ["Thig 1.16", "Vuḍḍhapabbajitasumanā"],
  ["Thig 1.17", "Dhammā"],
  ["Thig 1.18", "Saṅghā"],
  ["Thig 2.1", "Abhirūpanandā"],
  ["Thig 2.2", "Jentā"],
  ["Thig 2.3", "Sumangalamātā"],
  ["Thig 2.4", "Aḍḍhakāsī"],
  ["Thig 2.5", "Cittā"],
  ["Thig 2.6", "Mettikā"],
  ["Thig 2.7", "Mettā"],
  ["Thig 2.8", "Abhayamātā"],
  ["Thig 2.9", "Abhayā"],
  ["Thig 2.10", "Sāmā"],
  ["Thig 3.1", "Sāmā"],
  ["Thig 3.2", "Uttamā"],
  ["Thig 3.3", "Uttamā"],
  ["Thig 3.4", "Dantikā"],
  ["Thig 3.5", "Ubbirī"],
  ["Thig 3.6", "Sukkā"],
  ["Thig 3.7", "Selā"],
  ["Thig 3.8", "Somā"],
  ["Thig 4.1", "Bhaddākāpilānī"],
  ["Thig 5.1", "Aññatara"],
  ["Thig 5.2", "Vimalā"],
  ["Thig 5.3", "Sīhā"],
  ["Thig 5.4", "Nandā"],
  ["Thig 5.5", "Nanduttarā"],
  ["Thig 5.6", "Mittakālī"],
  ["Thig 5.7", "Sakulā"],
  ["Thig 5.8", "Soṇā"],
  ["Thig 5.9", "Bhaddākuṇḍalakesā"],
  ["Thig 5.10", "Paṭācārā"],
  ["Thig 5.11", "Tiṁsamattā"],
  ["Thig 5.12", "Candā"],
  ["Thig 6.1", "Pañcasatamattā"],
  ["Thig 6.2", "Vāseṭṭhi"],
  ["Thig 6.3", "Khemā"],
  ["Thig 6.4", "Sujātā"],
  ["Thig 6.5", "Anupamā"],
  ["Thig 6.6", "MahāPajāpatīGotamī"],
  ["Thig 6.7", "Guttā"],
  ["Thig 6.8", "Vijayā"],
  ["Thig 7.1", "Uttarā"],
  ["Thig 7.2", "Cālā"],
  ["Thig 7.3", "Upacālā"],
  ["Thig 8.1", "Sisūpacālā"],
  ["Thig 9.1", "Vaḍḍhamātā"],
  ["Thig 10.1", "Kisāgotamī"],
  ["Thig 11.1", "Uppalavaṇṇā"],
  ["Thig 12.1", "Puṇṇā"],
  ["Thig 13.1", "Ambapāli"],
  ["Thig 13.2", "Rohiṇī"],
  ["Thig 13.3", "Cāpā"],
  ["Thig 13.4", "Sundarī"],
  ["Thig 13.5", "Subhā"],
  ["Thig 14.1", "Subhā"],
  ["Thig 15.1", "Isidāsī"],
  ["Thig 16.1", "Sumedhā"],

  ["Ja 1", "Guaranteed "],
  ["Ja 2", "The Sandy Track"],
  ["Ja 3", "The Merchant Seriva"],
  ["Ja 4", "The Shorter Tale of the Financier"],
  ["Ja 5", "A Little Rice"],
  ["Ja 6", "Godlike"],
  ["Ja 7", "The King and the Stick-Collector"],
  ["Ja 8", "Prince Gāmaṇi"],
  ["Ja 9", "King Makhadeva"],
  ["Ja 10", "One Who Lives Happily"],
  ["Ja 11", "The Lucky Deer"],
  ["Ja 12", "The Banyan Deer"],
  ["Ja 13", "The Dart"],
  ["Ja 14", "The Wind-antelope"],
  ["Ja 15", "With Kharādiya"],
  ["Ja 16", "The Deer With Three Ruses"],
  ["Ja 17", "The Breeze"],
  ["Ja 18", "Feasts for the Dead"],
  ["Ja 19", "Sacrifice to Fulfill a Wish"],
  ["Ja 20", "Drinking With Straws"],
  ["Ja 21", "The Antelope"],
  ["Ja 22", "The Dog"],
  ["Ja 23", "The Destrier"],
  ["Ja 24", "The Thoroughbred"],
  ["Ja 25", "The Bathing-place"],
  ["Ja 26", "Damsel-face the Brigand"],
  ["Ja 27", "Familiarity"],
  ["Ja 28", "The Bull Named Ample Joy"],
  ["Ja 29", "The Bull Named Black"],
  ["Ja 30", "The Pig Named Munika"],
  ["Ja 31", "The Nestling Birds"],
  ["Ja 32", "Dancing"],
  ["Ja 33", "Mutual Appreciation"],
  ["Ja 34", "The Fish"],
  ["Ja 35", "The Quail"],
  ["Ja 36", "The Bird"],
  ["Ja 37", "The Partridge, the Monkey, and the Elephant"],
  ["Ja 38", "The Crane"],
  ["Ja 39", "Nanda the Bondservant"],
  ["Ja 40", "The Pit of Acacia Charcoal"],
  ["Ja 41", "On the Mendicant Losaka"],
  ["Ja 42", "The Pigeon"],
  ["Ja 43", "The Monk Who Kept a Viper in Bamboo as a Pet"],
  ["Ja 44", "The Mosquito"],
  ["Ja 45", "The Maid Rohinī"],
  ["Ja 46", "The One Who Spoiled a Park"],
  ["Ja 47", "The One Who Spoiled the Liquor"],
  ["Ja 48", "The Vedabba Spell"],
  ["Ja 49", "Lucky Stars"],
  ["Ja 50", "The Sacrifice of a Thousand Reprobates "],
  ["Ja 51", "King Great Morality"],
  ["Ja 52", "The Shorter Tale of King Janaka"],
  ["Ja 53", "The Full Cup"],
  ["Ja 54", "The Tree Named What-fruit"],
  ["Ja 55", "Five Weapons"],
  ["Ja 56", "A Mass of Gold"],
  ["Ja 57", "The Monkey King"],
  ["Ja 58", "Three Qualities"],
  ["Ja 59", "The Drummer"],
  ["Ja 60", "The Conch-blower"],
  ["Ja 61", "The Unpleasant Spell"],
  ["Ja 62", "The Blindfolded Husband"],
  ["Ja 63", "The Buttermilk Sage"],
  ["Ja 64", "The Inscrutability of Women"],
  ["Ja 65", "On Losing Interest"],
  ["Ja 66", "Queen Gentle-heart"],
  ["Ja 67", "Sons Fall in a Mother’s Lap"],
  ["Ja 68", "At Sāketa"],
  ["Ja 69", "Poison Once Expelled"],
  ["Ja 70", "The Spade Sage"],
  ["Ja 71", "The Temple Tree"],
  ["Ja 72", "The Virtuous Elephant King"],
  ["Ja 73", "It Seems They Knew the Truth"],
  ["Ja 74", "The Lesson of the Tree"],
  ["Ja 75", "The Fish"],
  ["Ja 76", "Fearlessness"],
  ["Ja 77", "The Great Dreams"],
  ["Ja 78", "The Two Illisas"],
  ["Ja 79", "A Harsh Drum"],
  ["Ja 80", "The Weaver Bhīmasena"],
  ["Ja 81", "Drinking Liquor"],
  ["Ja 82", "On Mittavindaka"],
  ["Ja 83", "Unlucky"],
  ["Ja 84", "The Gates of the Good"],
  ["Ja 85", "The Unknown Fruit"],
  ["Ja 86", "A Test of Virtue"],
  ["Ja 87", "Omens"],
  ["Ja 88", "The Ox Sārambha"],
  ["Ja 89", "The Cheat"],
  ["Ja 90", "Ungrateful"],
  ["Ja 91", "A Smear of Poison"],
  ["Ja 92", "The Loss of a Treasure"],
  ["Ja 93", "Trust"],
  ["Ja 94", "Hair-raising"],
  ["Ja 95", "The Great King Sudassana"],
  ["Ja 96", "The Pot of Oil"],
  ["Ja 97", "One Who Thought Names Had Power"],
  ["Ja 98", "The Cheating Merchant"],
  ["Ja 99", "Over a Thousand"],
  ["Ja 100", "Unpleasant"],
  ["Ja 101", "Over a Hundred "],
  ["Ja 102", "The Greengrocer"],
  ["Ja 103", "Enemies"],
  ["Ja 104", "On Mittavindaka"],
  ["Ja 105", "The Weak Branch"],
  ["Ja 106", "The Water Hauler"],
  ["Ja 107", "The Benefits of Good Aim"],
  ["Ja 108", "The Foreigner"],
  ["Ja 109", "The Cake"],
  ["Ja 110", "All-embracing"],
  ["Ja 111", "A Question for a Donkey"],
  ["Ja 112", "The Question of Queen Ambrosia"],
  ["Ja 113", "The Jackal "],
  ["Ja 114", "Thoughtful"],
  ["Ja 115", "The Warner"],
  ["Ja 116", "An Unadmonishable Mendicant"],
  ["Ja 117", "The Partridge"],
  ["Ja 118", "The Quail"],
  ["Ja 119", "Noisy at the Wrong Time"],
  ["Ja 120", "Release From Bonds"],
  ["Ja 121", "The Spirit in the Clump of Grass"],
  ["Ja 122", "Lacking Intelligence"],
  ["Ja 123", "The Shaft of a Plough"],
  ["Ja 124", "Mangoes"],
  ["Ja 125", "The Clever Young Man Kaṭāhaka"],
  ["Ja 126", "Lucky Swords"],
  ["Ja 127", "The Rascal Kalaṇḍuka"],
  ["Ja 128", "The Hypocrite Like a Cat"],
  ["Ja 129", "Bhāradvāja, Servant of the Sacred Flame"],
  ["Ja 130", "The Woman Kosiya"],
  ["Ja 131", "Refusing a Gift"],
  ["Ja 132", "Unshaken"],
  ["Ja 133", "Like a Fire Consuming Ghee"],
  ["Ja 134", "The Purification of Absorption"],
  ["Ja 135", "Moonshine"],
  ["Ja 136", "The Golden Goose"],
  ["Ja 137", "The Hungry Cat"],
  ["Ja 138", "The Lizard"],
  ["Ja 139", "A Twofold Failure"],
  ["Ja 140", "The Crow"],
  ["Ja 141", "The Iguana Who Befriended a Chameleon"],
  ["Ja 142", "The Jackal "],
  ["Ja 143", "The Shining"],
  ["Ja 144", "The Tail"],
  ["Ja 145", "The Tale of Rādha"],
  ["Ja 146", "The Crows Who Tried to Drink the Ocean"],
  ["Ja 147", "A Die of Flowers"],
  ["Ja 148", "The Jackal "],
  ["Ja 149", "One Poison Leaf"],
  ["Ja 150", "Life-bringer the Student"],
  ["Ja 151", "Kingly Advice"],
  ["Ja 152", "The Jackal "],
  ["Ja 153", "The Boar"],
  ["Ja 154", "The Snake"],
  ["Ja 155", "The Tale of Gagga"],
  ["Ja 156", "Prince Braveheart"],
  ["Ja 157", "Favors"],
  ["Ja 158", "The Tale of Strongjaw"],
  ["Ja 159", "The Peacock"],
  ["Ja 160", "The Goose Named Livid-blue "],
  ["Ja 161", "The Unteachable Ascetic Indasamānagotta"],
  ["Ja 162", "Acquaintance"],
  ["Ja 163", "King Susīma"],
  ["Ja 164", "The Vulture"],
  ["Ja 165", "The Mongoose"],
  ["Ja 166", "The Brahmin Upasāḷaka"],
  ["Ja 167", "The Tale of Venerable Samiddhi"],
  ["Ja 168", "The Falcon"],
  ["Ja 169", "The Ascetic Araka"],
  ["Ja 170", "The Iguana "],
  ["Ja 171", "A Fine Reputation"],
  ["Ja 172", "Who Sounds the Drum?"],
  ["Ja 173", "The Monkey "],
  ["Ja 174", "The Tricky Monkey"],
  ["Ja 175", "The Monkey Who Worshiped the Sun"],
  ["Ja 176", "The Lost Pea"],
  ["Ja 177", "The Pale-moon Ebony Tree"],
  ["Ja 178", "The Tortoise"],
  ["Ja 179", "The Brahmin Satadhamma"],
  ["Ja 180", "Hard to Give"],
  ["Ja 181", "Prince Peerless"],
  ["Ja 182", "At Home in Battle"],
  ["Ja 183", "A Nasty Drink"],
  ["Ja 184", "The Groom Giridatta"],
  ["Ja 185", "Dissatsfaction"],
  ["Ja 186", "The King Carried on Curds"],
  ["Ja 187", "Polished in Four Ways"],
  ["Ja 188", "The Lion-Jackal"],
  ["Ja 189", "The Jackal Wearing Lionskin"],
  ["Ja 190", "The Benefits of Morality"],
  ["Ja 191", "The Priest Named Ruddy"],
  ["Ja 192", "Lucky Unlucky"],
  ["Ja 193", "Prince Lotus"],
  ["Ja 194", "The Jewel Thief"],
  ["Ja 195", "Sheltered by a Hill"],
  ["Ja 196", "The Flying Horse"],
  ["Ja 197", "Friends and Enemies"],
  ["Ja 198", "Rādha the Parrot"],
  ["Ja 199", "The Householder"],
  ["Ja 200", "Good is Virtue"],
  ["Ja 201", "The Prison"],
  ["Ja 202", "The King Who Pranked the Elderly"],
  ["Ja 203", "Love For the Families of Snakes"],
  ["Ja 204", "The Crow Named Heroic"],
  ["Ja 205", "The Fish of the Ganges"],
  ["Ja 206", "The Antelope"],
  ["Ja 207", "How the Queen Loved a Worm More Than King Assaka"],
  ["Ja 208", "The Crocodile"],
  ["Ja 209", "The Old Chook"],
  ["Ja 210", "The Woodpecker Named Eatbulb"],
  ["Ja 211", "The Farmer’s Son Somadatta"],
  ["Ja 212", "A Meal of Leavings"],
  ["Ja 213", "King Bharu"],
  ["Ja 214", "Who Drinks When the River’s Full"],
  ["Ja 215", "The Tortoise"],
  ["Ja 216", "The Fish"],
  ["Ja 217", "The Innocent Daughter Seggu"],
  ["Ja 218", "The Cheating Merchant"],
  ["Ja 219", "Despised"],
  ["Ja 220", "The Chaplain Named Banner of the Teaching"],
  ["Ja 221", "The Ocher Robe"],
  ["Ja 222", "The Monkey Nandiya"],
  ["Ja 223", "The Meal-Packet"],
  ["Ja 224", "The Crocodile"],
  ["Ja 225", "In Praise of Patience"],
  ["Ja 226", "The Owl"],
  ["Ja 227", "The Dung-Beetle"],
  ["Ja 228", "The Brahmin Named Desire-Led"],
  ["Ja 229", "The Fleer (1st)"],
  ["Ja 230", "The Fleer (2nd)"],
  ["Ja 231", "A Pair of Sandals"],
  ["Ja 232", "The Arched Harp"],
  ["Ja 233", "A Barbed Arrow"],
  ["Ja 234", "The Ascetic Asitābhū"],
  ["Ja 235", "The Ascetic Vacchanakha"],
  ["Ja 236", "The Crane"],
  ["Ja 237", "The Brahmin Sāketa"],
  ["Ja 238", "One Word"],
  ["Ja 239", "The Green Frog"],
  ["Ja 240", "King Yellow the Great"],
  ["Ja 241", "All-Tooth the Jackal King"],
  ["Ja 242", "The Dog"],
  ["Ja 243", "The Musician Guttila"],
  ["Ja 244", "Free of Desires"],
  ["Ja 245", "The Backstory for the Discourse on the Root of All Things "],
  ["Ja 246", "Foolish Advice"],
  ["Ja 247", "Prince Pādañjalī"],
  ["Ja 248", "The Simile of the Parrot Tree"],
  ["Ja 249", "My Dear Brother Monkey"],
  ["Ja 250", "The Monkey"],
  ["Ja 251", "Thoughts of Lust"],
  ["Ja 252", "A Scattering of Sesame"],
  ["Ja 253", "Jewel-Neck the Dragon King"],
  ["Ja 254", "The Fine Foal Fed on Rice-Powder"],
  ["Ja 255", "The Parrot King"],
  ["Ja 256", "The Old Well"],
  ["Ja 257", "The Tale of Gāmaṇicanda"],
  ["Ja 258", "King Mandhātā"],
  ["Ja 259", "The Ascetic Tirīṭavaccha"],
  ["Ja 260", "The Messenger"],
  ["Ja 261", "The Lotus"],
  ["Ja 262", "A Gentle Hand"],
  ["Ja 263", "The Shorter Tale of Allure"],
  ["Ja 264", "The Palace of King Panāda"],
  ["Ja 265", "The Arrow"],
  ["Ja 266", "The Steed Windswift"],
  ["Ja 267", "The Golden Crab"],
  ["Ja 268", "The One Who Spoiled a Park"],
  ["Ja 269", "Sujātā the Merchant’s Daughter"],
  ["Ja 270", "The Owl"],
  ["Ja 271", "The Fouled Well"],
  ["Ja 272", "The Tiger"],
  ["Ja 273", "The Tortoise"],
  ["Ja 274", "The Greedy Monk"],
  ["Ja 275", "The Splendid Crane"],
  ["Ja 276", "The Laws of Kuru"],
  ["Ja 277", "The Pigeon"],
  ["Ja 278", "The Buffalo King"],
  ["Ja 279", "The Woodpecker"],
  ["Ja 280", "Destroyer of Leaf-Baskets"],
  ["Ja 281", "The Midmost Mango"],
  ["Ja 282", "Doing Better"],
  ["Ja 283", "The Young Boar"],
  ["Ja 284", "The Thief of Luck"],
  ["Ja 285", "The Boars and the Crystal Cave"],
  ["Ja 286", "The Pig Named Celery"],
  ["Ja 287", "In Dispraise of Acquisitions"],
  ["Ja 288", "A School of Fish"],
  ["Ja 289", "Of Diverse Desires"],
  ["Ja 290", "A Test of Virtue"],
  ["Ja 291", "The Lucky Cup"],
  ["Ja 292", "Fairwing the Crow King"],
  ["Ja 293", "Dispassion for the Body"],
  ["Ja 294", "The Crow Eating Rose-Apple"],
  ["Ja 295", "The Worst"],
  ["Ja 296", "Drinking Sea Water"],
  ["Ja 297", "A Lament of Love"],
  ["Ja 298", "Ripe are the Figs"],
  ["Ja 299", "The Ascetic Komāraputta"],
  ["Ja 300", "The Wolf"],
  ["Ja 301", "King Kāliṅga"],
  ["Ja 302", "The Great Horseman"],
  ["Ja 303", "The One King"],
  ["Ja 304", "The Dragon Named Drum"],
  ["Ja 305", "A Test of Virtue"],
  ["Ja 306", "Queen Sujātā "],
  ["Ja 307", "The Parrot Tree"],
  ["Ja 308", "The Swift Bird"],
  ["Ja 309", "The Wretch Who Became King of the Night"],
  ["Ja 310", "Better"],
  ["Ja 311", "The Neem Tree"],
  ["Ja 312", "Foolish Youth"],
  ["Ja 313", "The Teacher of Patience"],
  ["Ja 314", "The Copper Pots of Hell"],
  ["Ja 315", "One Who Gets All the Meat"],
  ["Ja 316", "The Wise Hare"],
  ["Ja 317", "Don’t Weep For the Dead"],
  ["Ja 318", "The Flowering Oleander"],
  ["Ja 319", "The Partridge"],
  ["Ja 320", "What is Easy to Give"],
  ["Ja 321", "One Who Ruined a Hut"],
  ["Ja 322", "It Fell With a Thud"],
  ["Ja 323", "King Brahmadatta"],
  ["Ja 324", "The Wanderer Who Wore a Leather Jacket"],
  ["Ja 325", "The Lizard King"],
  ["Ja 326", "The Heavenly Pumpkin Flowers"],
  ["Ja 327", "Queen Kākavatī"],
  ["Ja 328", "Grieve Not"],
  ["Ja 329", "The Monkey Black-Arm"],
  ["Ja 330", "A Test of Virtue"],
  ["Ja 331", "The Cuckoo"],
  ["Ja 332", "The Chariot-Whip"],
  ["Ja 333", "The Cooked Lizard"],
  ["Ja 334", "Kingly Advice"],
  ["Ja 335", "The Jackal"],
  ["Ja 336", "Chatta the Tall"],
  ["Ja 337", "The Seat"],
  ["Ja 338", "Grain by Grain"],
  ["Ja 339", "Selling Crows to Babylon"],
  ["Ja 340", "The Merchant Visayha"],
  ["Ja 341", "On Kaṇḍarī"],
  ["Ja 342", "The Langur"],
  ["Ja 343", "The Heron"],
  ["Ja 344", "The Mangoes"],
  ["Ja 345", "The Slow Tortoise"],
  ["Ja 346", "The Ascetic Named Hairy"],
  ["Ja 347", "The Iron Hammer"],
  ["Ja 348", "From the Wilderness"],
  ["Ja 349", "Slander"],
  ["Ja 350", "The Deity’s Question"],
  ["Ja 351", "Jeweled Earrings"],
  ["Ja 352", "The Pious Son Sujāta"],
  ["Ja 353", "Beneath the Spreading Branches"],
  ["Ja 354", "The Snake"],
  ["Ja 355", "Prince Ghaṭa"],
  ["Ja 356", "The Student Koraṇḍiya"],
  ["Ja 357", "The Quail "],
  ["Ja 358", "The Shorter Tale of Dhammapāla "],
  ["Ja 359", "The Golden Deer"],
  ["Ja 360", "Queen Suyonandī"],
  ["Ja 361", "In Beauty and Stature"],
  ["Ja 362", "A Test of Virtue"],
  ["Ja 363", "Conscience"],
  ["Ja 364", "The Firefly"],
  ["Ja 365", "The Snake-Charmer"],
  ["Ja 366", "Gumbiya the Ogre"],
  ["Ja 367", "The Hedgehog"],
  ["Ja 368", "Bamboo Fetters"],
  ["Ja 369", "On Mittavindaka"],
  ["Ja 370", "The Parrot Tree"],
  ["Ja 371", "King Dīghīti of Kosala"],
  ["Ja 372", "The Fawn"],
  ["Ja 373", "The Tale of Mūsikā"],
  ["Ja 374", "The Shorter Tale of Clever Little Archer"],
  ["Ja 375", "The Pigeon"],
  ["Ja 376", "Avāriyā’s Father, The Foolish Ferryman"],
  ["Ja 377", "The Student Setaketu"],
  ["Ja 378", "Darīmukha the Priest’s Son"],
  ["Ja 379", "Mount Neru"],
  ["Ja 380", "The Lotus-Maiden Named Doubt"],
  ["Ja 381", "Beast-Swiper the Vulture"],
  ["Ja 382", "Lucky Unlucky"],
  ["Ja 383", "The Chicken"],
  ["Ja 384", "A False Flag"],
  ["Ja 385", "Nandiya, King of Deer"],
  ["Ja 386", "The Foolish Donkey"],
  ["Ja 387", "Needles"],
  ["Ja 388", "The Boar-Brothers Tuṇḍila"],
  ["Ja 389", "The Golden Crab"],
  ["Ja 390", "The Bird Who Cried “Mine! Mine!”"],
  ["Ja 391", "The Wizard"],
  ["Ja 392", "The Thief of Scent"],
  ["Ja 393", "Eaters of Leftovers"],
  ["Ja 394", "The Quail"],
  ["Ja 395", "The Dove"],
  ["Ja 396", "Half a Yard"],
  ["Ja 397", "Manoja the Lion Cub who Befriended a Jackal"],
  ["Ja 398", "The Young Man Sutanū"],
  ["Ja 399", "The Vulture Who Cared for His Parents"],
  ["Ja 400", "Grey-Grass the Jackal"],
  ["Ja 401", "The Sword-Swallower"],
  ["Ja 402", "The Sack of Flour"],
  ["Ja 403", "The Ascetic Atthisena"],
  ["Ja 404", "The Monkey"],
  ["Ja 405", "The Crane"],
  ["Ja 406", "In the Kingdom of Gandhāra"],
  ["Ja 407", "The Great Monkey"],
  ["Ja 408", "The Potter"],
  ["Ja 409", "King Daḷhadhamma"],
  ["Ja 410", "The Elephant Somadatta"],
  ["Ja 411", "King Susīma"],
  ["Ja 412", "The Spirit of the Cotton-Tree"],
  ["Ja 413", "Smokemaker the Goatherd"],
  ["Ja 414", "Who Wakes?"],
  ["Ja 415", "A Spoon of Porridge"],
  ["Ja 416", "The Adulterer Parantapa"],
  ["Ja 417", "When Kaccāni Made a Death-Offering to Dhamma"],
  ["Ja 418", "The Eight Sounds"],
  ["Ja 419", "The Courtesan Sulasā"],
  ["Ja 420", "The Park-Keeper Sumaṅgala"],
  ["Ja 421", "The Royal Barber Gaṅgamāla"],
  ["Ja 422", "How the King of Ceti Told the First Lie"],
  ["Ja 423", "The Senses"],
  ["Ja 424", "When Your House is Burning Down"],
  ["Ja 425", "Impossible"],
  ["Ja 426", "The Panther"],
  ["Ja 427", "The Vulture"],
  ["Ja 428", "The Incident at Kosambi"],
  ["Ja 429", "The Longer Tale of King Parrot"],
  ["Ja 430", "The Shorter Tale of King Parrot"],
  ["Ja 431", "The Ascetic Goldskin"],
  ["Ja 432", "The Student Who Could Trace Footsteps"],
  ["Ja 433", "The Ascetic Hairy Kassapa"],
  ["Ja 434", "The Red-Ducks"],
  ["Ja 435", "Unstable as Turmeric Dye"],
  ["Ja 436", "The Box"],
  ["Ja 437", "The Jackal Pūtimaṁsa"],
  ["Ja 438", "The Partridge"],
  ["Ja 439", "Four Gates"],
  ["Ja 440", "The Black Sage"],
  ["Ja 441", "The Four Sabbaths"],
  ["Ja 442", "The Brahmin Saṅkha"],
  ["Ja 443", "The Shorter Tale of the Young Man Bodhi"],
  ["Ja 444", "Black Dīpāyana"],
  ["Ja 445", "The Banyan-Tree Boy"],
  ["Ja 446", "Bulbs"],
  ["Ja 447", "Dhammapāla the Great"],
  ["Ja 448", "The Chicken"],
  ["Ja 449", "Earrings"],
  ["Ja 450", "The Rich Man Bilārakosiya"],
  ["Ja 451", "The Red-Duck and the Crow"],
  ["Ja 452", "Vast Wisdom"],
  ["Ja 453", "The Backstory for the Discourse on Blessings"],
  ["Ja 454", "The Pot-Sage"],
  ["Ja 455", "The Elephant Who Cared for His Mother"],
  ["Ja 456", "Prince Moonlight"],
  ["Ja 457", "The Deity Named Dhamma"],
  ["Ja 458", "Prince Udayabhadda"],
  ["Ja 459", "The Stolen Drink"],
  ["Ja 460", "Crown Prince Yudhañcaya"],
  ["Ja 461", "King Dasaratha"],
  ["Ja 462", "Prince Saṁvara"],
  ["Ja 463", "The Sailor Suppāraka"],
  ["Ja 464", "The Shorter Tale of the Cuckoo"],
  ["Ja 465", "Lucky Sal"],
  ["Ja 466", "The Sea-Merchants"],
  ["Ja 467", "Desire"],
  ["Ja 468", "Prince Janasandha"],
  ["Ja 469", "The Black Hound"],
  ["Ja 470", "Kosiya the Miser"],
  ["Ja 471", "The Question of Meṇḍaka"],
  ["Ja 472", "Prince Lotus"],
  ["Ja 473", "Friends and Enemies"],
  ["Ja 474", "Mangoes"],
  ["Ja 475", "The Lion and the Boxwood Tree"],
  ["Ja 476", "The Swift Goose"],
  ["Ja 477", "The Ascetic Nārada"],
  ["Ja 478", "The Messenger"],
  ["Ja 479", "King Kāliṅga and the Bodhi Tree"],
  ["Ja 480", "The Ascetic Akitti and His Sister"],
  ["Ja 481", "The Student Takkāriya"],
  ["Ja 482", "The Golden Deer"],
  ["Ja 483", "The Stag"],
  ["Ja 484", "The Rice-Field"],
  ["Ja 485", "The Pixies on the Mountain of the Moon"],
  ["Ja 486", "The Osprey"],
  ["Ja 487", "Uddālaka, the False Ascetic"],
  ["Ja 488", "A Meal of Lotus Fibres"],
  ["Ja 489", "Prince Splendid"],
  ["Ja 490", "The Five-Precept Sabbath"],
  ["Ja 491", "The Golden Peacock"],
  ["Ja 492", "Carpenter’s Boar"],
  ["Ja 493", "The Merchants"],
  ["Ja 494", "King Sādhina of Mithila"],
  ["Ja 495", "Ten Kinds of Brahmin"],
  ["Ja 496", "Gathering Almsfood"],
  ["Ja 497", "Mātaṅga the Wise"],
  ["Ja 498", "The Tale of Citta and Sambhūta"],
  ["Ja 499", "Prince Sivi"],
  ["Ja 500", "The Question of Sirimanta"],
  ["Ja 501", "The Golden Deer"],
  ["Ja 502", "The Shorter Tale of the Golden Goose"],
  ["Ja 503", "The Parrot Named Bristling Spears"],
  ["Ja 504", "King Bhallātiya and the Pixies"],
  ["Ja 505", "Prince Happiness"],
  ["Ja 506", "The Dragon of Campeyya"],
  ["Ja 507", "The Longer Tale of Allure"],
  ["Ja 508", "The Five Wise Men"],
  ["Ja 509", "The Elephant Driver"],
  ["Ja 510", "The Prince of the Iron House"],
  ["Ja 511", "What Is Your Desire?"],
  ["Ja 512", "The Sin Within the Cup"],
  ["Ja 513", "Prince Victor"],
  ["Ja 514", "The Six-Tusked Elephant"],
  ["Ja 515", "The Wise Boy Sambhava"],
  ["Ja 516", "The Monkey Betrayed"],
  ["Ja 517", "Daka the Ogre"],
  ["Ja 518", "The Dragon-King Paṇḍara"],
  ["Ja 519", "Queen Sambulā"],
  ["Ja 520", "The Fragrant Pale-moon Ebony Tree"],
  ["Ja 521", "Three Birds"],
  ["Ja 522", "The Archer Sarabhaṅga"],
  ["Ja 523", "The Nymph Alambusā"],
  ["Ja 524", "The Dragon of Lake Saṅkhapāla"],
  ["Ja 525", "The Shorter Tale of Prince Moonface the Scholar"],
  ["Ja 526", "Princess Niḷinikā"],
  ["Ja 527", "The Merchant’s Daughter Ummadantī"],
  ["Ja 528", "The Longer Tale of the Young Man Bodhi"],
  ["Ja 529", "Prince Arindama and His Friend Soṇaka"],
  ["Ja 530", "Prince Brahmadatta and His Friend Saṁkicca"],
  ["Ja 531", "King Grass"],
  ["Ja 532", "The Brothers Soṇa and Nanda"],
  ["Ja 533", "The Shorter Tale of the Golden Goose"],
  ["Ja 534", "The Longer Tale of the Golden Goose"],
  ["Ja 535", "An Offering of Ambrosia"],
  ["Ja 536", "The Cuckoo"],
  ["Ja 537", "The Longer Tale of Prince Moonface the Scholar"],
  ["Ja 538", "Mūgapakkha the Wise"],
  ["Ja 539", "The Longer Tale of King Janaka"],
  ["Ja 540", "Golden Sāma"],
  ["Ja 541", "Good King Nimi"],
  ["Ja 542", "The Great Tunnel"],
  ["Ja 543", "Bhuridatta the Wise"],
  ["Ja 544", "Prince Moon"],
  ["Ja 545", "The Great Tale of Nārada Kassapa"],
  ["Ja 546", "Vidhura the Wise of Kuru"],
  ["Ja 547", "The Tale of Vessantara"],
];
